"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuntimeMessageTypes = void 0;
exports.RuntimeMessageTypes = {
    GetArticle: 'get-article',
    GetSelection: 'get-selection',
    HasSelection: 'has-selection',
    GetFullPage: 'get-full-page',
    OpenPopupWithSelection: 'open-popup-with-selection',
    StartNodeSelection: 'start-node-selection',
    ToggleScreenshotMode: 'toggle-screenshot-mode',
    CaptureVisibleTab: 'capture-visible-tab',
    RunHttpRequest: 'run-http-request',
};
