"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkdownTransformers = void 0;
const markdown_1 = require("@lexical/markdown");
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
const RemoteImageNode_1 = require("./Plugins/RemoteImagePlugin/RemoteImageNode");
const HorizontalRule = {
    dependencies: [LexicalHorizontalRuleNode_1.HorizontalRuleNode],
    export: (node) => {
        return (0, LexicalHorizontalRuleNode_1.$isHorizontalRuleNode)(node) ? '***' : null;
    },
    regExp: /^(---|\*\*\*|___)\s?$/,
    replace: (parentNode, _1, _2, isImport) => {
        const line = (0, LexicalHorizontalRuleNode_1.$createHorizontalRuleNode)();
        if (isImport || parentNode.getNextSibling() != null) {
            parentNode.replace(line);
        }
        else {
            parentNode.insertBefore(line);
        }
        line.selectNext();
    },
    type: 'element',
};
const IMAGE = {
    dependencies: [RemoteImageNode_1.RemoteImageNode],
    export: (node) => {
        if (!(0, RemoteImageNode_1.$isRemoteImageNode)(node)) {
            return null;
        }
        return `![${node.__alt ? node.__alt : 'image'}](${node.__src})`;
    },
    importRegExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))/,
    regExp: /!(?:\[([^[]*)\])(?:\(([^(]+)\))$/,
    replace: (textNode, match) => {
        const [, alt, src] = match;
        const imageNode = (0, RemoteImageNode_1.$createRemoteImageNode)(src, alt);
        textNode.replace(imageNode);
    },
    trigger: ')',
    type: 'text-match',
};
exports.MarkdownTransformers = [
    markdown_1.CHECK_LIST,
    IMAGE,
    ...markdown_1.ELEMENT_TRANSFORMERS,
    ...markdown_1.TEXT_FORMAT_TRANSFORMERS,
    ...markdown_1.TEXT_MATCH_TRANSFORMERS,
    HorizontalRule,
];
