"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTitleForLinkedTag = void 0;
const snjs_1 = require("@standardnotes/snjs");
function getTitleForLinkedTag(item, application) {
    const isTag = item instanceof snjs_1.SNTag;
    if (!isTag) {
        return;
    }
    const titlePrefix = application.items.getTagPrefixTitle(item);
    const longTitle = application.items.getTagLongTitle(item);
    return {
        titlePrefix,
        longTitle,
    };
}
exports.getTitleForLinkedTag = getTitleForLinkedTag;
