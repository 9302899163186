"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalEventBus = void 0;
const InternalEventPublishStrategy_1 = require("./InternalEventPublishStrategy");
class InternalEventBus {
    constructor() {
        this.eventHandlers = new Map();
    }
    deinit() {
        ;
        this.eventHandlers = undefined;
    }
    addEventHandler(handler, eventType) {
        let handlersForEventType = this.eventHandlers.get(eventType);
        if (handlersForEventType === undefined) {
            handlersForEventType = [];
        }
        handlersForEventType.push(handler);
        this.eventHandlers.set(eventType, handlersForEventType);
    }
    publish(event) {
        const handlersForEventType = this.eventHandlers.get(event.type);
        if (handlersForEventType === undefined) {
            return;
        }
        for (const handlerForEventType of handlersForEventType) {
            void handlerForEventType.handleEvent(event);
        }
    }
    async publishSync(event, strategy) {
        const handlersForEventType = this.eventHandlers.get(event.type);
        if (handlersForEventType === undefined) {
            return;
        }
        if (strategy === InternalEventPublishStrategy_1.InternalEventPublishStrategy.SEQUENCE) {
            for (const handlerForEventType of handlersForEventType) {
                await handlerForEventType.handleEvent(event);
            }
        }
        if (strategy === InternalEventPublishStrategy_1.InternalEventPublishStrategy.ASYNC) {
            const handlerPromises = [];
            for (const handlerForEventType of handlersForEventType) {
                handlerPromises.push(handlerForEventType.handleEvent(event));
            }
            await Promise.all(handlerPromises);
        }
    }
}
exports.InternalEventBus = InternalEventBus;
