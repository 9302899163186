"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaneLayout = void 0;
var PaneLayout;
(function (PaneLayout) {
    PaneLayout["TagSelection"] = "tag-selection";
    PaneLayout["ItemSelection"] = "item-selection";
    PaneLayout["TableView"] = "files-view";
    PaneLayout["Editing"] = "editing";
})(PaneLayout || (exports.PaneLayout = PaneLayout = {}));
