"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteService = void 0;
const services_1 = require("@standardnotes/services");
const RouteParser_1 = require("./RouteParser");
const RouteServiceEvent_1 = require("./RouteServiceEvent");
class RouteService extends services_1.AbstractService {
    constructor(application, internalEventBus) {
        super(internalEventBus);
        this.application = application;
        this.internalEventBus = internalEventBus;
        this.addAppEventObserver();
    }
    deinit() {
        super.deinit();
        this.application = undefined;
        this.unsubApp();
    }
    getRoute() {
        return new RouteParser_1.RouteParser(window.location.href);
    }
    get isDotOrg() {
        return window.location.host === 'app.standardnotes.org';
    }
    removeQueryParameterFromURL(param) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete(param);
        const newUrl = `${window.location.origin}${window.location.pathname}${urlSearchParams.toString()}`;
        window.history.replaceState(null, document.title, newUrl);
    }
    addAppEventObserver() {
        this.unsubApp = this.application.addEventObserver(async (event) => {
            if (event === services_1.ApplicationEvent.LocalDataLoaded) {
                void this.notifyRouteChange();
            }
        });
    }
    notifyRouteChange() {
        void this.notifyEvent(RouteServiceEvent_1.RouteServiceEvent.RouteChanged, this.getRoute());
    }
}
exports.RouteService = RouteService;
