"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultUserService = void 0;
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("./../Service/AbstractService");
const VaultUserServiceEvent_1 = require("./VaultUserServiceEvent");
class VaultUserService extends AbstractService_1.AbstractService {
    constructor(vaults, vaultLocks, _getVaultUsers, _removeVaultMember, _isVaultOwner, _getVault, _leaveVault, eventBus) {
        super(eventBus);
        this.vaults = vaults;
        this.vaultLocks = vaultLocks;
        this._getVaultUsers = _getVaultUsers;
        this._removeVaultMember = _removeVaultMember;
        this._isVaultOwner = _isVaultOwner;
        this._getVault = _getVault;
        this._leaveVault = _leaveVault;
    }
    deinit() {
        super.deinit();
        this.vaults = undefined;
        this._getVaultUsers = undefined;
        this._removeVaultMember = undefined;
        this._isVaultOwner = undefined;
        this._getVault = undefined;
        this._leaveVault = undefined;
    }
    async getSharedVaultUsers(sharedVault) {
        const result = await this._getVaultUsers.execute({
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (result.isFailed()) {
            return undefined;
        }
        return result.getValue();
    }
    isCurrentUserSharedVaultAdmin(sharedVault) {
        return this._isVaultOwner.execute(sharedVault).getValue();
    }
    async removeUserFromSharedVault(sharedVault, userUuid) {
        if (!this.isCurrentUserSharedVaultAdmin(sharedVault)) {
            throw new Error('Only vault admins can remove users');
        }
        if (this.vaultLocks.isVaultLocked(sharedVault)) {
            throw new Error('Cannot remove user from locked vault');
        }
        const result = await this._removeVaultMember.execute({
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
            userUuid,
        });
        if (result.isFailed()) {
            return result;
        }
        void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.UsersChanged);
        await this.vaults.rotateVaultRootKey(sharedVault);
        return result;
    }
    isVaultUserOwner(user) {
        const result = this._getVault.execute({ sharedVaultUuid: user.shared_vault_uuid });
        if (result.isFailed()) {
            return false;
        }
        const vault = result.getValue();
        return vault != undefined && vault.sharing.ownerUserUuid === user.user_uuid;
    }
    async leaveSharedVault(sharedVault) {
        const result = await this._leaveVault.execute({
            sharedVault: sharedVault,
        });
        if ((0, responses_1.isClientDisplayableError)(result)) {
            return result;
        }
        void this.notifyEvent(VaultUserServiceEvent_1.VaultUserServiceEvent.UsersChanged);
    }
}
exports.VaultUserService = VaultUserService;
