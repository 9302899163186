"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutIndicator = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ui_services_1 = require("@standardnotes/ui-services");
const react_1 = require("react");
const KeyboardShortcutIndicator = ({ shortcut, className }) => {
    const addPluses = !(0, ui_services_1.isMacPlatform)(shortcut.platform);
    const spacingClass = addPluses ? '' : 'ml-0.5';
    const keys = (0, react_1.useMemo)(() => {
        const modifiers = shortcut.modifiers || [];
        const primaryKey = (shortcut.key || '').toUpperCase();
        const results = [];
        modifiers.forEach((modifier, index) => {
            results.push((0, ui_services_1.keyboardCharacterForModifier)(modifier, shortcut.platform));
            if (addPluses && (primaryKey || index !== modifiers.length - 1)) {
                results.push('+');
            }
        });
        if (primaryKey) {
            results.push(primaryKey);
        }
        return results;
    }, [shortcut, addPluses]);
    if ((0, ui_services_1.isMobilePlatform)(shortcut.platform)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: `keyboard-shortcut-indicator flex opacity-[0.35] ${className}`, children: keys.map((key, index) => {
            return ((0, jsx_runtime_1.jsx)("div", { className: index !== 0 ? spacingClass : '', children: key }, index));
        }) }));
};
exports.KeyboardShortcutIndicator = KeyboardShortcutIndicator;
