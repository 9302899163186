"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ui_services_1 = require("@standardnotes/ui-services");
const useListKeyboardNavigation_1 = require("@/Hooks/useListKeyboardNavigation");
const mergeRefs_1 = require("@/Hooks/mergeRefs");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const Menu = (0, react_1.forwardRef)(({ children, className = '', style, a11yLabel, closeMenu, initialFocus, onKeyDown, shouldAutoFocus = true, }, forwardedRef) => {
    const menuElementRef = (0, react_1.useRef)(null);
    const handleKeyDown = (0, react_1.useCallback)((event) => {
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
        if (event.key === ui_services_1.KeyboardKey.Escape) {
            closeMenu === null || closeMenu === void 0 ? void 0 : closeMenu();
            return;
        }
    }, [closeMenu, onKeyDown]);
    const isMobileScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    const { setInitialFocus } = (0, useListKeyboardNavigation_1.useListKeyboardNavigation)(menuElementRef, initialFocus, isMobileScreen ? false : shouldAutoFocus);
    (0, react_1.useImperativeHandle)(forwardedRef, () => ({
        focus: () => {
            setInitialFocus();
        },
    }));
    return ((0, jsx_runtime_1.jsx)("menu", { className: `m-0 list-none pl-0 focus:shadow-none ${className}`, onKeyDown: handleKeyDown, ref: (0, mergeRefs_1.mergeRefs)([menuElementRef, forwardedRef]), style: style, "aria-label": a11yLabel, children: children }));
});
exports.default = Menu;
