"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricDecrypt004 = void 0;
const ParseConsistentBase64JsonPayload_1 = require("../Utils/ParseConsistentBase64JsonPayload");
class AsymmetricDecrypt004 {
    constructor(crypto) {
        this.crypto = crypto;
        this.parseBase64Usecase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(dto) {
        const [_, nonce, ciphertext, additionalDataString] = dto.stringToDecrypt.split(':');
        const additionalData = this.parseBase64Usecase.execute(additionalDataString);
        try {
            const plaintext = this.crypto.sodiumCryptoBoxEasyDecrypt(ciphertext, nonce, additionalData.senderPublicKey, dto.recipientSecretKey);
            if (!plaintext) {
                return null;
            }
            const signatureVerified = this.crypto.sodiumCryptoSignVerify(ciphertext, additionalData.signingData.signature, additionalData.signingData.publicKey);
            return {
                plaintext,
                signatureVerified,
                signaturePublicKey: additionalData.signingData.publicKey,
                senderPublicKey: additionalData.senderPublicKey,
            };
        }
        catch (error) {
            return null;
        }
    }
}
exports.AsymmetricDecrypt004 = AsymmetricDecrypt004;
