"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBulletedListBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const BulletedList_1 = require("../../Blocks/BulletedList");
function GetBulletedListBlockOption(editor) {
    const block = (0, BulletedList_1.GetBulletedListBlock)(editor);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetBulletedListBlockOption = GetBulletedListBlockOption;
