"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrossControllerEvent = void 0;
var CrossControllerEvent;
(function (CrossControllerEvent) {
    CrossControllerEvent["TagChanged"] = "CrossControllerEvent:TagChanged";
    CrossControllerEvent["ActiveEditorChanged"] = "CrossControllerEvent:ActiveEditorChanged";
    CrossControllerEvent["HydrateFromPersistedValues"] = "CrossControllerEvent:HydrateFromPersistedValues";
    CrossControllerEvent["RequestValuePersistence"] = "CrossControllerEvent:RequestValuePersistence";
    CrossControllerEvent["DisplayPremiumModal"] = "CrossControllerEvent:DisplayPremiumModal";
    CrossControllerEvent["UnselectAllNotes"] = "CrossControllerEvent:UnselectAllNotes";
})(CrossControllerEvent || (exports.CrossControllerEvent = CrossControllerEvent = {}));
