"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetKeyPairs = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetKeyPairs {
    constructor(rootKeyManager) {
        this.rootKeyManager = rootKeyManager;
    }
    execute() {
        const rootKey = this.rootKeyManager.getRootKey();
        if (!(rootKey === null || rootKey === void 0 ? void 0 : rootKey.encryptionKeyPair) || !(rootKey === null || rootKey === void 0 ? void 0 : rootKey.signingKeyPair)) {
            return domain_core_1.Result.fail('Account keypair not found');
        }
        return domain_core_1.Result.ok({
            encryption: rootKey.encryptionKeyPair,
            signing: rootKey.signingKeyPair,
        });
    }
}
exports.GetKeyPairs = GetKeyPairs;
