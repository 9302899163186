"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootQueryParam = void 0;
var RootQueryParam;
(function (RootQueryParam) {
    RootQueryParam["Purchase"] = "purchase";
    RootQueryParam["Settings"] = "settings";
    RootQueryParam["DemoToken"] = "demo-token";
    RootQueryParam["AcceptSubscriptionInvite"] = "accept-subscription-invite";
    RootQueryParam["UserRequest"] = "user-request";
    RootQueryParam["AppViewRoute"] = "route";
})(RootQueryParam || (exports.RootQueryParam = RootQueryParam = {}));
