"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileIconComponent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const IconNameToSvgMapping_1 = require("@/Components/Icon/IconNameToSvgMapping");
const getFileIconComponent = (iconType, className) => {
    const IconComponent = IconNameToSvgMapping_1.IconNameToSvgMapping[iconType];
    return (0, jsx_runtime_1.jsx)(IconComponent, { className: className });
};
exports.getFileIconComponent = getFileIconComponent;
