"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readFileAsText = void 0;
const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            var _a;
            if ((_a = e.target) === null || _a === void 0 ? void 0 : _a.result) {
                resolve(e.target.result);
            }
            else {
                reject();
            }
        };
        reader.readAsText(file);
    });
};
exports.readFileAsText = readFileAsText;
