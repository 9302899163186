"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLongPressEvent = void 0;
const react_1 = require("react");
// According to https://reactnative.dev/docs/touchablewithoutfeedback#onlongpress
const ReactNativeLongpressDelay = 370;
const useLongPressEvent = (elementRef, listener, delay = ReactNativeLongpressDelay) => {
    const longPressTimeout = (0, react_1.useRef)();
    const clearLongPressTimeout = (0, react_1.useCallback)(() => {
        if (longPressTimeout.current) {
            clearTimeout(longPressTimeout.current);
        }
    }, []);
    const createLongPressTimeout = (0, react_1.useCallback)((event) => {
        clearLongPressTimeout();
        longPressTimeout.current = window.setTimeout(() => {
            const x = event.clientX;
            const y = event.clientY;
            listener(x, y);
        }, delay);
    }, [clearLongPressTimeout, delay, listener]);
    const attachEvents = (0, react_1.useCallback)(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.addEventListener('pointerdown', createLongPressTimeout);
        elementRef.current.addEventListener('pointermove', clearLongPressTimeout);
        elementRef.current.addEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.addEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, createLongPressTimeout, elementRef]);
    const cleanupEvents = (0, react_1.useCallback)(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.removeEventListener('pointerdown', createLongPressTimeout);
        elementRef.current.removeEventListener('pointermove', clearLongPressTimeout);
        elementRef.current.removeEventListener('pointercancel', clearLongPressTimeout);
        elementRef.current.removeEventListener('pointerup', clearLongPressTimeout);
    }, [clearLongPressTimeout, createLongPressTimeout, elementRef]);
    const memoizedReturn = (0, react_1.useMemo)(() => ({
        attachEvents,
        cleanupEvents,
    }), [attachEvents, cleanupEvents]);
    return memoizedReturn;
};
exports.useLongPressEvent = useLongPressEvent;
