"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveItemsFromMemory = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
class RemoveItemsFromMemory {
    constructor(storage, items, payloads) {
        this.storage = storage;
        this.items = items;
        this.payloads = payloads;
    }
    async execute(items) {
        this.items.removeItemsFromMemory(items);
        const rawPayloads = await this.storage.getRawPayloads((0, utils_1.Uuids)(items));
        const encryptedPayloads = rawPayloads.map((payload) => (0, models_1.CreatePayload)(payload, models_1.PayloadSource.LocalDatabaseLoaded));
        await this.payloads.emitPayloads(encryptedPayloads, models_1.PayloadEmitSource.LocalDatabaseLoaded);
        return domain_core_1.Result.ok();
    }
}
exports.RemoveItemsFromMemory = RemoveItemsFromMemory;
