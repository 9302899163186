"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAutoElementRect = void 0;
const react_1 = require("react");
const DebounceTimeInMs = 100;
/**
 * Returns the bounding rect of an element, auto-updated when the element resizes.
 * Can optionally be auto-update on window resize.
 */
const useAutoElementRect = (element, { updateOnWindowResize } = { updateOnWindowResize: false }) => {
    const [rect, setRect] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        let windowResizeDebounceTimeout;
        let windowResizeHandler;
        if (element) {
            const resizeObserver = new ResizeObserver(() => {
                setRect(element.getBoundingClientRect());
            });
            resizeObserver.observe(element);
            if (updateOnWindowResize) {
                windowResizeHandler = () => {
                    window.clearTimeout(windowResizeDebounceTimeout);
                    window.setTimeout(() => {
                        setRect(element.getBoundingClientRect());
                    }, DebounceTimeInMs);
                };
                window.addEventListener('resize', windowResizeHandler);
            }
            return () => {
                resizeObserver.unobserve(element);
                if (windowResizeHandler) {
                    window.removeEventListener('resize', windowResizeHandler);
                }
            };
        }
        else {
            setRect(undefined);
            return;
        }
    }, [element, updateOnWindowResize]);
    return rect;
};
exports.useAutoElementRect = useAutoElementRect;
