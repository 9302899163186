"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeContentCallbackPlugin = void 0;
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const react_1 = require("react");
function ChangeContentCallbackPlugin({ providerCallback, }) {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        const changeContents = (jsonContent) => {
            editor.update(() => {
                const editorState = editor.parseEditorState(jsonContent);
                editor.setEditorState(editorState);
            });
        };
        providerCallback(changeContents);
    }, [editor, providerCallback]);
    return null;
}
exports.ChangeContentCallbackPlugin = ChangeContentCallbackPlugin;
