"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOutboundMessages = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class GetOutboundMessages {
    constructor(messageServer) {
        this.messageServer = messageServer;
    }
    async execute() {
        const response = await this.messageServer.getOutboundUserMessages();
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail((0, responses_1.getErrorFromErrorResponse)(response).message);
        }
        return domain_core_1.Result.ok(response.data.messages);
    }
}
exports.GetOutboundMessages = GetOutboundMessages;
