"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetHost = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class SetHost {
    constructor(http, legacyApi) {
        this.http = http;
        this.legacyApi = legacyApi;
    }
    async execute(host) {
        this.http.setHost(host);
        await this.legacyApi.setHost(host);
        return domain_core_1.Result.ok();
    }
}
exports.SetHost = SetHost;
