"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleRootKeyChangedMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class HandleRootKeyChangedMessage {
    constructor(mutator, sync, _getVault, _decryptErroredPayloads) {
        this.mutator = mutator;
        this.sync = sync;
        this._getVault = _getVault;
        this._decryptErroredPayloads = _decryptErroredPayloads;
    }
    async execute(message) {
        const rootKeyContent = message.data.rootKey;
        await this.mutator.createItem(domain_core_1.ContentType.TYPES.KeySystemRootKey, (0, models_1.FillItemContent)(rootKeyContent), true);
        const vault = this._getVault.execute({
            keySystemIdentifier: rootKeyContent.systemIdentifier,
        });
        if (!vault.isFailed()) {
            await this.mutator.changeItem(vault.getValue(), (mutator) => {
                mutator.rootKeyParams = rootKeyContent.keyParams;
            });
        }
        await this._decryptErroredPayloads.execute();
        void this.sync.sync({ sourceDescription: 'Not awaiting due to this event handler running from sync response' });
    }
}
exports.HandleRootKeyChangedMessage = HandleRootKeyChangedMessage;
