"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassicFileSaver = void 0;
const utils_1 = require("../utils");
class ClassicFileSaver {
    constructor() {
        this.loggingEnabled = false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(...args) {
        if (!this.loggingEnabled) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(args);
    }
    static maximumFileSize() {
        return 50 * 1000000;
    }
    saveFile(name, bytes) {
        this.log('Saving file to disk...');
        (0, utils_1.saveFile)(name, bytes);
        this.log('Closing write stream');
    }
}
exports.ClassicFileSaver = ClassicFileSaver;
