"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteAttributes = exports.useNoteAttributes = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const snjs_1 = require("@standardnotes/snjs");
const DateUtils_1 = require("@/Utils/DateUtils");
const calculateReadTime_1 = require("./Utils/calculateReadTime");
const countNoteAttributes_1 = require("./Utils/countNoteAttributes");
const useNoteAttributes = (application, note) => {
    const { words, characters, paragraphs } = (0, react_1.useMemo)(() => (0, countNoteAttributes_1.countNoteAttributes)(note.text), [note.text]);
    const readTime = (0, react_1.useMemo)(() => (typeof words === 'number' ? (0, calculateReadTime_1.calculateReadTime)(words) : 'N/A'), [words]);
    const dateLastModified = (0, react_1.useMemo)(() => (0, DateUtils_1.formatDateForContextMenu)(note.userModifiedDate), [note.userModifiedDate]);
    const dateCreated = (0, react_1.useMemo)(() => (0, DateUtils_1.formatDateForContextMenu)(note.created_at), [note.created_at]);
    const editor = application.componentManager.editorForNote(note);
    const format = editor.fileType;
    return {
        words,
        characters,
        paragraphs,
        readTime,
        dateLastModified,
        dateCreated,
        format,
    };
};
exports.useNoteAttributes = useNoteAttributes;
const NoteAttributes = ({ application, note, className }) => {
    const { words, characters, paragraphs, readTime, dateLastModified, dateCreated, format } = (0, exports.useNoteAttributes)(application, note);
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, snjs_1.classNames)('select-text px-3 py-1.5 text-sm font-medium text-neutral lg:text-xs', className), children: [typeof words === 'number' && (format === 'txt' || format === 'md') ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", { className: "mb-1", children: [words, " words \u00B7 ", characters, " characters \u00B7 ", paragraphs, " paragraphs"] }), (0, jsx_runtime_1.jsxs)("div", { className: "mb-1", children: [(0, jsx_runtime_1.jsx)("span", { className: "font-semibold", children: "Read time:" }), " ", readTime] })] })) : null, (0, jsx_runtime_1.jsxs)("div", { className: "mb-1", children: [(0, jsx_runtime_1.jsx)("span", { className: "font-semibold", children: "Last modified:" }), " ", dateLastModified] }), (0, jsx_runtime_1.jsxs)("div", { className: "mb-1", children: [(0, jsx_runtime_1.jsx)("span", { className: "font-semibold", children: "Created:" }), " ", dateCreated] }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("span", { className: "font-semibold", children: "Note ID:" }), " ", note.uuid] })] }));
};
exports.NoteAttributes = NoteAttributes;
