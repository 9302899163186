"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindContact = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class FindContact {
    constructor(items) {
        this.items = items;
    }
    execute(query) {
        if ('userUuid' in query && query.userUuid) {
            const contacts = this.items.itemsMatchingPredicate(domain_core_1.ContentType.TYPES.TrustedContact, new models_1.Predicate('contactUuid', '=', query.userUuid));
            if (contacts.length === 0) {
                return domain_core_1.Result.fail(`Contact not found for user ${query.userUuid}`);
            }
            if (contacts.length > 1) {
                return domain_core_1.Result.fail(`Multiple contacts found for user ${query.userUuid}`);
            }
            return domain_core_1.Result.ok(contacts[0]);
        }
        if ('signingPublicKey' in query && query.signingPublicKey) {
            const allContacts = this.items.getItems(domain_core_1.ContentType.TYPES.TrustedContact);
            const contact = allContacts.find((contact) => contact.hasCurrentOrPreviousSigningPublicKey(query.signingPublicKey));
            if (contact) {
                return domain_core_1.Result.ok(contact);
            }
            else {
                return domain_core_1.Result.fail('Contact not found');
            }
        }
        throw new Error('Invalid query');
    }
}
exports.FindContact = FindContact;
