"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBackupFileType = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
class GetBackupFileType {
    execute(file, payloads) {
        if (file.keyParams || file.auth_params) {
            return domain_core_1.Result.ok(encryption_1.BackupFileType.Encrypted);
        }
        const hasEncryptedItem = payloads.find(models_1.isEncryptedPayload);
        const hasDecryptedItemsKey = payloads.find((payload) => payload.content_type === domain_core_1.ContentType.TYPES.ItemsKey && (0, models_1.isDecryptedPayload)(payload));
        if (hasEncryptedItem && hasDecryptedItemsKey) {
            return domain_core_1.Result.ok(encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey);
        }
        else if (!hasEncryptedItem) {
            return domain_core_1.Result.ok(encryption_1.BackupFileType.FullyDecrypted);
        }
        else {
            return domain_core_1.Result.ok(encryption_1.BackupFileType.Corrupt);
        }
    }
}
exports.GetBackupFileType = GetBackupFileType;
