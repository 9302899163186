"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultInviteService = void 0;
const SyncEvent_1 = require("./../Event/SyncEvent");
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("./../Service/AbstractService");
const VaultInviteServiceEvent_1 = require("./VaultInviteServiceEvent");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultInviteService extends AbstractService_1.AbstractService {
    constructor(items, session, vaultUsers, sync, invitesServer, _getAllContacts, _getVault, _getVaultContacts, _inviteToVault, _getTrustedPayload, _getUntrustedPayload, _findContact, _acceptVaultInvite, _getKeyPairs, _decryptErroredPayloads, eventBus) {
        super(eventBus);
        this.session = session;
        this.vaultUsers = vaultUsers;
        this.sync = sync;
        this.invitesServer = invitesServer;
        this._getAllContacts = _getAllContacts;
        this._getVault = _getVault;
        this._getVaultContacts = _getVaultContacts;
        this._inviteToVault = _inviteToVault;
        this._getTrustedPayload = _getTrustedPayload;
        this._getUntrustedPayload = _getUntrustedPayload;
        this._findContact = _findContact;
        this._acceptVaultInvite = _acceptVaultInvite;
        this._getKeyPairs = _getKeyPairs;
        this._decryptErroredPayloads = _decryptErroredPayloads;
        this.pendingInvites = {};
        this.eventDisposers.push(items.addObserver(domain_core_1.ContentType.TYPES.TrustedContact, async ({ inserted, source }) => {
            if (source === models_1.PayloadEmitSource.LocalChanged && inserted.length > 0) {
                void this.downloadInboundInvites();
            }
            await this.reprocessCachedInvitesTrustStatusAfterTrustedContactsChange();
        }));
    }
    deinit() {
        super.deinit();
        this.session = undefined;
        this.vaultUsers = undefined;
        this.sync = undefined;
        this.invitesServer = undefined;
        this._getAllContacts = undefined;
        this._getVault = undefined;
        this._getVaultContacts = undefined;
        this._inviteToVault = undefined;
        this._getTrustedPayload = undefined;
        this._getUntrustedPayload = undefined;
        this._findContact = undefined;
        this._acceptVaultInvite = undefined;
        this._getKeyPairs = undefined;
        this._decryptErroredPayloads = undefined;
        this.pendingInvites = {};
    }
    async handleEvent(event) {
        switch (event.type) {
            case SyncEvent_1.SyncEvent.ReceivedSharedVaultInvites:
                await this.processInboundInvites(event.payload);
                break;
        }
    }
    getCachedPendingInviteRecords() {
        return Object.values(this.pendingInvites);
    }
    async downloadInboundInvites() {
        const response = await this.invitesServer.getInboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to get inbound user invites ${JSON.stringify(response)}`);
        }
        this.pendingInvites = {};
        await this.processInboundInvites(response.data.invites);
        return response.data.invites;
    }
    async getOutboundInvites(sharedVault) {
        const response = await this.invitesServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to get outbound user invites ${JSON.stringify(response)}`);
        }
        if (sharedVault) {
            return response.data.invites.filter((invite) => invite.shared_vault_uuid === sharedVault.sharing.sharedVaultUuid);
        }
        return response.data.invites;
    }
    async acceptInvite(pendingInvite) {
        if (!pendingInvite.trusted) {
            throw new Error('Cannot accept untrusted invite');
        }
        await this._acceptVaultInvite.execute({ invite: pendingInvite.invite, message: pendingInvite.message });
        delete this.pendingInvites[pendingInvite.invite.uuid];
        void this.sync.sync();
        await this._decryptErroredPayloads.execute();
        await this.sync.syncSharedVaultsFromScratch([pendingInvite.invite.shared_vault_uuid]);
    }
    async getInvitableContactsForSharedVault(sharedVault) {
        const users = await this.vaultUsers.getSharedVaultUsers(sharedVault);
        if (!users) {
            return [];
        }
        const contacts = this._getAllContacts.execute();
        if (contacts.isFailed()) {
            return [];
        }
        const outboundInvites = await this.getOutboundInvites(sharedVault);
        if ((0, responses_1.isClientDisplayableError)(outboundInvites)) {
            return [];
        }
        return contacts.getValue().filter((contact) => {
            const isContactAlreadyInVault = users.some((user) => user.user_uuid === contact.contactUuid);
            const isContactAlreadyInvited = outboundInvites.some((invite) => invite.user_uuid === contact.contactUuid);
            return !isContactAlreadyInVault && !isContactAlreadyInvited;
        });
    }
    async inviteContactToSharedVault(sharedVault, contact, permission) {
        const contactsResult = await this._getVaultContacts.execute({
            sharedVaultUuid: sharedVault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (contactsResult.isFailed()) {
            return domain_core_1.Result.fail(contactsResult.getError());
        }
        const contacts = contactsResult.getValue();
        const result = await this._inviteToVault.execute({
            sharedVault,
            recipient: contact,
            sharedVaultContacts: contacts,
            permission,
        });
        if (result.isFailed()) {
            return domain_core_1.Result.fail(result.getError());
        }
        void this.notifyEvent(VaultInviteServiceEvent_1.VaultInviteServiceEvent.InviteSent);
        await this.sync.sync();
        return result;
    }
    isVaultUserOwner(user) {
        const result = this._getVault.execute({ sharedVaultUuid: user.shared_vault_uuid });
        if (result.isFailed()) {
            return false;
        }
        const vault = result.getValue();
        return vault != undefined && vault.sharing.ownerUserUuid === user.user_uuid;
    }
    async deleteInvite(invite) {
        const response = await this.invitesServer.deleteInvite({
            sharedVaultUuid: invite.shared_vault_uuid,
            inviteUuid: invite.uuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to delete invite ${JSON.stringify(response)}`);
        }
        delete this.pendingInvites[invite.uuid];
    }
    async reprocessCachedInvitesTrustStatusAfterTrustedContactsChange() {
        const cachedInvites = this.getCachedPendingInviteRecords().map((record) => record.invite);
        await this.processInboundInvites(cachedInvites);
    }
    async processInboundInvites(invites) {
        if (invites.length === 0) {
            return;
        }
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return;
        }
        for (const invite of invites) {
            delete this.pendingInvites[invite.uuid];
            const sender = this._findContact.execute({ userUuid: invite.sender_uuid });
            if (!sender.isFailed()) {
                const trustedMessage = this._getTrustedPayload.execute({
                    message: invite,
                    privateKey: keys.getValue().encryption.privateKey,
                    ownUserUuid: this.session.userUuid,
                    sender: sender.getValue(),
                });
                if (!trustedMessage.isFailed()) {
                    this.pendingInvites[invite.uuid] = {
                        invite,
                        message: trustedMessage.getValue(),
                        trusted: true,
                    };
                    continue;
                }
            }
            const untrustedMessage = this._getUntrustedPayload.execute({
                message: invite,
                privateKey: keys.getValue().encryption.privateKey,
            });
            if (!untrustedMessage.isFailed()) {
                this.pendingInvites[invite.uuid] = {
                    invite,
                    message: untrustedMessage.getValue(),
                    trusted: false,
                };
            }
        }
        void this.notifyEvent(VaultInviteServiceEvent_1.VaultInviteServiceEvent.InvitesReloaded);
    }
}
exports.VaultInviteService = VaultInviteService;
