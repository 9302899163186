"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultLockService = void 0;
const models_1 = require("@standardnotes/models");
const VaultLockServiceEvent_1 = require("./VaultLockServiceEvent");
const AbstractService_1 = require("../Service/AbstractService");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultLockService extends AbstractService_1.AbstractService {
    constructor(items, encryption, keys, _getVaults, _decryptErroredPayloads, _removeItemsFromMemory, _getVaultItems, eventBus) {
        super(eventBus);
        this.encryption = encryption;
        this.keys = keys;
        this._getVaults = _getVaults;
        this._decryptErroredPayloads = _decryptErroredPayloads;
        this._removeItemsFromMemory = _removeItemsFromMemory;
        this._getVaultItems = _getVaultItems;
        this.lockMap = new Map();
        this.recomputeAllVaultsLockingState = async () => {
            const vaults = this._getVaults.execute().getValue();
            for (const vault of vaults) {
                const locked = this.computeVaultLockState(vault) === 'locked';
                if (this.lockMap.get(vault.uuid) !== locked) {
                    this.lockMap.set(vault.uuid, locked);
                    if (locked) {
                        void this.notifyEvent(VaultLockServiceEvent_1.VaultLockServiceEvent.VaultLocked, { vault });
                    }
                    else {
                        void this.notifyEvent(VaultLockServiceEvent_1.VaultLockServiceEvent.VaultUnlocked, { vault });
                    }
                }
            }
        };
        items.addObserver([domain_core_1.ContentType.TYPES.KeySystemItemsKey, domain_core_1.ContentType.TYPES.KeySystemRootKey, domain_core_1.ContentType.TYPES.VaultListing], () => {
            void this.recomputeAllVaultsLockingState();
        });
    }
    deinit() {
        super.deinit();
        this.encryption = undefined;
        this.keys = undefined;
        this._getVaults = undefined;
        this._decryptErroredPayloads = undefined;
        this._removeItemsFromMemory = undefined;
        this._getVaultItems = undefined;
        this.lockMap.clear();
    }
    getLockedvaults() {
        const vaults = this._getVaults.execute().getValue();
        return vaults.filter((vault) => this.isVaultLocked(vault));
    }
    isVaultLocked(vault) {
        return this.lockMap.get(vault.uuid) === true;
    }
    isVaultLockable(vault) {
        return vault.keyPasswordType === models_1.KeySystemPasswordType.UserInputted;
    }
    async lockNonPersistentVault(vault) {
        if (vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            throw new Error('Vault uses synced key storage and cannot be locked');
        }
        if (vault.keyPasswordType !== models_1.KeySystemPasswordType.UserInputted) {
            throw new Error('Vault uses randomized password and cannot be locked');
        }
        await this.keys.wipeVaultKeysFromMemory(vault);
        const vaultItems = this._getVaultItems.execute(vault).getValue();
        await this._removeItemsFromMemory.execute(vaultItems);
        this.lockMap.set(vault.uuid, true);
        void this.notifyEventSync(VaultLockServiceEvent_1.VaultLockServiceEvent.VaultLocked, { vault });
    }
    async unlockNonPersistentVault(vault, password) {
        if (vault.keyPasswordType !== models_1.KeySystemPasswordType.UserInputted) {
            throw new Error('Vault uses randomized password and cannot be unlocked with user inputted password');
        }
        if (vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            throw new Error('Vault uses synced root key and cannot be unlocked with user inputted password');
        }
        const derivedRootKey = this.encryption.deriveUserInputtedKeySystemRootKey({
            keyParams: vault.rootKeyParams,
            userInputtedPassword: password,
        });
        this.keys.cacheKey(derivedRootKey, vault.keyStorageMode);
        await this._decryptErroredPayloads.execute();
        if (this.computeVaultLockState(vault) === 'locked') {
            this.keys.removeKeyFromCache(vault.systemIdentifier);
            return false;
        }
        this.lockMap.set(vault.uuid, false);
        void this.notifyEventSync(VaultLockServiceEvent_1.VaultLockServiceEvent.VaultUnlocked, { vault });
        return true;
    }
    computeVaultLockState(vault) {
        const rootKey = this.keys.getPrimaryKeySystemRootKey(vault.systemIdentifier);
        if (!rootKey) {
            return 'locked';
        }
        const itemsKey = this.keys.getPrimaryKeySystemItemsKey(vault.systemIdentifier);
        if (!itemsKey) {
            return 'locked';
        }
        return 'unlocked';
    }
}
exports.VaultLockService = VaultLockService;
