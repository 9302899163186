"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doListItemPropsMeritRerender = void 0;
const snjs_1 = require("@standardnotes/snjs");
function doListItemPropsMeritRerender(previous, next) {
    const simpleComparison = [
        'onSelect',
        'hideDate',
        'hideIcon',
        'hideTags',
        'hidePreview',
        'selected',
        'sortBy',
        'isPreviousItemTiled',
        'isNextItemTiled',
    ];
    for (const key of simpleComparison) {
        if (previous[key] !== next[key]) {
            return true;
        }
    }
    if (previous['item'] !== next['item']) {
        if (doesItemChangeMeritRerender(previous['item'], next['item'])) {
            return true;
        }
    }
    return doesTagsChangeMeritRerender(previous['tags'], next['tags']);
}
exports.doListItemPropsMeritRerender = doListItemPropsMeritRerender;
function doesTagsChangeMeritRerender(previous, next) {
    if (previous === next) {
        return false;
    }
    if (previous.length !== next.length) {
        return true;
    }
    if (previous.length === 0 && next.length === 0) {
        return false;
    }
    if ((0, snjs_1.Uuids)(previous).sort().join() !== (0, snjs_1.Uuids)(next).sort().join()) {
        return true;
    }
    if (previous
        .map((t) => t.title)
        .sort()
        .join() !==
        next
            .map((t) => t.title)
            .sort()
            .join()) {
        return true;
    }
    return false;
}
function doesItemChangeMeritRerender(previous, next) {
    if (previous.uuid !== next.uuid) {
        return true;
    }
    const propertiesMeritingRerender = [
        'title',
        'protected',
        'updatedAtString',
        'createdAtString',
        'hidePreview',
        'preview_html',
        'preview_plain',
        'archived',
        'starred',
        'pinned',
    ];
    for (const key of propertiesMeritingRerender) {
        if (previous[key] !== next[key]) {
            return true;
        }
    }
    return false;
}
