"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeMonths = exports.insertMonthsWithTarget = exports.insertMonths = exports.getStartDayOfMonth = exports.isLeapYear = exports.createActivityRecord = exports.dateToDateOnlyString = void 0;
const DateUtils_1 = require("@/Utils/DateUtils");
function dateToDateOnlyString(date) {
    return date.toLocaleDateString();
}
exports.dateToDateOnlyString = dateToDateOnlyString;
function createActivityRecord(activities) {
    const map = {};
    for (const activity of activities) {
        const string = dateToDateOnlyString(activity.date);
        if (!map[string]) {
            map[string] = [];
        }
        map[string].push(activity);
    }
    return map;
}
exports.createActivityRecord = createActivityRecord;
function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
exports.isLeapYear = isLeapYear;
function getStartDayOfMonth(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return startDate === 0 ? 7 : startDate;
}
exports.getStartDayOfMonth = getStartDayOfMonth;
/**
 * Modifies months array in-place.
 */
function insertMonths(months, location, number) {
    const earlierMonth = months[0].date;
    const laterMonth = months[months.length - 1].date;
    for (let i = 1; i <= number; i++) {
        if (location === 'front') {
            const minusNFromFirstMonth = (0, DateUtils_1.addCalendarMonths)(earlierMonth, -i);
            months.unshift({
                date: minusNFromFirstMonth,
            });
        }
        else {
            const plusNFromLastMonth = (0, DateUtils_1.addCalendarMonths)(laterMonth, i);
            months.push({
                date: plusNFromLastMonth,
            });
        }
    }
    return months;
}
exports.insertMonths = insertMonths;
/**
 * Modifies months array in-place.
 */
function insertMonthsWithTarget(months, targetMonth) {
    const firstMonth = months[0].date;
    const lastMonth = months[months.length - 1].date;
    const isBeforeFirstMonth = targetMonth.getTime() < firstMonth.getTime();
    const numMonthsToAdd = Math.abs(isBeforeFirstMonth
        ? (0, DateUtils_1.numberOfMonthsBetweenDates)(firstMonth, targetMonth)
        : (0, DateUtils_1.numberOfMonthsBetweenDates)(lastMonth, targetMonth));
    if (isBeforeFirstMonth) {
        return insertMonths(months, 'front', numMonthsToAdd);
    }
    else {
        return insertMonths(months, 'end', numMonthsToAdd);
    }
}
exports.insertMonthsWithTarget = insertMonthsWithTarget;
/**
 * Modifies months array in-place.
 */
function removeMonths(months, location, number) {
    if (location === 'front') {
        months.splice(0, number);
    }
    else {
        months.splice(months.length - number - 1, number);
    }
}
exports.removeMonths = removeMonths;
