"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptBackupFile = void 0;
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
class DecryptBackupFile {
    constructor(encryption, keys, _getBackupFileType, _decryptBackupPayloads) {
        this.encryption = encryption;
        this.keys = keys;
        this._getBackupFileType = _getBackupFileType;
        this._decryptBackupPayloads = _decryptBackupPayloads;
    }
    async execute(file, password) {
        const payloads = this.convertToPayloads(file);
        const type = this._getBackupFileType.execute(file, payloads).getValue();
        if (type === encryption_1.BackupFileType.Corrupt) {
            return domain_core_1.Result.fail('Invalid backup file.');
        }
        const { encrypted, decrypted } = (0, models_1.CreatePayloadSplit)(payloads);
        if (type === encryption_1.BackupFileType.FullyDecrypted) {
            return domain_core_1.Result.ok([...decrypted, ...encrypted]);
        }
        if (type === encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey) {
            const result = await this.handleEncryptedWithNonEncryptedItemsKeyFileType(payloads);
            if (result.isFailed()) {
                return domain_core_1.Result.fail(result.getError());
            }
            return domain_core_1.Result.ok([...decrypted, ...result.getValue()]);
        }
        if (!password) {
            throw Error('Attempting to decrypt encrypted file with no password');
        }
        const results = await this.handleEncryptedFileType({
            payloads: encrypted,
            file,
            password,
        });
        if (results.isFailed()) {
            return domain_core_1.Result.fail(results.getError());
        }
        return domain_core_1.Result.ok([...decrypted, ...results.getValue()]);
    }
    /** This is a backup file made from a session which had an encryption source, such as an account or a passcode. */
    async handleEncryptedFileType(dto) {
        var _a;
        const keyParams = (0, encryption_1.CreateAnyKeyParams)((dto.file.keyParams || dto.file.auth_params));
        const rootKey = await this.encryption.computeRootKey(dto.password, keyParams);
        const results = [];
        const { rootKeyEncryption, itemsKeyEncryption, keySystemRootKeyEncryption } = (0, encryption_1.SplitPayloadsByEncryptionType)(dto.payloads);
        /** Decrypts items encrypted with a user root key, such as contacts, synced vault root keys, and items keys */
        const rootKeyBasedDecryptionResults = await this.encryption.decryptSplit({
            usesRootKey: {
                items: rootKeyEncryption || [],
                key: rootKey,
            },
        });
        /** Extract items keys and synced vault root keys from root key decryption results */
        const recentlyDecryptedKeys = rootKeyBasedDecryptionResults
            .filter((x) => (0, encryption_1.isItemsKey)(x) || (0, models_1.isKeySystemRootKey)(x))
            .filter(models_1.isDecryptedPayload)
            .map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p));
        /**
         * Now handle encrypted keySystemRootKeyEncryption items (vault items keys).  For every encrypted vault items key
         * find the respective vault root key, either from recently decrypted above, or from the key manager. Decrypt the
         * vault items key, and if successful, add it to recentlyDecryptedKeys so that it can be used to decrypt subsequent items
         */
        for (const payload of keySystemRootKeyEncryption !== null && keySystemRootKeyEncryption !== void 0 ? keySystemRootKeyEncryption : []) {
            if (!payload.key_system_identifier) {
                throw new Error('Attempting to decrypt key system root key encrypted payload with no key system identifier');
            }
            const keys = rootKeyBasedDecryptionResults
                .filter(models_1.isDecryptedPayload)
                .filter(models_1.isKeySystemRootKey)
                .map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p));
            const key = (_a = keys.find((k) => k.systemIdentifier === payload.key_system_identifier)) !== null && _a !== void 0 ? _a : this.keys.getPrimaryKeySystemRootKey(payload.key_system_identifier);
            if (!key) {
                results.push(payload.copy({
                    errorDecrypting: true,
                }));
                continue;
            }
            const result = await this.encryption.decryptSplitSingle({
                usesKeySystemRootKey: {
                    items: [payload],
                    key,
                },
            });
            if ((0, models_1.isDecryptedPayload)(result) && (0, encryption_1.isKeySystemItemsKey)(result)) {
                recentlyDecryptedKeys.push((0, models_1.CreateDecryptedItemFromPayload)(result));
            }
            results.push(result);
        }
        (0, utils_1.extendArray)(results, rootKeyBasedDecryptionResults);
        const payloadsToDecrypt = [...(itemsKeyEncryption !== null && itemsKeyEncryption !== void 0 ? itemsKeyEncryption : [])];
        const decryptedPayloads = await this._decryptBackupPayloads.execute({
            payloads: payloadsToDecrypt,
            recentlyDecryptedKeys: recentlyDecryptedKeys,
            keyParams: keyParams,
            rootKey: rootKey,
        });
        if (decryptedPayloads.isFailed()) {
            return domain_core_1.Result.fail(decryptedPayloads.getError());
        }
        (0, utils_1.extendArray)(results, decryptedPayloads.getValue());
        return domain_core_1.Result.ok(results);
    }
    /**
     * These are backup files made when not signed into an account and without an encryption source such as a passcode.
     * In this case the items key exists in the backup in plaintext, but the items are encrypted with this items key.
     */
    async handleEncryptedWithNonEncryptedItemsKeyFileType(payloads) {
        const decryptedItemsKeys = [];
        const encryptedPayloads = [];
        payloads.forEach((payload) => {
            if (payload.content_type === domain_core_1.ContentType.TYPES.ItemsKey && (0, models_1.isDecryptedPayload)(payload)) {
                decryptedItemsKeys.push(payload);
            }
            else if ((0, models_1.isEncryptedPayload)(payload)) {
                encryptedPayloads.push(payload);
            }
        });
        const itemsKeys = decryptedItemsKeys.map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p));
        return this._decryptBackupPayloads.execute({
            payloads: encryptedPayloads,
            recentlyDecryptedKeys: itemsKeys,
            rootKey: undefined,
        });
    }
    convertToPayloads(file) {
        return file.items.map((item) => {
            if ((0, models_1.isEncryptedTransferPayload)(item)) {
                return new models_1.EncryptedPayload(item);
            }
            else if ((0, models_1.isDecryptedTransferPayload)(item)) {
                return new models_1.DecryptedPayload(item);
            }
            else {
                throw Error('Unhandled case in DecryptBackupFile');
            }
        });
    }
}
exports.DecryptBackupFile = DecryptBackupFile;
