"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlaintextFontSize = void 0;
const Utils_1 = require("@/Utils");
const getPlaintextFontSize = (key) => {
    const desktopMapping = {
        ExtraSmall: 'text-xs',
        Small: 'text-sm',
        Normal: 'text-editor',
        Medium: 'text-lg',
        Large: 'text-xl',
    };
    const mobileMapping = {
        ExtraSmall: 'text-sm',
        Small: 'text-editor',
        Normal: 'text-lg',
        Medium: 'text-xl',
        Large: 'text-2xl',
    };
    const tabletMapping = {
        ExtraSmall: 'text-sm',
        Small: 'text-editor',
        Normal: 'text-base',
        Medium: 'text-xl',
        Large: 'text-2xl',
    };
    if ((0, Utils_1.isTabletScreen)()) {
        return tabletMapping[key];
    }
    return (0, Utils_1.isMobileScreen)() ? mobileMapping[key] : desktopMapping[key];
};
exports.getPlaintextFontSize = getPlaintextFontSize;
