"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQuoteBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const rich_text_1 = require("@lexical/rich-text");
function GetQuoteBlock(editor) {
    return {
        name: 'Quote',
        iconName: 'quote',
        keywords: ['block quote'],
        onSelect: () => editor.update(() => {
            const selection = (0, lexical_1.$getSelection)();
            if ((0, lexical_1.$isRangeSelection)(selection)) {
                (0, selection_1.$wrapNodes)(selection, () => (0, rich_text_1.$createQuoteNode)());
            }
        }),
    };
}
exports.GetQuoteBlock = GetQuoteBlock;
