"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileNode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const LexicalDecoratorBlockNode_1 = require("@lexical/react/LexicalDecoratorBlockNode");
const FileUtils_1 = require("./FileUtils");
const FileComponent_1 = require("./FileComponent");
class FileNode extends LexicalDecoratorBlockNode_1.DecoratorBlockNode {
    static getType() {
        return 'snfile';
    }
    static clone(node) {
        return new FileNode(node.__id, node.__format, node.__key, node.__zoomLevel);
    }
    static importJSON(serializedNode) {
        const node = (0, FileUtils_1.$createFileNode)(serializedNode.fileUuid);
        node.setFormat(serializedNode.format);
        node.setZoomLevel(serializedNode.zoomLevel);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            fileUuid: this.getId(),
            version: 1,
            type: 'snfile',
            zoomLevel: this.__zoomLevel,
        };
    }
    static importDOM() {
        return {
            div: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-file-uuid')) {
                    return null;
                }
                return {
                    conversion: FileUtils_1.convertToFileElement,
                    priority: 2,
                };
            },
        };
    }
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-file-uuid', this.__id);
        const text = document.createTextNode(this.getTextContent());
        element.append(text);
        return { element };
    }
    constructor(id, format, key, zoomLevel) {
        super(format, key);
        this.__id = id;
        this.__zoomLevel = zoomLevel || 100;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `[File: ${this.__id}]`;
    }
    setZoomLevel(zoomLevel) {
        const writable = this.getWritable();
        writable.__zoomLevel = zoomLevel;
    }
    decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return ((0, jsx_runtime_1.jsx)(FileComponent_1.FileComponent, { className: className, format: this.__format, nodeKey: this.getKey(), fileUuid: this.__id, zoomLevel: this.__zoomLevel, setZoomLevel: this.setZoomLevel.bind(this) }));
    }
}
exports.FileNode = FileNode;
