"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisibilityObserver = void 0;
const snjs_1 = require("@standardnotes/snjs");
class VisibilityObserver {
    constructor(onEvent) {
        this.onEvent = onEvent;
        this.onVisibilityChange = () => {
            const visible = document.visibilityState === 'visible';
            const event = visible ? snjs_1.WebAppEvent.WindowDidFocus : snjs_1.WebAppEvent.WindowDidBlur;
            this.notifyEvent(event);
        };
        this.onFocusEvent = () => {
            this.notifyEvent(snjs_1.WebAppEvent.WindowDidFocus);
        };
        /**
         * Browsers may handle focus and visibilitychange events differently.
         * Focus better handles window focus events but may not handle tab switching.
         * We will listen for both and debouce notifying so that the most recent event wins.
         */
        document.addEventListener('visibilitychange', this.onVisibilityChange);
        window.addEventListener('focus', this.onFocusEvent, false);
    }
    notifyEvent(event) {
        if (this.raceTimeout) {
            clearTimeout(this.raceTimeout);
        }
        this.raceTimeout = setTimeout(() => {
            this.onEvent(event);
        }, 250);
    }
    deinit() {
        document.removeEventListener('visibilitychange', this.onVisibilityChange);
        window.removeEventListener('focus', this.onFocusEvent);
        this.onEvent = undefined;
    }
}
exports.VisibilityObserver = VisibilityObserver;
