"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTrustedPayload = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetTrustedPayload {
    constructor(decryptMessage) {
        this.decryptMessage = decryptMessage;
    }
    execute(dto) {
        const result = this.decryptMessage.execute({
            message: dto.message.encrypted_message,
            sender: dto.sender,
            privateKey: dto.privateKey,
        });
        if (result.isFailed()) {
            return result;
        }
        const recipientUuid = result.getValue().data.recipientUuid;
        if (recipientUuid !== dto.ownUserUuid) {
            return domain_core_1.Result.fail('Message is not for this user');
        }
        return result;
    }
}
exports.GetTrustedPayload = GetTrustedPayload;
