"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMemoryCache = void 0;
const utils_1 = require("@standardnotes/utils");
class FileMemoryCache {
    constructor(maxSize) {
        this.maxSize = maxSize;
        this.cache = {};
        this.orderedQueue = [];
    }
    add(uuid, data) {
        if (data.encryptedBytes.length > this.maxSize) {
            return false;
        }
        while (this.size + data.encryptedBytes.length > this.maxSize) {
            this.remove(this.orderedQueue[0]);
        }
        this.cache[uuid] = data;
        this.orderedQueue.push(uuid);
        return true;
    }
    get size() {
        return Object.values(this.cache)
            .map((bytes) => bytes.encryptedBytes.length)
            .reduce((total, fileLength) => total + fileLength, 0);
    }
    get(uuid) {
        return this.cache[uuid];
    }
    remove(uuid) {
        delete this.cache[uuid];
        (0, utils_1.removeFromArray)(this.orderedQueue, uuid);
    }
    clear() {
        this.cache = {};
        this.orderedQueue = [];
    }
}
exports.FileMemoryCache = FileMemoryCache;
