"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultService = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultService extends AbstractService_1.AbstractService {
    constructor(sync, items, mutator, vaultLocks, alerts, _getVault, _getVaults, _changeVaultKeyOptions, _moveItemsToVault, _createVault, _removeItemFromVault, _deleteVault, _rotateVaultKey, _sendVaultDataChangeMessage, _isVaultOwner, _validateVaultPassword, _authorizeVaultDeletion, eventBus) {
        super(eventBus);
        this.sync = sync;
        this.items = items;
        this.mutator = mutator;
        this.vaultLocks = vaultLocks;
        this.alerts = alerts;
        this._getVault = _getVault;
        this._getVaults = _getVaults;
        this._changeVaultKeyOptions = _changeVaultKeyOptions;
        this._moveItemsToVault = _moveItemsToVault;
        this._createVault = _createVault;
        this._removeItemFromVault = _removeItemFromVault;
        this._deleteVault = _deleteVault;
        this._rotateVaultKey = _rotateVaultKey;
        this._sendVaultDataChangeMessage = _sendVaultDataChangeMessage;
        this._isVaultOwner = _isVaultOwner;
        this._validateVaultPassword = _validateVaultPassword;
        this._authorizeVaultDeletion = _authorizeVaultDeletion;
    }
    deinit() {
        super.deinit();
        this.sync = undefined;
        this.items = undefined;
        this.mutator = undefined;
        this.vaultLocks = undefined;
        this.alerts = undefined;
        this._getVault = undefined;
        this._getVaults = undefined;
        this._changeVaultKeyOptions = undefined;
        this._moveItemsToVault = undefined;
        this._createVault = undefined;
        this._removeItemFromVault = undefined;
        this._deleteVault = undefined;
        this._rotateVaultKey = undefined;
    }
    getVaults() {
        return this._getVaults.execute().getValue();
    }
    getVault(dto) {
        const result = this._getVault.execute(dto);
        if (result.isFailed()) {
            return undefined;
        }
        return result.getValue();
    }
    getSureVault(dto) {
        const vault = this.getVault(dto);
        if (!vault) {
            throw new Error('Vault not found');
        }
        return vault;
    }
    async createRandomizedVault(dto) {
        return this.createVaultWithParameters({
            name: dto.name,
            description: dto.description,
            iconString: dto.iconString,
            userInputtedPassword: undefined,
            storagePreference: models_1.KeySystemRootKeyStorageMode.Synced,
        });
    }
    async createUserInputtedPasswordVault(dto) {
        return this.createVaultWithParameters(dto);
    }
    async createVaultWithParameters(dto) {
        const result = await this._createVault.execute({
            vaultName: dto.name,
            vaultDescription: dto.description,
            vaultIcon: dto.iconString,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: dto.storagePreference,
        });
        return result;
    }
    async moveItemToVault(vault, item) {
        if (this.vaultLocks.isVaultLocked(vault)) {
            throw new Error('Attempting to add item to locked vault');
        }
        let linkedFiles = [];
        if ((0, models_1.isNote)(item)) {
            linkedFiles = this.items.getNoteLinkedFiles(item);
            if (linkedFiles.length > 0) {
                const confirmed = await this.alerts.confirmV2({
                    title: 'Linked files will be moved to vault',
                    text: `This note has ${linkedFiles.length} linked files. They will also be moved to the vault. Do you want to continue?`,
                });
                if (!confirmed) {
                    return undefined;
                }
            }
        }
        await this._moveItemsToVault.execute({ vault, items: [item, ...linkedFiles] });
        return this.items.findSureItem(item.uuid);
    }
    async removeItemFromVault(item) {
        const vault = this.getItemVault(item);
        if (!vault) {
            throw new Error('Cannot find vault to remove item from');
        }
        if (this.vaultLocks.isVaultLocked(vault)) {
            throw new Error('Attempting to remove item from locked vault');
        }
        await this._removeItemFromVault.execute({ item });
        return this.items.findSureItem(item.uuid);
    }
    authorizeVaultDeletion(vault) {
        return this._authorizeVaultDeletion.execute(vault);
    }
    async deleteVault(vault) {
        if (vault.isSharedVaultListing()) {
            throw new Error('Shared vault must be deleted through SharedVaultService');
        }
        const error = await this._deleteVault.execute(vault);
        if ((0, responses_1.isClientDisplayableError)(error)) {
            return false;
        }
        await this.sync.sync();
        return true;
    }
    async changeVaultMetadata(vault, params) {
        const updatedVault = await this.mutator.changeItem(vault, (mutator) => {
            mutator.name = params.name;
            mutator.description = params.description;
            mutator.iconString = params.iconString;
        });
        await this.sync.sync();
        if (updatedVault.isSharedVaultListing()) {
            await this._sendVaultDataChangeMessage.execute({
                vault: updatedVault,
            });
        }
        return updatedVault;
    }
    async rotateVaultRootKey(vault, vaultPassword) {
        if (this.vaultLocks.isVaultLocked(vault)) {
            throw new Error('Cannot rotate root key of locked vault');
        }
        await this._rotateVaultKey.execute({
            vault,
            userInputtedPassword: vaultPassword,
        });
        await this.sync.sync();
    }
    isItemInVault(item) {
        return item.key_system_identifier !== undefined;
    }
    getItemVault(item) {
        const latestItem = this.items.findItem(item.uuid);
        if (!latestItem) {
            throw new Error('Cannot find latest version of item to get vault for');
        }
        if (!latestItem.key_system_identifier) {
            return undefined;
        }
        return this.getVault({ keySystemIdentifier: latestItem.key_system_identifier });
    }
    async changeVaultKeyOptions(dto) {
        if (this.vaultLocks.isVaultLocked(dto.vault)) {
            throw new Error('Attempting to change vault options on a locked vault');
        }
        if (!this._isVaultOwner.execute(dto.vault).getValue()) {
            throw new Error('Third party vault options should be changed via changeThirdPartyVaultStorageOptions');
        }
        const result = await this._changeVaultKeyOptions.execute(dto);
        return result;
    }
    async changeThirdPartyVaultStorageOptions(dto) {
        if (this.vaultLocks.isVaultLocked(dto.vault)) {
            throw new Error('Attempting to change vault options on a locked vault');
        }
        if (this._isVaultOwner.execute(dto.vault).getValue()) {
            throw new Error('First party vault options should be changed via changeVaultKeyOptions');
        }
        const validPassword = this._validateVaultPassword.execute(dto.vault, dto.vaultPassword).getValue();
        if (!validPassword) {
            return domain_core_1.Result.fail('Invalid vault password');
        }
        const result = await this._changeVaultKeyOptions.execute({
            vault: dto.vault,
            newStorageMode: dto.newStorageMode,
            newPasswordOptions: undefined,
        });
        return result;
    }
}
exports.VaultService = VaultService;
