"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.V004Algorithm = exports.V003Algorithm = exports.V002Algorithm = exports.V001Algorithm = void 0;
const sncrypto_common_1 = require("@standardnotes/sncrypto-common");
exports.V001Algorithm = Object.freeze({
    SaltSeedLength: 128,
    /**
     * V001 supported a variable PBKDF2 cost
     */
    PbkdfMinCost: 3000,
    PbkdfCostsUsed: [3000, 5000, 10000, 60000],
    PbkdfOutputLength: 512,
    EncryptionKeyLength: 256,
});
exports.V002Algorithm = Object.freeze({
    SaltSeedLength: 128,
    /**
     * V002 supported a variable PBKDF2 cost
     */
    PbkdfMinCost: 3000,
    /**
     * While some 002 accounts also used costs in V001.PbkdfCostsUsed,
     * the vast majority used costs >= 100,000
     */
    PbkdfCostsUsed: exports.V001Algorithm.PbkdfCostsUsed.concat([100000, 101000, 102000, 103000]),
    /** Possible costs used, but statistically more likely these were 001 accounts */
    ImprobablePbkdfCostsUsed: [3000, 5000],
    PbkdfOutputLength: 768,
    EncryptionKeyLength: 256,
    EncryptionIvLength: 128,
});
exports.V003Algorithm = Object.freeze({
    SaltSeedLength: 256,
    PbkdfCost: 110000,
    PbkdfOutputLength: 768,
    EncryptionKeyLength: 256,
    EncryptionIvLength: 128,
});
exports.V004Algorithm = Object.freeze({
    ArgonSaltSeedLength: 256,
    ArgonSaltLength: 128,
    ArgonIterations: 5,
    ArgonMemLimit: 67108864,
    ArgonOutputKeyBytes: 64,
    EncryptionKeyLength: 256,
    EncryptionNonceLength: 192,
    AsymmetricEncryptionNonceLength: 192,
    MasterKeyEncryptionKeyPairSubKeyNumber: 1,
    MasterKeyEncryptionKeyPairSubKeyContext: 'sn-pkc-e',
    MasterKeyEncryptionKeyPairSubKeyBytes: sncrypto_common_1.SodiumConstant.crypto_box_SEEDBYTES,
    MasterKeySigningKeyPairSubKeyNumber: 2,
    MasterKeySigningKeyPairSubKeyContext: 'sn-pkc-s',
    MasterKeySigningKeyPairSubKeyBytes: sncrypto_common_1.SodiumConstant.crypto_sign_SEEDBYTES,
    PayloadKeyHashingKeySubKeyNumber: 1,
    PayloadKeyHashingKeySubKeyContext: 'sn-sym-h',
    PayloadKeyHashingKeySubKeyBytes: sncrypto_common_1.SodiumConstant.crypto_generichash_KEYBYTES,
});
