"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSearchResultAlreadyLinkedToItem = void 0;
function isSearchResultAlreadyLinkedToItem(searchResult, item) {
    let isAlreadyLinked = false;
    const isItemReferencedByActiveItem = item.references.some((ref) => ref.uuid === searchResult.uuid);
    const isActiveItemReferencedByItem = searchResult.references.some((ref) => ref.uuid === (item === null || item === void 0 ? void 0 : item.uuid));
    if (item.content_type === searchResult.content_type) {
        isAlreadyLinked = isItemReferencedByActiveItem;
    }
    else {
        isAlreadyLinked = isActiveItemReferencedByItem || isItemReferencedByActiveItem;
    }
    return isAlreadyLinked;
}
exports.isSearchResultAlreadyLinkedToItem = isSearchResultAlreadyLinkedToItem;
