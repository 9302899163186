"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReuploadVaultInvites = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class ReuploadVaultInvites {
    constructor(reuploadInvite, findContact, inviteServer) {
        this.reuploadInvite = reuploadInvite;
        this.findContact = findContact;
        this.inviteServer = inviteServer;
    }
    async execute(params) {
        const existingInvites = await this.getExistingInvites(params.sharedVault.sharing.sharedVaultUuid);
        if (existingInvites.isFailed()) {
            return existingInvites;
        }
        const deleteResult = await this.deleteExistingInvites(params.sharedVault.sharing.sharedVaultUuid);
        if (deleteResult.isFailed()) {
            return deleteResult;
        }
        const errors = [];
        for (const invite of existingInvites.getValue()) {
            const recipient = this.findContact.execute({ userUuid: invite.user_uuid });
            if (recipient.isFailed()) {
                errors.push(`Contact not found for invite ${invite.user_uuid}`);
                continue;
            }
            const result = await this.reuploadInvite.execute({
                keys: params.keys,
                recipient: recipient.getValue(),
                previousInvite: invite,
            });
            if (result.isFailed()) {
                errors.push(result.getError());
            }
        }
        if (errors.length > 0) {
            return domain_core_1.Result.fail(errors.join(', '));
        }
        return domain_core_1.Result.ok();
    }
    async getExistingInvites(sharedVaultUuid) {
        const response = await this.inviteServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to get outbound user invites ${JSON.stringify(response)}`);
        }
        const invites = response.data.invites;
        return domain_core_1.Result.ok(invites.filter((invite) => invite.shared_vault_uuid === sharedVaultUuid));
    }
    async deleteExistingInvites(sharedVaultUuid) {
        const response = await this.inviteServer.deleteAllSharedVaultInvites({
            sharedVaultUuid: sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to delete existing invites ${JSON.stringify(response)}`);
        }
        return domain_core_1.Result.ok();
    }
}
exports.ReuploadVaultInvites = ReuploadVaultInvites;
