"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAndroid = exports.isIOS = void 0;
const models_1 = require("@standardnotes/models");
// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
const isIOS = () => (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document && navigator.maxTouchPoints > 1) ||
    window.platform === models_1.Platform.Ios;
exports.isIOS = isIOS;
const isAndroid = () => navigator.userAgent.toLowerCase().includes('android');
exports.isAndroid = isAndroid;
