"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsTabletOrMobileScreen = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
class IsTabletOrMobileScreen {
    constructor(environment) {
        this.environment = environment;
        this._isNativeMobileWeb = new ui_services_1.IsNativeMobileWeb(this.environment);
    }
    execute() {
        const isNativeMobile = this._isNativeMobileWeb.execute().getValue();
        const isTabletOrMobile = (0, Utils_1.isTabletOrMobileScreen)() || isNativeMobile;
        const isTablet = (0, Utils_1.isTabletScreen)() || (isNativeMobile && !(0, Utils_1.isMobileScreen)());
        const isMobile = (0, Utils_1.isMobileScreen)() || (isNativeMobile && !isTablet);
        if (isTablet && isMobile) {
            throw Error('isTablet and isMobile cannot both be true');
        }
        return snjs_1.Result.ok({
            isTabletOrMobile,
            isTablet,
            isMobile,
        });
    }
}
exports.IsTabletOrMobileScreen = IsTabletOrMobileScreen;
