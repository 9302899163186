"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.copyTextToClipboard = exports.fallbackCopyTextToClipboard = void 0;
function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    }
    catch (err) {
        console.error('Unable to copy', err);
    }
    document.body.removeChild(textArea);
}
exports.fallbackCopyTextToClipboard = fallbackCopyTextToClipboard;
function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    void navigator.clipboard.writeText(text);
}
exports.copyTextToClipboard = copyTextToClipboard;
