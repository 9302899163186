"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemDisplayController = void 0;
const utils_1 = require("@standardnotes/utils");
const Item_1 = require("../../Abstract/Item");
const SortTwoItems_1 = require("./SortTwoItems");
const CollectionCriteriaValidator_1 = require("./Validator/CollectionCriteriaValidator");
const CustomFilterCriteriaValidator_1 = require("./Validator/CustomFilterCriteriaValidator");
const ExcludeVaultsCriteriaValidator_1 = require("./Validator/ExcludeVaultsCriteriaValidator");
const ExclusiveVaultCriteriaValidator_1 = require("./Validator/ExclusiveVaultCriteriaValidator");
const HiddenContentCriteriaValidator_1 = require("./Validator/HiddenContentCriteriaValidator");
const VaultDisplayOptionsTypes_1 = require("./VaultDisplayOptionsTypes");
class ItemDisplayController {
    constructor(collection, contentTypes, options, vaultOptions) {
        this.collection = collection;
        this.contentTypes = contentTypes;
        this.options = options;
        this.vaultOptions = vaultOptions;
        this.sortMap = {};
        this.sortedItems = [];
        this.needsSort = true;
        this.filterThenSortElements(this.collection.all(this.contentTypes));
    }
    items() {
        return this.sortedItems;
    }
    getDisplayOptions() {
        return this.options;
    }
    setVaultDisplayOptions(vaultOptions) {
        this.vaultOptions = vaultOptions;
        this.needsSort = true;
        this.filterThenSortElements(this.collection.all(this.contentTypes));
    }
    setDisplayOptions(displayOptions) {
        this.options = Object.assign(Object.assign({}, this.options), displayOptions);
        this.needsSort = true;
        this.filterThenSortElements(this.collection.all(this.contentTypes));
    }
    onCollectionChange(delta) {
        const items = [...delta.changed, ...delta.inserted, ...delta.discarded].filter((i) => this.contentTypes.includes(i.content_type));
        this.filterThenSortElements(items);
    }
    passesAllFilters(element) {
        const filters = [new CollectionCriteriaValidator_1.CollectionCriteriaValidator(this.collection, element)];
        if (this.vaultOptions) {
            const options = this.vaultOptions.getOptions();
            if ((0, VaultDisplayOptionsTypes_1.isExclusioanaryOptionsValue)(options)) {
                filters.push(new ExcludeVaultsCriteriaValidator_1.ExcludeVaultsCriteriaValidator([...options.exclude, ...options.locked], element));
            }
            else {
                filters.push(new ExclusiveVaultCriteriaValidator_1.ExclusiveVaultCriteriaValidator(options.exclusive, element));
            }
        }
        if ('hiddenContentTypes' in this.options && this.options.hiddenContentTypes) {
            filters.push(new HiddenContentCriteriaValidator_1.HiddenContentCriteriaValidator(this.options.hiddenContentTypes, element));
        }
        if ('customFilter' in this.options && this.options.customFilter) {
            filters.push(new CustomFilterCriteriaValidator_1.CustomFilterCriteriaValidator(this.options.customFilter, element));
        }
        return filters.every((f) => f.passes());
    }
    filterThenSortElements(elements) {
        for (const element of elements) {
            const previousIndex = this.sortMap[element.uuid];
            const previousElement = previousIndex != undefined ? this.sortedItems[previousIndex] : undefined;
            const remove = () => {
                if (previousIndex != undefined) {
                    delete this.sortMap[element.uuid];
                    this.sortedItems[previousIndex] = undefined;
                    /** Since an element is being removed from the array, we need to recompute
                     * the new positions for elements that are staying */
                    this.needsSort = true;
                }
            };
            if ((0, Item_1.isDeletedItem)(element) || (0, Item_1.isEncryptedItem)(element)) {
                remove();
                continue;
            }
            const passes = this.passesAllFilters(element);
            if (passes) {
                if (previousElement != undefined) {
                    /** Check to see if the element has changed its sort value. If so, we need to re-sort. */
                    const previousValue = previousElement[this.options.sortBy];
                    const newValue = element[this.options.sortBy];
                    /** Replace the current element with the new one. */
                    this.sortedItems[previousIndex] = element;
                    /** If the pinned status of the element has changed, it needs to be resorted */
                    const pinChanged = previousElement.pinned !== element.pinned;
                    if (!(0, utils_1.compareValues)(previousValue, newValue) || pinChanged) {
                        /** Needs resort because its re-sort value has changed,
                         * and thus its position might change */
                        this.needsSort = true;
                    }
                }
                else {
                    /** Has not yet been inserted */
                    this.sortedItems.push(element);
                    /** Needs re-sort because we're just pushing the element to the end here */
                    this.needsSort = true;
                }
            }
            else {
                /** Doesn't pass filter, remove from sorted and filtered */
                remove();
            }
        }
        if (this.needsSort) {
            this.needsSort = false;
            this.resortItems();
        }
    }
    /** Resort the sortedItems array, and update the saved positions */
    resortItems() {
        const resorted = this.sortedItems.sort((a, b) => {
            return (0, SortTwoItems_1.sortTwoItems)(a, b, this.options.sortBy, this.options.sortDirection);
        });
        /**
         * Now that resorted contains the sorted elements (but also can contain undefined element)
         * we create another array that filters out any of the undefinedes. We also keep track of the
         * current index while we loop and set that in the this.sortMap.
         * */
        const results = [];
        let currentIndex = 0;
        /** @O(n) */
        for (const element of resorted) {
            if (!element) {
                continue;
            }
            results.push(element);
            this.sortMap[element.uuid] = currentIndex;
            currentIndex++;
        }
        this.sortedItems = results;
    }
}
exports.ItemDisplayController = ItemDisplayController;
