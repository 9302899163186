"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatorManager = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
class AuthenticatorManager extends AbstractService_1.AbstractService {
    constructor(authenticatorApiService, preferencesService, internalEventBus) {
        super(internalEventBus);
        this.authenticatorApiService = authenticatorApiService;
        this.preferencesService = preferencesService;
        this.internalEventBus = internalEventBus;
    }
    async list() {
        try {
            const result = await this.authenticatorApiService.list();
            if ((0, responses_1.isErrorResponse)(result)) {
                return [];
            }
            const authenticatorNames = this.getAuthenticatorNamesFromPreferences();
            const nameDecoratedAuthenticators = result.data.authenticators.map((authenticator) => ({
                id: authenticator.id,
                name: authenticatorNames.has(authenticator.id)
                    ? authenticatorNames.get(authenticator.id)
                    : 'Security Key',
            }));
            return nameDecoratedAuthenticators;
        }
        catch (error) {
            return [];
        }
    }
    async delete(authenticatorId) {
        try {
            const result = await this.authenticatorApiService.delete(authenticatorId.value);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            const authenticatorNames = this.getAuthenticatorNamesFromPreferences();
            authenticatorNames.delete(authenticatorId.value);
            await this.preferencesService.setValue(models_1.PrefKey.AuthenticatorNames, JSON.stringify([...authenticatorNames]));
            return true;
        }
        catch (error) {
            return false;
        }
    }
    async generateRegistrationOptions() {
        try {
            const result = await this.authenticatorApiService.generateRegistrationOptions();
            if ((0, responses_1.isErrorResponse)(result)) {
                return null;
            }
            return result.data.options;
        }
        catch (error) {
            return null;
        }
    }
    async verifyRegistrationResponse(userUuid, name, registrationCredential) {
        try {
            const result = await this.authenticatorApiService.verifyRegistrationResponse(userUuid.value, name, registrationCredential);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            const authenticatorNames = this.getAuthenticatorNamesFromPreferences();
            authenticatorNames.set(result.data.id, name);
            await this.preferencesService.setValue(models_1.PrefKey.AuthenticatorNames, JSON.stringify([...authenticatorNames]));
            return true;
        }
        catch (error) {
            return false;
        }
    }
    async generateAuthenticationOptions(username) {
        try {
            const result = await this.authenticatorApiService.generateAuthenticationOptions(username.value);
            if ((0, responses_1.isErrorResponse)(result)) {
                return null;
            }
            return result.data.options;
        }
        catch (error) {
            return null;
        }
    }
    getAuthenticatorNamesFromPreferences() {
        let authenticatorNames = new Map();
        const authenticatorNamesFromPreferences = this.preferencesService.getValue(models_1.PrefKey.AuthenticatorNames);
        if (authenticatorNamesFromPreferences !== undefined) {
            try {
                authenticatorNames = new Map(JSON.parse(authenticatorNamesFromPreferences));
            }
            catch (error) {
                authenticatorNames = new Map();
            }
        }
        return authenticatorNames;
    }
}
exports.AuthenticatorManager = AuthenticatorManager;
