"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorLineHeight = void 0;
var EditorLineHeight;
(function (EditorLineHeight) {
    EditorLineHeight["None"] = "None";
    EditorLineHeight["Tight"] = "Tight";
    EditorLineHeight["Snug"] = "Snug";
    EditorLineHeight["Normal"] = "Normal";
    EditorLineHeight["Relaxed"] = "Relaxed";
    EditorLineHeight["Loose"] = "Loose";
})(EditorLineHeight || (exports.EditorLineHeight = EditorLineHeight = {}));
