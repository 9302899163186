"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionName = void 0;
var PermissionName;
(function (PermissionName) {
    PermissionName["AccountSwitcher"] = "app:account-switcher";
    PermissionName["AutobiographyTheme"] = "theme:autobiography";
    PermissionName["BoldEditor"] = "editor:bold";
    PermissionName["CodeEditor"] = "editor:code-editor";
    PermissionName["ComponentFilesafe"] = "component:filesafe";
    PermissionName["ComponentFolders"] = "component:folders";
    PermissionName["DailyEmailBackup"] = "server:daily-email-backup";
    PermissionName["Files"] = "app:files";
    PermissionName["FilesMaximumStorageTier"] = "server:files-max-storage-tier";
    PermissionName["FilesLowStorageTier"] = "server:files-low-storage-tier";
    PermissionName["FocusedTheme"] = "theme:focused";
    PermissionName["FocusMode"] = "app:focus-mode";
    PermissionName["FuturaTheme"] = "theme:futura";
    PermissionName["ListedCustomDomain"] = "listed:custom-domain";
    PermissionName["MarkdownBasicEditor"] = "editor:markdown-basic";
    PermissionName["MarkdownMathEditor"] = "editor:markdown-math";
    PermissionName["MarkdownMinimistEditor"] = "editor:markdown-minimist";
    PermissionName["MarkdownProEditor"] = "editor:markdown-pro";
    PermissionName["MarkdownVisualEditor"] = "editor:markdown-visual";
    PermissionName["MidnightTheme"] = "theme:midnight";
    PermissionName["NoteHistory30Days"] = "server:note-history-30-days";
    PermissionName["NoteHistory365Days"] = "server:note-history-365-days";
    PermissionName["NoteHistoryUnlimited"] = "server:note-history-unlimited";
    PermissionName["PlainEditor"] = "editor:plain";
    PermissionName["PlusEditor"] = "editor:plus";
    PermissionName["SheetsEditor"] = "editor:sheets";
    PermissionName["SignInAlerts"] = "server:sign-in-alerts";
    PermissionName["SmartFilters"] = "app:smart-filters";
    PermissionName["SolarizedDarkTheme"] = "theme:solarized-dark";
    PermissionName["TagNesting"] = "app:tag-nesting";
    PermissionName["TaskEditor"] = "editor:task-editor";
    PermissionName["ThemeDynamic"] = "theme:dynamic";
    PermissionName["TitaniumTheme"] = "theme:titanium";
    PermissionName["TokenVaultEditor"] = "editor:token-vault";
    PermissionName["TwoFactorAuth"] = "server:two-factor-auth";
    PermissionName["UniversalSecondFactor"] = "server:universal-second-factor";
    PermissionName["SubscriptionSharing"] = "server:subscription-sharing";
    PermissionName["SuperEditor"] = "editor:super-editor";
    PermissionName["Clipper"] = "app:clipper";
})(PermissionName || (exports.PermissionName = PermissionName = {}));
