"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator002 = void 0;
const Common = __importStar(require("@standardnotes/common"));
const Models = __importStar(require("@standardnotes/models"));
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
const Utils = __importStar(require("@standardnotes/utils"));
const utils_1 = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const ItemsKey_1 = require("../../Keys/ItemsKey/ItemsKey");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const RootKey_1 = require("../../Keys/RootKey/RootKey");
const Operator001_1 = require("../001/Operator001");
/**
 * @deprecated
 * A legacy operator no longer used to generate new accounts.
 */
class SNProtocolOperator002 extends Operator001_1.SNProtocolOperator001 {
    get version() {
        return Common.ProtocolVersion.V002;
    }
    generateNewItemsKeyContent() {
        const keyLength = Algorithm_1.V002Algorithm.EncryptionKeyLength;
        const itemsKey = this.crypto.generateRandomKey(keyLength);
        const authKey = this.crypto.generateRandomKey(keyLength);
        const response = Models.FillItemContent({
            itemsKey: itemsKey,
            dataAuthenticationKey: authKey,
            version: Common.ProtocolVersion.V002,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new Models.DecryptedPayload({
            uuid: utils_1.UuidGenerator.GenerateUuid(),
            content_type: domain_core_1.ContentType.TYPES.ItemsKey,
            content: this.generateNewItemsKeyContent(),
            ...(0, models_1.PayloadTimestampDefaults)(),
        });
        return Models.CreateDecryptedItemFromPayload(payload);
    }
    async createRootKey(identifier, password, origination) {
        const pwCost = Utils.lastElement(Algorithm_1.V002Algorithm.PbkdfCostsUsed);
        const pwNonce = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.SaltSeedLength);
        const pwSalt = await this.crypto.unsafeSha1(identifier + ':' + pwNonce);
        const keyParams = (0, KeyParamsFunctions_1.Create002KeyParams)({
            email: identifier,
            pw_nonce: pwNonce,
            pw_cost: pwCost,
            pw_salt: pwSalt,
            version: Common.ProtocolVersion.V002,
            origination,
            created: `${Date.now()}`,
        });
        return this.deriveKey(password, keyParams);
    }
    /**
     * Note that version 002 supported "dynamic" iteration counts. Some accounts
     * may have had costs of 5000, and others of 101000. Therefore, when computing
     * the root key, we must use the value returned by the server.
     */
    async computeRootKey(password, keyParams) {
        return this.deriveKey(password, keyParams);
    }
    async decryptString002(text, key, iv) {
        return this.crypto.aes256CbcDecrypt(text, iv, key);
    }
    async encryptString002(text, key, iv) {
        return this.crypto.aes256CbcEncrypt(text, iv, key);
    }
    /**
     * @param keyParams Supplied only when encrypting an items key
     */
    async encryptTextParams(string, encryptionKey, authKey, uuid, version, keyParams) {
        const iv = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.EncryptionIvLength);
        const contentCiphertext = await this.encryptString002(string, encryptionKey, iv);
        const ciphertextToAuth = [version, uuid, iv, contentCiphertext].join(':');
        const authHash = await this.crypto.hmac256(ciphertextToAuth, authKey);
        if (!authHash) {
            throw Error('Error generating hmac256 authHash');
        }
        const components = [version, authHash, uuid, iv, contentCiphertext];
        if (keyParams) {
            const keyParamsString = this.crypto.base64Encode(JSON.stringify(keyParams.content));
            components.push(keyParamsString);
        }
        const fullCiphertext = components.join(':');
        return fullCiphertext;
    }
    async decryptTextParams(ciphertextToAuth, contentCiphertext, encryptionKey, iv, authHash, authKey) {
        if (!encryptionKey) {
            throw 'Attempting to decryptTextParams with null encryptionKey';
        }
        const localAuthHash = await this.crypto.hmac256(ciphertextToAuth, authKey);
        if (!localAuthHash) {
            throw Error('Error generating hmac256 localAuthHash');
        }
        if (this.crypto.timingSafeEqual(authHash, localAuthHash) === false) {
            console.error(Error('Auth hash does not match.'));
            return null;
        }
        return this.decryptString002(contentCiphertext, encryptionKey, iv);
    }
    getPayloadAuthenticatedDataForExternalUse(encrypted) {
        const itemKeyComponents = this.encryptionComponentsFromString002(encrypted.enc_item_key);
        const authenticatedData = itemKeyComponents.keyParams;
        if (!authenticatedData) {
            return undefined;
        }
        const decoded = JSON.parse(this.crypto.base64Decode(authenticatedData));
        const data = {
            ...decoded,
        };
        return data;
    }
    async generateEncryptedParametersAsync(payload, key) {
        /**
         * Generate new item key that is double the key size.
         * Will be split to create encryption key and authentication key.
         */
        const itemKey = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.EncryptionKeyLength * 2);
        const encItemKey = await this.encryptTextParams(itemKey, key.itemsKey, key.dataAuthenticationKey, payload.uuid, key.keyVersion, key instanceof RootKey_1.SNRootKey ? key.keyParams : undefined);
        const ek = Utils.firstHalfOfString(itemKey);
        const ak = Utils.secondHalfOfString(itemKey);
        const ciphertext = await this.encryptTextParams(JSON.stringify(payload.content), ek, ak, payload.uuid, key.keyVersion, key instanceof RootKey_1.SNRootKey ? key.keyParams : undefined);
        return {
            uuid: payload.uuid,
            content_type: payload.content_type,
            items_key_id: (0, ItemsKey_1.isItemsKey)(key) ? key.uuid : undefined,
            content: ciphertext,
            enc_item_key: encItemKey,
            version: this.version,
            key_system_identifier: payload.key_system_identifier,
            shared_vault_uuid: payload.shared_vault_uuid,
        };
    }
    async generateDecryptedParametersAsync(encrypted, key) {
        if (!encrypted.enc_item_key) {
            console.error(Error('Missing item encryption key, skipping decryption.'));
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const encryptedItemKey = encrypted.enc_item_key;
        const itemKeyComponents = this.encryptionComponentsFromString002(encryptedItemKey, key.itemsKey, key.dataAuthenticationKey);
        const itemKey = await this.decryptTextParams(itemKeyComponents.ciphertextToAuth, itemKeyComponents.contentCiphertext, itemKeyComponents.encryptionKey, itemKeyComponents.iv, itemKeyComponents.authHash, itemKeyComponents.authKey);
        if (!itemKey) {
            console.error('Error decrypting item_key parameters', encrypted);
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const ek = Utils.firstHalfOfString(itemKey);
        const ak = Utils.secondHalfOfString(itemKey);
        const itemParams = this.encryptionComponentsFromString002(encrypted.content, ek, ak);
        const content = await this.decryptTextParams(itemParams.ciphertextToAuth, itemParams.contentCiphertext, itemParams.encryptionKey, itemParams.iv, itemParams.authHash, itemParams.authKey);
        if (!content) {
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        else {
            return {
                uuid: encrypted.uuid,
                content: JSON.parse(content),
                signatureData: { required: false, contentHash: '' },
            };
        }
    }
    async deriveKey(password, keyParams) {
        const derivedKey = await this.crypto.pbkdf2(password, keyParams.content002.pw_salt, keyParams.content002.pw_cost, Algorithm_1.V002Algorithm.PbkdfOutputLength);
        if (!derivedKey) {
            throw Error('Error deriving PBKDF2 key');
        }
        const partitions = Utils.splitString(derivedKey, 3);
        return (0, Functions_1.CreateNewRootKey)({
            serverPassword: partitions[0],
            masterKey: partitions[1],
            dataAuthenticationKey: partitions[2],
            version: Common.ProtocolVersion.V002,
            keyParams: keyParams.getPortableValue(),
        });
    }
    encryptionComponentsFromString002(string, encryptionKey, authKey) {
        const components = string.split(':');
        return {
            encryptionVersion: components[0],
            authHash: components[1],
            uuid: components[2],
            iv: components[3],
            contentCiphertext: components[4],
            keyParams: components[5],
            ciphertextToAuth: [components[0], components[2], components[3], components[4]].join(':'),
            encryptionKey: encryptionKey,
            authKey: authKey,
        };
    }
}
exports.SNProtocolOperator002 = SNProtocolOperator002;
