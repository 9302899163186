"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDropdownItemsForAllEditors = void 0;
const features_1 = require("@standardnotes/features");
const getIconAndTintForNoteType_1 = require("./Items/Icons/getIconAndTintForNoteType");
function getDropdownItemsForAllEditors(application) {
    const options = [];
    options.push(...(0, features_1.GetIframeAndNativeEditors)().map((editor) => {
        const [iconType, tint] = (0, getIconAndTintForNoteType_1.getIconAndTintForNoteType)(editor.note_type);
        return {
            label: editor.name,
            value: editor.identifier,
            id: features_1.NativeFeatureIdentifier.create(editor.identifier).getValue(),
            ...(iconType ? { icon: iconType } : null),
            ...(tint ? { iconClassName: `text-accessory-tint-${tint}` } : null),
        };
    }));
    options.push(...application.componentManager
        .thirdPartyComponentsForArea(features_1.ComponentArea.Editor)
        .filter((component) => (0, features_1.FindNativeFeature)(component.identifier) === undefined)
        .map((editor) => {
        const [iconType, tint] = (0, getIconAndTintForNoteType_1.getIconAndTintForNoteType)(editor.noteType);
        return {
            label: editor.displayName,
            value: editor.identifier,
            ...(iconType ? { icon: iconType } : null),
            ...(tint ? { iconClassName: `text-accessory-tint-${tint}` } : null),
        };
    }));
    options.sort((a, b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
    });
    return options;
}
exports.getDropdownItemsForAllEditors = getDropdownItemsForAllEditors;
