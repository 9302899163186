"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMessageAdditionalData = void 0;
class GetMessageAdditionalData {
    constructor(operators) {
        this.operators = operators;
    }
    execute(dto) {
        const operator = this.operators.defaultOperator();
        return operator.asymmetricStringGetAdditionalData({ encryptedString: dto.message });
    }
}
exports.GetMessageAdditionalData = GetMessageAdditionalData;
