"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetIndentOutdentBlocks = void 0;
const lexical_1 = require("lexical");
function GetIndentOutdentBlocks(editor) {
    return [
        {
            name: 'Indent',
            iconName: 'arrow-right',
            keywords: ['indent'],
            onSelect: () => editor.dispatchCommand(lexical_1.INDENT_CONTENT_COMMAND, undefined),
        },
        {
            name: 'Outdent',
            iconName: 'arrow-left',
            keywords: ['outdent'],
            onSelect: () => editor.dispatchCommand(lexical_1.OUTDENT_CONTENT_COMMAND, undefined),
        },
    ];
}
exports.GetIndentOutdentBlocks = GetIndentOutdentBlocks;
