"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const utils_1 = require("@lexical/utils");
const lexical_1 = require("lexical");
const react_1 = require("react");
const Commands_1 = require("../Commands");
const BubbleNode_1 = require("./Nodes/BubbleNode");
const BubbleUtils_1 = require("./Nodes/BubbleUtils");
function ItemBubblePlugin() {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        if (!editor.hasNodes([BubbleNode_1.BubbleNode])) {
            throw new Error('ItemBubblePlugin: BubbleNode not registered on editor');
        }
        return editor.registerCommand(Commands_1.INSERT_BUBBLE_COMMAND, (payload) => {
            const bubbleNode = (0, BubbleUtils_1.$createBubbleNode)(payload);
            (0, lexical_1.$insertNodes)([bubbleNode]);
            if ((0, lexical_1.$isRootOrShadowRoot)(bubbleNode.getParentOrThrow())) {
                (0, utils_1.$wrapNodeInElement)(bubbleNode, lexical_1.$createParagraphNode).selectEnd();
            }
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return null;
}
exports.default = ItemBubblePlugin;
