"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNRootKey = void 0;
const models_1 = require("@standardnotes/models");
const sncrypto_common_1 = require("@standardnotes/sncrypto-common");
const RootKeyParams_1 = require("./RootKeyParams");
/**
 * A root key is a local only construct that houses the key used for the encryption
 * and decryption of items keys. A root key extends SNItem for local convenience, but is
 * not part of the syncing or storage ecosystem—root keys are managed independently.
 */
class SNRootKey extends models_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.keyParams = new RootKeyParams_1.SNRootKeyParams(payload.content.keyParams);
    }
    get keyVersion() {
        return this.content.version;
    }
    /**
     * When the root key is used to encrypt items, we use the masterKey directly.
     */
    get itemsKey() {
        return this.masterKey;
    }
    get masterKey() {
        return this.content.masterKey;
    }
    /**
     * serverPassword is not persisted as part of keychainValue, so if loaded from disk,
     * this value may be undefined.
     */
    get serverPassword() {
        return this.content.serverPassword;
    }
    get encryptionKeyPair() {
        return this.content.encryptionKeyPair;
    }
    get signingKeyPair() {
        return this.content.signingKeyPair;
    }
    /** 003 and below only. */
    get dataAuthenticationKey() {
        return this.content.dataAuthenticationKey;
    }
    compare(otherKey) {
        if (this.keyVersion !== otherKey.keyVersion) {
            return false;
        }
        if (this.serverPassword && otherKey.serverPassword) {
            return ((0, sncrypto_common_1.timingSafeEqual)(this.masterKey, otherKey.masterKey) &&
                (0, sncrypto_common_1.timingSafeEqual)(this.serverPassword, otherKey.serverPassword));
        }
        else {
            return (0, sncrypto_common_1.timingSafeEqual)(this.masterKey, otherKey.masterKey);
        }
    }
    /**
     * @returns Object suitable for persist in storage when wrapped
     */
    persistableValueWhenWrapping() {
        return {
            ...this.getKeychainValue(),
            keyParams: this.keyParams.getPortableValue(),
        };
    }
    /**
     * @returns Object that is suitable for persisting in a keychain
     */
    getKeychainValue() {
        const values = {
            version: this.keyVersion,
            masterKey: this.masterKey,
            encryptionKeyPair: this.encryptionKeyPair,
            signingKeyPair: this.signingKeyPair,
        };
        if (this.dataAuthenticationKey) {
            values.dataAuthenticationKey = this.dataAuthenticationKey;
        }
        return values;
    }
}
exports.SNRootKey = SNRootKey;
