"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertBlanks = exports.createDailyItemsWithToday = exports.templateEntryForDate = exports.createItemsByDateMapping = void 0;
const utils_1 = require("@standardnotes/utils");
const DateUtils_1 = require("@/Utils/DateUtils");
const Utils_1 = require("./Utils");
const createItemsByDateMapping = (items) => {
    const mapping = {};
    for (const item of items) {
        const key = (0, Utils_1.dateToDailyDayIdentifier)(item.created_at);
        if (!mapping[key]) {
            mapping[key] = [];
        }
        mapping[key].push(item);
    }
    return mapping;
};
exports.createItemsByDateMapping = createItemsByDateMapping;
const templateEntryForDate = (date) => {
    const entryDateString = (0, Utils_1.dateToDailyDayIdentifier)(date);
    return {
        dateKey: entryDateString,
        date: date,
        day: date.getDate(),
        isToday: entryDateString === (0, Utils_1.dateToDailyDayIdentifier)(new Date()),
        id: entryDateString,
        weekday: (0, DateUtils_1.getWeekdayName)(date, 'short'),
    };
};
exports.templateEntryForDate = templateEntryForDate;
function createDailyItemsWithToday(count) {
    const items = [(0, exports.templateEntryForDate)(new Date())];
    insertBlanks(items, 'front', count / 2 - 1);
    return insertBlanks(items, 'end', count / 2);
}
exports.createDailyItemsWithToday = createDailyItemsWithToday;
/**
 * Modifies entries array in-place.
 */
function insertBlanks(entries, location, number) {
    let laterDay, earlierDay;
    if (entries.length > 0) {
        laterDay = entries[0].date;
        earlierDay = entries[entries.length - 1].date;
    }
    else {
        const today = new Date();
        laterDay = today;
        earlierDay = today;
    }
    for (let i = 1; i <= number; i++) {
        if (location === 'front') {
            const plusNFromFirstDay = (0, utils_1.addDaysToDate)(laterDay, i);
            const futureEntry = (0, exports.templateEntryForDate)(plusNFromFirstDay);
            entries.unshift(futureEntry);
        }
        else {
            const minusNFromLastDay = (0, utils_1.addDaysToDate)(earlierDay, -i);
            const pastEntry = (0, exports.templateEntryForDate)(minusNFromLastDay);
            entries.push(pastEntry);
        }
    }
    return entries;
}
exports.insertBlanks = insertBlanks;
