"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteToVault = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class InviteToVault {
    constructor(keyManager, _encryptMessage, _sendInvite, _shareContact, _getKeyPairs) {
        this.keyManager = keyManager;
        this._encryptMessage = _encryptMessage;
        this._sendInvite = _sendInvite;
        this._shareContact = _shareContact;
        this._getKeyPairs = _getKeyPairs;
    }
    async execute(params) {
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail('Cannot invite contact; keys not found');
        }
        const createInviteResult = await this.inviteContact({
            keys: keys.getValue(),
            sharedVault: params.sharedVault,
            sharedVaultContacts: params.sharedVaultContacts,
            recipient: params.recipient,
            permission: params.permission,
        });
        if (createInviteResult.isFailed()) {
            return createInviteResult;
        }
        await this.shareContactWithOtherVaultMembers({
            contact: params.recipient,
            keys: keys.getValue(),
            sharedVault: params.sharedVault,
        });
        return createInviteResult;
    }
    async shareContactWithOtherVaultMembers(params) {
        const result = await this._shareContact.execute({
            sharedVault: params.sharedVault,
            contactToShare: params.contact,
        });
        return result;
    }
    async inviteContact(params) {
        const permissionOrError = domain_core_1.SharedVaultUserPermission.create(params.permission);
        if (permissionOrError.isFailed()) {
            return domain_core_1.Result.fail(permissionOrError.getError());
        }
        const permission = permissionOrError.getValue();
        const keySystemRootKey = this.keyManager.getPrimaryKeySystemRootKey(params.sharedVault.systemIdentifier);
        if (!keySystemRootKey) {
            return domain_core_1.Result.fail('Cannot invite contact; key system root key not found');
        }
        const meContact = params.sharedVaultContacts.find((contact) => contact.isMe);
        if (!meContact) {
            return domain_core_1.Result.fail('Cannot invite contact; me contact not found');
        }
        const meContactContent = {
            name: undefined,
            contactUuid: meContact.contactUuid,
            publicKeySet: meContact.publicKeySet,
        };
        const delegatedContacts = params.sharedVaultContacts
            .filter((contact) => !contact.isMe && contact.contactUuid !== params.recipient.contactUuid)
            .map((contact) => {
            return {
                name: contact.name,
                contactUuid: contact.contactUuid,
                publicKeySet: contact.publicKeySet,
            };
        });
        const encryptedMessage = this._encryptMessage.execute({
            message: {
                type: models_1.AsymmetricMessagePayloadType.SharedVaultInvite,
                data: {
                    recipientUuid: params.recipient.contactUuid,
                    rootKey: keySystemRootKey.content,
                    trustedContacts: [meContactContent, ...delegatedContacts],
                    metadata: {
                        name: params.sharedVault.name,
                        description: params.sharedVault.description,
                        iconString: params.sharedVault.iconString,
                        fileBytesUsed: params.sharedVault.sharing.fileBytesUsed,
                    },
                },
            },
            keys: params.keys,
            recipientPublicKey: params.recipient.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const createInviteResult = await this._sendInvite.execute({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
            recipientUuid: params.recipient.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            permission: permission.value,
        });
        return createInviteResult;
    }
}
exports.InviteToVault = InviteToVault;
