"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const lexical_1 = require("lexical");
const react_1 = require("react");
const Commands_1 = require("../Commands");
const utils_1 = require("@lexical/utils");
const rich_text_1 = require("@lexical/rich-text");
const DateUtils_1 = require("@/Utils/DateUtils");
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
function DatetimePlugin() {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        return (0, utils_1.mergeRegister)(editor.registerCommand(Commands_1.INSERT_DATETIME_COMMAND, () => {
            const now = new Date();
            const selection = (0, lexical_1.$getSelection)();
            if (!(0, lexical_1.$isRangeSelection)(selection)) {
                return false;
            }
            const heading = (0, rich_text_1.$createHeadingNode)('h1');
            const dateString = (0, lexical_1.$createTextNode)((0, DateUtils_1.formatDateAndTimeForNote)(now, false));
            dateString.setFormat('italic');
            heading.append(dateString);
            const timeNode = (0, lexical_1.$createTextNode)((0, DateUtils_1.dateToHoursAndMinutesTimeString)(now));
            timeNode.toggleFormat('superscript');
            timeNode.toggleFormat('italic');
            heading.append(timeNode);
            const newLineNode = (0, lexical_1.$createParagraphNode)();
            selection.insertNodes([heading, newLineNode]);
            editor.dispatchCommand(LexicalHorizontalRuleNode_1.INSERT_HORIZONTAL_RULE_COMMAND, undefined);
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR), editor.registerCommand(Commands_1.INSERT_DATE_COMMAND, () => {
            const now = new Date();
            const selection = (0, lexical_1.$getSelection)();
            if (!(0, lexical_1.$isRangeSelection)(selection)) {
                return false;
            }
            const heading = (0, rich_text_1.$createHeadingNode)('h1');
            const dateString = (0, lexical_1.$createTextNode)((0, DateUtils_1.formatDateAndTimeForNote)(now, false));
            dateString.setFormat('italic');
            heading.append(dateString);
            const newLineNode = (0, lexical_1.$createParagraphNode)();
            selection.insertNodes([heading, newLineNode]);
            editor.dispatchCommand(LexicalHorizontalRuleNode_1.INSERT_HORIZONTAL_RULE_COMMAND, undefined);
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR), editor.registerCommand(Commands_1.INSERT_TIME_COMMAND, () => {
            const now = new Date();
            const selection = (0, lexical_1.$getSelection)();
            if (!(0, lexical_1.$isRangeSelection)(selection)) {
                return false;
            }
            const heading = (0, rich_text_1.$createHeadingNode)('h2');
            const dateString = (0, lexical_1.$createTextNode)((0, DateUtils_1.dateToHoursAndMinutesTimeString)(now));
            dateString.setFormat('italic');
            heading.append(dateString);
            const newLineNode = (0, lexical_1.$createParagraphNode)();
            selection.insertNodes([heading, newLineNode]);
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR));
    }, [editor]);
    return null;
}
exports.default = DatetimePlugin;
