"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCodeBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Code_1 = require("../../Blocks/Code");
function GetCodeBlockOption(editor) {
    const block = (0, Code_1.GetCodeBlock)(editor);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetCodeBlockOption = GetCodeBlockOption;
