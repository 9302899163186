"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useStateRef_1 = require("@/Hooks/useStateRef");
const useAndroidBackHandler_1 = require("@/NativeMobileWeb/useAndroidBackHandler");
const react_1 = require("react");
const ModalAndroidBackHandler = ({ close }) => {
    const addAndroidBackHandler = (0, useAndroidBackHandler_1.useAndroidBackHandler)();
    const closeFnRef = (0, useStateRef_1.useStateRef)(close);
    (0, react_1.useEffect)(() => {
        const removeListener = addAndroidBackHandler(() => {
            closeFnRef.current();
            return true;
        });
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, closeFnRef]);
    return null;
};
exports.default = ModalAndroidBackHandler;
