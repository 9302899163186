"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const webextension_polyfill_1 = require("webextension-polyfill");
async function sendMessageToActiveTab(message) {
    const [activeTab] = await webextension_polyfill_1.tabs.query({ active: true, currentWindow: true, windowType: 'normal' });
    if (!activeTab || !activeTab.id) {
        return;
    }
    return await webextension_polyfill_1.tabs.sendMessage(activeTab.id, message);
}
exports.default = sendMessageToActiveTab;
