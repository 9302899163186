"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBulletedListBlock = void 0;
const list_1 = require("@lexical/list");
function GetBulletedListBlock(editor) {
    return {
        name: 'Bulleted List',
        iconName: 'list-bulleted',
        keywords: ['bulleted list', 'unordered list', 'ul'],
        onSelect: () => editor.dispatchCommand(list_1.INSERT_UNORDERED_LIST_COMMAND, undefined),
    };
}
exports.GetBulletedListBlock = GetBulletedListBlock;
