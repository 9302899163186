"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsMenuController = void 0;
const mobx_1 = require("mobx");
class ActionsMenuController {
    constructor() {
        this.hiddenSections = {};
        this.toggleSectionVisibility = (uuid) => {
            this.hiddenSections[uuid] = !this.hiddenSections[uuid];
        };
        this.reset = () => {
            this.hiddenSections = {};
        };
        (0, mobx_1.makeObservable)(this, {
            hiddenSections: mobx_1.observable,
            toggleSectionVisibility: mobx_1.action,
            reset: mobx_1.action,
        });
    }
}
exports.ActionsMenuController = ActionsMenuController;
