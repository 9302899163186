"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranslateToBelowAnimation = exports.TranslateFromBelowAnimation = exports.TranslateToTopAnimation = exports.TranslateFromTopAnimation = void 0;
exports.TranslateFromTopAnimation = {
    keyframes: [
        {
            opacity: 0,
            transform: 'translateY(-100%)',
        },
        {
            opacity: 1,
            transform: 'translateY(0)',
        },
    ],
    options: {
        easing: 'ease-in-out',
        duration: 150,
        fill: 'forwards',
    },
    initialStyle: {
        transformOrigin: 'top',
    },
};
exports.TranslateToTopAnimation = {
    keyframes: [
        {
            opacity: 1,
            transform: 'translateY(0)',
        },
        {
            opacity: 0,
            transform: 'translateY(-100%)',
        },
    ],
    options: {
        easing: 'ease-in-out',
        duration: 150,
        fill: 'forwards',
    },
    initialStyle: {
        transformOrigin: 'top',
    },
};
exports.TranslateFromBelowAnimation = {
    keyframes: [
        {
            opacity: 0,
            transform: 'translateY(100%)',
        },
        {
            opacity: 1,
            transform: 'translateY(0)',
        },
    ],
    options: {
        easing: 'ease-in-out',
        duration: 150,
        fill: 'forwards',
    },
    initialStyle: {
        transformOrigin: 'bottom',
    },
};
exports.TranslateToBelowAnimation = {
    keyframes: [
        {
            opacity: 1,
            transform: 'translateY(0)',
        },
        {
            opacity: 0,
            transform: 'translateY(100%)',
        },
    ],
    options: {
        easing: 'ease-in-out',
        duration: 150,
        fill: 'forwards',
    },
    initialStyle: {
        transformOrigin: 'bottom',
    },
};
