"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const IsTabletOrMobileScreen_1 = require("@/Application/UseCase/IsTabletOrMobileScreen");
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const Utils_1 = require("@/Utils");
const react_1 = require("react");
function useIsTabletOrMobileScreen() {
    const [_windowSize, setWindowSize] = (0, react_1.useState)(0);
    const application = (0, ApplicationProvider_1.useApplication)();
    const usecase = (0, react_1.useMemo)(() => new IsTabletOrMobileScreen_1.IsTabletOrMobileScreen(application.environment), [application]);
    (0, react_1.useEffect)(() => {
        const handleResize = (0, Utils_1.debounce)(() => {
            setWindowSize(window.innerWidth);
        }, 100);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isTabletOrMobileScreen = usecase.execute().getValue();
    return isTabletOrMobileScreen;
}
exports.default = useIsTabletOrMobileScreen;
