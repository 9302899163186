"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_EDITOR_WIDTH_COMMAND = exports.OPEN_PREFERENCES_COMMAND = exports.SUPER_EXPORT_HTML = exports.SUPER_EXPORT_MARKDOWN = exports.SUPER_EXPORT_JSON = exports.SUPER_SHOW_MARKDOWN_PREVIEW = exports.SUPER_SEARCH_PREVIOUS_RESULT = exports.SUPER_SEARCH_NEXT_RESULT = exports.SUPER_SEARCH_TOGGLE_REPLACE_MODE = exports.SUPER_SEARCH_TOGGLE_CASE_SENSITIVE = exports.SUPER_TOGGLE_SEARCH = exports.PIN_NOTE_COMMAND = exports.STAR_NOTE_COMMAND = exports.CAPTURE_SAVE_COMMAND = exports.OPEN_NOTE_HISTORY_COMMAND = exports.CREATE_NEW_TAG_COMMAND = exports.FOCUS_TAGS_INPUT_COMMAND = exports.CHANGE_EDITOR_COMMAND = exports.TOGGLE_DARK_MODE_COMMAND = exports.TOGGLE_FOCUS_MODE_COMMAND = exports.ESCAPE_COMMAND = exports.TAB_COMMAND = exports.DELETE_NOTE_KEYBOARD_COMMAND = exports.SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND = exports.SELECT_ALL_ITEMS_KEYBOARD_COMMAND = exports.CANCEL_SEARCH_COMMAND = exports.SEARCH_KEYBOARD_COMMAND = exports.PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND = exports.NEXT_LIST_ITEM_KEYBOARD_COMMAND = exports.CREATE_NEW_NOTE_KEYBOARD_COMMAND = exports.TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND = exports.TOGGLE_LIST_PANE_KEYBOARD_COMMAND = void 0;
function createKeyboardCommand(type) {
    return Symbol(type);
}
exports.TOGGLE_LIST_PANE_KEYBOARD_COMMAND = createKeyboardCommand('TOGGLE_LIST_PANE_KEYBOARD_COMMAND');
exports.TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND = createKeyboardCommand('TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND');
exports.CREATE_NEW_NOTE_KEYBOARD_COMMAND = createKeyboardCommand('CREATE_NEW_NOTE_KEYBOARD_COMMAND');
exports.NEXT_LIST_ITEM_KEYBOARD_COMMAND = createKeyboardCommand('NEXT_LIST_ITEM_KEYBOARD_COMMAND');
exports.PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND = createKeyboardCommand('PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND');
exports.SEARCH_KEYBOARD_COMMAND = createKeyboardCommand('SEARCH_KEYBOARD_COMMAND');
exports.CANCEL_SEARCH_COMMAND = createKeyboardCommand('CANCEL_SEARCH_COMMAND');
exports.SELECT_ALL_ITEMS_KEYBOARD_COMMAND = createKeyboardCommand('SELECT_ALL_ITEMS_KEYBOARD_COMMAND');
exports.SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND = createKeyboardCommand('SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND');
exports.DELETE_NOTE_KEYBOARD_COMMAND = createKeyboardCommand('DELETE_NOTE_KEYBOARD_COMMAND');
exports.TAB_COMMAND = createKeyboardCommand('PLAIN_EDITOR_INSERT_TAB_KEYBOARD_COMMAND');
exports.ESCAPE_COMMAND = createKeyboardCommand('ESCAPE_COMMAND');
exports.TOGGLE_FOCUS_MODE_COMMAND = createKeyboardCommand('TOGGLE_FOCUS_MODE_COMMAND');
exports.TOGGLE_DARK_MODE_COMMAND = createKeyboardCommand('TOGGLE_DARK_MODE_COMMAND');
exports.CHANGE_EDITOR_COMMAND = createKeyboardCommand('CHANGE_EDITOR_COMMAND');
exports.FOCUS_TAGS_INPUT_COMMAND = createKeyboardCommand('FOCUS_TAGS_INPUT_COMMAND');
exports.CREATE_NEW_TAG_COMMAND = createKeyboardCommand('CREATE_NEW_TAG_COMMAND');
exports.OPEN_NOTE_HISTORY_COMMAND = createKeyboardCommand('OPEN_NOTE_HISTORY_COMMAND');
exports.CAPTURE_SAVE_COMMAND = createKeyboardCommand('CAPTURE_SAVE_COMMAND');
exports.STAR_NOTE_COMMAND = createKeyboardCommand('STAR_NOTE_COMMAND');
exports.PIN_NOTE_COMMAND = createKeyboardCommand('PIN_NOTE_COMMAND');
exports.SUPER_TOGGLE_SEARCH = createKeyboardCommand('SUPER_TOGGLE_SEARCH');
exports.SUPER_SEARCH_TOGGLE_CASE_SENSITIVE = createKeyboardCommand('SUPER_SEARCH_TOGGLE_CASE_SENSITIVE');
exports.SUPER_SEARCH_TOGGLE_REPLACE_MODE = createKeyboardCommand('SUPER_SEARCH_TOGGLE_REPLACE_MODE');
exports.SUPER_SEARCH_NEXT_RESULT = createKeyboardCommand('SUPER_SEARCH_NEXT_RESULT');
exports.SUPER_SEARCH_PREVIOUS_RESULT = createKeyboardCommand('SUPER_SEARCH_PREVIOUS_RESULT');
exports.SUPER_SHOW_MARKDOWN_PREVIEW = createKeyboardCommand('SUPER_SHOW_MARKDOWN_PREVIEW');
exports.SUPER_EXPORT_JSON = createKeyboardCommand('SUPER_EXPORT_JSON');
exports.SUPER_EXPORT_MARKDOWN = createKeyboardCommand('SUPER_EXPORT_MARKDOWN');
exports.SUPER_EXPORT_HTML = createKeyboardCommand('SUPER_EXPORT_HTML');
exports.OPEN_PREFERENCES_COMMAND = createKeyboardCommand('OPEN_PREFERENCES_COMMAND');
exports.CHANGE_EDITOR_WIDTH_COMMAND = createKeyboardCommand('CHANGE_EDITOR_WIDTH_COMMAND');
