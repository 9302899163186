"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractUIService = void 0;
const services_1 = require("@standardnotes/services");
class AbstractUIService extends services_1.AbstractService {
    constructor(application, internalEventBus) {
        super(internalEventBus);
        this.application = application;
        this.internalEventBus = internalEventBus;
        this.observers = new Set();
        this.addAppEventObserverAfterSubclassesFinishConstructing();
    }
    async onAppStart() {
        return;
    }
    async onAppEvent(_event) {
        return;
    }
    addAppEventObserverAfterSubclassesFinishConstructing() {
        setTimeout(() => {
            this.addAppEventObserver();
        }, 0);
    }
    addAppEventObserver() {
        if (this.application.isStarted()) {
            void this.onAppStart();
        }
        this.unsubApp = this.application.addEventObserver(async (event) => {
            await this.onAppEvent(event);
            if (event === services_1.ApplicationEvent.Started) {
                void this.onAppStart();
            }
        });
    }
    addObserver(observer) {
        this.observers.add(observer);
    }
    deinit() {
        ;
        this.application = undefined;
        this.observers.forEach((unsubObserver) => unsubObserver());
        this.unsubApp();
        this.unsubApp = undefined;
        super.deinit();
    }
}
exports.AbstractUIService = AbstractUIService;
