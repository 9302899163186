"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.previewHistoryEntryTitle = exports.sortRevisionListIntoGroups = exports.getGroupIndexForEntry = exports.formatDateAsMonthYearString = void 0;
const Constants_1 = require("@/Constants/Constants");
const CalculateDifferenceBetweenDatesInDays_1 = require("../../Utils/CalculateDifferenceBetweenDatesInDays");
const formatDateAsMonthYearString = (date) => {
    return date.toLocaleDateString(undefined, {
        month: 'long',
        year: 'numeric',
    });
};
exports.formatDateAsMonthYearString = formatDateAsMonthYearString;
const getGroupIndexForEntry = (entry, groups) => {
    var _a;
    const todayAsDate = new Date();
    const entryDate = new Date((_a = entry.created_at) !== null && _a !== void 0 ? _a : entry.payload.updated_at);
    const differenceBetweenDatesInDays = (0, CalculateDifferenceBetweenDatesInDays_1.calculateDifferenceBetweenDatesInDays)(todayAsDate, entryDate);
    if (differenceBetweenDatesInDays === 0) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_TODAY);
    }
    if (differenceBetweenDatesInDays > 0 && differenceBetweenDatesInDays < Constants_1.DAYS_IN_A_WEEK) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_WEEK);
    }
    if (differenceBetweenDatesInDays > Constants_1.DAYS_IN_A_YEAR) {
        return groups.findIndex((group) => group.title === GROUP_TITLE_YEAR);
    }
    const formattedEntryMonthYear = (0, exports.formatDateAsMonthYearString)(entryDate);
    return groups.findIndex((group) => group.title === formattedEntryMonthYear);
};
exports.getGroupIndexForEntry = getGroupIndexForEntry;
const GROUP_TITLE_TODAY = 'Today';
const GROUP_TITLE_WEEK = 'This Week';
const GROUP_TITLE_YEAR = 'More Than A Year Ago';
const sortRevisionListIntoGroups = (revisionList) => {
    const sortedGroups = [
        {
            title: GROUP_TITLE_TODAY,
            entries: [],
        },
        {
            title: GROUP_TITLE_WEEK,
            entries: [],
        },
        {
            title: GROUP_TITLE_YEAR,
            entries: [],
        },
    ];
    const addBeforeLastGroup = (group) => {
        sortedGroups.splice(sortedGroups.length - 1, 0, group);
    };
    revisionList === null || revisionList === void 0 ? void 0 : revisionList.forEach((entry) => {
        var _a, _b, _c;
        const groupIndex = (0, exports.getGroupIndexForEntry)(entry, sortedGroups);
        if (groupIndex > -1) {
            (_b = (_a = sortedGroups[groupIndex]) === null || _a === void 0 ? void 0 : _a.entries) === null || _b === void 0 ? void 0 : _b.push(entry);
        }
        else {
            addBeforeLastGroup({
                title: (0, exports.formatDateAsMonthYearString)(new Date((_c = entry.created_at) !== null && _c !== void 0 ? _c : entry.payload.updated_at)),
                entries: [entry],
            });
        }
    });
    return sortedGroups;
};
exports.sortRevisionListIntoGroups = sortRevisionListIntoGroups;
const previewHistoryEntryTitle = (revision) => {
    return new Date(revision.created_at).toLocaleString();
};
exports.previewHistoryEntryTitle = previewHistoryEntryTitle;
