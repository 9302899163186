"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isIconEmoji = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const IconNameToSvgMapping_1 = require("./IconNameToSvgMapping");
const utils_1 = require("@standardnotes/utils");
const LexicalIcons_1 = require("./LexicalIcons");
const ContainerDimensions = {
    small: 'w-3.5 h-3.5',
    medium: 'w-4 h-4',
    normal: 'w-5 h-5',
    large: 'w-6 h-6',
    custom: '',
};
const EmojiContainerDimensions = {
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
    normal: 'w-5 h-5',
    large: 'w-7 h-6',
    custom: '',
};
const EmojiOffset = {
    small: '',
    medium: '',
    normal: '-mt-0.5',
    large: '',
    custom: '',
};
const EmojiSize = {
    small: 'text-xs',
    medium: 'text-sm',
    normal: 'text-base',
    large: 'text-lg',
    custom: '',
};
const getIconComponent = (type) => {
    return (IconNameToSvgMapping_1.IconNameToSvgMapping[type] ||
        LexicalIcons_1.LexicalIconNameToSvgMapping[type]);
};
const isIconEmoji = (type) => {
    return getIconComponent(type) == undefined;
};
exports.isIconEmoji = isIconEmoji;
const Icon = ({ type, className = '', ariaLabel, size = 'normal', emojiSize }) => {
    const IconComponent = getIconComponent(type);
    if (!IconComponent) {
        return ((0, jsx_runtime_1.jsx)("label", { className: (0, utils_1.classNames)('fill-current', 'text-center', EmojiSize[emojiSize || size], EmojiContainerDimensions[emojiSize || size], EmojiOffset[emojiSize || size], className), children: type }));
    }
    return ((0, jsx_runtime_1.jsx)(IconComponent, { className: `${ContainerDimensions[size]} fill-current ${className}`, role: "img", ...(ariaLabel ? { 'aria-label': ariaLabel } : { 'aria-hidden': true }) }));
};
exports.default = Icon;
