"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsNativeIOS = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class IsNativeIOS {
    constructor(environment, platform) {
        this.environment = environment;
        this.platform = platform;
    }
    execute() {
        return domain_core_1.Result.ok(this.environment === models_1.Environment.Mobile && this.platform === models_1.Platform.Ios);
    }
}
exports.IsNativeIOS = IsNativeIOS;
