"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetHeadingsBlockOptions = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Headings_1 = require("../../Blocks/Headings");
function GetHeadingsBlockOptions(editor) {
    return (0, Headings_1.GetHeadingsBlocks)(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetHeadingsBlockOptions = GetHeadingsBlockOptions;
