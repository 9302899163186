"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FillRootKeyContent = exports.CreateNewRootKey = void 0;
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const RootKey_1 = require("./RootKey");
const domain_core_1 = require("@standardnotes/domain-core");
function CreateNewRootKey(content) {
    const uuid = utils_1.UuidGenerator.GenerateUuid();
    const payload = new models_1.DecryptedPayload({
        uuid: uuid,
        content_type: domain_core_1.ContentType.TYPES.RootKey,
        content: FillRootKeyContent(content),
        ...(0, models_1.PayloadTimestampDefaults)(),
    });
    return new RootKey_1.SNRootKey(payload);
}
exports.CreateNewRootKey = CreateNewRootKey;
function FillRootKeyContent(content) {
    if (!content.version) {
        if (content.dataAuthenticationKey) {
            /**
             * If there's no version stored, it must be either 001 or 002.
             * If there's a dataAuthenticationKey, it has to be 002. Otherwise it's 001.
             */
            content.version = models_1.ProtocolVersion.V002;
        }
        else {
            content.version = models_1.ProtocolVersion.V001;
        }
    }
    return (0, models_1.FillItemContentSpecialized)(content);
}
exports.FillRootKeyContent = FillRootKeyContent;
