"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadAndDecryptFileOperation = void 0;
const responses_1 = require("@standardnotes/responses");
const FileDownloader_1 = require("../UseCase/FileDownloader");
const FileDecryptor_1 = require("../UseCase/FileDecryptor");
class DownloadAndDecryptFileOperation {
    constructor(file, crypto, api, valetToken) {
        this.file = file;
        this.crypto = crypto;
        this.api = api;
        this.downloader = new FileDownloader_1.FileDownloader(this.file, this.api, valetToken);
    }
    createDecryptor() {
        return new FileDecryptor_1.FileDecryptor(this.file, this.crypto);
    }
    async run(onBytes) {
        const decryptor = this.createDecryptor();
        let decryptError;
        const onDownloadBytes = async (encryptedBytes, progress, abortDownload) => {
            const result = decryptor.decryptBytes(encryptedBytes);
            if (!result || result.decryptedBytes.length === 0) {
                decryptError = new responses_1.ClientDisplayableError('Failed to decrypt chunk');
                abortDownload();
                return;
            }
            const decryptedBytes = result.decryptedBytes;
            await onBytes({ decrypted: { decryptedBytes }, encrypted: { encryptedBytes }, progress });
        };
        const downloadResult = await this.downloader.run(onDownloadBytes);
        return {
            success: downloadResult instanceof responses_1.ClientDisplayableError ? false : true,
            error: downloadResult === 'aborted' ? undefined : downloadResult || decryptError,
            aborted: downloadResult === 'aborted',
        };
    }
    abort() {
        this.downloader.abort();
    }
}
exports.DownloadAndDecryptFileOperation = DownloadAndDecryptFileOperation;
