"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobilePlatform = exports.isWindowsPlatform = exports.isMacPlatform = void 0;
const snjs_1 = require("@standardnotes/snjs");
function isMacPlatform(platform) {
    return platform === snjs_1.Platform.MacDesktop || platform === snjs_1.Platform.MacWeb;
}
exports.isMacPlatform = isMacPlatform;
function isWindowsPlatform(platform) {
    return platform === snjs_1.Platform.WindowsDesktop || platform === snjs_1.Platform.WindowsWeb;
}
exports.isWindowsPlatform = isWindowsPlatform;
function isMobilePlatform(platform) {
    return platform === snjs_1.Platform.Ios || platform === snjs_1.Platform.Android;
}
exports.isMobilePlatform = isMobilePlatform;
