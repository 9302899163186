"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncOpStatus = void 0;
const SyncEvent_1 = require("./../Event/SyncEvent");
const HEALTHY_SYNC_DURATION_THRESHOLD_S = 5;
const TIMING_MONITOR_POLL_FREQUENCY_MS = 500;
class SyncOpStatus {
    constructor(interval, receiver) {
        this.completedUpload = 0;
        this.totalUpload = 0;
        this.downloaded = 0;
        this.databaseLoadCurrent = 0;
        this.databaseLoadTotal = 0;
        this.databaseLoadDone = false;
        this.syncing = false;
        this.interval = interval;
        this.receiver = receiver;
    }
    deinit() {
        this.stopTimingMonitor();
    }
    setUploadStatus(completed, total) {
        this.completedUpload = completed;
        this.totalUpload = total;
        this.receiver(SyncEvent_1.SyncEvent.StatusChanged);
    }
    setDownloadStatus(downloaded) {
        this.downloaded += downloaded;
        this.receiver(SyncEvent_1.SyncEvent.StatusChanged);
    }
    setDatabaseLoadStatus(current, total, done) {
        this.databaseLoadCurrent = current;
        this.databaseLoadTotal = total;
        this.databaseLoadDone = done;
        if (done) {
            this.receiver(SyncEvent_1.SyncEvent.LocalDataLoaded);
        }
        else {
            this.receiver(SyncEvent_1.SyncEvent.LocalDataIncrementalLoad);
        }
    }
    getStats() {
        return {
            uploadCompletionCount: this.completedUpload,
            uploadTotalCount: this.totalUpload,
            downloadCount: this.downloaded,
            localDataDone: this.databaseLoadDone,
            localDataCurrent: this.databaseLoadCurrent,
            localDataTotal: this.databaseLoadTotal,
        };
    }
    setDidBegin() {
        this.syncing = true;
        this.syncStart = new Date();
    }
    setDidEnd() {
        this.syncing = false;
    }
    get syncInProgress() {
        return this.syncing === true;
    }
    get secondsSinceSyncStart() {
        return (new Date().getTime() - this.syncStart.getTime()) / 1000;
    }
    /**
     * Notifies receiver if current sync request is taking too long to complete.
     */
    startTimingMonitor() {
        if (this.timingMonitor) {
            this.stopTimingMonitor();
        }
        this.timingMonitor = this.interval(() => {
            if (this.secondsSinceSyncStart > HEALTHY_SYNC_DURATION_THRESHOLD_S) {
                this.receiver(SyncEvent_1.SyncEvent.SyncTakingTooLong);
                this.stopTimingMonitor();
            }
        }, TIMING_MONITOR_POLL_FREQUENCY_MS);
    }
    stopTimingMonitor() {
        if (Object.prototype.hasOwnProperty.call(this.interval, 'cancel')) {
            this.interval.cancel(this.timingMonitor);
        }
        else {
            clearInterval(this.timingMonitor);
        }
        this.timingMonitor = null;
    }
    hasError() {
        return !!this.error;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setError(error) {
        this.error = error;
    }
    clearError() {
        this.error = null;
    }
    reset() {
        this.downloaded = 0;
        this.completedUpload = 0;
        this.totalUpload = 0;
        this.syncing = false;
        this.error = null;
        this.stopTimingMonitor();
        this.receiver(SyncEvent_1.SyncEvent.StatusChanged);
    }
}
exports.SyncOpStatus = SyncOpStatus;
