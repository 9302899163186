"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDecryptedBackupFile = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
class CreateDecryptedBackupFile {
    constructor(payloads, protections) {
        this.payloads = payloads;
        this.protections = protections;
    }
    async execute() {
        if (!(await this.protections.authorizeBackupCreation())) {
            return domain_core_1.Result.fail('Failed to authorize backup creation');
        }
        const payloads = this.payloads.nonDeletedItems.filter((item) => item.content_type !== domain_core_1.ContentType.TYPES.ItemsKey);
        const data = {
            version: common_1.ProtocolVersionLatest,
            items: payloads
                .map((payload) => {
                if ((0, models_1.isDecryptedPayload)(payload)) {
                    return (0, models_1.CreateDecryptedBackupFileContextPayload)(payload);
                }
                else if ((0, models_1.isEncryptedPayload)(payload)) {
                    return (0, models_1.CreateEncryptedBackupFileContextPayload)(payload);
                }
                return undefined;
            })
                .filter(utils_1.isNotUndefined),
        };
        return domain_core_1.Result.ok(data);
    }
}
exports.CreateDecryptedBackupFile = CreateDecryptedBackupFile;
