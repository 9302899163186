"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptMessage = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class EncryptMessage {
    constructor(operators) {
        this.operators = operators;
    }
    execute(dto) {
        const operator = this.operators.defaultOperator();
        const encrypted = operator.asymmetricEncrypt({
            stringToEncrypt: JSON.stringify(dto.message),
            senderKeyPair: dto.keys.encryption,
            senderSigningKeyPair: dto.keys.signing,
            recipientPublicKey: dto.recipientPublicKey,
        });
        return domain_core_1.Result.ok(encrypted);
    }
}
exports.EncryptMessage = EncryptMessage;
