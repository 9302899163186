"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthManager = void 0;
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("../Service/AbstractService");
class AuthManager extends AbstractService_1.AbstractService {
    constructor(authApiService, internalEventBus) {
        super(internalEventBus);
        this.authApiService = authApiService;
        this.internalEventBus = internalEventBus;
    }
    async generateRecoveryCodes() {
        try {
            const result = await this.authApiService.generateRecoveryCodes();
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.recoveryCodes;
        }
        catch (error) {
            return false;
        }
    }
    async recoveryKeyParams(dto) {
        try {
            const result = await this.authApiService.recoveryKeyParams(dto);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.keyParams;
        }
        catch (error) {
            return false;
        }
    }
    async signInWithRecoveryCodes(dto) {
        try {
            const result = await this.authApiService.signInWithRecoveryCodes(dto);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return {
                keyParams: result.data.key_params,
                session: result.data.session,
                user: result.data.user,
            };
        }
        catch (error) {
            return false;
        }
    }
}
exports.AuthManager = AuthManager;
