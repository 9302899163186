"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Logging_1 = require("@/Logging");
const Utils_1 = require("@/Utils");
const react_1 = require("react");
const U2F_IFRAME_ORIGIN = Utils_1.isDev ? 'http://localhost:3001/?route=u2f' : 'https://app.standardnotes.com/?route=u2f';
const U2FPromptIframeContainer = ({ contextData, onResponse, apiHost }) => {
    const iframeRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const messageHandler = (event) => {
            var _a;
            (0, Logging_1.log)(Logging_1.LoggingDomain.U2F, 'Native client received message', event);
            const eventDoesNotComeFromU2FIFrame = event.origin !== new URL(U2F_IFRAME_ORIGIN).origin;
            if (eventDoesNotComeFromU2FIFrame) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.U2F, 'Not sending data to U2F iframe; origin does not match', event.origin, new URL(U2F_IFRAME_ORIGIN).origin);
                return;
            }
            if (event.data.mountedAuthView) {
                if ((_a = iframeRef.current) === null || _a === void 0 ? void 0 : _a.contentWindow) {
                    (0, Logging_1.log)(Logging_1.LoggingDomain.U2F, 'Sending contextData to U2F iframe', contextData);
                    iframeRef.current.contentWindow.postMessage({ username: contextData.username, apiHost }, U2F_IFRAME_ORIGIN);
                }
                return;
            }
            if (event.data.assertionResponse) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.U2F, 'Received assertion response from U2F iframe', event.data.assertionResponse);
                onResponse(event.data.assertionResponse);
            }
        };
        window.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, [contextData, onResponse, apiHost]);
    return ((0, jsx_runtime_1.jsx)("iframe", { ref: iframeRef, src: U2F_IFRAME_ORIGIN, className: "h-40 w-full", title: "U2F", allow: "publickey-credentials-get", id: "u2f" }));
};
exports.default = U2FPromptIframeContainer;
