"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveThemeList = void 0;
const models_1 = require("@standardnotes/models");
const features_1 = require("@standardnotes/features");
const domain_core_1 = require("@standardnotes/domain-core");
class ActiveThemeList {
    constructor(items, initialList) {
        this.items = items;
        this.list = [];
        if (initialList) {
            this.list = initialList;
        }
    }
    getList() {
        return this.list.slice();
    }
    isEmpty() {
        return this.list.length === 0;
    }
    clear() {
        this.list = [];
    }
    has(candidate) {
        for (const entry of this.list) {
            if (entry.equals(candidate)) {
                return true;
            }
        }
        return false;
    }
    add(candidate) {
        if (!this.has(candidate)) {
            this.list.push(candidate);
        }
    }
    remove(candidate) {
        this.list = this.list.filter((entry) => {
            return !entry.equals(candidate);
        });
    }
    asThemes() {
        const results = [];
        for (const entry of this.list) {
            if (entry instanceof domain_core_1.Uuid) {
                const theme = this.items.findItem(entry.value);
                if (theme) {
                    const uiFeature = new models_1.UIFeature(theme);
                    results.push(uiFeature);
                }
            }
            else {
                const theme = (0, features_1.FindNativeTheme)(entry.value);
                if (theme) {
                    const uiFeature = new models_1.UIFeature(theme);
                    results.push(uiFeature);
                }
            }
        }
        return results;
    }
}
exports.ActiveThemeList = ActiveThemeList;
