"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadOrShareBlobBasedOnPlatform = void 0;
const DownloadBlobOnAndroid_1 = require("@/NativeMobileWeb/DownloadBlobOnAndroid");
const ShareBlobOnMobile_1 = require("@/NativeMobileWeb/ShareBlobOnMobile");
const snjs_1 = require("@standardnotes/snjs");
const downloadOrShareBlobBasedOnPlatform = async (dto) => {
    var _a;
    if (!dto.isNativeMobileWeb) {
        dto.archiveService.downloadData(dto.blob, dto.filename);
        return;
    }
    if (dto.mobileDevice && dto.platform === snjs_1.Platform.Ios) {
        void (0, ShareBlobOnMobile_1.shareBlobOnMobile)(dto.mobileDevice, dto.isNativeMobileWeb, dto.blob, dto.filename);
        return;
    }
    if (dto.mobileDevice && dto.platform === snjs_1.Platform.Android) {
        void (0, DownloadBlobOnAndroid_1.downloadBlobOnAndroid)(dto.mobileDevice, dto.blob, dto.filename, (_a = dto.showToastOnAndroid) !== null && _a !== void 0 ? _a : true);
        return;
    }
};
exports.downloadOrShareBlobBasedOnPlatform = downloadOrShareBlobBasedOnPlatform;
