"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDividerBlock = void 0;
const LexicalHorizontalRuleNode_1 = require("@lexical/react/LexicalHorizontalRuleNode");
function GetDividerBlock(editor) {
    return {
        name: 'Divider',
        iconName: 'horizontal-rule',
        keywords: ['horizontal rule', 'divider', 'hr'],
        onSelect: () => editor.dispatchCommand(LexicalHorizontalRuleNode_1.INSERT_HORIZONTAL_RULE_COMMAND, undefined),
    };
}
exports.GetDividerBlock = GetDividerBlock;
