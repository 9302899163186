"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultService = void 0;
const models_1 = require("@standardnotes/models");
const SharedVaultServiceEvent_1 = require("./SharedVaultServiceEvent");
const AbstractService_1 = require("../Service/AbstractService");
const SyncEvent_1 = require("../Event/SyncEvent");
const SessionEvent_1 = require("../Session/SessionEvent");
const NotificationServiceEvent_1 = require("../UserEvent/NotificationServiceEvent");
const domain_core_1 = require("@standardnotes/domain-core");
class SharedVaultService extends AbstractService_1.AbstractService {
    constructor(items, session, _syncLocalVaultsWithRemoteSharedVaults, _getVault, _getOwnedSharedVaults, _createSharedVault, _handleKeyPairChange, _findContact, _deleteThirdPartyVault, _shareContactWithVault, _convertToSharedVault, _deleteSharedVault, _discardItemsLocally, eventBus) {
        super(eventBus);
        this.items = items;
        this.session = session;
        this._syncLocalVaultsWithRemoteSharedVaults = _syncLocalVaultsWithRemoteSharedVaults;
        this._getVault = _getVault;
        this._getOwnedSharedVaults = _getOwnedSharedVaults;
        this._createSharedVault = _createSharedVault;
        this._handleKeyPairChange = _handleKeyPairChange;
        this._findContact = _findContact;
        this._deleteThirdPartyVault = _deleteThirdPartyVault;
        this._shareContactWithVault = _shareContactWithVault;
        this._convertToSharedVault = _convertToSharedVault;
        this._deleteSharedVault = _deleteSharedVault;
        this._discardItemsLocally = _discardItemsLocally;
        this.eventDisposers.push(items.addObserver(domain_core_1.ContentType.TYPES.TrustedContact, async ({ changed, source }) => {
            if (source === models_1.PayloadEmitSource.LocalChanged && changed.length > 0) {
                void this.handleTrustedContactsChange(changed);
            }
        }));
    }
    deinit() {
        super.deinit();
        this.items = undefined;
        this.session = undefined;
        this._syncLocalVaultsWithRemoteSharedVaults = undefined;
        this._getVault = undefined;
        this._createSharedVault = undefined;
        this._handleKeyPairChange = undefined;
        this._findContact = undefined;
        this._deleteThirdPartyVault = undefined;
        this._shareContactWithVault = undefined;
        this._convertToSharedVault = undefined;
        this._deleteSharedVault = undefined;
    }
    async handleEvent(event) {
        switch (event.type) {
            case SessionEvent_1.SessionEvent.UserKeyPairChanged: {
                const eventData = event.payload;
                void this._handleKeyPairChange.execute({
                    newKeys: eventData.current,
                    previousKeys: eventData.previous,
                });
                break;
            }
            case NotificationServiceEvent_1.NotificationServiceEvent.NotificationReceived:
                await this.handleNotification(event.payload);
                break;
            case SyncEvent_1.SyncEvent.ReceivedRemoteSharedVaults:
                void this.notifyEventSync(SharedVaultServiceEvent_1.SharedVaultServiceEvent.SharedVaultStatusChanged);
                break;
        }
    }
    async handleNotification(event) {
        switch (event.eventPayload.props.type.value) {
            case domain_core_1.NotificationType.TYPES.RemovedFromSharedVault: {
                const vault = this._getVault.execute({
                    sharedVaultUuid: event.eventPayload.props.sharedVaultUuid.value,
                });
                if (!vault.isFailed()) {
                    await this._deleteThirdPartyVault.execute(vault.getValue());
                }
                break;
            }
            case domain_core_1.NotificationType.TYPES.SharedVaultItemRemoved: {
                const item = this.items.findItem(event.eventPayload.props.itemUuid.value);
                if (item) {
                    void this._discardItemsLocally.execute([item]);
                }
                break;
            }
            case domain_core_1.NotificationType.TYPES.SharedVaultFileRemoved:
            case domain_core_1.NotificationType.TYPES.SharedVaultFileUploaded: {
                const vaultOrError = this._getVault.execute({
                    sharedVaultUuid: event.eventPayload.props.sharedVaultUuid.value,
                });
                if (!vaultOrError.isFailed()) {
                    await this._syncLocalVaultsWithRemoteSharedVaults.execute([vaultOrError.getValue()]);
                    void this.notifyEventSync(SharedVaultServiceEvent_1.SharedVaultServiceEvent.SharedVaultStatusChanged);
                }
                break;
            }
        }
    }
    async createSharedVault(dto) {
        var _a;
        return this._createSharedVault.execute({
            vaultName: dto.name,
            vaultDescription: dto.description,
            vaultIcon: dto.iconString,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: (_a = dto.storagePreference) !== null && _a !== void 0 ? _a : models_1.KeySystemRootKeyStorageMode.Synced,
        });
    }
    async convertVaultToSharedVault(vault) {
        return this._convertToSharedVault.execute({ vault });
    }
    async handleTrustedContactsChange(contacts) {
        for (const contact of contacts) {
            if (contact.isMe) {
                continue;
            }
            await this.shareContactWithVaults(contact);
        }
    }
    async deleteSharedVault(sharedVault) {
        return this._deleteSharedVault.execute({ sharedVault });
    }
    async shareContactWithVaults(contact) {
        if (contact.isMe) {
            throw new Error('Cannot share self contact');
        }
        const ownedVaults = this._getOwnedSharedVaults.execute().getValue();
        for (const vault of ownedVaults) {
            await this._shareContactWithVault.execute({
                sharedVault: vault,
                contactToShare: contact,
            });
        }
    }
    getItemLastEditedBy(item) {
        if (!item.last_edited_by_uuid) {
            return undefined;
        }
        const contact = this._findContact.execute({ userUuid: item.last_edited_by_uuid });
        return contact.isFailed() ? undefined : contact.getValue();
    }
    getItemSharedBy(item) {
        if (!item.user_uuid || item.user_uuid === this.session.getSureUser().uuid) {
            return undefined;
        }
        const contact = this._findContact.execute({ userUuid: item.user_uuid });
        return contact.isFailed() ? undefined : contact.getValue();
    }
}
exports.SharedVaultService = SharedVaultService;
