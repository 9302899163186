"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const snjs_1 = require("@standardnotes/snjs");
const ListItemNotePreviewText = ({ item, hidePreview, lineLimit = 1 }) => {
    if (item.hidePreview || item.protected || hidePreview) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, snjs_1.classNames)('overflow-hidden overflow-ellipsis text-base lg:text-sm', item.archived ? 'opacity-60' : ''), children: [item.preview_html && ((0, jsx_runtime_1.jsx)("div", { className: "my-1", dangerouslySetInnerHTML: {
                    __html: (0, snjs_1.sanitizeHtmlString)(item.preview_html),
                } })), !item.preview_html && item.preview_plain && ((0, jsx_runtime_1.jsx)("div", { className: `leading-1.3 line-clamp-${lineLimit} mt-1 overflow-hidden`, children: item.preview_plain }))] }));
};
exports.default = ListItemNotePreviewText;
