"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetItemTags = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetItemTags {
    constructor(items) {
        this.items = items;
    }
    execute(item) {
        return domain_core_1.Result.ok(this.items.itemsReferencingItem(item).filter((ref) => {
            return ref.content_type === domain_core_1.ContentType.TYPES.Tag;
        }));
    }
}
exports.GetItemTags = GetItemTags;
