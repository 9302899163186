"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleKeyPairChange = void 0;
const InternalFeatureService_1 = require("./../../InternalFeatures/InternalFeatureService");
const domain_core_1 = require("@standardnotes/domain-core");
const InternalFeature_1 = require("../../InternalFeatures/InternalFeature");
const responses_1 = require("@standardnotes/responses");
class HandleKeyPairChange {
    constructor(selfContactManager, invitesServer, messageServer, _reuploadAllInvites, _resendAllMessages, _getAllContacts, _sendOwnContactChangedMessage, _createOrEditContact, logger) {
        this.selfContactManager = selfContactManager;
        this.invitesServer = invitesServer;
        this.messageServer = messageServer;
        this._reuploadAllInvites = _reuploadAllInvites;
        this._resendAllMessages = _resendAllMessages;
        this._getAllContacts = _getAllContacts;
        this._sendOwnContactChangedMessage = _sendOwnContactChangedMessage;
        this._createOrEditContact = _createOrEditContact;
        this.logger = logger;
    }
    async execute(dto) {
        await this.updateSelfContact({
            encryption: dto.newKeys.encryption.publicKey,
            signing: dto.newKeys.signing.publicKey,
        });
        const results = await Promise.all([
            this._reuploadAllInvites.execute({
                keys: dto.newKeys,
                previousKeys: dto.previousKeys,
            }),
            this._resendAllMessages.execute({
                keys: dto.newKeys,
                previousKeys: dto.previousKeys,
            }),
        ]);
        for (const result of results) {
            if (result.isFailed()) {
                this.logger.error(result.getError());
            }
        }
        await this.sendOwnContactChangeEventToAllContacts(dto);
        const deleteResponses = await Promise.all([
            this.messageServer.deleteAllInboundMessages(),
            this.invitesServer.deleteAllInboundInvites(),
        ]);
        for (const response of deleteResponses) {
            if ((0, responses_1.isErrorResponse)(response)) {
                this.logger.error(JSON.stringify(response));
            }
        }
        return domain_core_1.Result.ok();
    }
    async updateSelfContact(publicKeySet) {
        if (!InternalFeatureService_1.InternalFeatureService.get().isFeatureEnabled(InternalFeature_1.InternalFeature.Vaults)) {
            return;
        }
        const selfContact = this.selfContactManager.selfContact;
        if (!selfContact) {
            return;
        }
        await this._createOrEditContact.execute({
            contactUuid: selfContact.contactUuid,
            publicKey: publicKeySet.encryption,
            signingPublicKey: publicKeySet.signing,
        });
    }
    async sendOwnContactChangeEventToAllContacts(data) {
        if (!data.previousKeys) {
            return;
        }
        const contacts = this._getAllContacts.execute();
        if (contacts.isFailed()) {
            return;
        }
        for (const contact of contacts.getValue()) {
            if (contact.isMe) {
                continue;
            }
            await this._sendOwnContactChangedMessage.execute({
                senderOldKeyPair: data.previousKeys.encryption,
                senderOldSigningKeyPair: data.previousKeys.signing,
                senderNewKeyPair: data.newKeys.encryption,
                senderNewSigningKeyPair: data.newKeys.signing,
                contact,
            });
        }
    }
}
exports.HandleKeyPairChange = HandleKeyPairChange;
