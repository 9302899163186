"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifiersForEvent = void 0;
const KeyboardModifier_1 = require("./KeyboardModifier");
function modifiersForEvent(event) {
    const allModifiers = Object.values(KeyboardModifier_1.KeyboardModifier);
    const eventModifiers = allModifiers.filter((modifier) => {
        // For a modifier like ctrlKey, must check both event.ctrlKey and event.key.
        // That's because on keyup, event.ctrlKey would be false, but event.key == Control would be true.
        const matches = ((event.ctrlKey || event.key === KeyboardModifier_1.KeyboardModifier.Ctrl) && modifier === KeyboardModifier_1.KeyboardModifier.Ctrl) ||
            ((event.metaKey || event.key === KeyboardModifier_1.KeyboardModifier.Meta) && modifier === KeyboardModifier_1.KeyboardModifier.Meta) ||
            ((event.altKey || event.key === KeyboardModifier_1.KeyboardModifier.Alt) && modifier === KeyboardModifier_1.KeyboardModifier.Alt) ||
            ((event.shiftKey || event.key === KeyboardModifier_1.KeyboardModifier.Shift) && modifier === KeyboardModifier_1.KeyboardModifier.Shift);
        return matches;
    });
    return eventModifiers;
}
exports.modifiersForEvent = modifiersForEvent;
