"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultUserCache = void 0;
class VaultUserCache {
    constructor() {
        this.cache = new Map();
    }
    get(sharedVaultUuid) {
        return this.cache.get(sharedVaultUuid);
    }
    set(sharedVaultUuid, users) {
        this.cache.set(sharedVaultUuid, users);
    }
}
exports.VaultUserCache = VaultUserCache;
