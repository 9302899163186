"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoadPurchaseFlowUrl = void 0;
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
class LoadPurchaseFlowUrl {
    constructor(application, _getPurchaseFlowUrl) {
        this.application = application;
        this._getPurchaseFlowUrl = _getPurchaseFlowUrl;
    }
    async execute() {
        var _a;
        const urlResult = await this._getPurchaseFlowUrl.execute();
        if (urlResult.isFailed()) {
            return urlResult;
        }
        const url = urlResult.getValue();
        const route = this.application.routeService.getRoute();
        const params = route.type === ui_services_1.RouteType.Purchase ? route.purchaseParams : { period: null, plan: null };
        const period = params.period ? `&period=${params.period}` : '';
        const plan = params.plan ? `&plan=${params.plan}` : '';
        if (url) {
            const finalUrl = `${url}${period}${plan}`;
            if (this.application.isNativeMobileWeb()) {
                this.application.mobileDevice.openUrl(finalUrl);
            }
            else if (this.application.environment === snjs_1.Environment.Desktop) {
                (_a = this.application.desktopDevice) === null || _a === void 0 ? void 0 : _a.openUrl(finalUrl);
            }
            else {
                const windowProxy = window.open('', '_blank');
                windowProxy.location = finalUrl;
            }
            return snjs_1.Result.ok();
        }
        return snjs_1.Result.fail('Could not load purchase flow URL.');
    }
}
exports.LoadPurchaseFlowUrl = LoadPurchaseFlowUrl;
