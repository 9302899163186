"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVaultUsers = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class GetVaultUsers {
    constructor(vaultUsersServer, cache) {
        this.vaultUsersServer = vaultUsersServer;
        this.cache = cache;
    }
    async execute(params) {
        if (params.readFromCache) {
            const cachedUsers = this.cache.get(params.sharedVaultUuid);
            if (cachedUsers) {
                return domain_core_1.Result.ok(cachedUsers);
            }
        }
        const response = await this.vaultUsersServer.getSharedVaultUsers({ sharedVaultUuid: params.sharedVaultUuid });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail((0, responses_1.getErrorFromErrorResponse)(response).message);
        }
        this.cache.set(params.sharedVaultUuid, response.data.users);
        return domain_core_1.Result.ok(response.data.users);
    }
}
exports.GetVaultUsers = GetVaultUsers;
