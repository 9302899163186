"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPurchaseFlowUrl = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
class GetPurchaseFlowUrl {
    constructor(application, legacyApi, isApplicationUsingThirdPartyHostUseCase) {
        this.application = application;
        this.legacyApi = legacyApi;
        this.isApplicationUsingThirdPartyHostUseCase = isApplicationUsingThirdPartyHostUseCase;
    }
    async execute() {
        const currentUrl = window.location.origin;
        const successUrl = (0, Utils_1.isDesktopApplication)() ? 'standardnotes://' : currentUrl;
        const isThirdPartyHostUsedOrError = this.isApplicationUsingThirdPartyHostUseCase.execute();
        if (isThirdPartyHostUsedOrError.isFailed()) {
            return snjs_1.Result.fail(isThirdPartyHostUsedOrError.getError());
        }
        const isThirdPartyHostUsed = isThirdPartyHostUsedOrError.getValue();
        if (this.application.sessions.isSignedOut() || isThirdPartyHostUsed) {
            return snjs_1.Result.ok(`${window.purchaseUrl}/offline?&success_url=${successUrl}`);
        }
        const token = await this.legacyApi.getNewSubscriptionToken();
        if (token) {
            return snjs_1.Result.ok(`${window.purchaseUrl}?subscription_token=${token}&success_url=${successUrl}`);
        }
        return snjs_1.Result.fail('Could not get purchase flow URL.');
    }
}
exports.GetPurchaseFlowUrl = GetPurchaseFlowUrl;
