"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLinkFromItem = void 0;
function createLinkFromItem(itemA, type) {
    return {
        id: `${itemA.uuid}-${type}`,
        item: itemA,
        type,
    };
}
exports.createLinkFromItem = createLinkFromItem;
