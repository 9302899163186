"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveItemFromVault = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class RemoveItemFromVault {
    constructor(mutator, sync, files) {
        this.mutator = mutator;
        this.sync = sync;
        this.files = files;
    }
    async execute(dto) {
        await this.mutator.changeItem(dto.item, (mutator) => {
            mutator.key_system_identifier = undefined;
            mutator.shared_vault_uuid = undefined;
        });
        await this.sync.sync();
        if (dto.item.content_type === domain_core_1.ContentType.TYPES.File) {
            await this.files.moveFileOutOfSharedVault(dto.item);
        }
    }
}
exports.RemoveItemFromVault = RemoveItemFromVault;
