"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteTitleFormatOptions = void 0;
const snjs_1 = require("@standardnotes/snjs");
exports.NoteTitleFormatOptions = [
    {
        label: 'Current date and time',
        value: snjs_1.NewNoteTitleFormat.CurrentDateAndTime,
    },
    {
        label: 'Current note count',
        value: snjs_1.NewNoteTitleFormat.CurrentNoteCount,
    },
    {
        label: 'Custom format',
        value: snjs_1.NewNoteTitleFormat.CustomFormat,
    },
    {
        label: 'Empty',
        value: snjs_1.NewNoteTitleFormat.Empty,
    },
];
