"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addToast = exports.dismissToast = exports.updateToast = exports.toastStore = void 0;
const nanoid_1 = require("nanoid");
const nanostores_1 = require("nanostores");
exports.toastStore = (0, nanostores_1.atom)([]);
exports.updateToast = (0, nanostores_1.action)(exports.toastStore, 'updateToast', (store, toastId, options) => {
    const existingToasts = store.get();
    store.set(existingToasts.map((toast) => {
        if (toast.id === toastId) {
            return {
                ...toast,
                ...options,
            };
        }
        else {
            return toast;
        }
    }));
});
const removeToast = (0, nanostores_1.action)(exports.toastStore, 'removeToast', (store, toastId) => {
    const existingToasts = store.get();
    store.set(existingToasts.filter((toast) => toast.id !== toastId));
});
const DelayBeforeRemovingToast = 175;
exports.dismissToast = (0, nanostores_1.action)(exports.toastStore, 'dismissToast', (store, toastId) => {
    const existingToasts = store.get();
    store.set(existingToasts.map((toast) => {
        if (toast.id === toastId) {
            return {
                ...toast,
                dismissed: true,
            };
        }
        else {
            return toast;
        }
    }));
    setTimeout(() => {
        removeToast(toastId);
    }, DelayBeforeRemovingToast);
});
exports.addToast = (0, nanostores_1.action)(exports.toastStore, 'addToast', (store, options) => {
    var _a;
    const existingToasts = store.get();
    const isToastIdDuplicate = existingToasts.findIndex((toast) => toast.id === options.id) > -1;
    const id = options.id && !isToastIdDuplicate ? options.id : (0, nanoid_1.nanoid)();
    if (isToastIdDuplicate) {
        console.warn(`Generated new ID for toast instead of overriding toast of ID "${options.id}".
If you want to update an existing toast, use the \`updateToast()\` function instead.`);
    }
    const toast = {
        ...options,
        id,
        dismissed: false,
        pauseOnWindowBlur: (_a = options.pauseOnWindowBlur) !== null && _a !== void 0 ? _a : true,
    };
    store.set([...existingToasts, toast]);
    return id;
});
