"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricDecryptOwnMessage004 = void 0;
const ParseConsistentBase64JsonPayload_1 = require("../Utils/ParseConsistentBase64JsonPayload");
const domain_core_1 = require("@standardnotes/domain-core");
class AsymmetricDecryptOwnMessage004 {
    constructor(crypto) {
        this.crypto = crypto;
        this.parseBase64Usecase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(dto) {
        const [_, nonce, ciphertext, additionalDataString] = dto.message.split(':');
        const additionalData = this.parseBase64Usecase.execute(additionalDataString);
        try {
            const plaintext = this.crypto.sodiumCryptoBoxEasyDecrypt(ciphertext, nonce, dto.recipientPublicKey, dto.ownPrivateKey);
            if (!plaintext) {
                return domain_core_1.Result.fail('Could not decrypt message');
            }
            const signatureVerified = this.crypto.sodiumCryptoSignVerify(ciphertext, additionalData.signingData.signature, additionalData.signingData.publicKey);
            return domain_core_1.Result.ok({
                plaintext,
                signatureVerified,
                signaturePublicKey: additionalData.signingData.publicKey,
                senderPublicKey: additionalData.senderPublicKey,
            });
        }
        catch (error) {
            return domain_core_1.Result.fail('Could not decrypt message');
        }
    }
}
exports.AsymmetricDecryptOwnMessage004 = AsymmetricDecryptOwnMessage004;
