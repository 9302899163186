"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppPaneIdToDivId = exports.AppPaneId = void 0;
const ElementIDs_1 = require("@/Constants/ElementIDs");
var AppPaneId;
(function (AppPaneId) {
    AppPaneId["Navigation"] = "NavigationColumn";
    AppPaneId["Items"] = "ItemsColumn";
    AppPaneId["Editor"] = "EditorColumn";
})(AppPaneId || (exports.AppPaneId = AppPaneId = {}));
exports.AppPaneIdToDivId = {
    [AppPaneId.Navigation]: ElementIDs_1.ElementIds.NavigationColumn,
    [AppPaneId.Items]: ElementIDs_1.ElementIds.ItemsColumn,
    [AppPaneId.Editor]: ElementIDs_1.ElementIds.EditorColumn,
};
