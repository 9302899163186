"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRootKeyUseCase = void 0;
const Algorithm_1 = require("../../../../Algorithm");
const common_1 = require("@standardnotes/common");
const DeriveRootKey_1 = require("./DeriveRootKey");
const KeyParamsFunctions_1 = require("../../../../Keys/RootKey/KeyParamsFunctions");
class CreateRootKeyUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    async execute(identifier, password, origination) {
        const version = common_1.ProtocolVersion.V004;
        const seed = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.ArgonSaltSeedLength);
        const keyParams = (0, KeyParamsFunctions_1.Create004KeyParams)({
            identifier: identifier,
            pw_nonce: seed,
            version: version,
            origination: origination,
            created: `${Date.now()}`,
        });
        const usecase = new DeriveRootKey_1.DeriveRootKeyUseCase(this.crypto);
        return usecase.execute(password, keyParams);
    }
}
exports.CreateRootKeyUseCase = CreateRootKeyUseCase;
