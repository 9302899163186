"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptAndUploadFileOperation = void 0;
const FileUploader_1 = require("../UseCase/FileUploader");
const FileEncryptor_1 = require("../UseCase/FileEncryptor");
class EncryptAndUploadFileOperation {
    constructor(file, valetToken, crypto, api, vault) {
        this.file = file;
        this.valetToken = valetToken;
        this.crypto = crypto;
        this.api = api;
        this.vault = vault;
        this.encryptedChunkSizes = [];
        this.totalBytesPushedInDecryptedTerms = 0;
        this.totalBytesUploadedInDecryptedTerms = 0;
        this.encryptor = new FileEncryptor_1.FileEncryptor(file, this.crypto);
        this.uploader = new FileUploader_1.FileUploader(this.api);
        this.encryptionHeader = this.encryptor.initializeHeader();
    }
    getValetToken() {
        return this.valetToken;
    }
    getProgress() {
        const reportedDecryptedSize = this.file.decryptedSize;
        return {
            decryptedFileSize: reportedDecryptedSize,
            decryptedBytesUploaded: this.totalBytesUploadedInDecryptedTerms,
            decryptedBytesRemaining: reportedDecryptedSize - this.totalBytesUploadedInDecryptedTerms,
            percentComplete: (this.totalBytesUploadedInDecryptedTerms / reportedDecryptedSize) * 100.0,
        };
    }
    getResult() {
        return {
            encryptionHeader: this.encryptionHeader,
            finalDecryptedSize: this.totalBytesPushedInDecryptedTerms,
            key: this.file.key,
            remoteIdentifier: this.file.remoteIdentifier,
        };
    }
    async pushBytes(decryptedBytes, chunkId, isFinalChunk) {
        this.totalBytesPushedInDecryptedTerms += decryptedBytes.byteLength;
        const encryptedBytes = this.encryptBytes(decryptedBytes, isFinalChunk);
        this.encryptedChunkSizes.push(encryptedBytes.length);
        const uploadSuccess = await this.uploadBytes(encryptedBytes, chunkId);
        if (uploadSuccess) {
            this.totalBytesUploadedInDecryptedTerms += decryptedBytes.byteLength;
        }
        return uploadSuccess;
    }
    encryptBytes(decryptedBytes, isFinalChunk) {
        const encryptedBytes = this.encryptor.pushBytes(decryptedBytes, isFinalChunk);
        return encryptedBytes;
    }
    async uploadBytes(encryptedBytes, chunkId) {
        const success = await this.uploader.uploadBytes(encryptedBytes, this.vault && this.vault.sharing ? 'shared-vault' : 'user', chunkId, this.valetToken);
        return success;
    }
}
exports.EncryptAndUploadFileOperation = EncryptAndUploadFileOperation;
