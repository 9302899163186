"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.keyboardCharacterForModifier = void 0;
const snjs_1 = require("@standardnotes/snjs");
const KeyboardModifier_1 = require("./KeyboardModifier");
function isMacPlatform(platform) {
    return platform === snjs_1.Platform.MacDesktop || platform === snjs_1.Platform.MacWeb;
}
function keyboardCharacterForModifier(modifier, platform) {
    const isMac = isMacPlatform(platform);
    if (modifier === KeyboardModifier_1.KeyboardModifier.Meta) {
        return isMac ? '⌘' : '⊞';
    }
    else if (modifier === KeyboardModifier_1.KeyboardModifier.Ctrl) {
        return isMac ? '⌃' : 'Ctrl';
    }
    else if (modifier === KeyboardModifier_1.KeyboardModifier.Alt) {
        return isMac ? '⌥' : 'Alt';
    }
    else if (modifier === KeyboardModifier_1.KeyboardModifier.Shift) {
        return isMac ? '⇧' : 'Shift';
    }
    else {
        return KeyboardModifier_1.KeyboardModifier[modifier];
    }
}
exports.keyboardCharacterForModifier = keyboardCharacterForModifier;
