"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNumberedListBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const NumberedList_1 = require("../../Blocks/NumberedList");
function GetNumberedListBlockOption(editor) {
    const block = (0, NumberedList_1.GetNumberedListBlock)(editor);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetNumberedListBlockOption = GetNumberedListBlockOption;
