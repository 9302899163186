"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncLocalVaultsWithRemoteSharedVaults = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const responses_1 = require("@standardnotes/responses");
class SyncLocalVaultsWithRemoteSharedVaults {
    constructor(sharedVaultServer, mutator) {
        this.sharedVaultServer = sharedVaultServer;
        this.mutator = mutator;
    }
    async execute(localVaults) {
        const remoteVaultsResponse = await this.sharedVaultServer.getSharedVaults();
        if ((0, responses_1.isErrorResponse)(remoteVaultsResponse)) {
            return domain_core_1.Result.fail(remoteVaultsResponse.data.error.message);
        }
        const remoteVaults = remoteVaultsResponse.data.sharedVaults;
        for (const localVault of localVaults) {
            if (!localVault.isSharedVaultListing()) {
                continue;
            }
            const remoteVault = remoteVaults.find((vault) => vault.uuid === localVault.sharing.sharedVaultUuid);
            if (remoteVault) {
                await this.mutator.changeItem(localVault, (mutator) => {
                    /* istanbul ignore next */
                    mutator.sharing = {
                        sharedVaultUuid: remoteVault.uuid,
                        ownerUserUuid: remoteVault.user_uuid,
                        fileBytesUsed: remoteVault.file_upload_bytes_used,
                    };
                });
            }
        }
        return domain_core_1.Result.ok();
    }
}
exports.SyncLocalVaultsWithRemoteSharedVaults = SyncLocalVaultsWithRemoteSharedVaults;
