"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetChecklistBlock = void 0;
const list_1 = require("@lexical/list");
function GetChecklistBlock(editor) {
    return {
        name: 'Check List',
        iconName: 'check',
        keywords: ['check list', 'todo list'],
        onSelect: () => editor.dispatchCommand(list_1.INSERT_CHECK_LIST_COMMAND, undefined),
    };
}
exports.GetChecklistBlock = GetChecklistBlock;
