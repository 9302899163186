"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactBelongsToVault = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class ContactBelongsToVault {
    constructor(getVaultUsers) {
        this.getVaultUsers = getVaultUsers;
    }
    async execute(dto) {
        const users = await this.getVaultUsers.execute({
            sharedVaultUuid: dto.vault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (users.isFailed()) {
            return domain_core_1.Result.fail('Failed to get vault users');
        }
        return domain_core_1.Result.ok(users.getValue().some((u) => u.user_uuid === dto.contact.contactUuid));
    }
}
exports.ContactBelongsToVault = ContactBelongsToVault;
