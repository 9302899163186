"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileViewController = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class FileViewController {
    constructor(item, items) {
        this.item = item;
        this.items = items;
        this.dealloced = false;
        this.runtimeId = `${Math.random()}`;
    }
    deinit() {
        var _a;
        this.dealloced = true;
        (_a = this.removeStreamObserver) === null || _a === void 0 ? void 0 : _a.call(this);
        this.removeStreamObserver = undefined;
        this.item = undefined;
    }
    async initialize() {
        this.streamItems();
    }
    streamItems() {
        this.removeStreamObserver = this.items.streamItems(domain_core_1.ContentType.TYPES.File, ({ changed, inserted }) => {
            if (this.dealloced) {
                return;
            }
            const files = changed.concat(inserted);
            const matchingFile = files.find((item) => {
                return item.uuid === this.item.uuid;
            });
            if (matchingFile) {
                this.item = matchingFile;
            }
        });
    }
}
exports.FileViewController = FileViewController;
