"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWordCount = void 0;
const getWordCount = (text) => {
    if (text.trim().length === 0) {
        return 0;
    }
    return text.split(/\s+/).length;
};
exports.getWordCount = getWordCount;
