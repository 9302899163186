"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultServer = void 0;
const Paths_1 = require("./Paths");
class SharedVaultServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    getSharedVaults() {
        return this.httpService.get(Paths_1.SharedVaultsPaths.getSharedVaults);
    }
    createSharedVault() {
        return this.httpService.post(Paths_1.SharedVaultsPaths.createSharedVault);
    }
    deleteSharedVault(params) {
        return this.httpService.delete(Paths_1.SharedVaultsPaths.deleteSharedVault(params.sharedVaultUuid));
    }
    createSharedVaultFileValetToken(params) {
        return this.httpService.post(Paths_1.SharedVaultsPaths.createSharedVaultFileValetToken(params.sharedVaultUuid), {
            file_uuid: params.fileUuid,
            remote_identifier: params.remoteIdentifier,
            operation: params.operation,
            unencrypted_file_size: params.unencryptedFileSize,
            move_operation_type: params.moveOperationType,
            shared_vault_to_shared_vault_move_target_uuid: params.sharedVaultToSharedVaultMoveTargetUuid,
        });
    }
}
exports.SharedVaultServer = SharedVaultServer;
