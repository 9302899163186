"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNumberedListBlock = void 0;
const list_1 = require("@lexical/list");
function GetNumberedListBlock(editor) {
    return {
        name: 'Numbered List',
        iconName: 'list-numbered',
        keywords: ['numbered list', 'ordered list', 'ol'],
        onSelect: () => editor.dispatchCommand(list_1.INSERT_ORDERED_LIST_COMMAND, undefined),
    };
}
exports.GetNumberedListBlock = GetNumberedListBlock;
