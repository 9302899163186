"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeySystemKeyManager = void 0;
const ApplicationStage_1 = require("./../Application/ApplicationStage");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
const domain_core_1 = require("@standardnotes/domain-core");
const ApplicationEvent_1 = require("../Event/ApplicationEvent");
const RootKeyStorageKeyPrefix = 'key-system-root-key-';
class KeySystemKeyManager extends AbstractService_1.AbstractService {
    constructor(items, mutator, storage, _removeItemsFromMemory, eventBus) {
        super(eventBus);
        this.items = items;
        this.mutator = mutator;
        this.storage = storage;
        this._removeItemsFromMemory = _removeItemsFromMemory;
        this.rootKeyMemoryCache = {};
    }
    deinit() {
        ;
        this.items = undefined;
        this.mutator = undefined;
        this.storage = undefined;
        this._removeItemsFromMemory = undefined;
        super.deinit();
    }
    async handleEvent(event) {
        if (event.type === ApplicationEvent_1.ApplicationEvent.ApplicationStageChanged) {
            const stage = event.payload.stage;
            if (stage === ApplicationStage_1.ApplicationStage.StorageDecrypted_09) {
                this.loadRootKeysFromStorage();
            }
        }
    }
    loadRootKeysFromStorage() {
        const storageKeys = this.storage.getAllKeys().filter((key) => key.startsWith(RootKeyStorageKeyPrefix));
        const keyRawPayloads = storageKeys.map((key) => this.storage.getValue(key));
        const keyPayloads = keyRawPayloads.map((rawPayload) => new models_1.DecryptedPayload(rawPayload));
        const keys = keyPayloads.map((payload) => new models_1.KeySystemRootKey(payload));
        for (const key of keys) {
            this.rootKeyMemoryCache[key.systemIdentifier] = key;
        }
    }
    getRootKeyFromStorageForVault(keySystemIdentifier) {
        const payload = this.storage.getValue(this.storageKeyForRootKey(keySystemIdentifier));
        if (!payload) {
            return undefined;
        }
        const keyPayload = new models_1.DecryptedPayload(payload);
        const key = new models_1.KeySystemRootKey(keyPayload);
        return key;
    }
    getMemCachedRootKey(systemIdentifier) {
        return this.rootKeyMemoryCache[systemIdentifier];
    }
    storageKeyForRootKey(systemIdentifier) {
        return `${RootKeyStorageKeyPrefix}${systemIdentifier}`;
    }
    /**
     * When the key system root key changes, we must re-encrypt all vault items keys
     * with this new key system root key (by simply re-syncing).
     */
    async queueVaultItemsKeysForReencryption(keySystemIdentifier) {
        const keySystemItemsKeys = this.getKeySystemItemsKeys(keySystemIdentifier);
        if (keySystemItemsKeys.length > 0) {
            await this.mutator.setItemsDirty(keySystemItemsKeys);
        }
    }
    cacheKey(key, storage) {
        this.rootKeyMemoryCache[key.systemIdentifier] = key;
        if (storage === models_1.KeySystemRootKeyStorageMode.Local) {
            this.storage.setValue(this.storageKeyForRootKey(key.systemIdentifier), key.payload.ejected());
        }
    }
    removeKeyFromCache(systemIdentifier) {
        delete this.rootKeyMemoryCache[systemIdentifier];
        void this.storage.removeValue(this.storageKeyForRootKey(systemIdentifier));
    }
    getAllSyncedKeySystemRootKeys() {
        return this.items.getItems(domain_core_1.ContentType.TYPES.KeySystemRootKey);
    }
    async wipeVaultKeysFromMemory(vault) {
        delete this.rootKeyMemoryCache[vault.systemIdentifier];
        const itemsKeys = this.getKeySystemItemsKeys(vault.systemIdentifier);
        await this._removeItemsFromMemory.execute(itemsKeys);
    }
    getSyncedKeySystemRootKeysForVault(systemIdentifier) {
        return this.items.itemsMatchingPredicate(domain_core_1.ContentType.TYPES.KeySystemRootKey, new models_1.Predicate('systemIdentifier', '=', systemIdentifier));
    }
    getAllKeySystemRootKeysForVault(systemIdentifier) {
        const synced = this.getSyncedKeySystemRootKeysForVault(systemIdentifier);
        const memory = this.rootKeyMemoryCache[systemIdentifier] ? [this.rootKeyMemoryCache[systemIdentifier]] : [];
        return [...synced, ...memory];
    }
    async deleteNonPersistentSystemRootKeysForVault(systemIdentifier) {
        delete this.rootKeyMemoryCache[systemIdentifier];
        await this.storage.removeValue(this.storageKeyForRootKey(systemIdentifier));
    }
    async deleteAllSyncedKeySystemRootKeys(systemIdentifier) {
        const keys = this.getSyncedKeySystemRootKeysForVault(systemIdentifier);
        await this.mutator.setItemsToBeDeleted(keys);
    }
    getPrimaryKeySystemRootKey(systemIdentifier) {
        const keys = this.getAllKeySystemRootKeysForVault(systemIdentifier);
        const sortedByNewestFirst = keys.sort((a, b) => b.keyParams.creationTimestamp - a.keyParams.creationTimestamp);
        return sortedByNewestFirst[0];
    }
    getAllKeySystemItemsKeys() {
        const decryptedItems = this.items.getItems(domain_core_1.ContentType.TYPES.KeySystemItemsKey);
        const encryptedItems = this.items.invalidItems.filter((item) => item.content_type === domain_core_1.ContentType.TYPES.KeySystemItemsKey);
        return [...decryptedItems, ...encryptedItems];
    }
    getKeySystemItemsKeys(systemIdentifier) {
        return this.items
            .getItems(domain_core_1.ContentType.TYPES.KeySystemItemsKey)
            .filter((key) => key.key_system_identifier === systemIdentifier);
    }
    getPrimaryKeySystemItemsKey(systemIdentifier) {
        const rootKey = this.getPrimaryKeySystemRootKey(systemIdentifier);
        if (!rootKey) {
            throw new Error('No primary key system root key found');
        }
        const matchingItemsKeys = this.getKeySystemItemsKeys(systemIdentifier).filter((key) => key.rootKeyToken === rootKey.token);
        const sortedByNewestFirst = matchingItemsKeys.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        return sortedByNewestFirst[0];
    }
}
exports.KeySystemKeyManager = KeySystemKeyManager;
