"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersistenceService = void 0;
const Persistence_1 = require("@/Components/Preferences/Panes/General/Persistence");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const CrossControllerEvent_1 = require("../CrossControllerEvent");
class PersistenceService {
    constructor(itemListController, navigationController, storage, items, sync, eventBus) {
        this.itemListController = itemListController;
        this.navigationController = navigationController;
        this.storage = storage;
        this.items = items;
        this.sync = sync;
        this.eventBus = eventBus;
        this.didHydrateOnce = false;
        this.hydratePersistedValues = () => {
            this.eventBus.publish({
                type: CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues,
                payload: this.persistenceEnabled ? this.getPersistedValues() : undefined,
            });
        };
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.LocalDataLoaded);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.LocalDataIncrementalLoad);
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues);
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence);
    }
    async handleEvent(event) {
        switch (event.type) {
            case snjs_1.ApplicationEvent.LocalDataLoaded: {
                if (!this.didHydrateOnce) {
                    this.hydratePersistedValues();
                    this.didHydrateOnce = true;
                }
                break;
            }
            case snjs_1.ApplicationEvent.LocalDataIncrementalLoad: {
                const canHydrate = this.items.getItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.Tag]).length > 0;
                if (!canHydrate) {
                    return;
                }
                this.hydratePersistedValues();
                this.didHydrateOnce = true;
                break;
            }
            case CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues: {
                this.hydrateFromPersistedValues(event.payload);
                break;
            }
            case CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence: {
                this.persistCurrentState();
                break;
            }
        }
    }
    get persistenceEnabled() {
        var _a;
        return (_a = this.storage.getValue(Persistence_1.ShouldPersistNoteStateKey)) !== null && _a !== void 0 ? _a : true;
    }
    persistCurrentState() {
        const values = {
            [ui_services_1.PersistenceKey.ItemListController]: this.itemListController.getPersistableValue(),
            [ui_services_1.PersistenceKey.NavigationController]: this.navigationController.getPersistableValue(),
        };
        this.persistValues(values);
        const selectedItemsState = values['selected-items-controller'];
        const navigationSelectionState = values['navigation-controller'];
        const launchPriorityUuids = [];
        if (selectedItemsState.selectedUuids.length) {
            launchPriorityUuids.push(...selectedItemsState.selectedUuids);
        }
        if (navigationSelectionState.selectedTagUuid) {
            launchPriorityUuids.push(navigationSelectionState.selectedTagUuid);
        }
        this.sync.setLaunchPriorityUuids(launchPriorityUuids);
    }
    hydrateFromPersistedValues(values) {
        const navigationState = values === null || values === void 0 ? void 0 : values[ui_services_1.PersistenceKey.NavigationController];
        this.navigationController.hydrateFromPersistedValue(navigationState);
        const selectedItemsState = values === null || values === void 0 ? void 0 : values[ui_services_1.PersistenceKey.ItemListController];
        this.itemListController.hydrateFromPersistedValue(selectedItemsState);
    }
    persistValues(values) {
        if (!this.sync.isDatabaseLoaded()) {
            return;
        }
        if (!this.persistenceEnabled) {
            return;
        }
        this.storage.setValue(ui_services_1.StorageKey.MasterStatePersistenceKey, values);
    }
    clearPersistedValues() {
        if (!this.sync.isDatabaseLoaded()) {
            return;
        }
        void this.storage.removeValue(ui_services_1.StorageKey.MasterStatePersistenceKey);
    }
    getPersistedValues() {
        return this.storage.getValue(ui_services_1.StorageKey.MasterStatePersistenceKey);
    }
}
exports.PersistenceService = PersistenceService;
