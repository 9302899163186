"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebAlertService = void 0;
const services_1 = require("@standardnotes/services");
const utils_1 = require("@standardnotes/utils");
const styles_1 = require("@standardnotes/styles");
const Functions_1 = require("./Functions");
class WebAlertService extends services_1.AlertService {
    confirmV2(dto) {
        return (0, Functions_1.confirmDialog)({
            text: dto.text,
            title: dto.title,
            confirmButtonText: dto.confirmButtonText,
            cancelButtonText: dto.cancelButtonText,
            confirmButtonStyle: dto.confirmButtonType === services_1.ButtonType.Danger ? 'danger' : 'info',
        });
    }
    alertV2(dto) {
        return (0, Functions_1.alertDialog)({ text: dto.text, title: dto.title, closeButtonText: dto.closeButtonText });
    }
    alert(text, title, closeButtonText) {
        return (0, Functions_1.alertDialog)({ text, title, closeButtonText });
    }
    confirm(text, title, confirmButtonText, confirmButtonType, cancelButtonText) {
        return (0, Functions_1.confirmDialog)({
            text,
            title,
            confirmButtonText,
            cancelButtonText,
            confirmButtonStyle: confirmButtonType === services_1.ButtonType.Danger ? 'danger' : 'info',
        });
    }
    blockingDialog(text, title) {
        const alert = new styles_1.SKAlert({
            title: title && (0, utils_1.sanitizeHtmlString)(title),
            text: (0, utils_1.sanitizeHtmlString)(text),
            buttons: [],
        });
        alert.present();
        return () => {
            alert.dismiss();
        };
    }
}
exports.WebAlertService = WebAlertService;
