"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const markdown_1 = require("@lexical/markdown");
const MarkdownTransformers_1 = require("../../MarkdownTransformers");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const GetMarkdownPlugin = (0, react_1.forwardRef)((_, ref) => {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useImperativeHandle)(ref, () => ({
        getMarkdown() {
            return getMarkdown();
        },
    }));
    const getMarkdown = (0, react_1.useCallback)(() => {
        return editor.getEditorState().read(() => {
            return (0, markdown_1.$convertToMarkdownString)(MarkdownTransformers_1.MarkdownTransformers);
        });
    }, [editor]);
    return null;
});
exports.default = GetMarkdownPlugin;
