"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParseConsistentBase64JsonPayloadUseCase = void 0;
class ParseConsistentBase64JsonPayloadUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(stringifiedData) {
        return JSON.parse(this.crypto.base64Decode(stringifiedData));
    }
}
exports.ParseConsistentBase64JsonPayloadUseCase = ParseConsistentBase64JsonPayloadUseCase;
