"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStateRef = void 0;
const react_1 = require("react");
function useStateRef(state) {
    const ref = (0, react_1.useRef)(state);
    (0, react_1.useLayoutEffect)(() => {
        ref.current = state;
    }, [state]);
    return ref;
}
exports.useStateRef = useStateRef;
