"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeAndSaveItem = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class ChangeAndSaveItem {
    constructor(items, mutator, sync) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
    }
    async execute(itemToLookupUuidFor, mutate, updateTimestamps = true, emitSource, syncOptions) {
        await this.mutator.changeItems([itemToLookupUuidFor], mutate, updateTimestamps ? models_1.MutationType.UpdateUserTimestamps : models_1.MutationType.NoUpdateUserTimestamps, emitSource);
        await this.sync.sync(syncOptions);
        return domain_core_1.Result.ok(this.items.findItem(itemToLookupUuidFor.uuid));
    }
}
exports.ChangeAndSaveItem = ChangeAndSaveItem;
