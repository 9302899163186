"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLifecycleAnimation = void 0;
const react_1 = require("react");
const useStateRef_1 = require("./useStateRef");
/**
 * A hook that animates an element when it mounts and unmounts.
 * Does not handle DOM insertion/removal. Use the `isMounted` return value to conditionally render the element.
 * @param open Whether the element is open or not
 * @param enter The animation to play when the element mounts
 * @param enterCallback A callback to run after the enter animation finishes
 * @param exit The animation to play when the element unmounts
 * @param exitCallback A callback to run after the exit animation finishes
 * @returns A tuple containing whether the element can be mounted and a ref callback to set the element
 */
const useLifecycleAnimation = ({ open, enter, enterCallback, exit, exitCallback }, disabled = false) => {
    const [element, setElement] = (0, react_1.useState)(null);
    const [isMounted, setIsMounted] = (0, react_1.useState)(() => open);
    (0, react_1.useEffect)(() => {
        if (disabled || open) {
            setIsMounted(open);
        }
    }, [disabled, open]);
    // Using "state ref"s to prevent changes from re-running the effect below
    // We only want changes to `open` and `element` to re-run the effect
    const enterRef = (0, useStateRef_1.useStateRef)(enter);
    const enterCallbackRef = (0, useStateRef_1.useStateRef)(enterCallback);
    const exitRef = (0, useStateRef_1.useStateRef)(exit);
    const exitCallbackRef = (0, useStateRef_1.useStateRef)(exitCallback);
    (0, react_1.useEffect)(() => {
        if (!element) {
            return;
        }
        if (disabled) {
            setIsMounted(open);
            return;
        }
        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
        const enter = enterRef.current;
        const enterCallback = enterCallbackRef.current;
        const exit = exitRef.current;
        const exitCallback = exitCallbackRef.current;
        if (prefersReducedMotion && !(enter === null || enter === void 0 ? void 0 : enter.reducedMotionKeyframes) && !(exit === null || exit === void 0 ? void 0 : exit.reducedMotionKeyframes)) {
            setIsMounted(open);
            return;
        }
        let animation;
        if (open) {
            if (!enter) {
                setIsMounted(true);
                enterCallback === null || enterCallback === void 0 ? void 0 : enterCallback(element);
                return;
            }
            if (enter.initialStyle) {
                Object.assign(element.style, enter.initialStyle);
            }
            animation = element.animate(prefersReducedMotion && enter.reducedMotionKeyframes ? enter.reducedMotionKeyframes : enter.keyframes, {
                ...enter.options,
                fill: 'forwards',
            });
            animation.finished
                .then(() => {
                enterCallback === null || enterCallback === void 0 ? void 0 : enterCallback(element);
            })
                .catch((error) => {
                if ((animation === null || animation === void 0 ? void 0 : animation.currentTime) === null) {
                    return;
                }
                console.error(error);
            });
        }
        else {
            if (!exit) {
                setIsMounted(false);
                exitCallback === null || exitCallback === void 0 ? void 0 : exitCallback(element);
                return;
            }
            if (exit.initialStyle) {
                Object.assign(element.style, exit.initialStyle);
            }
            animation = element.animate(prefersReducedMotion && exit.reducedMotionKeyframes ? exit.reducedMotionKeyframes : exit.keyframes, {
                ...exit.options,
                fill: 'forwards',
            });
            animation.finished
                .then(() => {
                setIsMounted(false);
                exitCallback === null || exitCallback === void 0 ? void 0 : exitCallback(element);
            })
                .catch((error) => {
                if ((animation === null || animation === void 0 ? void 0 : animation.currentTime) === null) {
                    return;
                }
                console.error(error);
            });
        }
        return () => {
            animation === null || animation === void 0 ? void 0 : animation.cancel();
        };
    }, [open, element, enterRef, enterCallbackRef, exitRef, exitCallbackRef, disabled]);
    return [isMounted, setElement];
};
exports.useLifecycleAnimation = useLifecycleAnimation;
