"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReuploadAllInvites = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class ReuploadAllInvites {
    constructor(reuploadInvite, findContact, inviteServer) {
        this.reuploadInvite = reuploadInvite;
        this.findContact = findContact;
        this.inviteServer = inviteServer;
    }
    async execute(params) {
        const invites = await this.getExistingInvites();
        if (invites.isFailed()) {
            return invites;
        }
        const deleteResult = await this.deleteExistingInvites();
        if (deleteResult.isFailed()) {
            return deleteResult;
        }
        const errors = [];
        for (const invite of invites.getValue()) {
            const recipient = this.findContact.execute({ userUuid: invite.user_uuid });
            if (recipient.isFailed()) {
                errors.push(`Contact not found for invite ${invite.user_uuid}`);
                continue;
            }
            const result = await this.reuploadInvite.execute({
                keys: params.keys,
                previousKeys: params.previousKeys,
                recipient: recipient.getValue(),
                previousInvite: invite,
            });
            if (result.isFailed()) {
                errors.push(result.getError());
            }
        }
        if (errors.length > 0) {
            return domain_core_1.Result.fail(errors.join(', '));
        }
        return domain_core_1.Result.ok();
    }
    async getExistingInvites() {
        const response = await this.inviteServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to get outbound user invites ${JSON.stringify(response)}`);
        }
        const invites = response.data.invites;
        return domain_core_1.Result.ok(invites);
    }
    async deleteExistingInvites() {
        const response = await this.inviteServer.deleteAllOutboundInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to delete existing invites ${JSON.stringify(response)}`);
        }
        return domain_core_1.Result.ok();
    }
}
exports.ReuploadAllInvites = ReuploadAllInvites;
