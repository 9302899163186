"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const baseClassNames = 'border border-solid w-3 h-3 p-0 rounded-full flex-shrink-0';
const IndicatorCircle = ({ style }) => {
    switch (style) {
        case 'neutral':
            return (0, jsx_runtime_1.jsx)("div", { className: `${baseClassNames} border-neutral bg-neutral` });
        case 'info':
            return (0, jsx_runtime_1.jsx)("div", { className: `${baseClassNames} border-info bg-info` });
        case 'danger':
            return (0, jsx_runtime_1.jsx)("div", { className: `${baseClassNames} border-danger bg-danger` });
    }
};
exports.default = IndicatorCircle;
