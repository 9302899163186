"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const react_1 = require("react");
const code_1 = require("@lexical/code");
const lexical_1 = require("lexical");
const markdown_1 = require("@lexical/markdown");
const MarkdownTransformers_1 = require("../../MarkdownTransformers");
function MarkdownPreviewPlugin({ onMarkdown }) {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        editor.update(() => {
            const root = (0, lexical_1.$getRoot)();
            const paragraphs = (0, lexical_1.$nodesOfType)(lexical_1.ParagraphNode);
            for (const paragraph of paragraphs) {
                if (paragraph.isEmpty()) {
                    paragraph.remove();
                }
            }
            const markdown = (0, markdown_1.$convertToMarkdownString)(MarkdownTransformers_1.MarkdownTransformers);
            root.clear().append((0, code_1.$createCodeNode)('markdown').append((0, lexical_1.$createTextNode)(markdown)));
            root.selectEnd();
            onMarkdown(markdown);
        });
    }, [editor, onMarkdown]);
    return null;
}
exports.default = MarkdownPreviewPlugin;
