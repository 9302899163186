"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyVaultUsersOfKeyRotation = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class NotifyVaultUsersOfKeyRotation {
    constructor(_findContact, _sendKeyChangedMessage, _inviteToVault, _inviteServer, _getVaultContacts, _decryptOwnMessage, _getKeyPairs) {
        this._findContact = _findContact;
        this._sendKeyChangedMessage = _sendKeyChangedMessage;
        this._inviteToVault = _inviteToVault;
        this._inviteServer = _inviteServer;
        this._getVaultContacts = _getVaultContacts;
        this._decryptOwnMessage = _decryptOwnMessage;
        this._getKeyPairs = _getKeyPairs;
    }
    async execute(params) {
        await this.reinvitePendingInvites(params);
        await this.performSendKeyChangeMessage(params);
        return domain_core_1.Result.ok();
    }
    async reinvitePendingInvites(params) {
        const existingInvites = await this.getExistingInvites(params.sharedVault.sharing.sharedVaultUuid);
        if (existingInvites.isFailed()) {
            return existingInvites;
        }
        await this.deleteAllInvites(params.sharedVault.sharing.sharedVaultUuid);
        const contacts = await this._getVaultContacts.execute({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail('Cannot send metadata changed message; keys not found');
        }
        for (const invite of existingInvites.getValue()) {
            const recipient = this._findContact.execute({ userUuid: invite.user_uuid });
            if (recipient.isFailed()) {
                continue;
            }
            const decryptedPreviousInvite = this._decryptOwnMessage.execute({
                message: invite.encrypted_message,
                privateKey: keys.getValue().encryption.privateKey,
                recipientPublicKey: recipient.getValue().publicKeySet.encryption,
            });
            if (decryptedPreviousInvite.isFailed()) {
                return domain_core_1.Result.fail(decryptedPreviousInvite.getError());
            }
            await this._inviteToVault.execute({
                sharedVault: params.sharedVault,
                sharedVaultContacts: !contacts.isFailed() ? contacts.getValue() : [],
                recipient: recipient.getValue(),
                permission: invite.permission,
            });
        }
        return domain_core_1.Result.ok();
    }
    async performSendKeyChangeMessage(params) {
        const result = await this._sendKeyChangedMessage.execute({
            keySystemIdentifier: params.sharedVault.systemIdentifier,
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
        });
        if (result.isFailed()) {
            return result;
        }
        return domain_core_1.Result.ok();
    }
    async deleteAllInvites(sharedVaultUuid) {
        const response = await this._inviteServer.deleteAllSharedVaultInvites({
            sharedVaultUuid: sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to delete existing invites ${JSON.stringify(response)}`);
        }
        return domain_core_1.Result.ok();
    }
    async getExistingInvites(sharedVaultUuid) {
        const response = await this._inviteServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail(`Failed to get outbound user invites ${JSON.stringify(response)}`);
        }
        const invites = response.data.invites;
        return domain_core_1.Result.ok(invites.filter((invite) => invite.shared_vault_uuid === sharedVaultUuid));
    }
}
exports.NotifyVaultUsersOfKeyRotation = NotifyVaultUsersOfKeyRotation;
