"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestCloseAllOpenModalsAndPopovers = void 0;
const requestCloseAllOpenModalsAndPopovers = () => {
    document.querySelectorAll('[role="dialog"], [data-popover]').forEach((element) => {
        // We add the close method to dialog & popovers to allow us to
        // close them from anywhere in the app.
        if ('close' in element && typeof element.close === 'function') {
            element.close();
        }
    });
};
exports.requestCloseAllOpenModalsAndPopovers = requestCloseAllOpenModalsAndPopovers;
