"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockPickerOption = void 0;
const LexicalTypeaheadMenuPlugin_1 = require("@lexical/react/LexicalTypeaheadMenuPlugin");
class BlockPickerOption extends LexicalTypeaheadMenuPlugin_1.MenuOption {
    constructor(title, options) {
        super(title);
        this.title = title;
        this.keywords = options.keywords || [];
        this.iconName = options.iconName;
        this.keyboardShortcut = options.keyboardShortcut;
        this.onSelect = options.onSelect.bind(this);
    }
}
exports.BlockPickerOption = BlockPickerOption;
