"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.performSafariAnimationFix = exports.animatePaneExitTransitionOffscreenToTheRight = exports.animatePaneEntranceTransitionFromOffscreenToTheRight = void 0;
const Logging_1 = require("@/Logging");
const ENTRANCE_DURATION = 200;
const EXIT_DURATION = 200;
async function animatePaneEntranceTransitionFromOffscreenToTheRight(elementId) {
    (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Animating pane entrance transition from offscreen to the right', elementId);
    const element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    const animation = element.animate([
        {
            transform: 'translateX(100%)',
        },
        {
            transform: 'translateX(0)',
        },
    ], {
        duration: ENTRANCE_DURATION,
        easing: 'ease-in-out',
        fill: 'both',
    });
    await animation.finished;
    performSafariAnimationFix(element);
    animation.finish();
}
exports.animatePaneEntranceTransitionFromOffscreenToTheRight = animatePaneEntranceTransitionFromOffscreenToTheRight;
async function animatePaneExitTransitionOffscreenToTheRight(elementId) {
    (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Animating pane exit transition offscreen to the right', elementId);
    const element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    const animation = element.animate([
        {
            transform: 'translateX(100%)',
        },
    ], {
        duration: EXIT_DURATION,
        easing: 'ease-in-out',
        fill: 'both',
    });
    await animation.finished;
    animation.finish();
}
exports.animatePaneExitTransitionOffscreenToTheRight = animatePaneExitTransitionOffscreenToTheRight;
/**
 * Safari has a bug where animations don't properly commit sometimes and a tap on the screen or click anywhere fixes it.
 * The workaround here is just to get Safari to recompute the element layout. This issue manifests itself when selecting
 * a Daily Notebook tag from navigation whereby two pane animations are simulatensouly triggered (the items panel, then
 * the editor panel), causing Safari to get tripped up.
 */
function performSafariAnimationFix(element) {
    const isSafari = /Safari/.test(navigator.userAgent) || /AppleWebKit/.test(navigator.userAgent);
    if (!isSafari) {
        return;
    }
    element.style.opacity = '0.999';
    setTimeout(() => {
        element.style.opacity = '1.0';
    }, 0);
}
exports.performSafariAnimationFix = performSafariAnimationFix;
