"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSyncOperator = exports.isAsyncOperator = void 0;
function isAsyncOperator(operator) {
    return 'generateEncryptedParametersAsync' in operator;
}
exports.isAsyncOperator = isAsyncOperator;
function isSyncOperator(operator) {
    return !isAsyncOperator(operator);
}
exports.isSyncOperator = isSyncOperator;
