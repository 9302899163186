"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareContactWithVault = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class ShareContactWithVault {
    constructor(users, _findContact, _encryptMessage, _sendMessage, _getVaultUsers, _getKeyPairs) {
        this.users = users;
        this._findContact = _findContact;
        this._encryptMessage = _encryptMessage;
        this._sendMessage = _sendMessage;
        this._getVaultUsers = _getVaultUsers;
        this._getKeyPairs = _getKeyPairs;
    }
    async execute(params) {
        if (params.sharedVault.sharing.ownerUserUuid !== this.users.sureUser.uuid) {
            return domain_core_1.Result.fail('Cannot share contact; user is not the owner of the shared vault');
        }
        const users = await this._getVaultUsers.execute({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (users.isFailed()) {
            return domain_core_1.Result.fail('Cannot share contact; shared vault users not found');
        }
        if (users.getValue().length === 0) {
            return domain_core_1.Result.ok();
        }
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail('Cannot share contact; keys not found');
        }
        for (const vaultUser of users.getValue()) {
            if (vaultUser.user_uuid === this.users.sureUser.uuid) {
                continue;
            }
            if (vaultUser.user_uuid === params.contactToShare.contactUuid) {
                continue;
            }
            const vaultUserAsContact = this._findContact.execute({ userUuid: vaultUser.user_uuid });
            if (vaultUserAsContact.isFailed()) {
                continue;
            }
            const encryptedMessage = this._encryptMessage.execute({
                message: {
                    type: models_1.AsymmetricMessagePayloadType.ContactShare,
                    data: {
                        recipientUuid: vaultUserAsContact.getValue().contactUuid,
                        trustedContact: params.contactToShare.content,
                    },
                },
                keys: keys.getValue(),
                recipientPublicKey: vaultUserAsContact.getValue().publicKeySet.encryption,
            });
            if (encryptedMessage.isFailed()) {
                continue;
            }
            await this._sendMessage.execute({
                recipientUuid: vaultUserAsContact.getValue().contactUuid,
                encryptedMessage: encryptedMessage.getValue(),
                replaceabilityIdentifier: undefined,
            });
        }
        return domain_core_1.Result.ok();
    }
}
exports.ShareContactWithVault = ShareContactWithVault;
