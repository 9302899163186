"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shareSelectedNotes = void 0;
const NoteExportUtils_1 = require("@/Utils/NoteExportUtils");
const filepicker_1 = require("@standardnotes/filepicker");
const ui_services_1 = require("@standardnotes/ui-services");
const ShareBlobOnMobile_1 = require("./ShareBlobOnMobile");
const shareSelectedNotes = async (application, notes) => {
    if (!application.isNativeMobileWeb()) {
        throw new Error('Share function being used outside mobile webview');
    }
    if (notes.length === 1) {
        const note = notes[0];
        const blob = (0, NoteExportUtils_1.getNoteBlob)(application, note);
        const { name, ext } = (0, filepicker_1.parseFileName)((0, NoteExportUtils_1.getNoteFileName)(application, note));
        const filename = `${(0, ui_services_1.sanitizeFileName)(name)}.${ext}`;
        void (0, ShareBlobOnMobile_1.shareBlobOnMobile)(application.mobileDevice, application.isNativeMobileWeb(), blob, filename);
        return;
    }
    if (notes.length > 1) {
        const zippedDataBlob = await application.archiveService.zipData(notes.map((note) => {
            return {
                name: (0, NoteExportUtils_1.getNoteFileName)(application, note),
                content: (0, NoteExportUtils_1.getNoteBlob)(application, note),
            };
        }));
        void (0, ShareBlobOnMobile_1.shareBlobOnMobile)(application.mobileDevice, application.isNativeMobileWeb(), zippedDataBlob, `Standard Notes Export - ${application.archiveService.formattedDateForExports()}.zip`);
    }
};
exports.shareSelectedNotes = shareSelectedNotes;
