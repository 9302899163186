"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteThirdPartyVault = void 0;
class DeleteThirdPartyVault {
    constructor(items, mutator, keys, sync, _discardItemsLocally) {
        this.items = items;
        this.mutator = mutator;
        this.keys = keys;
        this.sync = sync;
        this._discardItemsLocally = _discardItemsLocally;
    }
    async execute(vault) {
        await this.deleteDataSharedByVaultUsers(vault);
        await this.deleteDataOwnedByThisUser(vault);
        await this.keys.deleteNonPersistentSystemRootKeysForVault(vault.systemIdentifier);
        void this.sync.sync({ sourceDescription: 'Not awaiting due to this event handler running from sync response' });
    }
    /**
     * This data is shared with all vault users and does not belong to this particular user
     * The data will be removed locally without syncing the items
     */
    async deleteDataSharedByVaultUsers(vault) {
        const vaultItems = (this.items.allTrackedItems().filter((item) => item.key_system_identifier === vault.systemIdentifier));
        const itemsKeys = this.keys.getKeySystemItemsKeys(vault.systemIdentifier);
        await this._discardItemsLocally.execute([...vaultItems, ...itemsKeys]);
    }
    async deleteDataOwnedByThisUser(vault) {
        const rootKeys = this.keys.getSyncedKeySystemRootKeysForVault(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(rootKeys);
        await this.mutator.setItemToBeDeleted(vault);
    }
}
exports.DeleteThirdPartyVault = DeleteThirdPartyVault;
