"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPositionedPopoverStyles = void 0;
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const Utils_1 = require("@/Utils");
const Collisions_1 = require("./Utils/Collisions");
const Rect_1 = require("./Utils/Rect");
const percentOf = (percent, value) => (percent / 100) * value;
const getTransformOrigin = (side, align) => {
    switch (side) {
        case 'top':
            switch (align) {
                case 'start':
                    return 'bottom left';
                case 'center':
                    return 'bottom center';
                case 'end':
                    return 'bottom right';
            }
            break;
        case 'bottom':
            switch (align) {
                case 'start':
                    return 'top left';
                case 'center':
                    return 'top center';
                case 'end':
                    return 'top right';
            }
            break;
        case 'left':
            switch (align) {
                case 'start':
                    return 'top right';
                case 'center':
                    return 'top center';
                case 'end':
                    return 'bottom right';
            }
            break;
        case 'right':
            switch (align) {
                case 'start':
                    return 'top left';
                case 'center':
                    return 'top center';
                case 'end':
                    return 'bottom left';
            }
    }
};
const getStylesFromRect = (options) => {
    const { rect, disableMobileFullscreenTakeover = false, maxHeight = 'none' } = options;
    const canApplyMaxHeight = maxHeight !== 'none' && (!(0, Utils_1.isMobileScreen)() || disableMobileFullscreenTakeover);
    const shouldApplyMobileWidth = (0, Utils_1.isMobileScreen)() && disableMobileFullscreenTakeover;
    const marginForMobile = percentOf(10, window.innerWidth);
    return {
        willChange: 'transform',
        '--translate-x': `${shouldApplyMobileWidth ? marginForMobile / 2 : Math.floor(rect.x)}px`,
        '--translate-y': `${Math.floor(rect.y)}px`,
        '--offset': `${options.offset}px`,
        transform: 'translate3d(var(--translate-x), var(--translate-y), 0)',
        '--transform-origin': getTransformOrigin(options.side, options.align),
        visibility: 'visible',
        ...(canApplyMaxHeight && {
            maxHeight: `${maxHeight}px`,
        }),
        ...(shouldApplyMobileWidth && {
            width: `${window.innerWidth - marginForMobile}px`,
        }),
    };
};
const getPositionedPopoverStyles = ({ align, anchorRect, documentRect, popoverRect, side, disableMobileFullscreenTakeover, maxHeightFunction, offset, }) => {
    if (!popoverRect || !anchorRect) {
        return null;
    }
    const matchesMediumBreakpoint = matchMedia(useMediaQuery_1.MediaQueryBreakpoints.md).matches;
    if (!matchesMediumBreakpoint && !disableMobileFullscreenTakeover) {
        return null;
    }
    const rectForPreferredSide = (0, Rect_1.getPositionedPopoverRect)(popoverRect, anchorRect, side, align);
    const preferredSideRectCollisions = (0, Collisions_1.checkCollisions)(rectForPreferredSide, documentRect);
    const oppositeSide = Collisions_1.OppositeSide[side];
    const sideWithLessOverflows = preferredSideRectCollisions[side] ? oppositeSide : side;
    const finalAlignment = (0, Collisions_1.getNonCollidingAlignment)({
        finalSide: sideWithLessOverflows,
        preferredAlignment: align,
        collisions: preferredSideRectCollisions,
        popoverRect,
        buttonRect: anchorRect,
        documentRect,
    });
    const finalPositionedRect = (0, Rect_1.getPositionedPopoverRect)(popoverRect, anchorRect, sideWithLessOverflows, finalAlignment, offset);
    let maxHeight = (0, Rect_1.getPopoverMaxHeight)((0, Rect_1.getAppRect)(), anchorRect, sideWithLessOverflows, finalAlignment, disableMobileFullscreenTakeover);
    if (maxHeightFunction && typeof maxHeight === 'number') {
        maxHeight = maxHeightFunction(maxHeight);
    }
    return getStylesFromRect({
        rect: finalPositionedRect,
        side: sideWithLessOverflows,
        align: finalAlignment,
        disableMobileFullscreenTakeover,
        maxHeight,
        offset,
    });
};
exports.getPositionedPopoverStyles = getPositionedPopoverStyles;
