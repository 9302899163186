"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.doesItemMatchSearchQuery = exports.getItemTitleInContextOfLinkBubble = void 0;
const snjs_1 = require("@standardnotes/snjs");
function getItemTitleInContextOfLinkBubble(item) {
    return item.title && item.title.length > 0 ? item.title : (0, snjs_1.isNote)(item) ? item.preview_plain : '';
}
exports.getItemTitleInContextOfLinkBubble = getItemTitleInContextOfLinkBubble;
function getItemSearchableString(item, application) {
    var _a;
    if ((0, snjs_1.isNote)(item)) {
        return item.title.length > 0 ? item.title : item.preview_plain;
    }
    else if ((0, snjs_1.isTag)(item)) {
        return application.items.getTagLongTitle(item);
    }
    return (_a = item.title) !== null && _a !== void 0 ? _a : '';
}
function doesItemMatchSearchQuery(item, searchQuery, application) {
    const title = getItemSearchableString(item, application).toLowerCase();
    const matchesQuery = title.includes(searchQuery.toLowerCase());
    const isArchivedOrTrashed = item.archived || item.trashed;
    const isValidSearchResult = matchesQuery && !isArchivedOrTrashed;
    return isValidSearchResult;
}
exports.doesItemMatchSearchQuery = doesItemMatchSearchQuery;
