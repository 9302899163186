"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertToSharedVault = void 0;
const responses_1 = require("@standardnotes/responses");
class ConvertToSharedVault {
    constructor(mutator, sharedVaultServer, _moveItemsToVault, _getVaultItems) {
        this.mutator = mutator;
        this.sharedVaultServer = sharedVaultServer;
        this._moveItemsToVault = _moveItemsToVault;
        this._getVaultItems = _getVaultItems;
    }
    async execute(dto) {
        if (dto.vault.isSharedVaultListing()) {
            throw new Error('Cannot convert a shared vault to a shared vault');
        }
        const serverResult = await this.sharedVaultServer.createSharedVault();
        if ((0, responses_1.isErrorResponse)(serverResult)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to convert to shared vault ${JSON.stringify(serverResult)}`);
        }
        const serverVaultHash = serverResult.data.sharedVault;
        const sharedVaultListing = await this.mutator.changeItem(dto.vault, (mutator) => {
            mutator.sharing = {
                sharedVaultUuid: serverVaultHash.uuid,
                ownerUserUuid: serverVaultHash.user_uuid,
                fileBytesUsed: serverVaultHash.file_upload_bytes_used,
            };
        });
        const vaultItems = this._getVaultItems.execute(sharedVaultListing).getValue();
        await this._moveItemsToVault.execute({ vault: sharedVaultListing, items: vaultItems });
        return sharedVaultListing;
    }
}
exports.ConvertToSharedVault = ConvertToSharedVault;
