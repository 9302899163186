"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@nanostores/react");
const toastStore_1 = require("./toastStore");
const ToastTimer_1 = require("./ToastTimer");
const ToastContainer = () => {
    const toasts = (0, react_1.useStore)(toastStore_1.toastStore);
    if (!toasts.length) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "z-toast fixed bottom-6 right-6 flex w-[calc(100%-3rem)] flex-col items-end md:w-auto", children: toasts.map((toast, index) => ((0, jsx_runtime_1.jsx)(ToastTimer_1.ToastTimer, { toast: toast, index: index }, toast.id))) }));
};
exports.ToastContainer = ToastContainer;
