"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkingController = void 0;
const PopoverFileItemAction_1 = require("@/Components/AttachedFilesPopover/PopoverFileItemAction");
const NoteViewController_1 = require("@/Components/NoteView/Controller/NoteViewController");
const AppPaneMetadata_1 = require("@/Components/Panes/AppPaneMetadata");
const createLinkFromItem_1 = require("@/Utils/Items/Search/createLinkFromItem");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const CrossControllerEvent_1 = require("./CrossControllerEvent");
const FeatureTrunk_1 = require("@/FeatureTrunk");
class LinkingController extends AbstractViewController_1.AbstractViewController {
    constructor(itemListController, filesController, subscriptionController, navigationController, itemControllerGroup, vaultDisplayService, preferences, items, mutator, sync, vaults, eventBus) {
        super(eventBus);
        this.itemListController = itemListController;
        this.filesController = filesController;
        this.subscriptionController = subscriptionController;
        this.navigationController = navigationController;
        this.itemControllerGroup = itemControllerGroup;
        this.vaultDisplayService = vaultDisplayService;
        this.preferences = preferences;
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.vaults = vaults;
        this.isLinkingPanelOpen = false;
        this.setIsLinkingPanelOpen = (open) => {
            this.isLinkingPanelOpen = open;
        };
        this.getFilesLinksForItem = (item) => {
            if (!item || this.items.isTemplateItem(item)) {
                return {
                    filesLinkedToItem: [],
                    filesLinkingToItem: [],
                };
            }
            const referencesOfItem = (0, snjs_1.naturalSort)(this.items.referencesForItem(item).filter(snjs_1.isFile), 'title');
            const referencingItem = (0, snjs_1.naturalSort)(this.items.itemsReferencingItem(item).filter(snjs_1.isFile), 'title');
            if (item.content_type === snjs_1.ContentType.TYPES.File) {
                return {
                    filesLinkedToItem: referencesOfItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked')),
                    filesLinkingToItem: referencingItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by')),
                };
            }
            else {
                return {
                    filesLinkedToItem: referencingItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked')),
                    filesLinkingToItem: referencesOfItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by')),
                };
            }
        };
        this.getLinkedTagsForItem = (item) => {
            if (!item) {
                return;
            }
            return this.items.getSortedTagsForItem(item).map((tag) => (0, createLinkFromItem_1.createLinkFromItem)(tag, 'linked'));
        };
        this.getLinkedNotesForItem = (item) => {
            if (!item || this.items.isTemplateItem(item)) {
                return [];
            }
            return (0, snjs_1.naturalSort)(this.items.referencesForItem(item).filter(snjs_1.isNote), 'title').map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked'));
        };
        this.getNotesLinkingToItem = (item) => {
            if (!item) {
                return [];
            }
            return (0, snjs_1.naturalSort)(this.items.itemsReferencingItem(item).filter(snjs_1.isNote), 'title').map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by'));
        };
        this.activateItem = async (item) => {
            this.setIsLinkingPanelOpen(false);
            if (item instanceof snjs_1.SNTag) {
                await this.navigationController.setSelectedTag(item, 'all');
                return AppPaneMetadata_1.AppPaneId.Items;
            }
            else if (item instanceof snjs_1.SNNote) {
                await this.navigationController.selectHomeNavigationView();
                const { didSelect } = await this.itemListController.selectItem(item.uuid, true);
                if (didSelect) {
                    return AppPaneMetadata_1.AppPaneId.Editor;
                }
            }
            else if (item instanceof snjs_1.FileItem) {
                await this.filesController.handleFileAction({
                    type: PopoverFileItemAction_1.FileItemActionType.PreviewFile,
                    payload: {
                        file: item,
                        otherFiles: [],
                    },
                });
            }
            return undefined;
        };
        this.unlinkItems = async (item, itemToUnlink) => {
            try {
                await this.mutator.unlinkItems(item, itemToUnlink);
            }
            catch (error) {
                console.error(error);
            }
            void this.sync.sync();
        };
        this.unlinkItemFromSelectedItem = async (itemToUnlink) => {
            const selectedItem = this.itemListController.firstSelectedItem;
            if (!selectedItem) {
                return;
            }
            void this.unlinkItems(selectedItem, itemToUnlink);
        };
        this.ensureActiveItemIsInserted = async () => {
            const activeItemController = this.itemListController.getActiveItemController();
            if (activeItemController instanceof NoteViewController_1.NoteViewController && activeItemController.isTemplateNote) {
                await activeItemController.insertTemplatedNote();
            }
        };
        this.linkItems = async (item, itemToLink) => {
            var _a;
            const linkNoteAndFile = async (note, file) => {
                const updatedFile = await this.mutator.associateFileWithNote(file, note);
                if ((0, FeatureTrunk_1.featureTrunkVaultsEnabled)()) {
                    if (updatedFile) {
                        const noteVault = this.vaults.getItemVault(note);
                        const fileVault = this.vaults.getItemVault(updatedFile);
                        if (noteVault && !fileVault) {
                            await this.vaults.moveItemToVault(noteVault, file);
                        }
                    }
                }
            };
            const linkFileAndFile = async (file1, file2) => {
                await this.mutator.linkFileToFile(file1, file2);
            };
            const linkNoteToNote = async (note1, note2) => {
                await this.mutator.linkNoteToNote(note1, note2);
            };
            const linkTagToNote = async (tag, note) => {
                await this.addTagToItem(tag, note);
            };
            const linkTagToFile = async (tag, file) => {
                await this.addTagToItem(tag, file);
            };
            if ((0, snjs_1.isNote)(item)) {
                if ((0, snjs_1.isNote)(itemToLink) && !this.isEntitledToNoteLinking) {
                    void this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal, {
                        featureName: 'Note linking',
                    });
                    return;
                }
                if (item.uuid === ((_a = this.activeItem) === null || _a === void 0 ? void 0 : _a.uuid)) {
                    await this.ensureActiveItemIsInserted();
                }
                if ((0, snjs_1.isFile)(itemToLink)) {
                    await linkNoteAndFile(item, itemToLink);
                }
                else if ((0, snjs_1.isNote)(itemToLink)) {
                    await linkNoteToNote(item, itemToLink);
                }
                else if ((0, snjs_1.isTag)(itemToLink)) {
                    await linkTagToNote(itemToLink, item);
                }
                else {
                    throw Error('Invalid item type');
                }
            }
            else if ((0, snjs_1.isFile)(item)) {
                if ((0, snjs_1.isNote)(itemToLink)) {
                    await linkNoteAndFile(itemToLink, item);
                }
                else if ((0, snjs_1.isFile)(itemToLink)) {
                    await linkFileAndFile(item, itemToLink);
                }
                else if ((0, snjs_1.isTag)(itemToLink)) {
                    await linkTagToFile(itemToLink, item);
                }
                else {
                    throw Error('Invalid item to link');
                }
            }
            else {
                throw new Error('First item must be a note or file');
            }
            void this.sync.sync();
        };
        this.linkItemToSelectedItem = async (itemToLink) => {
            const cannotLinkItem = !this.isEntitledToNoteLinking && itemToLink instanceof snjs_1.SNNote;
            if (cannotLinkItem) {
                void this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal, {
                    featureName: 'Note linking',
                });
                return false;
            }
            await this.ensureActiveItemIsInserted();
            const activeItem = this.activeItem;
            if (!activeItem) {
                return false;
            }
            await this.linkItems(activeItem, itemToLink);
            return true;
        };
        this.createAndAddNewTag = async (title) => {
            await this.ensureActiveItemIsInserted();
            const vault = this.vaultDisplayService.exclusivelyShownVault;
            const newTag = await this.mutator.findOrCreateTag(title, vault);
            const activeItem = this.activeItem;
            if (activeItem) {
                await this.addTagToItem(newTag, activeItem);
            }
            return newTag;
        };
        this.addTagToItem = async (tag, item) => {
            if (item instanceof snjs_1.SNNote) {
                await this.mutator.addTagToNote(item, tag, this.shouldLinkToParentFolders);
            }
            else if (item instanceof snjs_1.FileItem) {
                await this.mutator.addTagToFile(item, tag, this.shouldLinkToParentFolders);
            }
            this.sync.sync().catch(console.error);
        };
        (0, mobx_1.makeObservable)(this, {
            isLinkingPanelOpen: mobx_1.observable,
            isEntitledToNoteLinking: mobx_1.computed,
            setIsLinkingPanelOpen: mobx_1.action,
        });
        this.shouldLinkToParentFolders = preferences.getValue(snjs_1.PrefKey.NoteAddToParentFolders, snjs_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.PreferencesChanged);
    }
    handleEvent(event) {
        switch (event.type) {
            case snjs_1.ApplicationEvent.PreferencesChanged: {
                this.shouldLinkToParentFolders = this.preferences.getValue(snjs_1.PrefKey.NoteAddToParentFolders, snjs_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
                break;
            }
        }
        return Promise.resolve();
    }
    get isEntitledToNoteLinking() {
        return this.subscriptionController.hasFirstPartyOnlineOrOfflineSubscription();
    }
    get activeItem() {
        var _a;
        return (_a = this.itemControllerGroup.activeItemViewController) === null || _a === void 0 ? void 0 : _a.item;
    }
}
exports.LinkingController = LinkingController;
