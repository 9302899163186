"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUserInputKeySystemRootKey = void 0;
const Algorithm_1 = require("../../../../Algorithm");
const models_1 = require("@standardnotes/models");
const DeriveKeySystemRootKey_1 = require("./DeriveKeySystemRootKey");
class CreateUserInputKeySystemRootKey {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(dto) {
        const version = models_1.ProtocolVersion.V004;
        const seed = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.ArgonSaltSeedLength);
        const keyParams = {
            systemIdentifier: dto.systemIdentifier,
            passwordType: models_1.KeySystemPasswordType.UserInputted,
            creationTimestamp: new Date().getTime(),
            seed,
            version,
        };
        const usecase = new DeriveKeySystemRootKey_1.DeriveKeySystemRootKeyUseCase(this.crypto);
        return usecase.execute({
            password: dto.userInputtedPassword,
            keyParams,
        });
    }
}
exports.CreateUserInputKeySystemRootKey = CreateUserInputKeySystemRootKey;
