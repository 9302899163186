"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplaceContactData = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class ReplaceContactData {
    constructor(mutator, sync, findContact) {
        this.mutator = mutator;
        this.sync = sync;
        this.findContact = findContact;
    }
    async execute(data) {
        const contactResult = this.findContact.execute({ userUuid: data.contactUuid });
        if (contactResult.isFailed()) {
            const newContact = await this.mutator.createItem(domain_core_1.ContentType.TYPES.TrustedContact, (0, models_1.FillItemContent)(data), true);
            await this.sync.sync();
            return domain_core_1.Result.ok(newContact);
        }
        const existingContact = contactResult.getValue();
        if (existingContact.isMe) {
            return domain_core_1.Result.fail('Cannot replace data for me contact');
        }
        const updatedContact = await this.mutator.changeItem(existingContact, (mutator) => {
            mutator.name = data.name;
            mutator.replacePublicKeySet(data.publicKeySet);
        }, models_1.MutationType.UpdateUserTimestamps, models_1.PayloadEmitSource.RemoteRetrieved);
        await this.sync.sync();
        return domain_core_1.Result.ok(updatedContact);
    }
}
exports.ReplaceContactData = ReplaceContactData;
