"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyMode = void 0;
var KeyMode;
(function (KeyMode) {
    /** i.e No account and no passcode */
    KeyMode[KeyMode["RootKeyNone"] = 0] = "RootKeyNone";
    /** i.e Account but no passcode */
    KeyMode[KeyMode["RootKeyOnly"] = 1] = "RootKeyOnly";
    /** i.e Account plus passcode */
    KeyMode[KeyMode["RootKeyPlusWrapper"] = 2] = "RootKeyPlusWrapper";
    /** i.e No account, but passcode */
    KeyMode[KeyMode["WrapperOnly"] = 3] = "WrapperOnly";
})(KeyMode || (exports.KeyMode = KeyMode = {}));
