"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateNewItemsKeyWithRollback = void 0;
class CreateNewItemsKeyWithRollback {
    constructor(mutator, items, _createDefaultItemsKey, _discardItemsLocally, _findDefaultItemsKey) {
        this.mutator = mutator;
        this.items = items;
        this._createDefaultItemsKey = _createDefaultItemsKey;
        this._discardItemsLocally = _discardItemsLocally;
        this._findDefaultItemsKey = _findDefaultItemsKey;
    }
    async execute() {
        const currentDefaultItemsKey = this._findDefaultItemsKey.execute(this.items.getDisplayableItemsKeys()).getValue();
        const newDefaultItemsKey = await this._createDefaultItemsKey.execute();
        const rollback = async () => {
            await this._discardItemsLocally.execute([newDefaultItemsKey]);
            if (currentDefaultItemsKey) {
                await this.mutator.changeItem(currentDefaultItemsKey, (mutator) => {
                    mutator.isDefault = true;
                });
            }
        };
        return rollback;
    }
}
exports.CreateNewItemsKeyWithRollback = CreateNewItemsKeyWithRollback;
