"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindPayloadInDecryptionSplit = exports.FindPayloadInEncryptionSplit = exports.CreateDecryptionSplitWithKeyLookup = exports.CreateEncryptionSplitWithKeyLookup = void 0;
function CreateEncryptionSplitWithKeyLookup(payloadSplit) {
    const result = {};
    if (payloadSplit.rootKeyEncryption) {
        result.usesRootKeyWithKeyLookup = { items: payloadSplit.rootKeyEncryption };
    }
    if (payloadSplit.keySystemRootKeyEncryption) {
        result.usesKeySystemRootKeyWithKeyLookup = { items: payloadSplit.keySystemRootKeyEncryption };
    }
    if (payloadSplit.itemsKeyEncryption) {
        result.usesItemsKeyWithKeyLookup = { items: payloadSplit.itemsKeyEncryption };
    }
    return result;
}
exports.CreateEncryptionSplitWithKeyLookup = CreateEncryptionSplitWithKeyLookup;
function CreateDecryptionSplitWithKeyLookup(payloadSplit) {
    const result = {};
    if (payloadSplit.rootKeyEncryption) {
        result.usesRootKeyWithKeyLookup = { items: payloadSplit.rootKeyEncryption };
    }
    if (payloadSplit.keySystemRootKeyEncryption) {
        result.usesKeySystemRootKeyWithKeyLookup = { items: payloadSplit.keySystemRootKeyEncryption };
    }
    if (payloadSplit.itemsKeyEncryption) {
        result.usesItemsKeyWithKeyLookup = { items: payloadSplit.itemsKeyEncryption };
    }
    return result;
}
exports.CreateDecryptionSplitWithKeyLookup = CreateDecryptionSplitWithKeyLookup;
function FindPayloadInEncryptionSplit(uuid, split) {
    var _a, _b, _c, _d, _e, _f;
    const inUsesItemsKey = (_a = split.usesItemsKey) === null || _a === void 0 ? void 0 : _a.items.find((item) => item.uuid === uuid);
    if (inUsesItemsKey) {
        return inUsesItemsKey;
    }
    const inUsesRootKey = (_b = split.usesRootKey) === null || _b === void 0 ? void 0 : _b.items.find((item) => item.uuid === uuid);
    if (inUsesRootKey) {
        return inUsesRootKey;
    }
    const inUsesKeySystemRootKey = (_c = split.usesKeySystemRootKey) === null || _c === void 0 ? void 0 : _c.items.find((item) => item.uuid === uuid);
    if (inUsesKeySystemRootKey) {
        return inUsesKeySystemRootKey;
    }
    const inUsesItemsKeyWithKeyLookup = (_d = split.usesItemsKeyWithKeyLookup) === null || _d === void 0 ? void 0 : _d.items.find((item) => item.uuid === uuid);
    if (inUsesItemsKeyWithKeyLookup) {
        return inUsesItemsKeyWithKeyLookup;
    }
    const inUsesRootKeyWithKeyLookup = (_e = split.usesRootKeyWithKeyLookup) === null || _e === void 0 ? void 0 : _e.items.find((item) => item.uuid === uuid);
    if (inUsesRootKeyWithKeyLookup) {
        return inUsesRootKeyWithKeyLookup;
    }
    const inUsesKeySystemRootKeyWithKeyLookup = (_f = split.usesKeySystemRootKeyWithKeyLookup) === null || _f === void 0 ? void 0 : _f.items.find((item) => item.uuid === uuid);
    if (inUsesKeySystemRootKeyWithKeyLookup) {
        return inUsesKeySystemRootKeyWithKeyLookup;
    }
    throw Error('Cannot find payload in encryption split');
}
exports.FindPayloadInEncryptionSplit = FindPayloadInEncryptionSplit;
function FindPayloadInDecryptionSplit(uuid, split) {
    var _a, _b, _c, _d, _e, _f;
    const inUsesItemsKey = (_a = split.usesItemsKey) === null || _a === void 0 ? void 0 : _a.items.find((item) => item.uuid === uuid);
    if (inUsesItemsKey) {
        return inUsesItemsKey;
    }
    const inUsesRootKey = (_b = split.usesRootKey) === null || _b === void 0 ? void 0 : _b.items.find((item) => item.uuid === uuid);
    if (inUsesRootKey) {
        return inUsesRootKey;
    }
    const inUsesKeySystemRootKey = (_c = split.usesKeySystemRootKey) === null || _c === void 0 ? void 0 : _c.items.find((item) => item.uuid === uuid);
    if (inUsesKeySystemRootKey) {
        return inUsesKeySystemRootKey;
    }
    const inUsesItemsKeyWithKeyLookup = (_d = split.usesItemsKeyWithKeyLookup) === null || _d === void 0 ? void 0 : _d.items.find((item) => item.uuid === uuid);
    if (inUsesItemsKeyWithKeyLookup) {
        return inUsesItemsKeyWithKeyLookup;
    }
    const inUsesRootKeyWithKeyLookup = (_e = split.usesRootKeyWithKeyLookup) === null || _e === void 0 ? void 0 : _e.items.find((item) => item.uuid === uuid);
    if (inUsesRootKeyWithKeyLookup) {
        return inUsesRootKeyWithKeyLookup;
    }
    const inUsesKeySystemRootKeyWithKeyLookup = (_f = split.usesKeySystemRootKeyWithKeyLookup) === null || _f === void 0 ? void 0 : _f.items.find((item) => item.uuid === uuid);
    if (inUsesKeySystemRootKeyWithKeyLookup) {
        return inUsesKeySystemRootKeyWithKeyLookup;
    }
    throw Error('Cannot find payload in encryption split');
}
exports.FindPayloadInDecryptionSplit = FindPayloadInDecryptionSplit;
