"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNItemsKey = exports.isItemsKey = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
function isItemsKey(x) {
    return x.content_type === domain_core_1.ContentType.TYPES.ItemsKey;
}
exports.isItemsKey = isItemsKey;
/**
 * A key used to encrypt other items. Items keys are synced and persisted.
 */
class SNItemsKey extends models_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.keyVersion = payload.content.version;
        this.isDefault = payload.content.isDefault;
        this.itemsKey = this.payload.content.itemsKey;
    }
    /** Do not duplicate items keys. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return models_1.ConflictStrategy.KeepBase;
    }
    get dataAuthenticationKey() {
        if (this.keyVersion === models_1.ProtocolVersion.V004) {
            throw 'Attempting to access legacy data authentication key.';
        }
        return this.payload.content.dataAuthenticationKey;
    }
}
exports.SNItemsKey = SNItemsKey;
