"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetQuoteBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Quote_1 = require("../../Blocks/Quote");
function GetQuoteBlockOption(editor) {
    const block = (0, Quote_1.GetQuoteBlock)(editor);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetQuoteBlockOption = GetQuoteBlockOption;
