"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDailyWritingStreak = exports.dateToDailyDayIdentifier = void 0;
const utils_1 = require("@standardnotes/utils");
function dateToDailyDayIdentifier(date) {
    return date.toLocaleDateString();
}
exports.dateToDailyDayIdentifier = dateToDailyDayIdentifier;
function getDailyWritingStreak(todayItem, itemsByDateMapping) {
    var _a;
    if (!todayItem) {
        return 0;
    }
    const startDay = todayItem.date;
    let checkingDayOffsetFromToday = -1;
    let keepLooping = true;
    let streak = 0;
    while (keepLooping) {
        const checkingDay = (0, utils_1.addDaysToDate)(startDay, checkingDayOffsetFromToday);
        const items = itemsByDateMapping[dateToDailyDayIdentifier(checkingDay)];
        if (!items || (items === null || items === void 0 ? void 0 : items.length) === 0) {
            keepLooping = false;
            break;
        }
        streak++;
        checkingDayOffsetFromToday--;
    }
    const hasEntryForToday = ((_a = itemsByDateMapping[dateToDailyDayIdentifier(todayItem.date)]) === null || _a === void 0 ? void 0 : _a.length) > 0;
    return streak + (hasEntryForToday ? 1 : 0);
}
exports.getDailyWritingStreak = getDailyWritingStreak;
