"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readAndDecryptBackupFileUsingBackupService = void 0;
const FileDecryptor_1 = require("../UseCase/FileDecryptor");
const OrderedByteChunker_1 = require("../Chunker/OrderedByteChunker");
const Logging_1 = require("../Logging");
async function readAndDecryptBackupFileUsingBackupService(file, backupService, crypto, onDecryptedBytes) {
    (0, Logging_1.log)(Logging_1.LoggingDomain.FilesPackage, 'Reading and decrypting backup file', file.uuid, 'chunk sizes', file.encryptedChunkSizes);
    const decryptor = new FileDecryptor_1.FileDecryptor(file, crypto);
    const byteChunker = new OrderedByteChunker_1.OrderedByteChunker(file.encryptedChunkSizes, 'local', async (chunk) => {
        (0, Logging_1.log)(Logging_1.LoggingDomain.FilesPackage, 'OrderedByteChunker did pop bytes', chunk.data.length, chunk.progress);
        const decryptResult = decryptor.decryptBytes(chunk.data);
        if (!decryptResult) {
            return;
        }
        await onDecryptedBytes({ ...chunk, data: decryptResult.decryptedBytes });
    });
    const readResult = await backupService.readEncryptedFileFromBackup(file.uuid, async (chunk) => {
        (0, Logging_1.log)(Logging_1.LoggingDomain.FilesPackage, 'Got file chunk from backup service', chunk.data.length, chunk.progress);
        await byteChunker.addBytes(chunk.data);
    });
    (0, Logging_1.log)(Logging_1.LoggingDomain.FilesPackage, 'Finished reading and decrypting backup file', file.uuid);
    return readResult;
}
exports.readAndDecryptBackupFileUsingBackupService = readAndDecryptBackupFileUsingBackupService;
