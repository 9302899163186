"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isHandlingBackupDrag = exports.isHandlingFileDrag = void 0;
const isBackupRelatedFile = (item, application) => {
    var _a;
    const fileName = ((_a = item.getAsFile()) === null || _a === void 0 ? void 0 : _a.name) || '';
    const isBackupMetadataFile = application.files.isFileNameFileBackupRelated(fileName) !== false;
    return isBackupMetadataFile;
};
const isHandlingFileDrag = (event, application) => {
    var _a;
    const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
    if (!items) {
        return false;
    }
    return Array.from(items).some((item) => {
        return item.kind === 'file' && !isBackupRelatedFile(item, application);
    });
};
exports.isHandlingFileDrag = isHandlingFileDrag;
const isHandlingBackupDrag = (event, application) => {
    var _a;
    const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
    if (!items) {
        return false;
    }
    return Array.from(items).every((item) => {
        return item.kind === 'file' && isBackupRelatedFile(item, application);
    });
};
exports.isHandlingBackupDrag = isHandlingBackupDrag;
