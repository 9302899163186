"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator003 = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const Operator002_1 = require("../002/Operator002");
const domain_core_1 = require("@standardnotes/domain-core");
/**
 * @legacy
 * Non-expired operator but no longer used for generating new accounts.
 * This operator subclasses the 002 operator to share functionality that has not
 * changed, and overrides functions where behavior may differ.
 */
class SNProtocolOperator003 extends Operator002_1.SNProtocolOperator002 {
    get version() {
        return common_1.ProtocolVersion.V003;
    }
    generateNewItemsKeyContent() {
        const keyLength = Algorithm_1.V003Algorithm.EncryptionKeyLength;
        const itemsKey = this.crypto.generateRandomKey(keyLength);
        const authKey = this.crypto.generateRandomKey(keyLength);
        const response = (0, models_1.FillItemContent)({
            itemsKey: itemsKey,
            dataAuthenticationKey: authKey,
            version: common_1.ProtocolVersion.V003,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const content = this.generateNewItemsKeyContent();
        const payload = new models_1.DecryptedPayload({
            uuid: utils_1.UuidGenerator.GenerateUuid(),
            content_type: domain_core_1.ContentType.TYPES.ItemsKey,
            content: (0, models_1.FillItemContent)(content),
            ...(0, models_1.PayloadTimestampDefaults)(),
        });
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
    async computeRootKey(password, keyParams) {
        return this.deriveKey(password, keyParams);
    }
    async deriveKey(password, keyParams) {
        const salt = await this.generateSalt(keyParams.content003.identifier, common_1.ProtocolVersion.V003, Algorithm_1.V003Algorithm.PbkdfCost, keyParams.content003.pw_nonce);
        const derivedKey = await this.crypto.pbkdf2(password, salt, Algorithm_1.V003Algorithm.PbkdfCost, Algorithm_1.V003Algorithm.PbkdfOutputLength);
        if (!derivedKey) {
            throw Error('Error deriving PBKDF2 key');
        }
        const partitions = (0, utils_1.splitString)(derivedKey, 3);
        return (0, Functions_1.CreateNewRootKey)({
            serverPassword: partitions[0],
            masterKey: partitions[1],
            dataAuthenticationKey: partitions[2],
            version: common_1.ProtocolVersion.V003,
            keyParams: keyParams.getPortableValue(),
        });
    }
    async createRootKey(identifier, password, origination) {
        const version = common_1.ProtocolVersion.V003;
        const pwNonce = this.crypto.generateRandomKey(Algorithm_1.V003Algorithm.SaltSeedLength);
        const keyParams = (0, KeyParamsFunctions_1.Create003KeyParams)({
            identifier: identifier,
            pw_nonce: pwNonce,
            version: version,
            origination: origination,
            created: `${Date.now()}`,
        });
        return this.deriveKey(password, keyParams);
    }
    async generateSalt(identifier, version, cost, nonce) {
        const result = await this.crypto.sha256([identifier, 'SF', version, cost, nonce].join(':'));
        return result;
    }
}
exports.SNProtocolOperator003 = SNProtocolOperator003;
