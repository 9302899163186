"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isBubbleNode = exports.$createBubbleNode = exports.convertToBubbleElement = void 0;
const BubbleNode_1 = require("./BubbleNode");
function convertToBubbleElement(domNode) {
    const itemUuid = domNode.getAttribute('data-lexical-item-uuid');
    if (itemUuid) {
        const node = $createBubbleNode(itemUuid);
        return { node };
    }
    return null;
}
exports.convertToBubbleElement = convertToBubbleElement;
function $createBubbleNode(itemUuid) {
    return new BubbleNode_1.BubbleNode(itemUuid);
}
exports.$createBubbleNode = $createBubbleNode;
function $isBubbleNode(node) {
    return node instanceof BubbleNode_1.BubbleNode;
}
exports.$isBubbleNode = $isBubbleNode;
