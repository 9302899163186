"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaveVault = void 0;
const responses_1 = require("@standardnotes/responses");
class LeaveVault {
    constructor(users, vaultUserServer, items, _deleteThirdPartyVault) {
        this.users = users;
        this.vaultUserServer = vaultUserServer;
        this.items = items;
        this._deleteThirdPartyVault = _deleteThirdPartyVault;
    }
    async execute(params) {
        const latestVaultListing = this.items.findItem(params.sharedVault.uuid);
        if (!latestVaultListing) {
            throw new Error(`LeaveVaultUseCase: Could not find vault ${params.sharedVault.uuid}`);
        }
        const response = await this.vaultUserServer.deleteSharedVaultUser({
            sharedVaultUuid: latestVaultListing.sharing.sharedVaultUuid,
            userUuid: this.users.sureUser.uuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to leave vault ${JSON.stringify(response)}`);
        }
        await this._deleteThirdPartyVault.execute(latestVaultListing);
    }
}
exports.LeaveVault = LeaveVault;
