"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const useSafeAreaPadding_1 = require("@/Hooks/useSafeAreaPadding");
const utils_1 = require("@standardnotes/utils");
const ModalDialogButtons = ({ children, className }) => {
    const { hasBottomInset } = (0, useSafeAreaPadding_1.useAvailableSafeAreaPadding)();
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('flex items-center justify-end gap-3 border-t border-border px-4 py-4', hasBottomInset && 'pb-safe-bottom', className), children: children }));
};
exports.default = ModalDialogButtons;
