"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AegisToAuthenticatorConverter = void 0;
const Utils_1 = require("../Utils");
const features_1 = require("@standardnotes/features");
const domain_core_1 = require("@standardnotes/domain-core");
const AegisEntryTypes = ['hotp', 'totp', 'steam', 'yandex'];
class AegisToAuthenticatorConverter {
    constructor(_generateUuid) {
        this._generateUuid = _generateUuid;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static isValidAegisJson(json) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return json.db && json.db.entries && json.db.entries.every((entry) => AegisEntryTypes.includes(entry.type));
    }
    async convertAegisBackupFileToNote(file, addEditorInfo) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const entries = this.parseEntries(content);
        if (!entries) {
            throw new Error('Could not parse entries');
        }
        return this.createNoteFromEntries(entries, file, addEditorInfo);
    }
    createNoteFromEntries(entries, file, addEditorInfo) {
        return {
            created_at: new Date(file.lastModified),
            created_at_timestamp: file.lastModified,
            updated_at: new Date(file.lastModified),
            updated_at_timestamp: file.lastModified,
            uuid: this._generateUuid.execute().getValue(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title: file.name.split('.')[0],
                text: JSON.stringify(entries),
                references: [],
                ...(addEditorInfo && {
                    noteType: features_1.NoteType.Authentication,
                    editorIdentifier: features_1.NativeFeatureIdentifier.TYPES.TokenVaultEditor,
                }),
            },
        };
    }
    parseEntries(data) {
        try {
            const json = JSON.parse(data);
            const entries = json.db.entries.map((entry) => {
                return {
                    service: entry.issuer,
                    account: entry.name,
                    secret: entry.info.secret,
                    notes: entry.note,
                };
            });
            return entries;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
exports.AegisToAuthenticatorConverter = AegisToAuthenticatorConverter;
