"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const snjs_1 = require("@standardnotes/snjs");
const ListItemMetadata = ({ item, hideDate, sortBy }) => {
    const showModifiedDate = sortBy === snjs_1.CollectionSort.UpdatedAt;
    if (hideDate && !item.protected) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "leading-1.4 mt-1 text-sm opacity-50 lg:text-xs", children: [item.protected && (0, jsx_runtime_1.jsxs)("span", { children: ["Protected ", hideDate ? '' : ' • '] }), !hideDate && showModifiedDate && (0, jsx_runtime_1.jsxs)("span", { children: ["Modified ", item.updatedAtString || 'Now'] }), !hideDate && !showModifiedDate && (0, jsx_runtime_1.jsx)("span", { children: item.createdAtString || 'Now' })] }));
};
exports.default = ListItemMetadata;
