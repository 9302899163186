"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDifferenceBetweenDatesInDays = void 0;
const Constants_1 = require("@/Constants/Constants");
const calculateDifferenceBetweenDatesInDays = (firstDate, secondDate) => {
    const firstDateAsUTCMilliseconds = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    const secondDateAsUTCMilliseconds = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());
    return Math.round((firstDateAsUTCMilliseconds - secondDateAsUTCMilliseconds) / Constants_1.MILLISECONDS_IN_A_DAY);
};
exports.calculateDifferenceBetweenDatesInDays = calculateDifferenceBetweenDatesInDays;
