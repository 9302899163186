"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLinkingController = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const mobx_react_lite_1 = require("mobx-react-lite");
const LinkingControllerContext = (0, react_1.createContext)(undefined);
const useLinkingController = () => {
    const value = (0, react_1.useContext)(LinkingControllerContext);
    if (!value) {
        throw new Error('Component must be a child of <LinkingControllerProvider />');
    }
    return value;
};
exports.useLinkingController = useLinkingController;
const MemoizedChildren = (0, react_1.memo)(({ children }) => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
const LinkingControllerProvider = ({ controller, children }) => {
    return ((0, jsx_runtime_1.jsx)(LinkingControllerContext.Provider, { value: controller, children: (0, jsx_runtime_1.jsx)(MemoizedChildren, { children: children }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(LinkingControllerProvider);
