"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadSecretKey = void 0;
// Temporary implementation until integration
function downloadSecretKey(text) {
    const link = document.createElement('a');
    const blob = new Blob([text], {
        type: 'text/plain;charset=utf-8',
    });
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'standardnotes_2fa_key.txt');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(link.href);
}
exports.downloadSecretKey = downloadSecretKey;
