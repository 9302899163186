"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
function AutoFocusPlugin({ isEnabled }) {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const [didInitialFocus, setDidInitialFocus] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        if (!isEnabled) {
            return;
        }
        if (application.platform !== snjs_1.Platform.Ios) {
            editor.focus();
            return;
        }
        return editor.registerUpdateListener(() => {
            if (didInitialFocus) {
                return;
            }
            const rootElement = editor.getRootElement();
            if (!rootElement) {
                return;
            }
            rootElement.focus();
            setDidInitialFocus(true);
        });
    }, [application.platform, didInitialFocus, editor, isEnabled]);
    return null;
}
exports.default = AutoFocusPlugin;
