"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readAndDecryptBackupFileUsingFileSystemAPI = void 0;
const FileDecryptor_1 = require("../UseCase/FileDecryptor");
const OrderedByteChunker_1 = require("../Chunker/OrderedByteChunker");
async function readAndDecryptBackupFileUsingFileSystemAPI(fileHandle, file, fileSystem, crypto, onDecryptedBytes) {
    const decryptor = new FileDecryptor_1.FileDecryptor(file, crypto);
    const byteChunker = new OrderedByteChunker_1.OrderedByteChunker(file.encryptedChunkSizes, 'local', async (chunk) => {
        const decryptResult = decryptor.decryptBytes(chunk.data);
        if (!decryptResult) {
            return;
        }
        await onDecryptedBytes(decryptResult.decryptedBytes);
    });
    const readResult = await fileSystem.readFile(fileHandle, async (encryptedBytes) => {
        await byteChunker.addBytes(encryptedBytes);
    });
    return readResult;
}
exports.readAndDecryptBackupFileUsingFileSystemAPI = readAndDecryptBackupFileUsingFileSystemAPI;
