"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTabStateContext = exports.TabStateContext = exports.useTabState = void 0;
const react_1 = require("react");
const useTabState = ({ defaultTab }) => {
    const [activeTab, setActiveTab] = (0, react_1.useState)(defaultTab);
    return { activeTab, setActiveTab };
};
exports.useTabState = useTabState;
exports.TabStateContext = (0, react_1.createContext)(undefined);
const useTabStateContext = () => {
    const context = (0, react_1.useContext)(exports.TabStateContext);
    if (context === undefined) {
        throw new Error('useTabStateContext must be used within a <TabList/>');
    }
    if (context.state === undefined) {
        throw new Error('Tab state must be provided to the parent <TabList/>');
    }
    return context;
};
exports.useTabStateContext = useTabStateContext;
