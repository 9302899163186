"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDividerBlockOption = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Divider_1 = require("../../Blocks/Divider");
function GetDividerBlockOption(editor) {
    const block = (0, Divider_1.GetDividerBlock)(editor);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetDividerBlockOption = GetDividerBlockOption;
