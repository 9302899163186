"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetHeadingsBlocks = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const rich_text_1 = require("@lexical/rich-text");
function GetHeadingsBlocks(editor) {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((n) => ({
        name: `Heading ${n}`,
        iconName: `h${n}`,
        keywords: ['heading', 'header', `h${n}`],
        onSelect: () => editor.update(() => {
            const selection = (0, lexical_1.$getSelection)();
            if ((0, lexical_1.$isRangeSelection)(selection)) {
                (0, selection_1.$wrapNodes)(selection, () => (0, rich_text_1.$createHeadingNode)(`h${n}`));
            }
        }),
    }));
}
exports.GetHeadingsBlocks = GetHeadingsBlocks;
