"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storage = exports.StorageKey = void 0;
var StorageKey;
(function (StorageKey) {
    StorageKey["AnonymousUserId"] = "AnonymousUserId";
    StorageKey["ShowBetaWarning"] = "ShowBetaWarning";
    StorageKey["ShowNoAccountWarning"] = "ShowNoAccountWarning";
    StorageKey["FilesNavigationEnabled"] = "FilesNavigationEnabled";
    StorageKey["MasterStatePersistenceKey"] = "master-persistence-key";
})(StorageKey || (exports.StorageKey = StorageKey = {}));
exports.storage = {
    get(key) {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    },
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    remove(key) {
        localStorage.removeItem(key);
    },
};
