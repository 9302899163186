"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotateVaultKey = void 0;
const utils_1 = require("@standardnotes/utils");
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class RotateVaultKey {
    constructor(mutator, encryption, keys, _notifyVaultUsersOfKeyRotation, _isVaultOwner) {
        this.mutator = mutator;
        this.encryption = encryption;
        this.keys = keys;
        this._notifyVaultUsersOfKeyRotation = _notifyVaultUsersOfKeyRotation;
        this._isVaultOwner = _isVaultOwner;
    }
    async execute(params) {
        const { newRootKey, updatedVault } = await this.updateRootKeyparams(params);
        await this.createNewKeySystemItemsKey({
            keySystemIdentifier: updatedVault.systemIdentifier,
            sharedVaultUuid: updatedVault.isSharedVaultListing() ? updatedVault.sharing.sharedVaultUuid : undefined,
            rootKeyToken: newRootKey.token,
        });
        await this.keys.queueVaultItemsKeysForReencryption(updatedVault.systemIdentifier);
        const shareResult = await this.shareNewKeyWithMembers({
            vault: updatedVault,
            newRootKey,
        });
        if (shareResult.isFailed()) {
            return domain_core_1.Result.fail(shareResult.getError());
        }
        return domain_core_1.Result.ok(updatedVault);
    }
    async updateRootKeyparams(params) {
        const currentRootKey = this.keys.getPrimaryKeySystemRootKey(params.vault.systemIdentifier);
        if (!currentRootKey) {
            throw new Error('Cannot rotate key system root key; key system root key not found');
        }
        let newRootKey;
        if (params.userInputtedPassword) {
            newRootKey = this.encryption.createUserInputtedKeySystemRootKey({
                systemIdentifier: params.vault.systemIdentifier,
                userInputtedPassword: params.userInputtedPassword,
            });
        }
        else {
            newRootKey = this.encryption.createRandomizedKeySystemRootKey({
                systemIdentifier: params.vault.systemIdentifier,
            });
        }
        if (!newRootKey) {
            throw new Error('Cannot rotate key system root key; new root key not created');
        }
        if (!params.userInputtedPassword || params.vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            await this.mutator.insertItem(newRootKey, true);
        }
        else {
            this.keys.cacheKey(newRootKey, params.vault.keyStorageMode);
        }
        const updatedVault = await this.mutator.changeItem(params.vault, (mutator) => {
            (0, utils_1.assert)(newRootKey);
            mutator.rootKeyParams = newRootKey.keyParams;
        });
        return { newRootKey, updatedVault };
    }
    async createNewKeySystemItemsKey(params) {
        const newItemsKeyUuid = utils_1.UuidGenerator.GenerateUuid();
        const newItemsKey = this.encryption.createKeySystemItemsKey(newItemsKeyUuid, params.keySystemIdentifier, params.sharedVaultUuid, params.rootKeyToken);
        await this.mutator.insertItem(newItemsKey);
    }
    async shareNewKeyWithMembers(params) {
        if (!params.vault.isSharedVaultListing()) {
            return domain_core_1.Result.ok();
        }
        const isOwner = this._isVaultOwner.execute(params.vault).getValue();
        if (!isOwner) {
            return domain_core_1.Result.ok();
        }
        const result = await this._notifyVaultUsersOfKeyRotation.execute({
            sharedVault: params.vault,
        });
        return result;
    }
}
exports.RotateVaultKey = RotateVaultKey;
