"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEncryptedBackupFile = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
const common_1 = require("@standardnotes/common");
const encryption_1 = require("@standardnotes/encryption");
class CreateEncryptedBackupFile {
    constructor(items, protections, encryption) {
        this.items = items;
        this.protections = protections;
        this.encryption = encryption;
    }
    async execute(params = { skipAuthorization: false }) {
        if (!params.skipAuthorization && !(await this.protections.authorizeBackupCreation())) {
            return domain_core_1.Result.fail('Failed to authorize backup creation');
        }
        const payloads = this.items.items.map((item) => item.payload);
        const split = (0, encryption_1.SplitPayloadsByEncryptionType)(payloads);
        const keyLookupSplit = (0, encryption_1.CreateEncryptionSplitWithKeyLookup)(split);
        const result = await this.encryption.encryptSplit(keyLookupSplit);
        const ejected = result.map((payload) => (0, models_1.CreateEncryptedBackupFileContextPayload)(payload));
        const data = {
            version: common_1.ProtocolVersionLatest,
            items: ejected,
        };
        const keyParams = this.encryption.getRootKeyParams();
        data.keyParams = keyParams === null || keyParams === void 0 ? void 0 : keyParams.getPortableValue();
        return domain_core_1.Result.ok(data);
    }
}
exports.CreateEncryptedBackupFile = CreateEncryptedBackupFile;
