"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReencryptTypeAItems = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
/**
 * When the user root key changes, we must re-encrypt all relevant items with this new root key (by simply re-syncing).
 */
class ReencryptTypeAItems {
    constructor(items, mutator) {
        this.items = items;
        this.mutator = mutator;
    }
    async execute() {
        const items = this.items.getItems((0, models_1.ContentTypesUsingRootKeyEncryption)());
        if (items.length > 0) {
            /**
             * Do not call sync after marking dirty.
             * Re-encrypting items keys is called by consumers who have specific flows who
             * will sync on their own timing
             */
            await this.mutator.setItemsDirty(items);
        }
        return domain_core_1.Result.ok();
    }
}
exports.ReencryptTypeAItems = ReencryptTypeAItems;
