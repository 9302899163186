"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteParser = void 0;
const AppViewRouteParams_1 = require("./Params/AppViewRouteParams");
const RootQueryParam_1 = require("./RootQueryParam");
const RootRoutes_1 = require("./RootRoutes");
const RouteType_1 = require("./RouteType");
class RouteParser {
    constructor(url) {
        this.url = new URL(url);
        this.path = this.url.pathname;
        this.searchParams = this.url.searchParams;
        this.parsedType = this.parseTypeFromQueryParameters();
    }
    get type() {
        return this.parsedType;
    }
    get userRequestParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.UserRequest);
        return {
            requestType: this.searchParams.get(RootQueryParam_1.RootQueryParam.UserRequest),
        };
    }
    get subscriptionInviteParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.AcceptSubscriptionInvite);
        return {
            inviteUuid: this.searchParams.get(RootQueryParam_1.RootQueryParam.AcceptSubscriptionInvite),
        };
    }
    get demoParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.Demo);
        return {
            token: this.searchParams.get(RootQueryParam_1.RootQueryParam.DemoToken),
        };
    }
    get settingsParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.Settings);
        return {
            panel: this.searchParams.get(RootQueryParam_1.RootQueryParam.Settings),
        };
    }
    get purchaseParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.Purchase);
        return {
            plan: this.searchParams.get('plan'),
            period: this.searchParams.get('period'),
        };
    }
    get onboardingParams() {
        this.checkForProperRouteType(RouteType_1.RouteType.Onboarding);
        return {
            fromHomepage: !!this.searchParams.get('from_homepage'),
        };
    }
    get appViewRouteParam() {
        this.checkForProperRouteType(RouteType_1.RouteType.AppViewRoute);
        const appViewRoute = this.searchParams.get(RootQueryParam_1.RootQueryParam.AppViewRoute);
        if (!AppViewRouteParams_1.ValidAppViewRoutes.includes(appViewRoute)) {
            return;
        }
        return this.searchParams.get(RootQueryParam_1.RootQueryParam.AppViewRoute);
    }
    checkForProperRouteType(type) {
        if (this.parsedType !== type) {
            throw new Error('Accessing invalid params');
        }
    }
    parseTypeFromQueryParameters() {
        if (this.path === RootRoutes_1.RootRoutes.Onboarding) {
            return RouteType_1.RouteType.Onboarding;
        }
        const isValidPath = ['index.html', 'popup.html'].some((path) => this.path.endsWith(path));
        if (this.path !== RootRoutes_1.RootRoutes.None && !isValidPath) {
            return RouteType_1.RouteType.None;
        }
        const rootQueryParametersMap = new Map([
            [RootQueryParam_1.RootQueryParam.Purchase, RouteType_1.RouteType.Purchase],
            [RootQueryParam_1.RootQueryParam.Settings, RouteType_1.RouteType.Settings],
            [RootQueryParam_1.RootQueryParam.DemoToken, RouteType_1.RouteType.Demo],
            [RootQueryParam_1.RootQueryParam.AcceptSubscriptionInvite, RouteType_1.RouteType.AcceptSubscriptionInvite],
            [RootQueryParam_1.RootQueryParam.UserRequest, RouteType_1.RouteType.UserRequest],
            [RootQueryParam_1.RootQueryParam.AppViewRoute, RouteType_1.RouteType.AppViewRoute],
        ]);
        for (const rootQueryParam of rootQueryParametersMap.keys()) {
            if (this.searchParams.has(rootQueryParam)) {
                return rootQueryParametersMap.get(rootQueryParam);
            }
        }
        return RouteType_1.RouteType.None;
    }
}
exports.RouteParser = RouteParser;
