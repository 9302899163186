"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplitPayloadsByEncryptionType = void 0;
const models_1 = require("@standardnotes/models");
function SplitPayloadsByEncryptionType(payloads) {
    const usesRootKey = [];
    const usesItemsKey = [];
    const usesKeySystemRootKey = [];
    for (const item of payloads) {
        if ((0, models_1.ContentTypeUsesRootKeyEncryption)(item.content_type)) {
            usesRootKey.push(item);
        }
        else if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(item.content_type)) {
            usesKeySystemRootKey.push(item);
        }
        else {
            usesItemsKey.push(item);
        }
    }
    return {
        rootKeyEncryption: usesRootKey.length > 0 ? usesRootKey : undefined,
        itemsKeyEncryption: usesItemsKey.length > 0 ? usesItemsKey : undefined,
        keySystemRootKeyEncryption: usesKeySystemRootKey.length > 0 ? usesKeySystemRootKey : undefined,
    };
}
exports.SplitPayloadsByEncryptionType = SplitPayloadsByEncryptionType;
