"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPasswordBlock = void 0;
const Commands_1 = require("../Commands");
const DEFAULT_PASSWORD_LENGTH = 16;
function GetPasswordBlock(editor) {
    return {
        name: 'Generate cryptographically secure password',
        iconName: 'password',
        keywords: ['password', 'secure'],
        onSelect: () => editor.dispatchCommand(Commands_1.INSERT_PASSWORD_COMMAND, String(DEFAULT_PASSWORD_LENGTH)),
    };
}
exports.GetPasswordBlock = GetPasswordBlock;
