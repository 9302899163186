"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeriveHashingKeyUseCase = void 0;
const Algorithm_1 = require("../../../../Algorithm");
class DeriveHashingKeyUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(key) {
        const hashingKey = this.crypto.sodiumCryptoKdfDeriveFromKey(key.itemsKey, Algorithm_1.V004Algorithm.PayloadKeyHashingKeySubKeyNumber, Algorithm_1.V004Algorithm.PayloadKeyHashingKeySubKeyBytes, Algorithm_1.V004Algorithm.PayloadKeyHashingKeySubKeyContext);
        return {
            key: hashingKey,
        };
    }
}
exports.DeriveHashingKeyUseCase = DeriveHashingKeyUseCase;
