"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpService = void 0;
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
const responses_1 = require("@standardnotes/responses");
const Paths_1 = require("../Server/Auth/Paths");
const FetchRequestHandler_1 = require("./FetchRequestHandler");
class HttpService {
    constructor(environment, appVersion, snjsVersion, logger) {
        this.environment = environment;
        this.appVersion = appVersion;
        this.snjsVersion = snjsVersion;
        this.logger = logger;
        this.loggingEnabled = false;
        this.requestHandler = new FetchRequestHandler_1.FetchRequestHandler(this.snjsVersion, this.appVersion, this.environment, this.logger);
    }
    setCallbacks(updateMetaCallback, refreshSessionCallback) {
        this.updateMetaCallback = updateMetaCallback;
        this.refreshSessionCallback = refreshSessionCallback;
    }
    deinit() {
        ;
        this.session = undefined;
        this.updateMetaCallback = undefined;
        this.refreshSessionCallback = undefined;
    }
    setSession(session) {
        this.session = session;
    }
    setHost(host) {
        this.host = host;
    }
    getHost() {
        return this.host;
    }
    getSessionAccessToken() {
        if (!this.session) {
            return undefined;
        }
        if (this.session instanceof domain_core_1.Session) {
            return this.session.accessToken.value;
        }
        else {
            return this.session.accessToken;
        }
    }
    get(path, params, authentication) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.host) {
                throw new Error('Attempting to make network request before host is set');
            }
            return this.runHttp({
                url: (0, utils_1.joinPaths)(this.host, path),
                params,
                verb: responses_1.HttpVerb.Get,
                authentication: authentication !== null && authentication !== void 0 ? authentication : this.getSessionAccessToken(),
            });
        });
    }
    getExternal(url, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.runHttp({
                url,
                params,
                verb: responses_1.HttpVerb.Get,
                external: true,
            });
        });
    }
    post(path, params, authentication) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.host) {
                throw new Error('Attempting to make network request before host is set');
            }
            return this.runHttp({
                url: (0, utils_1.joinPaths)(this.host, path),
                params,
                verb: responses_1.HttpVerb.Post,
                authentication: authentication !== null && authentication !== void 0 ? authentication : this.getSessionAccessToken(),
            });
        });
    }
    put(path, params, authentication) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.runHttp({
                url: (0, utils_1.joinPaths)(this.host, path),
                params,
                verb: responses_1.HttpVerb.Put,
                authentication: authentication !== null && authentication !== void 0 ? authentication : this.getSessionAccessToken(),
            });
        });
    }
    patch(path, params, authentication) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.runHttp({
                url: (0, utils_1.joinPaths)(this.host, path),
                params,
                verb: responses_1.HttpVerb.Patch,
                authentication: authentication !== null && authentication !== void 0 ? authentication : this.getSessionAccessToken(),
            });
        });
    }
    delete(path, params, authentication) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.runHttp({
                url: (0, utils_1.joinPaths)(this.host, path),
                params,
                verb: responses_1.HttpVerb.Delete,
                authentication: authentication !== null && authentication !== void 0 ? authentication : this.getSessionAccessToken(),
            });
        });
    }
    runHttp(httpRequest) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.__latencySimulatorMs) {
                yield (0, utils_1.sleep)(this.__latencySimulatorMs, true);
            }
            const isRefreshRequest = httpRequest.url === (0, utils_1.joinPaths)(this.host, Paths_1.Paths.v1.refreshSession);
            if (this.inProgressRefreshSessionPromise && !isRefreshRequest) {
                yield this.inProgressRefreshSessionPromise;
                httpRequest.authentication = this.getSessionAccessToken();
            }
            const response = yield this.requestHandler.handleRequest(httpRequest);
            if (this.loggingEnabled && (0, responses_1.isErrorResponse)(response)) {
                this.logger.error('Request failed', httpRequest, response);
            }
            if (response.meta && !httpRequest.external) {
                (_a = this.updateMetaCallback) === null || _a === void 0 ? void 0 : _a.call(this, response.meta);
            }
            if (response.status === responses_1.HttpStatusCode.ExpiredAccessToken && !isRefreshRequest && !httpRequest.external) {
                if (this.inProgressRefreshSessionPromise) {
                    yield this.inProgressRefreshSessionPromise;
                }
                else {
                    this.inProgressRefreshSessionPromise = this.refreshSession();
                    const isSessionRefreshed = yield this.inProgressRefreshSessionPromise;
                    this.inProgressRefreshSessionPromise = undefined;
                    if (!isSessionRefreshed) {
                        return response;
                    }
                }
                httpRequest.authentication = this.getSessionAccessToken();
                return this.runHttp(httpRequest);
            }
            return response;
        });
    }
    refreshSession() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.session) {
                return false;
            }
            if (this.session instanceof domain_core_1.LegacySession) {
                return false;
            }
            const response = yield this.post(Paths_1.Paths.v1.refreshSession, {
                access_token: this.session.accessToken.value,
                refresh_token: this.session.refreshToken.value,
            });
            if ((0, responses_1.isErrorResponse)(response)) {
                return false;
            }
            if (response.meta) {
                (_a = this.updateMetaCallback) === null || _a === void 0 ? void 0 : _a.call(this, response.meta);
            }
            const accessTokenOrError = domain_core_1.SessionToken.create(response.data.session.access_token, response.data.session.access_expiration);
            if (accessTokenOrError.isFailed()) {
                return false;
            }
            const accessToken = accessTokenOrError.getValue();
            const refreshTokenOrError = domain_core_1.SessionToken.create(response.data.session.refresh_token, response.data.session.refresh_expiration);
            if (refreshTokenOrError.isFailed()) {
                return false;
            }
            const refreshToken = refreshTokenOrError.getValue();
            const sessionOrError = domain_core_1.Session.create(accessToken, refreshToken, response.data.session.readonly_access);
            if (sessionOrError.isFailed()) {
                return false;
            }
            this.setSession(sessionOrError.getValue());
            this.refreshSessionCallback(this.session);
            return true;
        });
    }
}
exports.HttpService = HttpService;
