"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseFlowController = void 0;
const Logging_1 = require("@/Logging");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
const PurchaseFlowPane_1 = require("./PurchaseFlowPane");
class PurchaseFlowController extends AbstractViewController_1.AbstractViewController {
    constructor(sessions, subscriptions, legacyApi, alerts, mobileDevice, _loadPurchaseFlowUrl, _isNativeIOS, eventBus) {
        super(eventBus);
        this.sessions = sessions;
        this.subscriptions = subscriptions;
        this.legacyApi = legacyApi;
        this.alerts = alerts;
        this.mobileDevice = mobileDevice;
        this._loadPurchaseFlowUrl = _loadPurchaseFlowUrl;
        this._isNativeIOS = _isNativeIOS;
        this.isOpen = false;
        this.currentPane = PurchaseFlowPane_1.PurchaseFlowPane.CreateAccount;
        this.setCurrentPane = (currentPane) => {
            this.currentPane = currentPane;
        };
        this.openPurchaseFlow = async (plan = snjs_1.AppleIAPProductId.ProPlanYearly) => {
            const user = this.sessions.getUser();
            if (!user) {
                this.isOpen = true;
                return;
            }
            if (this._isNativeIOS.execute().getValue()) {
                await this.beginIosIapPurchaseFlow(plan);
            }
            else {
                await this._loadPurchaseFlowUrl.execute();
            }
        };
        this.openPurchaseWebpage = async () => {
            const result = await this._loadPurchaseFlowUrl.execute();
            if (result.isFailed()) {
                console.error(result.getError());
                void this.alerts.alert(result.getError());
            }
        };
        this.beginIosIapPurchaseFlow = async (plan) => {
            var _a;
            const result = await ((_a = this.mobileDevice) === null || _a === void 0 ? void 0 : _a.purchaseSubscriptionIAP(plan));
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'BeginIosIapPurchaseFlow result', result);
            if (!result) {
                void this.alerts.alert('Your purchase was canceled or failed. Please try again.');
                return;
            }
            const showGenericError = () => {
                void this.alerts.alert('There was an error confirming your purchase. Please contact support at help@standardnotes.com.');
            };
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Confirming result with our server');
            const token = await this.legacyApi.getNewSubscriptionToken();
            if (!token) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Unable to generate subscription token');
                showGenericError();
                return;
            }
            const confirmResult = await this.subscriptions.confirmAppleIAP(result, token);
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Server confirm result', confirmResult);
            if (confirmResult) {
                void this.alerts.alert('Please allow a few minutes for your subscription benefits to activate. You will see a confirmation alert in the app when your subscription is ready.', 'Your purchase was successful!');
            }
            else {
                showGenericError();
            }
        };
        this.closePurchaseFlow = () => {
            this.isOpen = false;
        };
        (0, mobx_1.makeObservable)(this, {
            isOpen: mobx_1.observable,
            currentPane: mobx_1.observable,
            setCurrentPane: mobx_1.action,
            openPurchaseFlow: mobx_1.action,
            closePurchaseFlow: mobx_1.action,
        });
    }
}
exports.PurchaseFlowController = PurchaseFlowController;
