"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptTypeAPayload = void 0;
const encryption_1 = require("@standardnotes/encryption");
class EncryptTypeAPayload {
    constructor(operators) {
        this.operators = operators;
    }
    async executeOne(payload, key, signingKeyPair) {
        return (0, encryption_1.encryptPayload)(payload, key, this.operators, signingKeyPair);
    }
    async executeMany(payloads, key, signingKeyPair) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload, key, signingKeyPair)));
    }
}
exports.EncryptTypeAPayload = EncryptTypeAPayload;
