"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengePrompt = void 0;
const utils_1 = require("@standardnotes/utils");
const ChallengeValidation_1 = require("../Types/ChallengeValidation");
const PromptTitles_1 = require("./PromptTitles");
/* istanbul ignore file */
class ChallengePrompt {
    constructor(validation, title, placeholder, secureTextEntry = true, keyboardType, initialValue, contextData) {
        this.validation = validation;
        this.secureTextEntry = secureTextEntry;
        this.keyboardType = keyboardType;
        this.initialValue = initialValue;
        this.contextData = contextData;
        this.id = Math.random();
        switch (this.validation) {
            case ChallengeValidation_1.ChallengeValidation.AccountPassword:
                this.title = title !== null && title !== void 0 ? title : PromptTitles_1.ChallengePromptTitle.AccountPassword;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : PromptTitles_1.ChallengePromptTitle.AccountPassword;
                this.validates = true;
                break;
            case ChallengeValidation_1.ChallengeValidation.LocalPasscode:
                this.title = title !== null && title !== void 0 ? title : PromptTitles_1.ChallengePromptTitle.LocalPasscode;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : PromptTitles_1.ChallengePromptTitle.LocalPasscode;
                this.validates = true;
                break;
            case ChallengeValidation_1.ChallengeValidation.Biometric:
                this.title = title !== null && title !== void 0 ? title : PromptTitles_1.ChallengePromptTitle.Biometrics;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation_1.ChallengeValidation.Authenticator:
                this.title = title !== null && title !== void 0 ? title : PromptTitles_1.ChallengePromptTitle.U2F;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation_1.ChallengeValidation.ProtectionSessionDuration:
                this.title = title !== null && title !== void 0 ? title : PromptTitles_1.ChallengePromptTitle.RememberFor;
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = true;
                break;
            case ChallengeValidation_1.ChallengeValidation.None:
                this.title = title !== null && title !== void 0 ? title : '';
                this.placeholder = placeholder !== null && placeholder !== void 0 ? placeholder : '';
                this.validates = false;
                break;
            default:
                (0, utils_1.assertUnreachable)(this.validation);
        }
        Object.freeze(this);
    }
}
exports.ChallengePrompt = ChallengePrompt;
