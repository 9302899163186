"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCommandService = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const mobx_react_lite_1 = require("mobx-react-lite");
const CommandServiceContext = (0, react_1.createContext)(undefined);
const useCommandService = () => {
    const value = (0, react_1.useContext)(CommandServiceContext);
    if (!value) {
        throw new Error('Component must be a child of <CommandServiceProvider />');
    }
    return value;
};
exports.useCommandService = useCommandService;
const MemoizedChildren = (0, react_1.memo)(({ children }) => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
const CommandServiceProvider = ({ service, children }) => {
    return ((0, jsx_runtime_1.jsx)(CommandServiceContext.Provider, { value: service, children: (0, jsx_runtime_1.jsx)(MemoizedChildren, { children: children }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(CommandServiceProvider);
