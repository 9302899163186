"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendVaultInvite = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class SendVaultInvite {
    constructor(vaultInvitesServer) {
        this.vaultInvitesServer = vaultInvitesServer;
    }
    async execute(params) {
        const permissionOrError = domain_core_1.SharedVaultUserPermission.create(params.permission);
        if (permissionOrError.isFailed()) {
            return domain_core_1.Result.fail(permissionOrError.getError());
        }
        const permission = permissionOrError.getValue();
        const response = await this.vaultInvitesServer.createInvite({
            sharedVaultUuid: params.sharedVaultUuid,
            recipientUuid: params.recipientUuid,
            encryptedMessage: params.encryptedMessage,
            permission: permission,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail((0, responses_1.getErrorFromErrorResponse)(response).message);
        }
        return domain_core_1.Result.ok(response.data.invite);
    }
}
exports.SendVaultInvite = SendVaultInvite;
