"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileDownloader = void 0;
const utils_1 = require("@standardnotes/utils");
class FileDownloader {
    constructor(file, api, valetToken) {
        this.file = file;
        this.api = api;
        this.valetToken = valetToken;
        this.aborted = false;
        this.abortDeferred = (0, utils_1.Deferred)();
        this.totalBytesDownloaded = 0;
    }
    getProgress() {
        const encryptedSize = this.file.encryptedChunkSizes.reduce((total, chunk) => total + chunk, 0);
        return {
            encryptedFileSize: encryptedSize,
            encryptedBytesDownloaded: this.totalBytesDownloaded,
            encryptedBytesRemaining: encryptedSize - this.totalBytesDownloaded,
            percentComplete: (this.totalBytesDownloaded / encryptedSize) * 100.0,
            source: 'network',
        };
    }
    async run(onEncryptedBytes) {
        return this.performDownload(onEncryptedBytes);
    }
    async performDownload(onEncryptedBytes) {
        const chunkIndex = 0;
        const startRange = 0;
        const onRemoteBytesReceived = async (bytes) => {
            if (this.aborted) {
                return;
            }
            this.totalBytesDownloaded += bytes.byteLength;
            await onEncryptedBytes(bytes, this.getProgress(), this.abort);
        };
        const downloadPromise = this.api.downloadFile({
            file: this.file,
            chunkIndex,
            valetToken: this.valetToken,
            contentRangeStart: startRange,
            onBytesReceived: onRemoteBytesReceived,
            ownershipType: this.file.shared_vault_uuid ? 'shared-vault' : 'user',
        });
        const result = await Promise.race([this.abortDeferred.promise, downloadPromise]);
        return result;
    }
    abort() {
        this.aborted = true;
        this.abortDeferred.resolve('aborted');
    }
}
exports.FileDownloader = FileDownloader;
