"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptTypeAPayloadWithKeyLookup = void 0;
const models_1 = require("@standardnotes/models");
const EncryptPayload_1 = require("./EncryptPayload");
class EncryptTypeAPayloadWithKeyLookup {
    constructor(operators, keySystemKeyManager, rootKeyManager) {
        this.operators = operators;
        this.keySystemKeyManager = keySystemKeyManager;
        this.rootKeyManager = rootKeyManager;
    }
    async executeOne(payload, signingKeyPair) {
        let key;
        if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(payload.content_type)) {
            if (!payload.key_system_identifier) {
                throw Error(`Key system-encrypted payload ${payload.content_type}is missing a key_system_identifier`);
            }
            key = this.keySystemKeyManager.getPrimaryKeySystemRootKey(payload.key_system_identifier);
        }
        else {
            key = this.rootKeyManager.getRootKey();
        }
        if (key == undefined) {
            throw Error('Attempting root key encryption with no root key');
        }
        const usecase = new EncryptPayload_1.EncryptTypeAPayload(this.operators);
        return usecase.executeOne(payload, key, signingKeyPair);
    }
    async executeMany(payloads, signingKeyPair) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload, signingKeyPair)));
    }
}
exports.EncryptTypeAPayloadWithKeyLookup = EncryptTypeAPayloadWithKeyLookup;
