"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEmbedsBlockOptions = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Embeds_1 = require("../../Blocks/Embeds");
function GetEmbedsBlockOptions(editor) {
    return (0, Embeds_1.GetEmbedsBlocks)(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetEmbedsBlockOptions = GetEmbedsBlockOptions;
