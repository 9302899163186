"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
function usePreference(preference) {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [value, setValue] = (0, react_1.useState)(application.getPreference(preference, snjs_1.PrefDefaults[preference]));
    (0, react_1.useEffect)(() => {
        return application.addEventObserver(async () => {
            const latestValue = application.getPreference(preference, snjs_1.PrefDefaults[preference]);
            setValue(latestValue);
        }, snjs_1.ApplicationEvent.PreferencesChanged);
    }, [application, preference]);
    return value;
}
exports.default = usePreference;
