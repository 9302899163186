"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator004 = void 0;
const models_1 = require("@standardnotes/models");
const common_1 = require("@standardnotes/common");
const Algorithm_1 = require("../../Algorithm");
const AsymmetricEncrypt_1 = require("./UseCase/Asymmetric/AsymmetricEncrypt");
const ParseConsistentBase64JsonPayload_1 = require("./UseCase/Utils/ParseConsistentBase64JsonPayload");
const AsymmetricDecrypt_1 = require("./UseCase/Asymmetric/AsymmetricDecrypt");
const GenerateDecryptedParameters_1 = require("./UseCase/Symmetric/GenerateDecryptedParameters");
const GenerateEncryptedParameters_1 = require("./UseCase/Symmetric/GenerateEncryptedParameters");
const DeriveRootKey_1 = require("./UseCase/RootKey/DeriveRootKey");
const GetPayloadAuthenticatedDataDetached_1 = require("./UseCase/Symmetric/GetPayloadAuthenticatedDataDetached");
const CreateRootKey_1 = require("./UseCase/RootKey/CreateRootKey");
const utils_1 = require("@standardnotes/utils");
const CreateKeySystemItemsKey_1 = require("./UseCase/KeySystem/CreateKeySystemItemsKey");
const CreateRandomKeySystemRootKey_1 = require("./UseCase/KeySystem/CreateRandomKeySystemRootKey");
const CreateUserInputKeySystemRootKey_1 = require("./UseCase/KeySystem/CreateUserInputKeySystemRootKey");
const AsymmetricSignatureVerificationDetached_1 = require("./UseCase/Asymmetric/AsymmetricSignatureVerificationDetached");
const DeriveKeySystemRootKey_1 = require("./UseCase/KeySystem/DeriveKeySystemRootKey");
const domain_core_1 = require("@standardnotes/domain-core");
const AsymmetricStringGetAdditionalData_1 = require("./UseCase/Asymmetric/AsymmetricStringGetAdditionalData");
const AsymmetricDecryptOwnMessage_1 = require("./UseCase/Asymmetric/AsymmetricDecryptOwnMessage");
class SNProtocolOperator004 {
    constructor(crypto) {
        this.crypto = crypto;
    }
    getEncryptionDisplayName() {
        return 'XChaCha20-Poly1305';
    }
    get version() {
        return common_1.ProtocolVersion.V004;
    }
    generateNewItemsKeyContent() {
        const itemsKey = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionKeyLength);
        const response = (0, models_1.FillItemContentSpecialized)({
            itemsKey: itemsKey,
            version: common_1.ProtocolVersion.V004,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new models_1.DecryptedPayload({
            uuid: utils_1.UuidGenerator.GenerateUuid(),
            content_type: domain_core_1.ContentType.TYPES.ItemsKey,
            content: this.generateNewItemsKeyContent(),
            key_system_identifier: undefined,
            shared_vault_uuid: undefined,
            ...(0, models_1.PayloadTimestampDefaults)(),
        });
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
    createRandomizedKeySystemRootKey(dto) {
        const usecase = new CreateRandomKeySystemRootKey_1.CreateRandomKeySystemRootKey(this.crypto);
        return usecase.execute(dto);
    }
    createUserInputtedKeySystemRootKey(dto) {
        const usecase = new CreateUserInputKeySystemRootKey_1.CreateUserInputKeySystemRootKey(this.crypto);
        return usecase.execute(dto);
    }
    deriveUserInputtedKeySystemRootKey(dto) {
        const usecase = new DeriveKeySystemRootKey_1.DeriveKeySystemRootKeyUseCase(this.crypto);
        return usecase.execute({
            keyParams: dto.keyParams,
            password: dto.userInputtedPassword,
        });
    }
    createKeySystemItemsKey(uuid, keySystemIdentifier, sharedVaultUuid, rootKeyToken) {
        const usecase = new CreateKeySystemItemsKey_1.CreateKeySystemItemsKeyUseCase(this.crypto);
        return usecase.execute({ uuid, keySystemIdentifier, sharedVaultUuid, rootKeyToken });
    }
    async computeRootKey(password, keyParams) {
        const usecase = new DeriveRootKey_1.DeriveRootKeyUseCase(this.crypto);
        return usecase.execute(password, keyParams);
    }
    async createRootKey(identifier, password, origination) {
        const usecase = new CreateRootKey_1.CreateRootKeyUseCase(this.crypto);
        return usecase.execute(identifier, password, origination);
    }
    getPayloadAuthenticatedDataForExternalUse(encrypted) {
        const usecase = new GetPayloadAuthenticatedDataDetached_1.GetPayloadAuthenticatedDataDetachedUseCase(this.crypto);
        return usecase.execute(encrypted);
    }
    generateEncryptedParameters(payload, key, signingKeyPair) {
        const usecase = new GenerateEncryptedParameters_1.GenerateEncryptedParametersUseCase(this.crypto);
        return usecase.execute(payload, key, signingKeyPair);
    }
    generateDecryptedParameters(encrypted, key) {
        const usecase = new GenerateDecryptedParameters_1.GenerateDecryptedParametersUseCase(this.crypto);
        return usecase.execute(encrypted, key);
    }
    asymmetricEncrypt(dto) {
        const usecase = new AsymmetricEncrypt_1.AsymmetricEncrypt004(this.crypto);
        return usecase.execute(dto);
    }
    asymmetricDecrypt(dto) {
        const usecase = new AsymmetricDecrypt_1.AsymmetricDecrypt004(this.crypto);
        return usecase.execute(dto);
    }
    asymmetricDecryptOwnMessage(dto) {
        const usecase = new AsymmetricDecryptOwnMessage_1.AsymmetricDecryptOwnMessage004(this.crypto);
        return usecase.execute(dto);
    }
    asymmetricSignatureVerifyDetached(encryptedString) {
        const usecase = new AsymmetricSignatureVerificationDetached_1.AsymmetricSignatureVerificationDetached004(this.crypto);
        return usecase.execute({ encryptedString });
    }
    asymmetricStringGetAdditionalData(dto) {
        const usecase = new AsymmetricStringGetAdditionalData_1.AsymmetricStringGetAdditionalData004(this.crypto);
        return usecase.execute(dto);
    }
    getSenderPublicKeySetFromAsymmetricallyEncryptedString(string) {
        const [_, __, ___, additionalDataString] = string.split(':');
        const parseBase64Usecase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
        const additionalData = parseBase64Usecase.execute(additionalDataString);
        return {
            encryption: additionalData.senderPublicKey,
            signing: additionalData.signingData.publicKey,
        };
    }
    versionForAsymmetricallyEncryptedString(string) {
        const [versionPrefix] = string.split(':');
        const version = versionPrefix.split('_')[0];
        return version;
    }
}
exports.SNProtocolOperator004 = SNProtocolOperator004;
