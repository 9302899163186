"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanesForLayout = void 0;
const AppPaneMetadata_1 = require("./../../Components/Panes/AppPaneMetadata");
const PaneLayout_1 = require("./../../Controllers/PaneController/PaneLayout");
const snjs_1 = require("@standardnotes/snjs");
class PanesForLayout {
    constructor(_isTabletOrMobileScreen) {
        this._isTabletOrMobileScreen = _isTabletOrMobileScreen;
    }
    execute(layout) {
        const screen = this._isTabletOrMobileScreen.execute().getValue();
        if (screen.isTablet) {
            if (layout === PaneLayout_1.PaneLayout.TagSelection || layout === PaneLayout_1.PaneLayout.TableView) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items]);
            }
            else if (layout === PaneLayout_1.PaneLayout.ItemSelection || layout === PaneLayout_1.PaneLayout.Editing) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor]);
            }
        }
        else if (screen.isMobile) {
            if (layout === PaneLayout_1.PaneLayout.TagSelection) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation]);
            }
            else if (layout === PaneLayout_1.PaneLayout.ItemSelection || layout === PaneLayout_1.PaneLayout.TableView) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items]);
            }
            else if (layout === PaneLayout_1.PaneLayout.Editing) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor]);
            }
        }
        else {
            if (layout === PaneLayout_1.PaneLayout.TableView) {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items]);
            }
            else {
                return snjs_1.Result.ok([AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor]);
            }
        }
        throw Error('Unhandled pane layout');
    }
}
exports.PanesForLayout = PanesForLayout;
