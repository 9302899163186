"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetParagraphBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
function GetParagraphBlock(editor) {
    return {
        name: 'Paragraph',
        iconName: 'paragraph',
        keywords: ['normal', 'paragraph', 'p', 'text'],
        onSelect: () => editor.update(() => {
            const selection = (0, lexical_1.$getSelection)();
            if ((0, lexical_1.$isRangeSelection)(selection)) {
                (0, selection_1.$wrapNodes)(selection, () => (0, lexical_1.$createParagraphNode)());
            }
        }),
    };
}
exports.GetParagraphBlock = GetParagraphBlock;
