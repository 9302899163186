"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardException = void 0;
class StandardException {
    constructor(message, log = false) {
        this.message = message;
        if (log) {
            console.error('StandardException raised: ', message);
        }
    }
}
exports.StandardException = StandardException;
