"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log = exports.LoggingDomain = void 0;
const utils_1 = require("@standardnotes/utils");
const Utils_1 = require("./Utils");
var LoggingDomain;
(function (LoggingDomain) {
    LoggingDomain[LoggingDomain["DailyNotes"] = 0] = "DailyNotes";
    LoggingDomain[LoggingDomain["NoteView"] = 1] = "NoteView";
    LoggingDomain[LoggingDomain["ItemsList"] = 2] = "ItemsList";
    LoggingDomain[LoggingDomain["NavigationList"] = 3] = "NavigationList";
    LoggingDomain[LoggingDomain["Viewport"] = 4] = "Viewport";
    LoggingDomain[LoggingDomain["Selection"] = 5] = "Selection";
    LoggingDomain[LoggingDomain["BlockEditor"] = 6] = "BlockEditor";
    LoggingDomain[LoggingDomain["Purchasing"] = 7] = "Purchasing";
    LoggingDomain[LoggingDomain["Panes"] = 8] = "Panes";
    LoggingDomain[LoggingDomain["U2F"] = 9] = "U2F";
})(LoggingDomain || (exports.LoggingDomain = LoggingDomain = {}));
const LoggingStatus = {
    [LoggingDomain.DailyNotes]: false,
    [LoggingDomain.NoteView]: false,
    [LoggingDomain.ItemsList]: false,
    [LoggingDomain.NavigationList]: false,
    [LoggingDomain.Viewport]: false,
    [LoggingDomain.Selection]: false,
    [LoggingDomain.BlockEditor]: false,
    [LoggingDomain.Purchasing]: false,
    [LoggingDomain.Panes]: false,
    [LoggingDomain.U2F]: false,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function log(domain, ...args) {
    if (!Utils_1.isDev || !LoggingStatus[domain]) {
        return;
    }
    (0, utils_1.log)(LoggingDomain[domain], ...args);
}
exports.log = log;
