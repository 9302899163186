"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVaultItems = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetVaultItems {
    constructor(items) {
        this.items = items;
    }
    execute(vault) {
        return domain_core_1.Result.ok(this.items.items.filter((item) => item.key_system_identifier === vault.systemIdentifier));
    }
}
exports.GetVaultItems = GetVaultItems;
