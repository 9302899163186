"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTable = void 0;
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
function useTable({ data, columns, sortBy, sortReversed, onSortChange, getRowId, enableRowSelection, enableMultipleRowSelection, selectedRowIds, onRowSelectionChange, onRowActivate, onRowContextMenu, rowActions, selectionActions, showSelectionActions, }) {
    const [selectedRows, setSelectedRows] = (0, react_1.useState)(selectedRowIds || []);
    const id = (0, react_1.useRef)(snjs_1.UuidGenerator.GenerateUuid());
    (0, react_1.useEffect)(() => {
        if (selectedRowIds) {
            setSelectedRows(selectedRowIds);
        }
    }, [selectedRowIds]);
    (0, react_1.useEffect)(() => {
        if (onRowSelectionChange) {
            onRowSelectionChange(selectedRows);
        }
    }, [selectedRows, onRowSelectionChange]);
    const headers = (0, react_1.useMemo)(() => columns.map((column, index) => {
        return {
            name: column.name,
            isSorting: sortBy && sortBy === column.sortBy,
            sortBy: column.sortBy,
            sortReversed: sortReversed,
            onSortChange: () => {
                if (!onSortChange || !column.sortBy) {
                    return;
                }
                onSortChange(column.sortBy, sortBy === column.sortBy ? !sortReversed : false);
            },
            hidden: column.hidden || false,
            colIndex: index,
        };
    }), [columns, onSortChange, sortBy, sortReversed]);
    const rows = (0, react_1.useMemo)(() => data.map((rowData, index) => {
        const cells = columns.map((column, index) => {
            return {
                render: column.cell(rowData),
                hidden: column.hidden || false,
                colIndex: index,
            };
        });
        const id = getRowId ? getRowId(rowData) : index.toString();
        const row = {
            id,
            isSelected: enableRowSelection ? selectedRows.includes(id) : false,
            cells,
            rowData,
            rowActions: rowActions ? rowActions(rowData) : undefined,
        };
        return row;
    }), [columns, data, enableRowSelection, getRowId, rowActions, selectedRows]);
    const selectRow = (0, react_1.useCallback)((id) => {
        if (!enableRowSelection) {
            return;
        }
        setSelectedRows([id]);
    }, [enableRowSelection]);
    const multiSelectRow = (0, react_1.useCallback)((id) => {
        if (!enableRowSelection || !enableMultipleRowSelection) {
            return;
        }
        setSelectedRows((prev) => (prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]));
    }, [enableMultipleRowSelection, enableRowSelection]);
    const rangeSelectUpToRow = (0, react_1.useCallback)((id) => {
        if (!enableRowSelection || !enableMultipleRowSelection) {
            return;
        }
        const lastSelectedIndex = rows.findIndex((row) => row.id === selectedRows[selectedRows.length - 1]);
        const currentIndex = rows.findIndex((row) => row.id === id);
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        const newSelectedRows = rows.slice(start, end + 1).map((row) => row.id);
        setSelectedRows(newSelectedRows);
    }, [enableMultipleRowSelection, enableRowSelection, rows, selectedRows]);
    const handleActivateRow = (0, react_1.useCallback)((id) => {
        var _a;
        if (!onRowActivate) {
            return;
        }
        const rowData = (_a = rows.find((row) => row.id === id)) === null || _a === void 0 ? void 0 : _a.rowData;
        if (rowData) {
            onRowActivate(rowData);
        }
    }, [onRowActivate, rows]);
    const handleRowContextMenu = (0, react_1.useCallback)((id) => {
        const handler = (event) => {
            var _a;
            if (!onRowContextMenu) {
                return;
            }
            event.preventDefault();
            const rowData = (_a = rows.find((row) => row.id === id)) === null || _a === void 0 ? void 0 : _a.rowData;
            if (rowData) {
                setSelectedRows([id]);
                onRowContextMenu(event.clientX, event.clientY, rowData);
            }
        };
        return handler;
    }, [onRowContextMenu, rows]);
    const colCount = (0, react_1.useMemo)(() => columns.length, [columns]);
    const rowCount = (0, react_1.useMemo)(() => data.length, [data.length]);
    const table = (0, react_1.useMemo)(() => ({
        id: id.current,
        headers,
        rows,
        colCount,
        rowCount,
        selectRow,
        multiSelectRow,
        rangeSelectUpToRow,
        handleActivateRow,
        handleRowContextMenu,
        selectedRows,
        canSelectRows: enableRowSelection || false,
        canSelectMultipleRows: enableMultipleRowSelection || false,
        selectionActions: selectionActions ? selectionActions(selectedRows) : undefined,
        showSelectionActions: showSelectionActions || false,
    }), [
        headers,
        rows,
        colCount,
        rowCount,
        selectRow,
        multiSelectRow,
        rangeSelectUpToRow,
        handleActivateRow,
        handleRowContextMenu,
        selectedRows,
        enableRowSelection,
        enableMultipleRowSelection,
        selectionActions,
        showSelectionActions,
    ]);
    return table;
}
exports.useTable = useTable;
