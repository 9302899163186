"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemSignatureValidationResult = void 0;
var ItemSignatureValidationResult;
(function (ItemSignatureValidationResult) {
    ItemSignatureValidationResult["NotApplicable"] = "NotApplicable";
    ItemSignatureValidationResult["Trusted"] = "Trusted";
    ItemSignatureValidationResult["SignedWithNonCurrentKey"] = "SignedWithNonCurrentKey";
    ItemSignatureValidationResult["NotTrusted"] = "NotTrusted";
})(ItemSignatureValidationResult || (exports.ItemSignatureValidationResult = ItemSignatureValidationResult = {}));
