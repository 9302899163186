"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsMobileDevice = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const Utils_1 = require("../Utils/Utils");
class IsMobileDevice {
    constructor(_isNativeMobileWeb) {
        this._isNativeMobileWeb = _isNativeMobileWeb;
    }
    execute() {
        return domain_core_1.Result.ok(this._isNativeMobileWeb.execute().getValue() || (0, Utils_1.isIOS)() || (0, Utils_1.isAndroid)());
    }
}
exports.IsMobileDevice = IsMobileDevice;
