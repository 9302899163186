"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendVaultKeyChangedMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
const GetReplaceabilityIdentifier_1 = require("../../AsymmetricMessage/UseCase/GetReplaceabilityIdentifier");
class SendVaultKeyChangedMessage {
    constructor(users, keyManager, _encryptMessage, _findContact, _sendMessage, _getVaultUsers, _getKeyPairs) {
        this.users = users;
        this.keyManager = keyManager;
        this._encryptMessage = _encryptMessage;
        this._findContact = _findContact;
        this._sendMessage = _sendMessage;
        this._getVaultUsers = _getVaultUsers;
        this._getKeyPairs = _getKeyPairs;
    }
    async execute(params) {
        const users = await this._getVaultUsers.execute({ sharedVaultUuid: params.sharedVaultUuid, readFromCache: false });
        if (users.isFailed()) {
            return domain_core_1.Result.fail('Cannot send root key changed message; users not found');
        }
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail('Cannot send root key changed message; keys not found');
        }
        const errors = [];
        for (const user of users.getValue()) {
            if (user.user_uuid === this.users.sureUser.uuid) {
                continue;
            }
            const trustedContact = this._findContact.execute({ userUuid: user.user_uuid });
            if (trustedContact.isFailed()) {
                continue;
            }
            const result = await this.sendToContact({
                keySystemIdentifier: params.keySystemIdentifier,
                sharedVaultUuid: params.sharedVaultUuid,
                keys: keys.getValue(),
                contact: trustedContact.getValue(),
            });
            if (result.isFailed()) {
                errors.push(result.getError());
            }
        }
        if (errors.length > 0) {
            return domain_core_1.Result.fail(errors.join(', '));
        }
        return domain_core_1.Result.ok();
    }
    async sendToContact(params) {
        const keySystemRootKey = this.keyManager.getPrimaryKeySystemRootKey(params.keySystemIdentifier);
        if (!keySystemRootKey) {
            throw new Error(`Vault key not found for keySystemIdentifier ${params.keySystemIdentifier}`);
        }
        const message = {
            type: models_1.AsymmetricMessagePayloadType.SharedVaultRootKeyChanged,
            data: { recipientUuid: params.contact.contactUuid, rootKey: keySystemRootKey.content },
        };
        const encryptedMessage = this._encryptMessage.execute({
            message: message,
            keys: params.keys,
            recipientPublicKey: params.contact.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const replaceabilityIdentifier = (0, GetReplaceabilityIdentifier_1.GetReplaceabilityIdentifier)(models_1.AsymmetricMessagePayloadType.SharedVaultRootKeyChanged, params.sharedVaultUuid, params.keySystemIdentifier);
        const sendMessageResult = await this._sendMessage.execute({
            recipientUuid: params.contact.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            replaceabilityIdentifier,
        });
        return sendMessageResult;
    }
}
exports.SendVaultKeyChangedMessage = SendVaultKeyChangedMessage;
