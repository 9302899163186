"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCounter = void 0;
const utils_1 = require("@standardnotes/utils");
const Tag_1 = require("../../../Syncable/Tag/Tag");
const Item_1 = require("../../../Abstract/Item");
const CollectionCriteriaValidator_1 = require("../../Display/Validator/CollectionCriteriaValidator");
const ExcludeVaultsCriteriaValidator_1 = require("../../Display/Validator/ExcludeVaultsCriteriaValidator");
const ExclusiveVaultCriteriaValidator_1 = require("../../Display/Validator/ExclusiveVaultCriteriaValidator");
const HiddenContentCriteriaValidator_1 = require("../../Display/Validator/HiddenContentCriteriaValidator");
const CustomFilterCriteriaValidator_1 = require("../../Display/Validator/CustomFilterCriteriaValidator");
const VaultDisplayOptionsTypes_1 = require("../../Display/VaultDisplayOptionsTypes");
const domain_core_1 = require("@standardnotes/domain-core");
class ItemCounter {
    constructor(collection, observers = []) {
        this.collection = collection;
        this.observers = observers;
        this.tagToItemsMap = {};
        this.allCountableItems = new Set();
        this.countableItemsByType = new Map();
        this.isItemCountable = (item) => {
            if ((0, Item_1.isDecryptedItem)(item)) {
                const passesFilters = this.passesAllFilters(item);
                return passesFilters && !item.archived && !item.trashed && !item.conflictOf;
            }
            return false;
        };
    }
    addCountChangeObserver(observer) {
        this.observers.push(observer);
        const thislessEventObservers = this.observers;
        return () => {
            (0, utils_1.removeFromArray)(thislessEventObservers, observer);
        };
    }
    setDisplayOptions(options) {
        this.displayOptions = options;
        this.receiveItemChanges(this.collection.all());
    }
    setVaultDisplayOptions(options) {
        this.vaultDisplayOptions = options;
        this.receiveItemChanges(this.collection.all());
    }
    allCountableItemsCount() {
        return this.allCountableItems.size;
    }
    allCountableNotesCount() {
        var _a;
        return ((_a = this.countableItemsByType.get(domain_core_1.ContentType.TYPES.Note)) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    allCountableFilesCount() {
        var _a;
        return ((_a = this.countableItemsByType.get(domain_core_1.ContentType.TYPES.File)) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    countableItemsForTag(tag) {
        var _a;
        return ((_a = this.tagToItemsMap[tag.uuid]) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    onChange(delta) {
        const items = [...delta.changed, ...delta.inserted, ...delta.discarded].filter((i) => i.content_type === domain_core_1.ContentType.TYPES.Note || i.content_type === domain_core_1.ContentType.TYPES.File);
        const tags = [...delta.changed, ...delta.inserted].filter(Item_1.isDecryptedItem).filter(Tag_1.isTag);
        this.receiveItemChanges(items);
        this.receiveTagChanges(tags);
    }
    passesAllFilters(element) {
        if (!this.displayOptions) {
            return true;
        }
        const filters = [new CollectionCriteriaValidator_1.CollectionCriteriaValidator(this.collection, element)];
        if (this.vaultDisplayOptions) {
            const options = this.vaultDisplayOptions.getOptions();
            if ((0, VaultDisplayOptionsTypes_1.isExclusioanaryOptionsValue)(options)) {
                filters.push(new ExcludeVaultsCriteriaValidator_1.ExcludeVaultsCriteriaValidator([...options.exclude, ...options.locked], element));
            }
            else {
                filters.push(new ExclusiveVaultCriteriaValidator_1.ExclusiveVaultCriteriaValidator(options.exclusive, element));
            }
        }
        if ('hiddenContentTypes' in this.displayOptions && this.displayOptions.hiddenContentTypes) {
            filters.push(new HiddenContentCriteriaValidator_1.HiddenContentCriteriaValidator(this.displayOptions.hiddenContentTypes, element));
        }
        if ('customFilter' in this.displayOptions && this.displayOptions.customFilter) {
            filters.push(new CustomFilterCriteriaValidator_1.CustomFilterCriteriaValidator(this.displayOptions.customFilter, element));
        }
        return filters.every((f) => f.passes());
    }
    notifyObservers(tagUuid) {
        for (const observer of this.observers) {
            observer(tagUuid);
        }
    }
    receiveTagChanges(tags) {
        for (const tag of tags) {
            const uuids = tag.references
                .filter((ref) => ref.content_type === domain_core_1.ContentType.TYPES.Note || ref.content_type === domain_core_1.ContentType.TYPES.File)
                .map((ref) => ref.uuid);
            const countableUuids = uuids.filter((uuid) => this.allCountableItems.has(uuid));
            const previousSet = this.tagToItemsMap[tag.uuid];
            this.tagToItemsMap[tag.uuid] = new Set(countableUuids);
            if ((previousSet === null || previousSet === void 0 ? void 0 : previousSet.size) !== countableUuids.length) {
                this.notifyObservers(tag.uuid);
            }
        }
    }
    receiveItemChanges(items) {
        var _a, _b;
        const previousAllCount = this.allCountableItems.size;
        for (const item of items) {
            const isCountable = this.isItemCountable(item);
            if (isCountable) {
                this.allCountableItems.add(item.uuid);
                if (!this.countableItemsByType.has(item.content_type)) {
                    this.countableItemsByType.set(item.content_type, new Set());
                }
                (_a = this.countableItemsByType.get(item.content_type)) === null || _a === void 0 ? void 0 : _a.add(item.uuid);
            }
            else {
                this.allCountableItems.delete(item.uuid);
                (_b = this.countableItemsByType.get(item.content_type)) === null || _b === void 0 ? void 0 : _b.delete(item.uuid);
            }
            const associatedTagUuids = this.collection.uuidsThatReferenceUuid(item.uuid);
            for (const tagUuid of associatedTagUuids) {
                const set = this.setForTag(tagUuid);
                const previousCount = set.size;
                if (isCountable) {
                    set.add(item.uuid);
                }
                else {
                    set.delete(item.uuid);
                }
                if (previousCount !== set.size) {
                    this.notifyObservers(tagUuid);
                }
            }
        }
        if (previousAllCount !== this.allCountableItems.size) {
            this.notifyObservers(undefined);
        }
    }
    setForTag(uuid) {
        let set = this.tagToItemsMap[uuid];
        if (!set) {
            set = new Set();
            this.tagToItemsMap[uuid] = set;
        }
        return set;
    }
}
exports.ItemCounter = ItemCounter;
