"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptMessage = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class DecryptMessage {
    constructor(operators) {
        this.operators = operators;
    }
    execute(dto) {
        const defaultOperator = this.operators.defaultOperator();
        const version = defaultOperator.versionForAsymmetricallyEncryptedString(dto.message);
        const keyOperator = this.operators.operatorForVersion(version);
        const decryptedResult = keyOperator.asymmetricDecrypt({
            stringToDecrypt: dto.message,
            recipientSecretKey: dto.privateKey,
        });
        if (!decryptedResult) {
            return domain_core_1.Result.fail('Failed to decrypt message');
        }
        if (!decryptedResult.signatureVerified) {
            return domain_core_1.Result.fail('Failed to verify signature');
        }
        if (dto.sender) {
            const publicKeyTrustStatus = dto.sender.getTrustStatusForPublicKey(decryptedResult.senderPublicKey);
            if (publicKeyTrustStatus !== models_1.PublicKeyTrustStatus.Trusted) {
                return domain_core_1.Result.fail('Sender public key is not trusted');
            }
            const signingKeyTrustStatus = dto.sender.getTrustStatusForSigningPublicKey(decryptedResult.signaturePublicKey);
            if (signingKeyTrustStatus !== models_1.PublicKeyTrustStatus.Trusted) {
                return domain_core_1.Result.fail('Signature public key is not trusted');
            }
        }
        return domain_core_1.Result.ok(JSON.parse(decryptedResult.plaintext));
    }
}
exports.DecryptMessage = DecryptMessage;
