"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperSearchContextProvider = exports.useSuperSearchContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const SuperSearchContext = (0, react_1.createContext)(undefined);
const useSuperSearchContext = () => {
    const context = (0, react_1.useContext)(SuperSearchContext);
    if (!context) {
        throw new Error('useSuperSearchContext must be used within a SuperSearchContextProvider');
    }
    return context;
};
exports.useSuperSearchContext = useSuperSearchContext;
const initialState = {
    query: '',
    results: [],
    currentResultIndex: -1,
    isCaseSensitive: false,
    isSearchActive: false,
    isReplaceMode: false,
};
const searchContextReducer = (state, action) => {
    switch (action.type) {
        case 'set-query':
            return {
                ...state,
                query: action.query,
            };
        case 'set-results':
            return {
                ...state,
                results: action.results,
                currentResultIndex: action.results.length > 0 ? 0 : -1,
            };
        case 'clear-results':
            return {
                ...state,
                results: [],
                currentResultIndex: -1,
            };
        case 'set-current-result-index':
            return {
                ...state,
                currentResultIndex: action.index,
            };
        case 'toggle-search':
            return {
                ...initialState,
                isSearchActive: !state.isSearchActive,
            };
        case 'toggle-case-sensitive':
            return {
                ...state,
                isCaseSensitive: !state.isCaseSensitive,
            };
        case 'toggle-replace-mode': {
            const toggledValue = !state.isReplaceMode;
            return {
                ...state,
                isSearchActive: toggledValue && !state.isSearchActive ? true : state.isSearchActive,
                isReplaceMode: toggledValue,
            };
        }
        case 'go-to-next-result':
            return {
                ...state,
                currentResultIndex: state.results.length < 1
                    ? -1
                    : state.currentResultIndex + 1 < state.results.length
                        ? state.currentResultIndex + 1
                        : 0,
            };
        case 'go-to-previous-result':
            return {
                ...state,
                currentResultIndex: state.results.length < 1
                    ? -1
                    : state.currentResultIndex - 1 >= 0
                        ? state.currentResultIndex - 1
                        : state.results.length - 1,
            };
        case 'reset-search':
            return { ...initialState };
    }
};
const SuperSearchContextProvider = ({ children }) => {
    const [state, dispatch] = (0, react_1.useReducer)(searchContextReducer, initialState);
    const replaceEventListeners = (0, react_1.useRef)(new Set());
    const addReplaceEventListener = (0, react_1.useCallback)((listener) => {
        replaceEventListeners.current.add(listener);
        return () => {
            replaceEventListeners.current.delete(listener);
        };
    }, []);
    const dispatchReplaceEvent = (0, react_1.useCallback)((type) => {
        replaceEventListeners.current.forEach((listener) => listener(type));
    }, []);
    const value = (0, react_1.useMemo)(() => ({
        ...state,
        dispatch,
        addReplaceEventListener,
        dispatchReplaceEvent,
    }), [addReplaceEventListener, dispatchReplaceEvent, state]);
    return (0, jsx_runtime_1.jsx)(SuperSearchContext.Provider, { value: value, children: children });
};
exports.SuperSearchContextProvider = SuperSearchContextProvider;
