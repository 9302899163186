"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptTypeAPayloadWithKeyLookup = void 0;
const models_1 = require("@standardnotes/models");
const DecryptPayload_1 = require("./DecryptPayload");
class DecryptTypeAPayloadWithKeyLookup {
    constructor(operators, keySystemKeyManager, rootKeyManager, logger) {
        this.operators = operators;
        this.keySystemKeyManager = keySystemKeyManager;
        this.rootKeyManager = rootKeyManager;
        this.logger = logger;
    }
    async executeOne(payload) {
        let key;
        if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(payload.content_type)) {
            if (!payload.key_system_identifier) {
                this.logger.error('Payload is missing key system identifier', payload);
                return {
                    uuid: payload.uuid,
                    errorDecrypting: true,
                };
            }
            key = this.keySystemKeyManager.getPrimaryKeySystemRootKey(payload.key_system_identifier);
        }
        else {
            key = this.rootKeyManager.getRootKey();
        }
        if (key == undefined) {
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
                waitingForKey: true,
            };
        }
        const usecase = new DecryptPayload_1.DecryptTypeAPayload(this.operators);
        return usecase.executeOne(payload, key);
    }
    async executeMany(payloads) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload)));
    }
}
exports.DecryptTypeAPayloadWithKeyLookup = DecryptTypeAPayloadWithKeyLookup;
