"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResendAllMessages = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class ResendAllMessages {
    constructor(resendMessage, decryptOwnMessage, messageServer, findContact) {
        this.resendMessage = resendMessage;
        this.decryptOwnMessage = decryptOwnMessage;
        this.messageServer = messageServer;
        this.findContact = findContact;
    }
    messagesToExcludeFromResending() {
        /**
         * Sender key pair changed messages should never be re-encrypted with new keys as they must use the
         * previous keys used by the sender before their keypair changed.
         */
        return [models_1.AsymmetricMessagePayloadType.SenderKeypairChanged];
    }
    async execute(params) {
        var _a, _b;
        const messages = await this.messageServer.getOutboundUserMessages();
        if ((0, responses_1.isErrorResponse)(messages)) {
            return domain_core_1.Result.fail('Failed to get outbound user messages');
        }
        const errors = [];
        for (const message of messages.data.messages) {
            const recipient = this.findContact.execute({ userUuid: message.recipient_uuid });
            if (recipient.isFailed()) {
                errors.push(`Contact not found for invite ${message.recipient_uuid}`);
                continue;
            }
            const decryptionResult = this.decryptOwnMessage.execute({
                message: message.encrypted_message,
                privateKey: (_b = (_a = params.previousKeys) === null || _a === void 0 ? void 0 : _a.encryption.privateKey) !== null && _b !== void 0 ? _b : params.keys.encryption.privateKey,
                recipientPublicKey: recipient.getValue().publicKeySet.encryption,
            });
            if (decryptionResult.isFailed()) {
                errors.push(`Failed to decrypt message ${message.uuid}`);
                continue;
            }
            const decryptedMessage = decryptionResult.getValue();
            if (this.messagesToExcludeFromResending().includes(decryptedMessage.type)) {
                continue;
            }
            await this.resendMessage.execute({
                keys: params.keys,
                previousKeys: params.previousKeys,
                decryptedMessage: decryptedMessage,
                rawMessage: message,
                recipient: recipient.getValue(),
            });
            await this.messageServer.deleteMessage({
                messageUuid: message.uuid,
            });
        }
        if (errors.length > 0) {
            return domain_core_1.Result.fail(errors.join(', '));
        }
        return domain_core_1.Result.ok();
    }
}
exports.ResendAllMessages = ResendAllMessages;
