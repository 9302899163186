"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const SyncEvent_1 = require("../Event/SyncEvent");
const AbstractService_1 = require("../Service/AbstractService");
const NotificationServiceEvent_1 = require("./NotificationServiceEvent");
const domain_core_1 = require("@standardnotes/domain-core");
class NotificationService extends AbstractService_1.AbstractService {
    constructor(internalEventBus) {
        super(internalEventBus);
        this.handledNotifications = new Set();
    }
    async handleEvent(event) {
        if (event.type === SyncEvent_1.SyncEvent.ReceivedNotifications) {
            return this.handleReceivedNotifications(event.payload);
        }
    }
    async handleReceivedNotifications(notifications) {
        if (notifications.length === 0) {
            return;
        }
        for (const notification of notifications) {
            if (this.handledNotifications.has(notification.uuid)) {
                continue;
            }
            this.handledNotifications.add(notification.uuid);
            const eventPayloadOrError = domain_core_1.NotificationPayload.createFromString(notification.payload);
            if (eventPayloadOrError.isFailed()) {
                continue;
            }
            const payload = eventPayloadOrError.getValue();
            const serviceEvent = { eventPayload: payload };
            await this.notifyEventSync(NotificationServiceEvent_1.NotificationServiceEvent.NotificationReceived, serviceEvent);
        }
    }
}
exports.NotificationService = NotificationService;
