"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPayloadAuthenticatedDataDetachedUseCase = void 0;
const V004AlgorithmHelpers_1 = require("../../V004AlgorithmHelpers");
const ParseConsistentBase64JsonPayload_1 = require("../Utils/ParseConsistentBase64JsonPayload");
class GetPayloadAuthenticatedDataDetachedUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.parseStringUseCase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(encrypted) {
        const contentKeyComponents = (0, V004AlgorithmHelpers_1.deconstructEncryptedPayloadString)(encrypted.enc_item_key);
        const authenticatedDataString = contentKeyComponents.authenticatedData;
        const result = this.parseStringUseCase.execute(authenticatedDataString);
        return result;
    }
}
exports.GetPayloadAuthenticatedDataDetachedUseCase = GetPayloadAuthenticatedDataDetachedUseCase;
