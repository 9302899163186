"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOwnedSharedVaults = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetOwnedSharedVaults {
    constructor(_getSharedVaults, _isVaultOwnwer) {
        this._getSharedVaults = _getSharedVaults;
        this._isVaultOwnwer = _isVaultOwnwer;
    }
    execute() {
        const sharedVaults = this._getSharedVaults.execute().getValue();
        const ownedVaults = sharedVaults.filter((vault) => {
            return this._isVaultOwnwer.execute(vault).getValue();
        });
        return domain_core_1.Result.ok(ownedVaults);
    }
}
exports.GetOwnedSharedVaults = GetOwnedSharedVaults;
