"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUntrustedPayload = void 0;
class GetUntrustedPayload {
    constructor(decryptMessage) {
        this.decryptMessage = decryptMessage;
    }
    execute(dto) {
        const result = this.decryptMessage.execute({
            message: dto.message.encrypted_message,
            sender: undefined,
            privateKey: dto.privateKey,
        });
        return result;
    }
}
exports.GetUntrustedPayload = GetUntrustedPayload;
