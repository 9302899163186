"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addTimedToast = void 0;
const toastStore_1 = require("./toastStore");
const addTimedToast = (initialOptions, callback, timeInSeconds) => {
    let timeRemainingInSeconds = timeInSeconds;
    const intervalId = window.setInterval(() => {
        timeRemainingInSeconds--;
        if (timeRemainingInSeconds > 0) {
            (0, toastStore_1.updateToast)(toastId, {
                message: initialOptions.message(timeRemainingInSeconds),
            });
        }
        else {
            (0, toastStore_1.dismissToast)(toastId);
            clearInterval(intervalId);
            callback();
        }
    }, 1000);
    const toastId = (0, toastStore_1.addToast)({
        ...initialOptions,
        message: initialOptions.message(timeRemainingInSeconds),
        autoClose: false,
    });
    return [toastId, intervalId];
};
exports.addTimedToast = addTimedToast;
