"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyWebToMobileVersionMapping = void 0;
/**
 * Before 11/23/2022, web and mobile used independent semantic versioning. After this point, mobile automatically
 * uses web's versioning. The mapping below allows us to see what particular web version a legacy mobile version maps to
 */
exports.LegacyWebToMobileVersionMapping = {
    '3.107.0': '3.46.31',
    '3.106.0': '3.46.30',
    '3.105.0': '3.46.29',
    '3.104.1': '3.46.28',
    '3.104.0': '3.46.27',
    '3.103.2': '3.46.26',
    '3.103.1': '3.46.25',
    '3.103.0': '3.46.24',
    '3.102.0': '3.46.23',
    '3.101.2': '3.46.22',
    '3.101.1': '3.46.21',
    '3.101.0': '3.46.20',
    '3.100.8': '3.46.19',
    '3.100.7': '3.46.18',
    '3.100.6': '3.46.17',
    '3.100.5': '3.46.16',
    '3.100.4': '3.46.15',
    '3.100.3': '3.46.14',
    '3.100.2': '3.46.13',
    '3.100.1': '3.46.12',
    '3.100.0': '3.46.11',
    '3.99.0': '3.46.10',
    '3.98.2': '3.46.9',
    '3.98.1': '3.46.8',
    '3.98.0': '3.46.7',
    '3.97.0': '3.46.6',
    '3.96.1': '3.46.5',
    '3.96.0': '3.46.4',
    '3.95.1': '3.46.3',
    '3.95.0': '3.46.2',
    '3.94.2': '3.46.1',
    '3.94.1': '3.46.0',
    '3.94.0': '3.45.42',
    '3.93.19': '3.45.41',
    '3.93.18': '3.45.40',
    '3.93.17': '3.45.39',
    '3.93.16': '3.45.38',
    '3.93.15': '3.45.37',
    '3.93.14': '3.45.36',
    '3.93.13': '3.45.35',
    '3.93.12': '3.45.34',
    '3.93.11': '3.45.33',
    '3.93.10': '3.45.32',
    '3.93.9': '3.45.31',
    '3.93.8': '3.45.30',
    '3.93.7': '3.45.29',
    '3.93.6': '3.45.28',
    '3.93.5': '3.45.27',
    '3.93.4': '3.45.26',
    '3.93.3': '3.45.25',
    '3.93.2': '3.45.24',
    '3.93.1': '3.45.23',
    '3.93.0': '3.45.22',
    '3.92.0': '3.45.21',
    '3.91.1': '3.45.20',
    '3.91.0': '3.45.19',
    '3.90.11': '3.45.18',
    '3.90.10': '3.45.17',
    '3.90.9': '3.45.16',
    '3.90.8': '3.45.15',
    '3.90.7': '3.45.14',
    '3.90.6': '3.45.13',
    '3.90.5': '3.45.12',
    '3.90.4': '3.45.11',
    '3.90.3': '3.45.10',
    '3.90.2': '3.45.9',
    '3.90.1': '3.45.8',
    '3.90.0': '3.45.7',
    '3.89.0': '3.45.6',
    '3.88.1': '3.45.5',
    '3.88.0': '3.45.4',
    '3.87.2': '3.45.3',
    '3.87.1': '3.45.2',
    '3.87.0': '3.45.1',
    '3.86.0': '3.45.0',
    '3.85.2': '3.44.5',
    '3.85.1': '3.44.4',
    '3.85.0': '3.44.3',
    '3.84.7': '3.44.2',
    '3.84.6': '3.44.1',
    '3.84.5': '3.44.0',
    '3.84.4': '3.43.33',
    '3.84.3': '3.43.32',
    '3.84.2': '3.43.31',
    '3.84.1': '3.43.30',
    '3.84.0': '3.43.29',
    '3.83.0': '3.43.28',
    '3.82.6': '3.43.27',
    '3.82.5': '3.43.26',
    '3.82.4': '3.43.25',
    '3.82.3': '3.43.24',
    '3.82.2': '3.43.23',
    '3.82.1': '3.43.22',
    '3.82.0': '3.43.21',
    '3.81.0': '3.43.20',
    '3.80.1': '3.43.19',
    '3.80.0': '3.43.18',
    '3.79.0': '3.43.17',
    '3.78.1': '3.43.16',
    '3.78.0': '3.43.15',
    '3.77.1': '3.43.14',
    '3.77.0': '3.43.13',
    '3.76.2': '3.43.12',
    '3.76.1': '3.43.11',
    '3.76.0': '3.43.10',
    '3.75.1': '3.43.9',
    '3.75.0': '3.43.8',
    '3.74.0': '3.43.7',
    '3.73.0': '3.43.6',
    '3.72.4': '3.43.5',
    '3.72.3': '3.43.4',
    '3.72.2': '3.43.3',
    '3.72.1': '3.43.2',
    '3.72.0': '3.43.1',
    '3.71.8': '3.43.0',
    '3.71.7': '3.42.5',
    '3.71.6': '3.42.4',
    '3.71.5': '3.42.3',
    '3.71.4': '3.42.2',
    '3.71.3': '3.42.1',
    '3.71.2': '3.42.0',
    '3.71.1': '3.41.10',
    '3.71.0': '3.41.9',
    '3.70.5': '3.41.8',
    '3.70.4': '3.41.7',
    '3.70.3': '3.41.6',
    '3.70.2': '3.41.5',
    '3.70.1': '3.41.4',
    '3.70.0': '3.41.3',
    '3.69.1': '3.41.2',
    '3.69.0': '3.41.1',
    '3.68.6': '3.41.0',
    '3.68.5': '3.40.2',
    '3.68.4': '3.40.1',
    '3.68.3': '3.40.0',
    '3.68.2': '3.39.12',
    '3.68.1': '3.39.11',
    '3.68.0': '3.39.10',
    '3.67.4': '3.39.9',
    '3.67.3': '3.39.8',
    '3.67.2': '3.39.7',
    '3.67.1': '3.39.6',
    '3.67.0': '3.39.5',
    '3.66.0': '3.39.4',
    '3.65.0': '3.39.3',
    '3.64.0': '3.39.2',
    '3.63.3': '3.39.1',
    '3.63.2': '3.39.0',
    '3.63.1': '3.38.1',
    '3.63.0': '3.38.0',
    '3.62.4': '3.37.14',
    '3.62.3': '3.37.13',
    '3.62.2': '3.37.12',
    '3.62.1': '3.37.11',
    '3.62.0': '3.37.10',
    '3.61.0': '3.37.9',
    '3.60.0': '3.37.8',
    '3.59.3': '3.37.7',
    '3.59.2': '3.37.6',
    '3.59.1': '3.37.5',
    '3.59.0': '3.37.4',
    '3.58.1': '3.37.3',
    '3.58.0': '3.37.2',
    '3.57.3': '3.37.1',
    '3.57.2': '3.37.0',
    '3.57.1': '3.36.18',
    '3.57.0': '3.36.17',
    '3.56.0': '3.36.16',
    '3.55.2': '3.36.15',
    '3.55.1': '3.36.14',
    '3.55.0': '3.36.13',
    '3.54.6': '3.36.12',
    '3.54.5': '3.36.11',
    '3.54.4': '3.36.10',
    '3.54.3': '3.36.9',
    '3.54.2': '3.36.8',
    '3.54.1': '3.36.7',
    '3.54.0': '3.36.6',
    '3.53.0': '3.36.5',
    '3.52.1': '3.36.4',
    '3.52.0': '3.36.3',
    '3.51.0': '3.36.2',
    '3.50.6': '3.36.1',
    '3.50.5': '3.36.0',
    '3.50.4': '3.35.16',
    '3.50.3': '3.35.15',
    '3.50.2': '3.35.14',
    '3.50.1': '3.35.13',
    '3.50.0': '3.35.12',
    '3.49.3': '3.35.11',
    '3.49.2': '3.35.10',
    '3.49.1': '3.35.9',
    '3.49.0': '3.35.8',
    '3.48.3': '3.35.7',
    '3.48.2': '3.35.6',
    '3.48.1': '3.35.5',
    '3.48.0': '3.35.4',
    '3.47.3': '3.35.3',
    '3.47.2': '3.35.2',
    '3.47.1': '3.35.1',
    '3.47.0': '3.35.0',
    '3.46.3': '3.34.3',
    '3.46.2': '3.34.2',
    '3.46.1': '3.34.1',
    '3.46.0': '3.34.0',
    '3.45.18': '3.33.7',
    '3.45.17': '3.33.6',
    '3.45.16': '3.33.5',
    '3.45.15': '3.33.4',
    '3.45.14': '3.33.3',
    '3.45.13': '3.33.2',
    '3.45.12': '3.33.1',
    '3.45.11': '3.33.0',
    '3.45.10': '3.32.5',
    '3.45.9': '3.32.4',
    '3.45.8': '3.32.3',
    '3.45.7': '3.32.2',
    '3.45.6': '3.32.1',
    '3.45.5': '3.32.0',
    '3.45.4': '3.31.29',
    '3.45.3': '3.31.28',
    '3.45.2': '3.31.27',
    '3.45.1': '3.31.26',
    '3.45.0': '3.31.25',
    '3.44.7': '3.31.24',
    '3.44.6': '3.31.23',
    '3.44.5': '3.31.22',
    '3.44.4': '3.31.21',
    '3.44.3': '3.31.20',
    '3.44.2': '3.31.19',
    '3.44.1': '3.31.18',
    '3.44.0': '3.31.17',
    '3.43.1': '3.31.16',
    '3.43.0': '3.31.15',
    '3.42.1': '3.31.14',
    '3.42.0': '3.31.13',
    '3.41.3': '3.31.12',
    '3.41.2': '3.31.11',
    '3.41.1': '3.31.10',
    '3.41.0': '3.31.9',
    '3.40.7': '3.31.8',
    '3.40.6': '3.31.7',
    '3.40.5': '3.31.6',
    '3.40.4': '3.31.5',
    '3.40.3': '3.31.4',
    '3.40.2': '3.31.3',
    '3.40.1': '3.31.2',
    '3.40.0': '3.31.1',
    '3.39.1': '3.31.0',
    '3.39.0': '3.30.38',
    '3.38.6': '3.30.37',
    '3.38.5': '3.30.36',
    '3.38.4': '3.30.35',
    '3.38.3': '3.30.34',
    '3.38.2': '3.30.33',
    '3.38.1': '3.30.32',
    '3.38.0': '3.30.31',
    '3.37.7': '3.30.30',
    '3.37.6': '3.30.29',
    '3.37.5': '3.30.28',
    '3.37.4': '3.30.27',
    '3.37.3': '3.30.26',
    '3.37.2': '3.30.25',
    '3.37.1': '3.30.24',
    '3.37.0': '3.30.23',
    '3.36.0': '3.30.22',
    '3.35.2': '3.30.21',
    '3.35.1': '3.30.20',
    '3.35.0': '3.30.19',
    '3.34.0': '3.30.18',
    '3.33.8': '3.30.17',
    '3.33.7': '3.30.16',
    '3.33.6': '3.30.15',
    '3.33.5': '3.30.14',
    '3.33.4': '3.30.13',
    '3.33.3': '3.30.12',
    '3.33.2': '3.30.11',
    '3.33.1': '3.30.10',
    '3.33.0': '3.30.9',
    '3.32.0': '3.30.8',
    '3.31.1': '3.30.7',
    '3.31.0': '3.30.6',
    '3.30.0': '3.30.5',
    '3.29.2': '3.30.4',
    '3.29.1': '3.30.3',
    '3.29.0': '3.30.2',
    '3.28.1': '3.30.1',
    '3.28.0': '3.30.0',
    '3.27.7': '3.29.0',
    '3.27.6': '3.28.3',
    '3.27.5': '3.28.2',
    '3.27.4': '3.28.1',
    '3.27.3': '3.28.0',
    '3.27.2': '3.27.13',
    '3.27.1': '3.27.12',
    '3.27.0': '3.27.11',
    '3.26.4': '3.27.10',
    '3.26.3': '3.27.9',
    '3.26.2': '3.27.8',
    '3.26.1': '3.27.7',
    '3.26.0': '3.27.6',
    '3.25.0': '3.27.5',
    '3.24.6': '3.27.4',
    '3.24.5': '3.27.3',
    '3.24.4': '3.27.2',
    '3.24.3': '3.27.1',
    '3.24.3-alpha.1': '3.27.0',
    '3.24.3-alpha.0': '3.26.14',
    '3.24.2': '3.26.13',
    '3.24.2-alpha.1': '3.26.12',
    '3.24.2-alpha.0': '3.26.11',
    '3.24.1': '3.26.10',
    '3.24.1-alpha.0': '3.26.9',
    '3.24.0': '3.26.8',
    '3.24.0-alpha.7': '3.26.7',
    '3.24.0-alpha.6': '3.26.6',
    '3.24.0-alpha.5': '3.26.5',
    '3.24.0-alpha.4': '3.26.4',
    '3.24.0-alpha.3': '3.26.3',
    '3.24.0-alpha.2': '3.26.2',
    '3.24.0-alpha.1': '3.26.1',
    '3.24.0-alpha.0': '3.26.0',
    '3.23.0': '3.25.15',
    '3.23.0-alpha.1': '3.25.14',
    '3.23.0-alpha.0': '3.25.13',
    '3.22.7-alpha.0': '3.25.12',
    '3.22.6': '3.25.11',
    '3.22.6-alpha.0': '3.25.10',
    '3.22.5': '3.25.9',
    '3.22.4': '3.24.4',
    '3.22.3': '3.24.3',
    '3.22.3-alpha.9': '3.25.6',
    '3.22.3-alpha.8': '3.25.5',
    '3.22.3-alpha.7': '3.25.4',
    '3.22.3-alpha.6': '3.25.3',
    '3.22.3-alpha.5': '3.25.2',
    '3.22.3-alpha.4': '3.25.1',
    '3.22.3-alpha.3': '3.25.0',
    '3.22.3-alpha.2': '3.24.8',
    '3.22.3-alpha.1': '3.24.7',
    '3.22.3-alpha.0': '3.24.6',
    '3.22.2': '3.24.5',
    '3.22.1': '3.24.2',
    '3.22.0': '3.24.2-alpha.2',
    '3.21.0': '3.24.2-alpha.1',
};
