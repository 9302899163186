"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoveItemsToVault = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class MoveItemsToVault {
    constructor(mutator, sync, files) {
        this.mutator = mutator;
        this.sync = sync;
        this.files = files;
    }
    async execute(dto) {
        for (const item of dto.items) {
            await this.mutator.changeItem(item, (mutator) => {
                mutator.key_system_identifier = dto.vault.systemIdentifier;
                mutator.shared_vault_uuid = dto.vault.isSharedVaultListing() ? dto.vault.sharing.sharedVaultUuid : undefined;
            });
        }
        await this.sync.sync();
        for (const item of dto.items) {
            if (item.content_type !== domain_core_1.ContentType.TYPES.File) {
                continue;
            }
            if (dto.vault.isSharedVaultListing()) {
                await this.files.moveFileToSharedVault(item, dto.vault);
            }
            else {
                const itemPreviouslyBelongedToSharedVault = item.shared_vault_uuid;
                if (itemPreviouslyBelongedToSharedVault) {
                    await this.files.moveFileOutOfSharedVault(item);
                }
            }
        }
    }
}
exports.MoveItemsToVault = MoveItemsToVault;
