"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptOwnMessage = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class DecryptOwnMessage {
    constructor(operators) {
        this.operators = operators;
    }
    execute(dto) {
        const defaultOperator = this.operators.defaultOperator();
        const version = defaultOperator.versionForAsymmetricallyEncryptedString(dto.message);
        const keyOperator = this.operators.operatorForVersion(version);
        const result = keyOperator.asymmetricDecryptOwnMessage({
            message: dto.message,
            ownPrivateKey: dto.privateKey,
            recipientPublicKey: dto.recipientPublicKey,
        });
        if (result.isFailed()) {
            return domain_core_1.Result.fail(result.getError());
        }
        const decryptedObject = result.getValue();
        if (!decryptedObject.signatureVerified) {
            return domain_core_1.Result.fail('Failed to verify signature');
        }
        return domain_core_1.Result.ok(JSON.parse(decryptedObject.plaintext));
    }
}
exports.DecryptOwnMessage = DecryptOwnMessage;
