"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateDecryptedParametersUseCase = void 0;
const V004AlgorithmHelpers_1 = require("../../V004AlgorithmHelpers");
const StringToAuthenticatedData_1 = require("../Utils/StringToAuthenticatedData");
const CreateConsistentBase64JsonPayload_1 = require("../Utils/CreateConsistentBase64JsonPayload");
const GenerateSymmetricPayloadSignatureResult_1 = require("./GenerateSymmetricPayloadSignatureResult");
const DeriveHashingKey_1 = require("../Hash/DeriveHashingKey");
class GenerateDecryptedParametersUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.base64DataUsecase = new CreateConsistentBase64JsonPayload_1.CreateConsistentBase64JsonPayloadUseCase(this.crypto);
        this.stringToAuthenticatedDataUseCase = new StringToAuthenticatedData_1.StringToAuthenticatedDataUseCase(this.crypto);
        this.signingVerificationUseCase = new GenerateSymmetricPayloadSignatureResult_1.GenerateSymmetricPayloadSignatureResultUseCase(this.crypto);
        this.deriveHashingKeyUseCase = new DeriveHashingKey_1.DeriveHashingKeyUseCase(this.crypto);
    }
    execute(encrypted, key) {
        const contentKeyResult = this.decryptContentKey(encrypted, key);
        if (!contentKeyResult) {
            console.error('Error decrypting contentKey from parameters', encrypted);
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const contentResult = this.decryptContent(encrypted, contentKeyResult.contentKey);
        if (!contentResult) {
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const hashingKey = this.deriveHashingKeyUseCase.execute(key);
        const signatureVerificationResult = this.signingVerificationUseCase.execute(encrypted, hashingKey, {
            additionalData: contentKeyResult.components.additionalData,
            plaintext: contentKeyResult.contentKey,
        }, {
            additionalData: contentResult.components.additionalData,
            plaintext: contentResult.content,
        });
        return {
            uuid: encrypted.uuid,
            content: JSON.parse(contentResult.content),
            signatureData: signatureVerificationResult,
        };
    }
    decryptContent(encrypted, contentKey) {
        const contentComponents = (0, V004AlgorithmHelpers_1.deconstructEncryptedPayloadString)(encrypted.content);
        const contentAuthenticatedData = this.stringToAuthenticatedDataUseCase.execute(contentComponents.authenticatedData, {
            u: encrypted.uuid,
            v: encrypted.version,
            ksi: encrypted.key_system_identifier,
            svu: encrypted.shared_vault_uuid,
        });
        const authenticatedDataString = this.base64DataUsecase.execute(contentAuthenticatedData);
        const content = this.crypto.xchacha20Decrypt(contentComponents.ciphertext, contentComponents.nonce, contentKey, authenticatedDataString);
        if (!content) {
            return null;
        }
        return {
            content,
            components: contentComponents,
            authenticatedDataString,
        };
    }
    decryptContentKey(encrypted, key) {
        const contentKeyComponents = (0, V004AlgorithmHelpers_1.deconstructEncryptedPayloadString)(encrypted.enc_item_key);
        const contentKeyAuthenticatedData = this.stringToAuthenticatedDataUseCase.execute(contentKeyComponents.authenticatedData, {
            u: encrypted.uuid,
            v: encrypted.version,
            ksi: encrypted.key_system_identifier,
            svu: encrypted.shared_vault_uuid,
        });
        const authenticatedDataString = this.base64DataUsecase.execute(contentKeyAuthenticatedData);
        const contentKey = this.crypto.xchacha20Decrypt(contentKeyComponents.ciphertext, contentKeyComponents.nonce, key.itemsKey, authenticatedDataString);
        if (!contentKey) {
            return null;
        }
        return {
            contentKey,
            components: contentKeyComponents,
            authenticatedDataString,
        };
    }
}
exports.GenerateDecryptedParametersUseCase = GenerateDecryptedParametersUseCase;
