"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidFutureSiblings = exports.tagSiblings = exports.rootTags = void 0;
const rootTags = (items) => {
    const hasNoParent = (tag) => !items.getTagParent(tag);
    const allTags = items.getDisplayableTags();
    const rootTags = allTags.filter(hasNoParent);
    return rootTags;
};
exports.rootTags = rootTags;
const tagSiblings = (items, tag) => {
    const withoutCurrentTag = (tags) => tags.filter((other) => other.uuid !== tag.uuid);
    const isTemplateTag = items.isTemplateItem(tag);
    const parentTag = !isTemplateTag && items.getTagParent(tag);
    if (parentTag) {
        const siblingsAndTag = items.getTagChildren(parentTag);
        return withoutCurrentTag(siblingsAndTag);
    }
    return withoutCurrentTag((0, exports.rootTags)(items));
};
exports.tagSiblings = tagSiblings;
const isValidFutureSiblings = (alerts, futureSiblings, tag) => {
    const siblingWithSameName = futureSiblings.find((otherTag) => otherTag.title === tag.title);
    if (siblingWithSameName) {
        alerts === null || alerts === void 0 ? void 0 : alerts.alert(`A tag with the name ${tag.title} already exists at this destination. Please rename this tag before moving and try again.`).catch(console.error);
        return false;
    }
    return true;
};
exports.isValidFutureSiblings = isValidFutureSiblings;
