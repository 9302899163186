"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getKeyboardShortcuts = void 0;
const platformCheck_1 = require("./platformCheck");
const KeyboardCommands_1 = require("./KeyboardCommands");
const KeyboardKey_1 = require("./KeyboardKey");
const KeyboardModifier_1 = require("./KeyboardModifier");
function getKeyboardShortcuts(platform, _environment) {
    const isMac = (0, platformCheck_1.isMacPlatform)(platform);
    const primaryModifier = isMac ? KeyboardModifier_1.KeyboardModifier.Meta : KeyboardModifier_1.KeyboardModifier.Ctrl;
    return [
        {
            command: KeyboardCommands_1.TOGGLE_LIST_PANE_KEYBOARD_COMMAND,
            key: 'l',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND,
            key: 'e',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.CREATE_NEW_NOTE_KEYBOARD_COMMAND,
            code: 'KeyN',
            modifiers: [KeyboardModifier_1.KeyboardModifier.Alt, KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.NEXT_LIST_ITEM_KEYBOARD_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Down,
        },
        {
            command: KeyboardCommands_1.PREVIOUS_LIST_ITEM_KEYBOARD_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Up,
        },
        {
            command: KeyboardCommands_1.SEARCH_KEYBOARD_COMMAND,
            code: 'KeyF',
            modifiers: [KeyboardModifier_1.KeyboardModifier.Alt, KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.CANCEL_SEARCH_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Escape,
        },
        {
            command: KeyboardCommands_1.SELECT_ALL_ITEMS_KEYBOARD_COMMAND,
            key: 'a',
            modifiers: [primaryModifier],
        },
        {
            command: KeyboardCommands_1.SHOW_HIDDEN_OPTIONS_KEYBOARD_COMMAND,
            modifiers: [KeyboardModifier_1.KeyboardModifier.Alt],
        },
        {
            command: KeyboardCommands_1.DELETE_NOTE_KEYBOARD_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Backspace,
            modifiers: [primaryModifier],
        },
        {
            command: KeyboardCommands_1.TAB_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Tab,
        },
        {
            command: KeyboardCommands_1.ESCAPE_COMMAND,
            key: KeyboardKey_1.KeyboardKey.Escape,
        },
        {
            command: KeyboardCommands_1.TOGGLE_FOCUS_MODE_COMMAND,
            key: 'f',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.TOGGLE_DARK_MODE_COMMAND,
            key: 'd',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.CHANGE_EDITOR_COMMAND,
            key: '/',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.FOCUS_TAGS_INPUT_COMMAND,
            code: 'KeyT',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Alt],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.CREATE_NEW_TAG_COMMAND,
            code: 'KeyN',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Alt],
        },
        {
            command: KeyboardCommands_1.OPEN_NOTE_HISTORY_COMMAND,
            key: 'h',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.CAPTURE_SAVE_COMMAND,
            key: 's',
            modifiers: [primaryModifier],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.STAR_NOTE_COMMAND,
            key: 's',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.PIN_NOTE_COMMAND,
            key: 'p',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.SUPER_TOGGLE_SEARCH,
            key: 'f',
            modifiers: [primaryModifier],
        },
        {
            command: KeyboardCommands_1.SUPER_SEARCH_TOGGLE_REPLACE_MODE,
            key: 'h',
            modifiers: [primaryModifier],
        },
        {
            command: KeyboardCommands_1.SUPER_SEARCH_TOGGLE_CASE_SENSITIVE,
            key: 'c',
            modifiers: [KeyboardModifier_1.KeyboardModifier.Alt],
        },
        {
            command: KeyboardCommands_1.SUPER_SEARCH_NEXT_RESULT,
            key: 'F3',
        },
        {
            command: KeyboardCommands_1.SUPER_SEARCH_PREVIOUS_RESULT,
            key: 'F3',
            modifiers: [KeyboardModifier_1.KeyboardModifier.Shift],
        },
        {
            command: KeyboardCommands_1.SUPER_SHOW_MARKDOWN_PREVIEW,
            key: 'm',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.OPEN_PREFERENCES_COMMAND,
            key: ',',
            modifiers: [primaryModifier],
            preventDefault: true,
        },
        {
            command: KeyboardCommands_1.CHANGE_EDITOR_WIDTH_COMMAND,
            key: 'j',
            modifiers: [primaryModifier, KeyboardModifier_1.KeyboardModifier.Shift],
            preventDefault: true,
        },
    ];
}
exports.getKeyboardShortcuts = getKeyboardShortcuts;
