"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBeforeUnload = void 0;
const react_1 = require("react");
const useBeforeUnload = () => {
    (0, react_1.useEffect)(() => {
        window.onbeforeunload = () => true;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);
};
exports.useBeforeUnload = useBeforeUnload;
