"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAlignmentBlockOptions = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const Alignment_1 = require("../../Blocks/Alignment");
function GetAlignmentBlockOptions(editor) {
    return (0, Alignment_1.GetAlignmentBlocks)(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetAlignmentBlockOptions = GetAlignmentBlockOptions;
