"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidKeyParamsKeys = void 0;
exports.ValidKeyParamsKeys = [
    'identifier',
    'pw_cost',
    'pw_nonce',
    'pw_salt',
    'version',
    'origination',
    'created',
];
