"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptionService = void 0;
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const common_1 = require("@standardnotes/common");
const AbstractService_1 = require("../Service/AbstractService");
const SyncEvent_1 = require("../Event/SyncEvent");
const EncryptionServiceEvent_1 = require("./EncryptionServiceEvent");
const RootKeyManagerEvent_1 = require("../RootKeyManager/RootKeyManagerEvent");
const KeyMode_1 = require("../RootKeyManager/KeyMode");
/**
 * The encryption service is responsible for the encryption and decryption of payloads, and
 * handles delegation of a task to the respective protocol operator. Each version of the protocol
 * (001, 002, 003, 004, etc) uses a respective operator version to perform encryption operations.
 * Operators are located in /protocol/operator.
 * The protocol service depends on the keyManager for determining which key to use for the
 * encryption and decryption of a particular payload.
 * The protocol service is also responsible for dictating which protocol versions are valid,
 * and which are no longer valid or not supported.

 * The key manager is responsible for managing root key and root key wrapper states.
 * When the key manager is initialized, it initiates itself with a keyMode, which
 * dictates the entire flow of key management. The key manager's responsibilities include:
 * - interacting with the device keychain to save or clear the root key
 * - interacting with storage to save root key params or wrapper params, or the wrapped root key.
 * - exposing methods that allow the application to unwrap the root key (unlock the application)
 *
 * It also exposes two primary methods for determining what key should be used to encrypt
 * or decrypt a particular payload. Some payloads are encrypted directly with the rootKey
 * (such as itemsKeys and encryptedStorage). Others are encrypted with itemsKeys (notes, tags, etc).

 * The items key manager manages the lifecycle of items keys.
 * It is responsible for creating the default items key when conditions call for it
 * (such as after the first sync completes and no key exists).
 * It also exposes public methods that allows consumers to retrieve an items key
 * for a particular payload, and also retrieve all available items keys.
*/
class EncryptionService extends AbstractService_1.AbstractService {
    constructor(items, mutator, payloads, operators, itemsEncryption, rootKeyManager, crypto, _createNewItemsKeyWithRollback, _findDefaultItemsKey, _rootKeyEncryptPayloadWithKeyLookup, _rootKeyEncryptPayload, _rootKeyDecryptPayload, _rootKeyDecryptPayloadWithKeyLookup, _createDefaultItemsKey, _getKeyPairs, internalEventBus) {
        super(internalEventBus);
        this.items = items;
        this.mutator = mutator;
        this.payloads = payloads;
        this.operators = operators;
        this.itemsEncryption = itemsEncryption;
        this.rootKeyManager = rootKeyManager;
        this.crypto = crypto;
        this._createNewItemsKeyWithRollback = _createNewItemsKeyWithRollback;
        this._findDefaultItemsKey = _findDefaultItemsKey;
        this._rootKeyEncryptPayloadWithKeyLookup = _rootKeyEncryptPayloadWithKeyLookup;
        this._rootKeyEncryptPayload = _rootKeyEncryptPayload;
        this._rootKeyDecryptPayload = _rootKeyDecryptPayload;
        this._rootKeyDecryptPayloadWithKeyLookup = _rootKeyDecryptPayloadWithKeyLookup;
        this._createDefaultItemsKey = _createDefaultItemsKey;
        this._getKeyPairs = _getKeyPairs;
        this.internalEventBus = internalEventBus;
        internalEventBus.addEventHandler(this, RootKeyManagerEvent_1.RootKeyManagerEvent.RootKeyManagerKeyStatusChanged);
        utils_1.UuidGenerator.SetGenerator(this.crypto.generateUUID);
    }
    async handleEvent(event) {
        if (event.type === RootKeyManagerEvent_1.RootKeyManagerEvent.RootKeyManagerKeyStatusChanged) {
            this.itemsEncryption.userVersion = this.getUserVersion();
            void this.notifyEvent(EncryptionServiceEvent_1.EncryptionServiceEvent.RootKeyStatusChanged);
        }
    }
    deinit() {
        ;
        this.items = undefined;
        this.payloads = undefined;
        this.operators = undefined;
        this.itemsEncryption = undefined;
        this.rootKeyManager = undefined;
        this.crypto = undefined;
        this._createNewItemsKeyWithRollback = undefined;
        this._findDefaultItemsKey = undefined;
        this._rootKeyEncryptPayloadWithKeyLookup = undefined;
        this._rootKeyEncryptPayload = undefined;
        this._rootKeyDecryptPayload = undefined;
        this._rootKeyDecryptPayloadWithKeyLookup = undefined;
        this._createDefaultItemsKey = undefined;
        super.deinit();
    }
    hasSigningKeyPair() {
        var _a;
        return !!((_a = this.getRootKey()) === null || _a === void 0 ? void 0 : _a.signingKeyPair);
    }
    async initialize() {
        await this.rootKeyManager.initialize();
    }
    /**
     * Returns encryption protocol display name for active account/wrapper
     */
    async getEncryptionDisplayName() {
        const version = await this.rootKeyManager.getEncryptionSourceVersion();
        if (version) {
            return this.operators.operatorForVersion(version).getEncryptionDisplayName();
        }
        throw Error('Attempting to access encryption display name wtihout source');
    }
    getLatestVersion() {
        return common_1.ProtocolVersionLatest;
    }
    /** Unlike SessionManager.isSignedIn, hasAccount can be read before the application is unlocked and is based on the key state */
    hasAccount() {
        return this.rootKeyManager.hasAccount();
    }
    hasRootKeyEncryptionSource() {
        return this.rootKeyManager.hasRootKeyEncryptionSource();
    }
    getUserVersion() {
        return this.rootKeyManager.getUserVersion();
    }
    async upgradeAvailable() {
        const accountUpgradeAvailable = this.accountUpgradeAvailable();
        const passcodeUpgradeAvailable = await this.passcodeUpgradeAvailable();
        return accountUpgradeAvailable || passcodeUpgradeAvailable;
    }
    getSureDefaultItemsKey() {
        return this.itemsEncryption.getDefaultItemsKey();
    }
    async repersistAllItems() {
        return this.itemsEncryption.repersistAllItems();
    }
    async createNewItemsKeyWithRollback() {
        return this._createNewItemsKeyWithRollback.execute();
    }
    itemsKeyForEncryptedPayload(payload) {
        return this.itemsEncryption.itemsKeyForEncryptedPayload(payload);
    }
    defaultItemsKeyForItemVersion(version, fromKeys) {
        return this.itemsEncryption.defaultItemsKeyForItemVersion(version, fromKeys);
    }
    async encryptSplitSingle(split) {
        return (await this.encryptSplit(split))[0];
    }
    async encryptSplit(split) {
        const allEncryptedParams = [];
        const { usesRootKey, usesItemsKey, usesKeySystemRootKey, usesRootKeyWithKeyLookup, usesItemsKeyWithKeyLookup, usesKeySystemRootKeyWithKeyLookup, } = split;
        const keys = this._getKeyPairs.execute();
        const signingKeyPair = keys.isFailed() ? undefined : keys.getValue().signing;
        if (usesRootKey) {
            const rootKeyEncrypted = await this._rootKeyEncryptPayload.executeMany(usesRootKey.items, usesRootKey.key, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, rootKeyEncrypted);
        }
        if (usesRootKeyWithKeyLookup) {
            const rootKeyEncrypted = await this._rootKeyEncryptPayloadWithKeyLookup.executeMany(usesRootKeyWithKeyLookup.items, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, rootKeyEncrypted);
        }
        if (usesKeySystemRootKey) {
            const keySystemRootKeyEncrypted = await this._rootKeyEncryptPayload.executeMany(usesKeySystemRootKey.items, usesKeySystemRootKey.key, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, keySystemRootKeyEncrypted);
        }
        if (usesKeySystemRootKeyWithKeyLookup) {
            const keySystemRootKeyEncrypted = await this._rootKeyEncryptPayloadWithKeyLookup.executeMany(usesKeySystemRootKeyWithKeyLookup.items, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, keySystemRootKeyEncrypted);
        }
        if (usesItemsKey) {
            const itemsKeyEncrypted = await this.itemsEncryption.encryptPayloads(usesItemsKey.items, usesItemsKey.key, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, itemsKeyEncrypted);
        }
        if (usesItemsKeyWithKeyLookup) {
            const itemsKeyEncrypted = await this.itemsEncryption.encryptPayloadsWithKeyLookup(usesItemsKeyWithKeyLookup.items, signingKeyPair);
            (0, utils_1.extendArray)(allEncryptedParams, itemsKeyEncrypted);
        }
        const packagedEncrypted = allEncryptedParams.map((encryptedParams) => {
            const original = (0, encryption_1.FindPayloadInEncryptionSplit)(encryptedParams.uuid, split);
            return new models_1.EncryptedPayload({
                ...original,
                ...encryptedParams,
                waitingForKey: false,
                errorDecrypting: false,
            });
        });
        return packagedEncrypted;
    }
    async decryptSplitSingle(split) {
        const results = await this.decryptSplit(split);
        return results[0];
    }
    async decryptSplit(split) {
        const resultParams = [];
        const { usesRootKey, usesItemsKey, usesKeySystemRootKey, usesRootKeyWithKeyLookup, usesItemsKeyWithKeyLookup, usesKeySystemRootKeyWithKeyLookup, } = split;
        if (usesRootKey) {
            const rootKeyDecrypted = await this._rootKeyDecryptPayload.executeMany(usesRootKey.items, usesRootKey.key);
            (0, utils_1.extendArray)(resultParams, rootKeyDecrypted);
        }
        if (usesRootKeyWithKeyLookup) {
            const rootKeyDecrypted = await this._rootKeyDecryptPayloadWithKeyLookup.executeMany(usesRootKeyWithKeyLookup.items);
            (0, utils_1.extendArray)(resultParams, rootKeyDecrypted);
        }
        if (usesKeySystemRootKey) {
            const keySystemRootKeyDecrypted = await this._rootKeyDecryptPayload.executeMany(usesKeySystemRootKey.items, usesKeySystemRootKey.key);
            (0, utils_1.extendArray)(resultParams, keySystemRootKeyDecrypted);
        }
        if (usesKeySystemRootKeyWithKeyLookup) {
            const keySystemRootKeyDecrypted = await this._rootKeyDecryptPayloadWithKeyLookup.executeMany(usesKeySystemRootKeyWithKeyLookup.items);
            (0, utils_1.extendArray)(resultParams, keySystemRootKeyDecrypted);
        }
        if (usesItemsKey) {
            const itemsKeyDecrypted = await this.itemsEncryption.decryptPayloads(usesItemsKey.items, usesItemsKey.key);
            (0, utils_1.extendArray)(resultParams, itemsKeyDecrypted);
        }
        if (usesItemsKeyWithKeyLookup) {
            const itemsKeyDecrypted = await this.itemsEncryption.decryptPayloadsWithKeyLookup(usesItemsKeyWithKeyLookup.items);
            (0, utils_1.extendArray)(resultParams, itemsKeyDecrypted);
        }
        const packagedResults = resultParams.map((params) => {
            const original = (0, encryption_1.FindPayloadInDecryptionSplit)(params.uuid, split);
            if ((0, encryption_1.isErrorDecryptingParameters)(params)) {
                return new models_1.EncryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
            else {
                return new models_1.DecryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
        });
        return packagedResults;
    }
    async decryptPayloadWithKeyLookup(payload) {
        const decryptedParameters = await this.itemsEncryption.decryptPayloadWithKeyLookup(payload);
        if ((0, encryption_1.isErrorDecryptingParameters)(decryptedParameters)) {
            return {
                parameters: decryptedParameters,
                payload: new models_1.EncryptedPayload({
                    ...payload.ejected(),
                    ...decryptedParameters,
                }),
            };
        }
        else {
            return {
                parameters: decryptedParameters,
                payload: new models_1.DecryptedPayload({
                    ...payload.ejected(),
                    ...decryptedParameters,
                }),
            };
        }
    }
    /**
     * Returns true if the user's account protocol version is not equal to the latest version.
     */
    accountUpgradeAvailable() {
        const userVersion = this.getUserVersion();
        if (!userVersion) {
            return false;
        }
        return userVersion !== common_1.ProtocolVersionLatest;
    }
    /**
     * Returns true if the user's account protocol version is not equal to the latest version.
     */
    async passcodeUpgradeAvailable() {
        return this.rootKeyManager.passcodeUpgradeAvailable();
    }
    /**
     * Determines whether the current environment is capable of supporting
     * key derivation.
     */
    platformSupportsKeyDerivation(keyParams) {
        /**
         * If the version is 003 or lower, key derivation is supported unless the browser is
         * IE or Edge (or generally, where WebCrypto is not available) or React Native environment is detected.
         *
         * Versions 004 and above are always supported.
         */
        if ((0, common_1.compareVersions)(keyParams.version, common_1.ProtocolVersion.V004) >= 0) {
            /* keyParams.version >= 004 */
            return true;
        }
        else {
            return !!(0, utils_1.isWebCryptoAvailable)() || (0, utils_1.isReactNativeEnvironment)();
        }
    }
    supportedVersions() {
        return [common_1.ProtocolVersion.V001, common_1.ProtocolVersion.V002, common_1.ProtocolVersion.V003, common_1.ProtocolVersion.V004];
    }
    /**
     * Determines whether the input version is greater than the latest supported library version.
     */
    isVersionNewerThanLibraryVersion(version) {
        const libraryVersion = common_1.ProtocolVersionLatest;
        return (0, common_1.compareVersions)(version, libraryVersion) === 1;
    }
    /**
     * Versions 001 and 002 of the protocol supported dynamic costs, as reported by the server.
     * This function returns the client-enforced minimum cost, to prevent the server from
     * overwhelmingly under-reporting the cost.
     */
    costMinimumForVersion(version) {
        if ((0, common_1.compareVersions)(version, common_1.ProtocolVersion.V003) >= 0) {
            throw 'Cost minimums only apply to versions <= 002';
        }
        if (version === common_1.ProtocolVersion.V001) {
            return encryption_1.V001Algorithm.PbkdfMinCost;
        }
        else if (version === common_1.ProtocolVersion.V002) {
            return encryption_1.V002Algorithm.PbkdfMinCost;
        }
        else {
            throw `Invalid version for cost minimum: ${version}`;
        }
    }
    /**
     * Computes a root key given a password and key params.
     * Delegates computation to respective protocol operator.
     */
    async computeRootKey(password, keyParams) {
        return this.rootKeyManager.computeRootKey(password, keyParams);
    }
    /**
     * Creates a root key using the latest protocol version
     */
    async createRootKey(identifier, password, origination, version) {
        return this.rootKeyManager.createRootKey(identifier, password, origination, version);
    }
    createRandomizedKeySystemRootKey(dto) {
        return this.operators.defaultOperator().createRandomizedKeySystemRootKey(dto);
    }
    createUserInputtedKeySystemRootKey(dto) {
        return this.operators.defaultOperator().createUserInputtedKeySystemRootKey(dto);
    }
    deriveUserInputtedKeySystemRootKey(dto) {
        return this.operators.defaultOperator().deriveUserInputtedKeySystemRootKey(dto);
    }
    createKeySystemItemsKey(uuid, keySystemIdentifier, sharedVaultUuid, rootKeyToken) {
        return this.operators
            .defaultOperator()
            .createKeySystemItemsKey(uuid, keySystemIdentifier, sharedVaultUuid, rootKeyToken);
    }
    asymmetricSignatureVerifyDetached(encryptedString) {
        const defaultOperator = this.operators.defaultOperator();
        const version = defaultOperator.versionForAsymmetricallyEncryptedString(encryptedString);
        const keyOperator = this.operators.operatorForVersion(version);
        return keyOperator.asymmetricSignatureVerifyDetached(encryptedString);
    }
    getSenderPublicKeySetFromAsymmetricallyEncryptedString(string) {
        const defaultOperator = this.operators.defaultOperator();
        const version = defaultOperator.versionForAsymmetricallyEncryptedString(string);
        const keyOperator = this.operators.operatorForVersion(version);
        return keyOperator.getSenderPublicKeySetFromAsymmetricallyEncryptedString(string);
    }
    /**
     * Creates a key params object from a raw object
     * @param keyParams - The raw key params object to create a KeyParams object from
     */
    createKeyParams(keyParams) {
        return (0, encryption_1.CreateAnyKeyParams)(keyParams);
    }
    hasPasscode() {
        return this.rootKeyManager.hasPasscode();
    }
    /**
     * @returns True if the root key has not yet been unwrapped (passcode locked).
     */
    async isPasscodeLocked() {
        return (await this.rootKeyManager.hasRootKeyWrapper()) && this.rootKeyManager.getRootKey() == undefined;
    }
    getRootKeyParams() {
        return this.rootKeyManager.getRootKeyParams();
    }
    getAccountKeyParams() {
        return this.rootKeyManager.getMemoizedRootKeyParams();
    }
    /**
     * Computes the root key wrapping key given a passcode.
     * Wrapping key params are read from disk.
     */
    async computeWrappingKey(passcode) {
        const keyParams = this.rootKeyManager.getSureRootKeyWrapperKeyParams();
        const key = await this.computeRootKey(passcode, keyParams);
        return key;
    }
    /**
     * Unwraps the persisted root key value using the supplied wrappingKey.
     * Application interfaces must check to see if the root key requires unwrapping on load.
     * If so, they must generate the unwrapping key by getting our saved wrapping key keyParams.
     * After unwrapping, the root key is automatically loaded.
     */
    async unwrapRootKey(wrappingKey) {
        return this.rootKeyManager.unwrapRootKey(wrappingKey);
    }
    /**
     * Encrypts rootKey and saves it in storage instead of keychain, and then
     * clears keychain. This is because we don't want to store large encrypted
     * payloads in the keychain. If the root key is not wrapped, it is stored
     * in plain form in the user's secure keychain.
     */
    async setNewRootKeyWrapper(wrappingKey) {
        return this.rootKeyManager.setNewRootKeyWrapper(wrappingKey);
    }
    async removePasscode() {
        await this.rootKeyManager.removeRootKeyWrapper();
    }
    async setRootKey(key, wrappingKey) {
        await this.rootKeyManager.setRootKey(key, wrappingKey);
    }
    /**
     * Returns the in-memory root key value.
     */
    getRootKey() {
        return this.rootKeyManager.getRootKey();
    }
    getSureRootKey() {
        return this.rootKeyManager.getRootKey();
    }
    /**
     * Deletes root key and wrapper from keychain. Used when signing out of application.
     */
    async deleteWorkspaceSpecificKeyStateFromDevice() {
        await this.rootKeyManager.deleteWorkspaceSpecificKeyStateFromDevice();
    }
    async validateAccountPassword(password) {
        return this.rootKeyManager.validateAccountPassword(password);
    }
    async validatePasscode(passcode) {
        return this.rootKeyManager.validatePasscode(passcode);
    }
    getEmbeddedPayloadAuthenticatedData(payload) {
        const version = payload.version;
        if (!version) {
            return undefined;
        }
        const operator = this.operators.operatorForVersion(version);
        const authenticatedData = operator.getPayloadAuthenticatedDataForExternalUse((0, encryption_1.encryptedInputParametersFromPayload)(payload));
        return authenticatedData;
    }
    /** Returns the key params attached to this key's encrypted payload */
    getKeyEmbeddedKeyParamsFromItemsKey(key) {
        const authenticatedData = this.getEmbeddedPayloadAuthenticatedData(key);
        if (!authenticatedData) {
            return undefined;
        }
        if ((0, common_1.isVersionLessThanOrEqualTo)(key.version, common_1.ProtocolVersion.V003)) {
            const rawKeyParams = authenticatedData;
            return this.createKeyParams(rawKeyParams);
        }
        else {
            const rawKeyParams = authenticatedData.kp;
            return this.createKeyParams(rawKeyParams);
        }
    }
    /**
     * A new rootkey-based items key is needed if a user changes their account password
     * on an 003 client and syncs on a signed in 004 client.
     */
    needsNewRootKeyBasedItemsKey() {
        if (!this.hasAccount()) {
            return false;
        }
        const rootKey = this.rootKeyManager.getRootKey();
        if (!rootKey) {
            return false;
        }
        if ((0, common_1.compareVersions)(rootKey.keyVersion, common_1.ProtocolVersionLastNonrootItemsKey) > 0) {
            /** Is >= 004, not needed */
            return false;
        }
        /**
         * A new root key based items key is needed if our default items key content
         * isnt equal to our current root key
         */
        const defaultItemsKey = this._findDefaultItemsKey.execute(this.itemsEncryption.getItemsKeys()).getValue();
        /** Shouldn't be undefined, but if it is, we'll take the corrective action */
        if (!defaultItemsKey) {
            return true;
        }
        return defaultItemsKey.itemsKey !== rootKey.itemsKey;
    }
    async createNewDefaultItemsKey() {
        return this._createDefaultItemsKey.execute();
    }
    getPasswordCreatedDate() {
        const rootKey = this.getRootKey();
        return rootKey ? rootKey.keyParams.createdDate : undefined;
    }
    async onSyncEvent(eventName) {
        if (eventName === SyncEvent_1.SyncEvent.SyncCompletedWithAllItemsUploaded) {
            await this.handleFullSyncCompletion();
        }
        if (eventName === SyncEvent_1.SyncEvent.DownloadFirstSyncCompleted) {
            await this.handleDownloadFirstSyncCompletion();
        }
    }
    /**
     * When a download-first sync completes, it means we've completed a (potentially multipage)
     * sync where we only downloaded what the server had before uploading anything. We will be
     * allowed to make local accomadations here before the server begins with the upload
     * part of the sync (automatically runs after download-first sync completes).
     * We use this to see if the server has any default itemsKeys, and if so, allows us to
     * delete any never-synced items keys we have here locally.
     */
    async handleDownloadFirstSyncCompletion() {
        if (!this.hasAccount()) {
            return;
        }
        const itemsKeys = this.itemsEncryption.getItemsKeys();
        const neverSyncedKeys = itemsKeys.filter((key) => {
            return key.neverSynced;
        });
        const syncedKeys = itemsKeys.filter((key) => {
            return !key.neverSynced;
        });
        /**
         * Find isDefault items key that have been previously synced.
         * If we find one, this means we can delete any non-synced keys.
         */
        const defaultSyncedKey = syncedKeys.find((key) => {
            return key.isDefault;
        });
        const hasSyncedItemsKey = !(0, utils_1.isNullOrUndefined)(defaultSyncedKey);
        if (hasSyncedItemsKey) {
            /** Delete all never synced keys */
            await this.mutator.setItemsToBeDeleted(neverSyncedKeys);
        }
        else {
            /**
             * No previous synced items key.
             * We can keep the one(s) we have, only if their version is equal to our root key
             * version. If their version is not equal to our root key version, delete them. If
             * we end up with 0 items keys, create a new one. This covers the case when you open
             * the app offline and it creates an 004 key, and then you sign into an 003 account.
             */
            const rootKeyParams = this.getRootKeyParams();
            if (rootKeyParams) {
                /** If neverSynced.version != rootKey.version, delete. */
                const toDelete = neverSyncedKeys.filter((itemsKey) => {
                    return itemsKey.keyVersion !== rootKeyParams.version;
                });
                if (toDelete.length > 0) {
                    await this.mutator.setItemsToBeDeleted(toDelete);
                }
                if (this.itemsEncryption.getItemsKeys().length === 0) {
                    await this.createNewDefaultItemsKey();
                }
            }
        }
        /** If we do not have an items key for our current account version, create one */
        const userVersion = this.getUserVersion();
        const accountVersionedKey = this.itemsEncryption.getItemsKeys().find((key) => key.keyVersion === userVersion);
        if ((0, utils_1.isNullOrUndefined)(accountVersionedKey)) {
            await this.createNewDefaultItemsKey();
        }
        this.syncUnsyncedItemsKeys();
    }
    async handleFullSyncCompletion() {
        /** Always create a new items key after full sync, if no items key is found */
        const currentItemsKey = this._findDefaultItemsKey.execute(this.itemsEncryption.getItemsKeys()).getValue();
        if (!currentItemsKey) {
            await this.createNewDefaultItemsKey();
            if (this.rootKeyManager.getKeyMode() === KeyMode_1.KeyMode.WrapperOnly) {
                return this.itemsEncryption.repersistAllItems();
            }
        }
    }
    /**
     * There is presently an issue where an items key created while signed out of account (
     * or possibly signed in but with invalid session), then signing into account, results in that
     * items key never syncing to the account even though it is being used to encrypt synced items.
     * Until we can determine its cause, this corrective function will find any such keys and sync them.
     */
    syncUnsyncedItemsKeys() {
        if (!this.hasAccount()) {
            return;
        }
        const unsyncedKeys = this.itemsEncryption.getItemsKeys().filter((key) => key.neverSynced && !key.dirty);
        if (unsyncedKeys.length > 0) {
            void this.mutator.setItemsDirty(unsyncedKeys);
        }
    }
}
exports.EncryptionService = EncryptionService;
