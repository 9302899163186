"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const FloatingLabelInput = (0, react_1.forwardRef)(({ id, type, label, disabled, value, isInvalid, onChange, className = '', labelClassName = '', inputClassName = '', }, ref) => {
    const [focused, setFocused] = (0, react_1.useState)(false);
    const BASE_CLASSNAME = 'relative bg-default';
    const LABEL_CLASSNAME = `absolute ${!focused ? 'text-neutral' : 'text-info'} ${focused || value ? 'flex top-0 left-2 pt-1.5 px-1' : 'hidden'} ${isInvalid ? 'text-danger' : ''} ${labelClassName}`;
    const INPUT_CLASSNAME = `w-full h-full ${focused || value ? 'pt-6 pb-2' : 'py-2.5'} px-3 text-sm border border-solid border-border rounded placeholder:font-medium focus:ring-info ${isInvalid ? 'border-danger placeholder:text-danger' : ''} ${inputClassName}`;
    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);
    return ((0, jsx_runtime_1.jsxs)("div", { className: `${BASE_CLASSNAME} ${className}`, children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: id, className: LABEL_CLASSNAME, children: label }), (0, jsx_runtime_1.jsx)("input", { id: id, className: INPUT_CLASSNAME, placeholder: !focused ? label : '', type: type, value: value, onChange: onChange, onFocus: handleFocus, onBlur: handleBlur, ref: ref, disabled: disabled })] }));
});
exports.default = FloatingLabelInput;
