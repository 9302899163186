"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllContacts = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetAllContacts {
    constructor(items) {
        this.items = items;
    }
    execute() {
        return domain_core_1.Result.ok(this.items.getItems(domain_core_1.ContentType.TYPES.TrustedContact));
    }
}
exports.GetAllContacts = GetAllContacts;
