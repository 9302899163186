"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log = exports.LoggingDomain = exports.isDev = void 0;
const utils_1 = require("@standardnotes/utils");
exports.isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
var LoggingDomain;
(function (LoggingDomain) {
    LoggingDomain[LoggingDomain["FilesPackage"] = 0] = "FilesPackage";
})(LoggingDomain || (exports.LoggingDomain = LoggingDomain = {}));
const LoggingStatus = {
    [LoggingDomain.FilesPackage]: false,
};
const DomainColor = {
    [LoggingDomain.FilesPackage]: 'green',
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function log(domain, ...args) {
    if (!exports.isDev || !LoggingStatus[domain]) {
        return;
    }
    (0, utils_1.logWithColor)(LoggingDomain[domain], DomainColor[domain], ...args);
}
exports.log = log;
