"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDisableBodyScrollOnMobile = void 0;
const Utils_1 = require("@/Utils");
const react_1 = require("react");
/**
 * Used to disable scroll on document.body when opening popovers or preferences view
 * on mobile so that user can only scroll within the popover or prefs view
 */
const useDisableBodyScrollOnMobile = () => {
    const styleElementRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const isMobile = (0, Utils_1.isMobileScreen)();
        if (isMobile && !styleElementRef.current) {
            const styleElement = document.createElement('style');
            styleElement.textContent = 'body { overflow: hidden; }';
            document.body.appendChild(styleElement);
            styleElementRef.current = styleElement;
        }
        return () => {
            if (isMobile && styleElementRef.current) {
                styleElementRef.current.remove();
            }
        };
    }, []);
};
exports.useDisableBodyScrollOnMobile = useDisableBodyScrollOnMobile;
