"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitQueryInString = exports.getIndexOfQueryInString = exports.splitRangeWithinString = void 0;
const splitRangeWithinString = (string, start, end) => {
    const isStartOutOfBounds = start > string.length || start < 0;
    const isEndOutOfBounds = end > string.length || end < 0;
    const isInvalidRange = start > end;
    if (isStartOutOfBounds || isEndOutOfBounds || isInvalidRange) {
        return [string];
    }
    else {
        return [string.slice(0, start), string.slice(start, end), string.slice(end)].filter((slice) => slice.length > 0);
    }
};
exports.splitRangeWithinString = splitRangeWithinString;
const getIndexOfQueryInString = (string, query) => {
    const lowercasedTitle = string.toLowerCase();
    const lowercasedQuery = query.toLowerCase();
    return lowercasedTitle.indexOf(lowercasedQuery);
};
exports.getIndexOfQueryInString = getIndexOfQueryInString;
const splitQueryInString = (string, query) => {
    const indexOfQueryInTitle = (0, exports.getIndexOfQueryInString)(string, query);
    if (indexOfQueryInTitle < 0) {
        return [string];
    }
    return (0, exports.splitRangeWithinString)(string, indexOfQueryInTitle, indexOfQueryInTitle + query.length);
};
exports.splitQueryInString = splitQueryInString;
