"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemsKeyMutator = void 0;
const models_1 = require("@standardnotes/models");
class ItemsKeyMutator extends models_1.DecryptedItemMutator {
    set isDefault(isDefault) {
        this.mutableContent.isDefault = isDefault;
    }
}
exports.ItemsKeyMutator = ItemsKeyMutator;
