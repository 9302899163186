"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemRelationshipDirection = void 0;
var ItemRelationshipDirection;
(function (ItemRelationshipDirection) {
    ItemRelationshipDirection[ItemRelationshipDirection["AReferencesB"] = 0] = "AReferencesB";
    ItemRelationshipDirection[ItemRelationshipDirection["BReferencesA"] = 1] = "BReferencesA";
    ItemRelationshipDirection[ItemRelationshipDirection["NoRelationship"] = 2] = "NoRelationship";
})(ItemRelationshipDirection || (exports.ItemRelationshipDirection = ItemRelationshipDirection = {}));
