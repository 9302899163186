"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoAccountWarningController = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
class NoAccountWarningController extends AbstractViewController_1.AbstractViewController {
    constructor(sessions, eventBus) {
        var _a;
        super(eventBus);
        this.sessions = sessions;
        this.hide = () => {
            this.show = false;
            ui_services_1.storage.set(ui_services_1.StorageKey.ShowNoAccountWarning, false);
        };
        this.reset = () => {
            ui_services_1.storage.remove(ui_services_1.StorageKey.ShowNoAccountWarning);
        };
        this.show = sessions.isSignedIn() ? false : (_a = ui_services_1.storage.get(ui_services_1.StorageKey.ShowNoAccountWarning)) !== null && _a !== void 0 ? _a : true;
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.SignedIn);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.Started);
        (0, mobx_1.makeObservable)(this, {
            show: mobx_1.observable,
            hide: mobx_1.action,
        });
    }
    async handleEvent(event) {
        switch (event.type) {
            case snjs_1.ApplicationEvent.SignedIn:
                (0, mobx_1.runInAction)(() => {
                    this.show = false;
                });
                break;
            case snjs_1.ApplicationEvent.Started:
                if (this.sessions.isSignedIn()) {
                    (0, mobx_1.runInAction)(() => {
                        this.show = false;
                    });
                }
                break;
        }
    }
}
exports.NoAccountWarningController = NoAccountWarningController;
