"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardService = void 0;
const snjs_1 = require("@standardnotes/snjs");
const eventMatchesKeyAndModifiers_1 = require("./eventMatchesKeyAndModifiers");
const KeyboardKeyEvent_1 = require("./KeyboardKeyEvent");
const KeyboardModifier_1 = require("./KeyboardModifier");
const getKeyboardShortcuts_1 = require("./getKeyboardShortcuts");
class KeyboardService {
    constructor(platform, environment) {
        this.platform = platform;
        this.activeModifiers = new Set();
        this.commandHandlers = new Set();
        this.commandMap = new Map();
        this.addActiveModifier = (modifier) => {
            if (!modifier) {
                return;
            }
            switch (modifier) {
                case KeyboardModifier_1.KeyboardModifier.Meta: {
                    if (this.isMac) {
                        this.activeModifiers.add(modifier);
                    }
                    break;
                }
                case KeyboardModifier_1.KeyboardModifier.Ctrl: {
                    if (!this.isMac) {
                        this.activeModifiers.add(modifier);
                    }
                    break;
                }
                default: {
                    this.activeModifiers.add(modifier);
                    break;
                }
            }
        };
        this.removeActiveModifier = (modifier) => {
            if (!modifier) {
                return;
            }
            this.activeModifiers.delete(modifier);
        };
        this.cancelAllKeyboardModifiers = () => {
            this.activeModifiers.clear();
        };
        this.handleComponentKeyDown = (modifier) => {
            this.addActiveModifier(modifier);
        };
        this.handleComponentKeyUp = (modifier) => {
            this.removeActiveModifier(modifier);
        };
        this.handleKeyDown = (event) => {
            this.updateAllModifiersFromEvent(event);
            this.handleKeyboardEvent(event, KeyboardKeyEvent_1.KeyboardKeyEvent.Down);
        };
        this.handleKeyUp = (event) => {
            this.updateAllModifiersFromEvent(event);
            this.handleKeyboardEvent(event, KeyboardKeyEvent_1.KeyboardKeyEvent.Up);
        };
        this.handleWindowBlur = () => {
            for (const modifier of this.activeModifiers) {
                this.activeModifiers.delete(modifier);
            }
        };
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('blur', this.handleWindowBlur);
        const shortcuts = (0, getKeyboardShortcuts_1.getKeyboardShortcuts)(platform, environment);
        for (const shortcut of shortcuts) {
            this.registerShortcut(shortcut);
        }
    }
    get isMac() {
        return this.platform === snjs_1.Platform.MacDesktop || this.platform === snjs_1.Platform.MacWeb;
    }
    deinit() {
        this.commandHandlers.clear();
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('blur', this.handleWindowBlur);
        this.handleKeyDown = undefined;
        this.handleKeyUp = undefined;
        this.handleWindowBlur = undefined;
    }
    updateAllModifiersFromEvent(event) {
        for (const modifier of Object.values(KeyboardModifier_1.KeyboardModifier)) {
            if (event.getModifierState(modifier)) {
                this.addActiveModifier(modifier);
            }
            else {
                this.removeActiveModifier(modifier);
            }
        }
    }
    handleKeyboardEvent(event, keyEvent) {
        for (const command of this.commandMap.keys()) {
            const shortcut = this.commandMap.get(command);
            if (!shortcut) {
                continue;
            }
            if ((0, eventMatchesKeyAndModifiers_1.eventMatchesKeyAndModifiers)(event, shortcut)) {
                if (shortcut.preventDefault) {
                    event.preventDefault();
                }
                this.handleCommand(command, event, keyEvent);
            }
        }
    }
    handleCommand(command, event, keyEvent) {
        const target = event.target;
        for (const observer of Array.from(this.commandHandlers).reverse()) {
            if (observer.command !== command) {
                continue;
            }
            if (observer.element && event.target !== observer.element) {
                continue;
            }
            if (observer.elements && !observer.elements.includes(target)) {
                continue;
            }
            if (observer.notElement && observer.notElement === event.target) {
                continue;
            }
            if (observer.notElementIds && observer.notElementIds.includes(target.id)) {
                continue;
            }
            if (observer.notTags && observer.notTags.includes(target.tagName)) {
                continue;
            }
            const callback = keyEvent === KeyboardKeyEvent_1.KeyboardKeyEvent.Down ? observer.onKeyDown : observer.onKeyUp;
            if (callback) {
                const exclusive = callback(event);
                if (exclusive) {
                    return;
                }
            }
        }
    }
    triggerCommand(command, data) {
        for (const observer of Array.from(this.commandHandlers).reverse()) {
            if (observer.command !== command) {
                continue;
            }
            const callback = observer.onKeyDown || observer.onKeyUp;
            if (callback) {
                const exclusive = callback(new KeyboardEvent('command-trigger'), data);
                if (exclusive) {
                    return;
                }
            }
        }
    }
    registerShortcut(shortcut) {
        this.commandMap.set(shortcut.command, shortcut);
    }
    addCommandHandler(observer) {
        this.commandHandlers.add(observer);
        return () => {
            observer.onKeyDown = undefined;
            observer.onKeyDown = undefined;
            this.commandHandlers.delete(observer);
        };
    }
    addCommandHandlers(handlers) {
        const disposers = handlers.map((handler) => this.addCommandHandler(handler));
        return () => {
            for (const disposer of disposers) {
                disposer();
            }
        };
    }
    keyboardShortcutForCommand(command) {
        const shortcut = this.commandMap.get(command);
        if (!shortcut) {
            return undefined;
        }
        return {
            platform: this.platform,
            ...shortcut,
        };
    }
}
exports.KeyboardService = KeyboardService;
