"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Utils_1 = require("@/Utils");
const utils_1 = require("@standardnotes/utils");
const react_1 = require("@ariakit/react");
const react_2 = require("react");
const mergeRefs_1 = require("@/Hooks/mergeRefs");
const HistoryModalDialog = (0, react_2.forwardRef)(({ children, onDismiss }, ref) => {
    const dialog = (0, react_1.useDialogStore)({
        open: true,
    });
    const addCloseMethod = (0, react_2.useCallback)((element) => {
        if (element) {
            ;
            element.close = onDismiss;
        }
    }, [onDismiss]);
    return ((0, jsx_runtime_1.jsxs)(react_1.Dialog, { store: dialog, "aria-label": "Note revision history", ref: (0, mergeRefs_1.mergeRefs)([addCloseMethod, ref]), className: "fixed left-0 top-0 z-modal h-full w-full", children: [(0, jsx_runtime_1.jsx)("div", { className: "absolute z-0 h-full w-full bg-passive-5 opacity-0 md:opacity-75", role: "presentation", onClick: onDismiss }), (0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('absolute z-[1] my-0 flex h-full w-full flex-col rounded-md bg-[--modal-background-color]', 'p-0 pb-safe-bottom pt-safe-top shadow-lg md:max-h-[90%] md:w-[90%] md:max-w-[90%]', 'md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:transform', (0, Utils_1.getPlatformString)()), children: children })] }));
});
exports.default = HistoryModalDialog;
