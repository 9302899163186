"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileDecryptor = void 0;
const sncrypto_common_1 = require("@standardnotes/sncrypto-common");
class FileDecryptor {
    constructor(file, crypto) {
        this.file = file;
        this.crypto = crypto;
        this.decryptor = this.crypto.xchacha20StreamInitDecryptor(this.file.encryptionHeader, this.file.key);
    }
    decryptBytes(encryptedBytes) {
        const result = this.crypto.xchacha20StreamDecryptorPush(this.decryptor, encryptedBytes, this.file.remoteIdentifier);
        if (result === false) {
            return undefined;
        }
        const isFinal = result.tag === sncrypto_common_1.SodiumTag.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL;
        return { decryptedBytes: result.message, isFinalChunk: isFinal };
    }
}
exports.FileDecryptor = FileDecryptor;
