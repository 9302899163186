"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPanesChangePush = exports.isPanesChangeLeafDismiss = void 0;
function isPanesChangeLeafDismiss(from, to) {
    const fromWithoutLast = from.slice(0, from.length - 1);
    return fromWithoutLast.length === to.length && fromWithoutLast.every((pane, index) => pane === to[index]);
}
exports.isPanesChangeLeafDismiss = isPanesChangeLeafDismiss;
function isPanesChangePush(from, to) {
    const toWithoutLast = to.slice(0, to.length - 1);
    return toWithoutLast.length === from.length && toWithoutLast.every((pane, index) => pane === from[index]);
}
exports.isPanesChangePush = isPanesChangePush;
