"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const useTabState_1 = require("./useTabState");
const TabList = ({ state, children, ...props }) => {
    const providerValue = (0, react_1.useMemo)(() => ({
        state,
    }), [state]);
    return ((0, jsx_runtime_1.jsx)(useTabState_1.TabStateContext.Provider, { value: providerValue, children: (0, jsx_runtime_1.jsx)("div", { role: "tablist", ...props, children: children }) }));
};
exports.default = TabList;
