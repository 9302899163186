"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INSERT_REMOTE_IMAGE_COMMAND = exports.INSERT_PASSWORD_COMMAND = exports.INSERT_DATETIME_COMMAND = exports.INSERT_DATE_COMMAND = exports.INSERT_TIME_COMMAND = exports.INSERT_BUBBLE_COMMAND = exports.INSERT_FILE_COMMAND = void 0;
const lexical_1 = require("lexical");
exports.INSERT_FILE_COMMAND = (0, lexical_1.createCommand)('INSERT_FILE_COMMAND');
exports.INSERT_BUBBLE_COMMAND = (0, lexical_1.createCommand)('INSERT_BUBBLE_COMMAND');
exports.INSERT_TIME_COMMAND = (0, lexical_1.createCommand)('INSERT_TIME_COMMAND');
exports.INSERT_DATE_COMMAND = (0, lexical_1.createCommand)('INSERT_DATE_COMMAND');
exports.INSERT_DATETIME_COMMAND = (0, lexical_1.createCommand)('INSERT_DATETIME_COMMAND');
exports.INSERT_PASSWORD_COMMAND = (0, lexical_1.createCommand)('INSERT_PASSWORD_COMMAND');
exports.INSERT_REMOTE_IMAGE_COMMAND = (0, lexical_1.createCommand)('INSERT_REMOTE_IMAGE_COMMAND');
