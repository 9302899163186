"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptErroredTypeAPayloads = void 0;
const models_1 = require("@standardnotes/models");
const encryption_1 = require("@standardnotes/encryption");
class DecryptErroredTypeAPayloads {
    constructor(payloads, _decryptTypeAPayloadWithKeyLookup) {
        this.payloads = payloads;
        this._decryptTypeAPayloadWithKeyLookup = _decryptTypeAPayloadWithKeyLookup;
    }
    async execute() {
        const erroredRootPayloads = this.payloads.invalidPayloads.filter((i) => (0, models_1.ContentTypeUsesRootKeyEncryption)(i.content_type) || (0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(i.content_type));
        if (erroredRootPayloads.length === 0) {
            return;
        }
        const resultParams = await this._decryptTypeAPayloadWithKeyLookup.executeMany(erroredRootPayloads);
        const decryptedPayloads = resultParams.map((params) => {
            const original = (0, models_1.SureFindPayload)(erroredRootPayloads, params.uuid);
            if ((0, encryption_1.isErrorDecryptingParameters)(params)) {
                return new models_1.EncryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
            else {
                return new models_1.DecryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
        });
        await this.payloads.emitPayloads(decryptedPayloads, models_1.PayloadEmitSource.LocalChanged);
    }
}
exports.DecryptErroredTypeAPayloads = DecryptErroredTypeAPayloads;
