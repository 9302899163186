"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForwardedRef = void 0;
const react_1 = require("react");
const useForwardedRef = (ref, initialValue = null) => {
    const targetRef = (0, react_1.useRef)(initialValue);
    (0, react_1.useEffect)(() => {
        if (!ref) {
            return;
        }
        if (typeof ref === 'function') {
            ref(targetRef.current);
        }
        else {
            ref.current = targetRef.current;
        }
    }, [ref]);
    return targetRef;
};
exports.useForwardedRef = useForwardedRef;
