"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HashStringUseCase = void 0;
class HashStringUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(string, hashingKey) {
        return this.crypto.sodiumCryptoGenericHash(string, hashingKey.key);
    }
}
exports.HashStringUseCase = HashStringUseCase;
