"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticItemCounter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class StaticItemCounter {
    countNotesAndTags(items) {
        const counts = {
            notes: 0,
            archived: 0,
            deleted: 0,
            tags: 0,
        };
        for (const item of items) {
            if (item.trashed) {
                counts.deleted++;
                continue;
            }
            if (item.archived) {
                counts.archived++;
                continue;
            }
            if (item.content_type === domain_core_1.ContentType.TYPES.Note && !item.conflictOf) {
                counts.notes++;
                continue;
            }
            if (item.content_type === domain_core_1.ContentType.TYPES.Tag) {
                counts.tags++;
                continue;
            }
        }
        return counts;
    }
}
exports.StaticItemCounter = StaticItemCounter;
