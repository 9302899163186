"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.keyboardStringForShortcut = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const keyboardCharacterForModifier_1 = require("./keyboardCharacterForModifier");
function stringForCode(code = '') {
    return code.replace('Key', '').replace('Digit', '');
}
function keyboardStringForShortcut(shortcut) {
    var _a;
    if (!shortcut) {
        return '';
    }
    const key = ((_a = shortcut.key) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || stringForCode(shortcut.code);
    if (!shortcut.modifiers || shortcut.modifiers.length === 0) {
        return key;
    }
    const modifierCharacters = shortcut.modifiers.map((modifier) => (0, keyboardCharacterForModifier_1.keyboardCharacterForModifier)(modifier, shortcut.platform));
    if ((0, ui_services_1.isMacPlatform)(shortcut.platform)) {
        return `${modifierCharacters.join('')}${key}`;
    }
    else {
        return `${modifierCharacters.join('+')}+${key}`;
    }
}
exports.keyboardStringForShortcut = keyboardStringForShortcut;
