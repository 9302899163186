"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteVault = void 0;
class DeleteVault {
    constructor(mutator, keys, _getVaultItems) {
        this.mutator = mutator;
        this.keys = keys;
        this._getVaultItems = _getVaultItems;
    }
    async execute(vault) {
        if (!vault.systemIdentifier) {
            throw new Error('Vault system identifier is missing');
        }
        await this.keys.deleteNonPersistentSystemRootKeysForVault(vault.systemIdentifier);
        const rootKeys = this.keys.getSyncedKeySystemRootKeysForVault(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(rootKeys);
        const itemsKeys = this.keys.getKeySystemItemsKeys(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(itemsKeys);
        const vaultItems = this._getVaultItems.execute(vault).getValue();
        await this.mutator.setItemsToBeDeleted(vaultItems);
        await this.mutator.setItemToBeDeleted(vault);
    }
}
exports.DeleteVault = DeleteVault;
