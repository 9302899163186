"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crypto_generichash_BYTES = exports.to_string = exports.to_hex = exports.to_base64 = exports.ready = exports.from_string = exports.from_hex = exports.from_base64 = exports.crypto_sign_verify_detached = exports.crypto_sign_seed_keypair = exports.crypto_sign_keypair = exports.crypto_sign_detached = exports.crypto_secretstream_xchacha20poly1305_push = exports.crypto_secretstream_xchacha20poly1305_pull = exports.crypto_secretstream_xchacha20poly1305_init_push = exports.crypto_secretstream_xchacha20poly1305_init_pull = exports.crypto_pwhash = exports.crypto_pwhash_ALG_DEFAULT = exports.crypto_kdf_derive_from_key = exports.crypto_generichash = exports.crypto_box_seed_keypair = exports.crypto_box_open_easy = exports.crypto_box_keypair = exports.crypto_box_easy = exports.crypto_aead_xchacha20poly1305_ietf_encrypt = exports.crypto_aead_xchacha20poly1305_ietf_decrypt = exports.base64_variants = void 0;
/* eslint-disable camelcase */
var libsodium_wrappers_1 = require("libsodium-wrappers");
Object.defineProperty(exports, "base64_variants", { enumerable: true, get: function () { return libsodium_wrappers_1.base64_variants; } });
Object.defineProperty(exports, "crypto_aead_xchacha20poly1305_ietf_decrypt", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_aead_xchacha20poly1305_ietf_decrypt; } });
Object.defineProperty(exports, "crypto_aead_xchacha20poly1305_ietf_encrypt", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_aead_xchacha20poly1305_ietf_encrypt; } });
Object.defineProperty(exports, "crypto_box_easy", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_box_easy; } });
Object.defineProperty(exports, "crypto_box_keypair", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_box_keypair; } });
Object.defineProperty(exports, "crypto_box_open_easy", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_box_open_easy; } });
Object.defineProperty(exports, "crypto_box_seed_keypair", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_box_seed_keypair; } });
Object.defineProperty(exports, "crypto_generichash", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_generichash; } });
Object.defineProperty(exports, "crypto_kdf_derive_from_key", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_kdf_derive_from_key; } });
Object.defineProperty(exports, "crypto_pwhash_ALG_DEFAULT", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_pwhash_ALG_DEFAULT; } });
Object.defineProperty(exports, "crypto_pwhash", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_pwhash; } });
Object.defineProperty(exports, "crypto_secretstream_xchacha20poly1305_init_pull", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_secretstream_xchacha20poly1305_init_pull; } });
Object.defineProperty(exports, "crypto_secretstream_xchacha20poly1305_init_push", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_secretstream_xchacha20poly1305_init_push; } });
Object.defineProperty(exports, "crypto_secretstream_xchacha20poly1305_pull", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_secretstream_xchacha20poly1305_pull; } });
Object.defineProperty(exports, "crypto_secretstream_xchacha20poly1305_push", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_secretstream_xchacha20poly1305_push; } });
Object.defineProperty(exports, "crypto_sign_detached", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_sign_detached; } });
Object.defineProperty(exports, "crypto_sign_keypair", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_sign_keypair; } });
Object.defineProperty(exports, "crypto_sign_seed_keypair", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_sign_seed_keypair; } });
Object.defineProperty(exports, "crypto_sign_verify_detached", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_sign_verify_detached; } });
Object.defineProperty(exports, "from_base64", { enumerable: true, get: function () { return libsodium_wrappers_1.from_base64; } });
Object.defineProperty(exports, "from_hex", { enumerable: true, get: function () { return libsodium_wrappers_1.from_hex; } });
Object.defineProperty(exports, "from_string", { enumerable: true, get: function () { return libsodium_wrappers_1.from_string; } });
Object.defineProperty(exports, "ready", { enumerable: true, get: function () { return libsodium_wrappers_1.ready; } });
Object.defineProperty(exports, "to_base64", { enumerable: true, get: function () { return libsodium_wrappers_1.to_base64; } });
Object.defineProperty(exports, "to_hex", { enumerable: true, get: function () { return libsodium_wrappers_1.to_hex; } });
Object.defineProperty(exports, "to_string", { enumerable: true, get: function () { return libsodium_wrappers_1.to_string; } });
Object.defineProperty(exports, "crypto_generichash_BYTES", { enumerable: true, get: function () { return libsodium_wrappers_1.crypto_generichash_BYTES; } });
