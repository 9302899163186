"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastService = void 0;
const toast_1 = require("@standardnotes/toast");
class ToastService {
    hideToast(toastId) {
        (0, toast_1.dismissToast)(toastId);
    }
    showToast(type, message) {
        return (0, toast_1.addToast)({
            type: type,
            message,
        });
    }
}
exports.ToastService = ToastService;
