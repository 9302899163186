"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator001 = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const ItemsKey_1 = require("../../Keys/ItemsKey/ItemsKey");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const domain_core_1 = require("@standardnotes/domain-core");
const NO_IV = '00000000000000000000000000000000';
/**
 * @deprecated
 * A legacy operator no longer used to generate new accounts
 */
class SNProtocolOperator001 {
    constructor(crypto) {
        this.crypto = crypto;
    }
    getEncryptionDisplayName() {
        return 'AES-256';
    }
    get version() {
        return common_1.ProtocolVersion.V001;
    }
    generateNewItemsKeyContent() {
        const keyLength = Algorithm_1.V001Algorithm.EncryptionKeyLength;
        const itemsKey = this.crypto.generateRandomKey(keyLength);
        const response = (0, models_1.FillItemContent)({
            itemsKey: itemsKey,
            version: common_1.ProtocolVersion.V001,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new models_1.DecryptedPayload({
            uuid: utils_1.UuidGenerator.GenerateUuid(),
            content_type: domain_core_1.ContentType.TYPES.ItemsKey,
            content: this.generateNewItemsKeyContent(),
            ...(0, models_1.PayloadTimestampDefaults)(),
        });
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
    async createRootKey(identifier, password, origination) {
        const pwCost = Algorithm_1.V001Algorithm.PbkdfMinCost;
        const pwNonce = this.crypto.generateRandomKey(Algorithm_1.V001Algorithm.SaltSeedLength);
        const pwSalt = await this.crypto.unsafeSha1(identifier + 'SN' + pwNonce);
        const keyParams = (0, KeyParamsFunctions_1.Create001KeyParams)({
            email: identifier,
            pw_cost: pwCost,
            pw_nonce: pwNonce,
            pw_salt: pwSalt,
            version: common_1.ProtocolVersion.V001,
            origination,
            created: `${Date.now()}`,
        });
        return this.deriveKey(password, keyParams);
    }
    getPayloadAuthenticatedDataForExternalUse(_encrypted) {
        return undefined;
    }
    async computeRootKey(password, keyParams) {
        return this.deriveKey(password, keyParams);
    }
    async decryptString(ciphertext, key) {
        return this.crypto.aes256CbcDecrypt(ciphertext, NO_IV, key);
    }
    async encryptString(text, key) {
        return this.crypto.aes256CbcEncrypt(text, NO_IV, key);
    }
    async generateEncryptedParametersAsync(payload, key) {
        /**
         * Generate new item key that is double the key size.
         * Will be split to create encryption key and authentication key.
         */
        const itemKey = this.crypto.generateRandomKey(Algorithm_1.V001Algorithm.EncryptionKeyLength * 2);
        const encItemKey = await this.encryptString(itemKey, key.itemsKey);
        /** Encrypt content */
        const ek = (0, utils_1.firstHalfOfString)(itemKey);
        const ak = (0, utils_1.secondHalfOfString)(itemKey);
        const contentCiphertext = await this.encryptString(JSON.stringify(payload.content), ek);
        const ciphertext = key.keyVersion + contentCiphertext;
        const authHash = await this.crypto.hmac256(ciphertext, ak);
        if (!authHash) {
            throw Error('Error generating hmac256 authHash');
        }
        return {
            uuid: payload.uuid,
            content_type: payload.content_type,
            items_key_id: (0, ItemsKey_1.isItemsKey)(key) ? key.uuid : undefined,
            content: ciphertext,
            enc_item_key: encItemKey,
            auth_hash: authHash,
            version: this.version,
            key_system_identifier: payload.key_system_identifier,
            shared_vault_uuid: payload.shared_vault_uuid,
        };
    }
    async generateDecryptedParametersAsync(encrypted, key) {
        if (!encrypted.enc_item_key) {
            console.error(Error('Missing item encryption key, skipping decryption.'));
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        let encryptedItemKey = encrypted.enc_item_key;
        encryptedItemKey = this.version + encryptedItemKey;
        const itemKeyComponents = this.encryptionComponentsFromString(encryptedItemKey, key.itemsKey);
        const itemKey = await this.decryptString(itemKeyComponents.ciphertext, itemKeyComponents.key);
        if (!itemKey) {
            console.error('Error decrypting parameters', encrypted);
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const ek = (0, utils_1.firstHalfOfString)(itemKey);
        const itemParams = this.encryptionComponentsFromString(encrypted.content, ek);
        const content = await this.decryptString(itemParams.ciphertext, itemParams.key);
        if (!content) {
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        else {
            return {
                uuid: encrypted.uuid,
                content: JSON.parse(content),
                signatureData: { required: false, contentHash: '' },
            };
        }
    }
    encryptionComponentsFromString(string, encryptionKey) {
        const encryptionVersion = string.substring(0, common_1.ProtocolVersionLength);
        return {
            ciphertext: string.substring(common_1.ProtocolVersionLength, string.length),
            version: encryptionVersion,
            key: encryptionKey,
        };
    }
    async deriveKey(password, keyParams) {
        const derivedKey = await this.crypto.pbkdf2(password, keyParams.content001.pw_salt, keyParams.content001.pw_cost, Algorithm_1.V001Algorithm.PbkdfOutputLength);
        if (!derivedKey) {
            throw Error('Error deriving PBKDF2 key');
        }
        const partitions = (0, utils_1.splitString)(derivedKey, 2);
        return (0, Functions_1.CreateNewRootKey)({
            serverPassword: partitions[0],
            masterKey: partitions[1],
            version: common_1.ProtocolVersion.V001,
            keyParams: keyParams.getPortableValue(),
        });
    }
    createRandomizedKeySystemRootKey(_dto) {
        throw new Error('Method not implemented.');
    }
    createUserInputtedKeySystemRootKey(_dto) {
        throw new Error('Method not implemented.');
    }
    deriveUserInputtedKeySystemRootKey(_dto) {
        throw new Error('Method not implemented.');
    }
    createKeySystemItemsKey(_uuid, _keySystemIdentifier, _sharedVaultUuid) {
        throw new Error('Method not implemented.');
    }
    versionForAsymmetricallyEncryptedString(_encryptedString) {
        throw new Error('Method not implemented.');
    }
    asymmetricEncrypt(_dto) {
        throw new Error('Method not implemented.');
    }
    asymmetricDecrypt(_dto) {
        throw new Error('Method not implemented.');
    }
    asymmetricDecryptOwnMessage(_dto) {
        throw new Error('Method not implemented.');
    }
    asymmetricSignatureVerifyDetached(_encryptedString) {
        throw new Error('Method not implemented.');
    }
    asymmetricStringGetAdditionalData(_dto) {
        throw new Error('Method not implemented.');
    }
    getSenderPublicKeySetFromAsymmetricallyEncryptedString(_string) {
        throw new Error('Method not implemented.');
    }
}
exports.SNProtocolOperator001 = SNProtocolOperator001;
