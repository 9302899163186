"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportData = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
const Strings_1 = require("./Strings");
class ImportData {
    constructor(items, sync, protections, encryption, payloads, history, _decryptBackFile, _getFilePassword) {
        this.items = items;
        this.sync = sync;
        this.protections = protections;
        this.encryption = encryption;
        this.payloads = payloads;
        this.history = history;
        this._decryptBackFile = _decryptBackFile;
        this._getFilePassword = _getFilePassword;
    }
    async execute(data, awaitSync = false) {
        const versionValidation = this.validateFileVersion(data);
        if (versionValidation.isFailed()) {
            return domain_core_1.Result.fail(versionValidation.getError());
        }
        const decryptedPayloadsOrError = await this.decryptData(data);
        if (decryptedPayloadsOrError.isFailed()) {
            return domain_core_1.Result.fail(decryptedPayloadsOrError.getError());
        }
        const valid = this.getValidPayloadsToImportFromDecryptedResult(decryptedPayloadsOrError.getValue());
        if (!(await this.protections.authorizeFileImport())) {
            return domain_core_1.Result.fail('Import aborted');
        }
        const affectedUuids = await this.payloads.importPayloads(valid, this.history.getHistoryMapCopy());
        const promise = this.sync.sync();
        if (awaitSync) {
            await promise;
        }
        const affectedItems = this.items.findItems(affectedUuids);
        return domain_core_1.Result.ok({
            affectedItems: affectedItems,
            errorCount: decryptedPayloadsOrError.getValue().length - valid.length,
        });
    }
    validateFileVersion(data) {
        if (data.version) {
            const result = this.validateVersion(data.version);
            if (result.isFailed()) {
                return domain_core_1.Result.fail(result.getError());
            }
        }
        return domain_core_1.Result.ok();
    }
    async decryptData(data) {
        let password;
        if (data.auth_params || data.keyParams) {
            const passwordResult = await this._getFilePassword.execute();
            if (passwordResult.isFailed()) {
                return domain_core_1.Result.fail(passwordResult.getError());
            }
            password = passwordResult.getValue();
        }
        this.cleanImportData(data);
        const decryptedPayloadsOrError = await this._decryptBackFile.execute(data, password);
        if (decryptedPayloadsOrError.isFailed()) {
            return domain_core_1.Result.fail(decryptedPayloadsOrError.getError());
        }
        return domain_core_1.Result.ok(decryptedPayloadsOrError.getValue());
    }
    getValidPayloadsToImportFromDecryptedResult(results) {
        const decrypted = results.filter(models_1.isDecryptedPayload);
        const encrypted = results.filter(models_1.isEncryptedPayload);
        const vaulted = encrypted.filter((payload) => {
            return payload.key_system_identifier !== undefined;
        });
        const valid = [...decrypted, ...vaulted];
        return valid;
    }
    cleanImportData(data) {
        data.items = data.items.map((item) => {
            if ((0, models_1.isEncryptedTransferPayload)(item)) {
                return (0, models_1.CreateEncryptedBackupFileContextPayload)(item);
            }
            else {
                return (0, models_1.CreateDecryptedBackupFileContextPayload)(item);
            }
        });
    }
    /**
     * Prior to 003 backup files did not have a version field so we cannot
     * stop importing if there is no backup file version, only if there is
     * an unsupported version.
     */
    validateVersion(version) {
        const supportedVersions = this.encryption.supportedVersions();
        if (!supportedVersions.includes(version)) {
            return domain_core_1.Result.fail(Strings_1.Strings.UnsupportedBackupFileVersion);
        }
        const userVersion = this.encryption.getUserVersion();
        if (userVersion && (0, common_1.compareVersions)(version, userVersion) === 1) {
            /** File was made with a greater version than the user's account */
            return domain_core_1.Result.fail(Strings_1.Strings.BackupFileMoreRecentThanAccount);
        }
        return domain_core_1.Result.ok();
    }
}
exports.ImportData = ImportData;
