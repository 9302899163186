"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deconstructEncryptedPayloadString = exports.doesPayloadRequireSigning = void 0;
const V004AlgorithmTypes_1 = require("./V004AlgorithmTypes");
function doesPayloadRequireSigning(payload) {
    return payload.shared_vault_uuid != undefined;
}
exports.doesPayloadRequireSigning = doesPayloadRequireSigning;
function deconstructEncryptedPayloadString(payloadString) {
    var _a;
    /** Base64 encoding of JSON.stringify({}) */
    const EmptyAdditionalDataString = 'e30=';
    const components = payloadString.split(V004AlgorithmTypes_1.V004PartitionCharacter);
    return {
        version: components[0],
        nonce: components[1],
        ciphertext: components[2],
        authenticatedData: components[3],
        additionalData: (_a = components[4]) !== null && _a !== void 0 ? _a : EmptyAdditionalDataString,
    };
}
exports.deconstructEncryptedPayloadString = deconstructEncryptedPayloadString;
