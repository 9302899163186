"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeObserverPlugin = void 0;
const react_1 = require("react");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const lexical_1 = require("lexical");
function NodeObserverPlugin({ nodeType, onRemove }) {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const map = (0, react_1.useRef)(new Map());
    (0, react_1.useEffect)(() => {
        const removeMutationListener = editor.registerMutationListener(nodeType, (mutatedNodes) => {
            editor.getEditorState().read(() => {
                for (const [nodeKey, mutation] of mutatedNodes) {
                    if (mutation === 'updated' || mutation === 'created') {
                        const node = (0, lexical_1.$getNodeByKey)(nodeKey);
                        if (node) {
                            const uuid = node.getId();
                            map.current.set(nodeKey, uuid);
                        }
                    }
                    else if (mutation === 'destroyed') {
                        const uuid = map.current.get(nodeKey);
                        if (uuid) {
                            onRemove(uuid);
                        }
                    }
                }
            });
        });
        return () => {
            removeMutationListener();
        };
    });
    return null;
}
exports.NodeObserverPlugin = NodeObserverPlugin;
