"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptVaultInvite = void 0;
class AcceptVaultInvite {
    constructor(inviteServer, processInvite) {
        this.inviteServer = inviteServer;
        this.processInvite = processInvite;
    }
    async execute(dto) {
        await this.processInvite.execute(dto.message, dto.invite.shared_vault_uuid, dto.invite.sender_uuid);
        await this.inviteServer.acceptInvite({
            sharedVaultUuid: dto.invite.shared_vault_uuid,
            inviteUuid: dto.invite.uuid,
        });
    }
}
exports.AcceptVaultInvite = AcceptVaultInvite;
