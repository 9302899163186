"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const mobx_react_lite_1 = require("mobx-react-lite");
const TwoFactorTitle = ({ auth }) => {
    if (!auth.isLoggedIn()) {
        return (0, jsx_runtime_1.jsx)(Content_1.Title, { children: "Two-factor authentication not available" });
    }
    return (0, jsx_runtime_1.jsx)(Content_1.Title, { children: "Two-factor authentication" });
};
exports.default = (0, mobx_react_lite_1.observer)(TwoFactorTitle);
