"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateEncryptedParametersUseCase = void 0;
const models_1 = require("@standardnotes/models");
const CreateConsistentBase64JsonPayload_1 = require("../Utils/CreateConsistentBase64JsonPayload");
const V004AlgorithmHelpers_1 = require("../../V004AlgorithmHelpers");
const GenerateAuthenticatedData_1 = require("./GenerateAuthenticatedData");
const GenerateEncryptedProtocolString_1 = require("./GenerateEncryptedProtocolString");
const GenerateSymmetricAdditionalData_1 = require("./GenerateSymmetricAdditionalData");
const ItemsKey_1 = require("../../../../Keys/ItemsKey/ItemsKey");
const KeySystemItemsKey_1 = require("../../../../Keys/KeySystemItemsKey/KeySystemItemsKey");
const Algorithm_1 = require("../../../../Algorithm");
const DeriveHashingKey_1 = require("../Hash/DeriveHashingKey");
class GenerateEncryptedParametersUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.generateProtocolStringUseCase = new GenerateEncryptedProtocolString_1.GenerateEncryptedProtocolStringUseCase(this.crypto);
        this.generateAuthenticatedDataUseCase = new GenerateAuthenticatedData_1.GenerateAuthenticatedDataUseCase();
        this.generateAdditionalDataUseCase = new GenerateSymmetricAdditionalData_1.GenerateSymmetricAdditionalDataUseCase(this.crypto);
        this.encodeBase64DataUsecase = new CreateConsistentBase64JsonPayload_1.CreateConsistentBase64JsonPayloadUseCase(this.crypto);
        this.deriveHashingKeyUseCase = new DeriveHashingKey_1.DeriveHashingKeyUseCase(this.crypto);
    }
    execute(payload, key, signingKeyPair) {
        if ((0, V004AlgorithmHelpers_1.doesPayloadRequireSigning)(payload) && !signingKeyPair) {
            throw Error('Payload requires signing but no signing key pair was provided.');
        }
        const commonAuthenticatedData = this.generateAuthenticatedDataUseCase.execute(payload, key);
        const hashingKey = this.deriveHashingKeyUseCase.execute(key);
        const { contentKey, encryptedContentKey } = this.generateEncryptedContentKey(key, hashingKey, commonAuthenticatedData, signingKeyPair);
        const { encryptedContent } = this.generateEncryptedContent(payload, hashingKey, contentKey, commonAuthenticatedData, signingKeyPair);
        return {
            uuid: payload.uuid,
            content_type: payload.content_type,
            items_key_id: (0, ItemsKey_1.isItemsKey)(key) || (0, KeySystemItemsKey_1.isKeySystemItemsKey)(key) ? key.uuid : undefined,
            content: encryptedContent,
            enc_item_key: encryptedContentKey,
            version: models_1.ProtocolVersion.V004,
            key_system_identifier: payload.key_system_identifier,
            shared_vault_uuid: payload.shared_vault_uuid,
        };
    }
    generateEncryptedContent(payload, hashingKey, contentKey, commonAuthenticatedData, signingKeyPair) {
        const content = JSON.stringify(payload.content);
        const { additionalData } = this.generateAdditionalDataUseCase.execute(content, hashingKey, signingKeyPair);
        const encryptedContent = this.generateProtocolStringUseCase.execute(content, contentKey, this.encodeBase64DataUsecase.execute(commonAuthenticatedData), this.encodeBase64DataUsecase.execute(additionalData));
        return {
            encryptedContent,
        };
    }
    generateEncryptedContentKey(key, hashingKey, commonAuthenticatedData, signingKeyPair) {
        const contentKey = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionKeyLength);
        const { additionalData } = this.generateAdditionalDataUseCase.execute(contentKey, hashingKey, signingKeyPair);
        const encryptedContentKey = this.generateProtocolStringUseCase.execute(contentKey, key.itemsKey, this.encodeBase64DataUsecase.execute(commonAuthenticatedData), this.encodeBase64DataUsecase.execute(additionalData));
        return {
            contentKey,
            encryptedContentKey,
        };
    }
}
exports.GenerateEncryptedParametersUseCase = GenerateEncryptedParametersUseCase;
