"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteContact = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class DeleteContact {
    constructor(mutator, sync, getOwnedVaults, contactBelongsToVault) {
        this.mutator = mutator;
        this.sync = sync;
        this.getOwnedVaults = getOwnedVaults;
        this.contactBelongsToVault = contactBelongsToVault;
    }
    async execute(dto) {
        if (dto.contact.isMe) {
            throw new Error('Cannot delete self');
        }
        const vaults = this.getOwnedVaults.execute();
        if (vaults.isFailed()) {
            return domain_core_1.Result.fail('Failed to get owned vaults');
        }
        for (const vault of vaults.getValue()) {
            const contactBelongsToVault = await this.contactBelongsToVault.execute({
                contact: dto.contact,
                vault: vault,
            });
            if (contactBelongsToVault.isFailed()) {
                return domain_core_1.Result.fail('Failed to check contact membership');
            }
            if (contactBelongsToVault.getValue()) {
                return domain_core_1.Result.fail('Cannot delete contact that belongs to an owned vault');
            }
        }
        await this.mutator.setItemToBeDeleted(dto.contact);
        await this.sync.sync();
        return domain_core_1.Result.ok();
    }
}
exports.DeleteContact = DeleteContact;
