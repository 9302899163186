"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOrEditContact = void 0;
const models_1 = require("@standardnotes/models");
const UnknownContactName_1 = require("../UnknownContactName");
const domain_core_1 = require("@standardnotes/domain-core");
class CreateOrEditContact {
    constructor(mutator, findContact, editContact) {
        this.mutator = mutator;
        this.findContact = findContact;
        this.editContact = editContact;
    }
    async execute(params) {
        var _a, _b, _c;
        const existingContact = this.findContact.execute({ userUuid: params.contactUuid });
        if (!existingContact.isFailed()) {
            await this.editContact.execute(existingContact.getValue(), {
                ...params,
                name: (_a = params.name) !== null && _a !== void 0 ? _a : existingContact.getValue().name,
            });
            return existingContact.getValue();
        }
        const content = {
            name: (_b = params.name) !== null && _b !== void 0 ? _b : UnknownContactName_1.UnknownContactName,
            publicKeySet: models_1.ContactPublicKeySet.FromJson({
                encryption: params.publicKey,
                signing: params.signingPublicKey,
                timestamp: new Date(),
            }),
            contactUuid: params.contactUuid,
            isMe: (_c = params.isMe) !== null && _c !== void 0 ? _c : false,
        };
        const contact = await this.mutator.createItem(domain_core_1.ContentType.TYPES.TrustedContact, (0, models_1.FillItemContent)(content), true);
        return contact;
    }
}
exports.CreateOrEditContact = CreateOrEditContact;
