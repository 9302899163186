"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebDependencies = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const utils_1 = require("@standardnotes/utils");
const Types_1 = require("./Types");
const snjs_1 = require("@standardnotes/snjs");
const DesktopManager_1 = require("../Device/DesktopManager");
const MomentsService_1 = require("@/Controllers/Moments/MomentsService");
const PersistenceService_1 = require("@/Controllers/Abstract/PersistenceService");
const FilePreviewModalController_1 = require("@/Controllers/FilePreviewModalController");
const PaneController_1 = require("@/Controllers/PaneController/PaneController");
const PreferencesController_1 = require("@/Controllers/PreferencesController");
const FeaturesController_1 = require("@/Controllers/FeaturesController");
const NavigationController_1 = require("@/Controllers/Navigation/NavigationController");
const NotesController_1 = require("@/Controllers/NotesController/NotesController");
const ItemListController_1 = require("@/Controllers/ItemList/ItemListController");
const NoAccountWarningController_1 = require("@/Controllers/NoAccountWarningController");
const AccountMenuController_1 = require("@/Controllers/AccountMenu/AccountMenuController");
const SubscriptionController_1 = require("@/Controllers/Subscription/SubscriptionController");
const PurchaseFlowController_1 = require("@/Controllers/PurchaseFlow/PurchaseFlowController");
const FilesController_1 = require("@/Controllers/FilesController");
const HistoryModalController_1 = require("@/Controllers/NoteHistory/HistoryModalController");
const ImportModalController_1 = require("@/Controllers/ImportModalController");
const ApplicationEventObserver_1 = require("@/Event/ApplicationEventObserver");
const SearchOptionsController_1 = require("@/Controllers/SearchOptionsController");
const LinkingController_1 = require("@/Controllers/LinkingController");
const SyncStatusController_1 = require("@/Controllers/SyncStatusController");
const ActionsMenuController_1 = require("@/Controllers/ActionsMenuController");
const ItemGroupController_1 = require("@/Components/NoteView/Controller/ItemGroupController");
const MobileWebReceiver_1 = require("@/NativeMobileWeb/MobileWebReceiver");
const AndroidBackHandler_1 = require("@/NativeMobileWeb/AndroidBackHandler");
const IsTabletOrMobileScreen_1 = require("../UseCase/IsTabletOrMobileScreen");
const PanesForLayout_1 = require("../UseCase/PanesForLayout");
const LoadPurchaseFlowUrl_1 = require("../UseCase/LoadPurchaseFlowUrl");
const GetPurchaseFlowUrl_1 = require("../UseCase/GetPurchaseFlowUrl");
const OpenSubscriptionDashboard_1 = require("../UseCase/OpenSubscriptionDashboard");
class WebDependencies extends utils_1.DependencyContainer {
    constructor(application) {
        super();
        this.application = application;
        this.bind(Types_1.Web_TYPES.Importer, () => {
            return new ui_services_1.Importer(application.features, application.mutator, application.items, application.generateUuid);
        });
        this.bind(Types_1.Web_TYPES.IsNativeIOS, () => {
            return new ui_services_1.IsNativeIOS(application.environment, application.platform);
        });
        this.bind(Types_1.Web_TYPES.OpenSubscriptionDashboard, () => {
            return new OpenSubscriptionDashboard_1.OpenSubscriptionDashboard(application, application.legacyApi);
        });
        this.bind(Types_1.Web_TYPES.IsNativeMobileWeb, () => {
            return new ui_services_1.IsNativeMobileWeb(application.environment);
        });
        this.bind(Types_1.Web_TYPES.IsGlobalSpellcheckEnabled, () => {
            return new ui_services_1.IsGlobalSpellcheckEnabled(application.preferences);
        });
        this.bind(Types_1.Web_TYPES.MobileWebReceiver, () => {
            if (!application.isNativeMobileWeb()) {
                return undefined;
            }
            return new MobileWebReceiver_1.MobileWebReceiver(application);
        });
        this.bind(Types_1.Web_TYPES.AndroidBackHandler, () => {
            if (!application.isNativeMobileWeb()) {
                return undefined;
            }
            return new AndroidBackHandler_1.AndroidBackHandler();
        });
        this.bind(Types_1.Web_TYPES.Application, () => this.application);
        this.bind(Types_1.Web_TYPES.ItemGroupController, () => {
            return new ItemGroupController_1.ItemGroupController(application.items, application.mutator, application.sync, application.sessions, application.preferences, application.componentManager, application.alerts, this.get(Types_1.Web_TYPES.IsNativeMobileWeb));
        });
        this.bind(Types_1.Web_TYPES.RouteService, () => {
            return new ui_services_1.RouteService(this.application, this.application.events);
        });
        this.bind(Types_1.Web_TYPES.KeyboardService, () => {
            return new ui_services_1.KeyboardService(application.platform, application.environment);
        });
        this.bind(Types_1.Web_TYPES.ArchiveManager, () => {
            return new ui_services_1.ArchiveManager(this.get(Types_1.Web_TYPES.Application));
        });
        this.bind(Types_1.Web_TYPES.ThemeManager, () => {
            return new ui_services_1.ThemeManager(application, application.preferences, application.componentManager, application.events);
        });
        this.bind(Types_1.Web_TYPES.AutolockService, () => {
            return application.isNativeMobileWeb() ? undefined : new ui_services_1.AutolockService(application, application.events);
        });
        this.bind(Types_1.Web_TYPES.DesktopManager, () => {
            return (0, snjs_1.isDesktopDevice)(application.device)
                ? new DesktopManager_1.DesktopManager(application, application.device, application.fileBackups)
                : undefined;
        });
        this.bind(Types_1.Web_TYPES.ChangelogService, () => {
            return new ui_services_1.ChangelogService(application.environment, application.storage);
        });
        this.bind(Types_1.Web_TYPES.IsMobileDevice, () => {
            return new ui_services_1.IsMobileDevice(this.get(Types_1.Web_TYPES.IsNativeMobileWeb));
        });
        this.bind(Types_1.Web_TYPES.MomentsService, () => {
            return new MomentsService_1.MomentsService(this.get(Types_1.Web_TYPES.FilesController), this.get(Types_1.Web_TYPES.LinkingController), application.storage, application.preferences, application.items, application.protections, application.desktopDevice, this.get(Types_1.Web_TYPES.IsMobileDevice), application.events);
        });
        this.bind(Types_1.Web_TYPES.VaultDisplayService, () => {
            return new ui_services_1.VaultDisplayService(application, application.events);
        });
        this.bind(Types_1.Web_TYPES.PersistenceService, () => {
            return new PersistenceService_1.PersistenceService(this.get(Types_1.Web_TYPES.ItemListController), this.get(Types_1.Web_TYPES.NavigationController), application.storage, application.items, application.sync, application.events);
        });
        this.bind(Types_1.Web_TYPES.FilePreviewModalController, () => {
            return new FilePreviewModalController_1.FilePreviewModalController(application.items);
        });
        this.bind(Types_1.Web_TYPES.PaneController, () => {
            return new PaneController_1.PaneController(application.preferences, this.get(Types_1.Web_TYPES.KeyboardService), this.get(Types_1.Web_TYPES.IsTabletOrMobileScreen), this.get(Types_1.Web_TYPES.PanesForLayout), application.events);
        });
        this.bind(Types_1.Web_TYPES.PanesForLayout, () => {
            return new PanesForLayout_1.PanesForLayout(this.get(Types_1.Web_TYPES.IsTabletOrMobileScreen));
        });
        this.bind(Types_1.Web_TYPES.GetHost, () => {
            return new snjs_1.GetHost(application.legacyApi);
        });
        this.bind(Types_1.Web_TYPES.IsApplicationUsingThirdPartyHost, () => {
            return new snjs_1.IsApplicationUsingThirdPartyHost(this.get(Types_1.Web_TYPES.GetHost));
        });
        this.bind(Types_1.Web_TYPES.IsTabletOrMobileScreen, () => {
            return new IsTabletOrMobileScreen_1.IsTabletOrMobileScreen(application.environment);
        });
        this.bind(Types_1.Web_TYPES.PreferencesController, () => {
            return new PreferencesController_1.PreferencesController(this.get(Types_1.Web_TYPES.RouteService), application.events);
        });
        this.bind(Types_1.Web_TYPES.FeaturesController, () => {
            return new FeaturesController_1.FeaturesController(application.features, application.events);
        });
        this.bind(Types_1.Web_TYPES.NavigationController, () => {
            return new NavigationController_1.NavigationController(this.get(Types_1.Web_TYPES.FeaturesController), this.get(Types_1.Web_TYPES.VaultDisplayService), this.get(Types_1.Web_TYPES.KeyboardService), this.get(Types_1.Web_TYPES.PaneController), application.sync, application.mutator, application.items, application.preferences, application.alerts, application.changeAndSaveItem, application.events);
        });
        this.bind(Types_1.Web_TYPES.NotesController, () => {
            return new NotesController_1.NotesController(this.get(Types_1.Web_TYPES.ItemListController), this.get(Types_1.Web_TYPES.NavigationController), this.get(Types_1.Web_TYPES.ItemGroupController), this.get(Types_1.Web_TYPES.KeyboardService), application.preferences, application.items, application.mutator, application.sync, application.protections, application.alerts, this.get(Types_1.Web_TYPES.IsGlobalSpellcheckEnabled), this.get(Types_1.Web_TYPES.GetItemTags), application.events);
        });
        this.bind(Types_1.Web_TYPES.GetItemTags, () => {
            return new ui_services_1.GetItemTags(application.items);
        });
        this.bind(Types_1.Web_TYPES.SearchOptionsController, () => {
            return new SearchOptionsController_1.SearchOptionsController(application.protections, application.events);
        });
        this.bind(Types_1.Web_TYPES.LinkingController, () => {
            return new LinkingController_1.LinkingController(this.get(Types_1.Web_TYPES.ItemListController), this.get(Types_1.Web_TYPES.FilesController), this.get(Types_1.Web_TYPES.SubscriptionController), this.get(Types_1.Web_TYPES.NavigationController), this.get(Types_1.Web_TYPES.ItemGroupController), this.get(Types_1.Web_TYPES.VaultDisplayService), application.preferences, application.items, application.mutator, application.sync, application.vaults, application.events);
        });
        this.bind(Types_1.Web_TYPES.ItemListController, () => {
            return new ItemListController_1.ItemListController(this.get(Types_1.Web_TYPES.KeyboardService), this.get(Types_1.Web_TYPES.PaneController), this.get(Types_1.Web_TYPES.NavigationController), this.get(Types_1.Web_TYPES.SearchOptionsController), application.items, application.preferences, this.get(Types_1.Web_TYPES.ItemGroupController), this.get(Types_1.Web_TYPES.VaultDisplayService), this.get(Types_1.Web_TYPES.DesktopManager), application.protections, application.options, this.get(Types_1.Web_TYPES.IsNativeMobileWeb), application.changeAndSaveItem, application.events);
        });
        this.bind(Types_1.Web_TYPES.NoAccountWarningController, () => {
            return new NoAccountWarningController_1.NoAccountWarningController(application.sessions, application.events);
        });
        this.bind(Types_1.Web_TYPES.AccountMenuController, () => {
            return new AccountMenuController_1.AccountMenuController(application.items, application.getHost, application.events);
        });
        this.bind(Types_1.Web_TYPES.SubscriptionController, () => {
            return new SubscriptionController_1.SubscriptionController(application.subscriptions, application.sessions, application.features, application.events);
        });
        this.bind(Types_1.Web_TYPES.PurchaseFlowController, () => {
            return new PurchaseFlowController_1.PurchaseFlowController(application.sessions, application.subscriptions, application.legacyApi, application.alerts, application.mobileDevice, this.get(Types_1.Web_TYPES.LoadPurchaseFlowUrl), this.get(Types_1.Web_TYPES.IsNativeIOS), application.events);
        });
        this.bind(Types_1.Web_TYPES.LoadPurchaseFlowUrl, () => {
            return new LoadPurchaseFlowUrl_1.LoadPurchaseFlowUrl(application, this.get(Types_1.Web_TYPES.GetPurchaseFlowUrl));
        });
        this.bind(Types_1.Web_TYPES.GetPurchaseFlowUrl, () => {
            return new GetPurchaseFlowUrl_1.GetPurchaseFlowUrl(application, application.legacyApi, this.get(Types_1.Web_TYPES.IsApplicationUsingThirdPartyHost));
        });
        this.bind(Types_1.Web_TYPES.SyncStatusController, () => {
            return new SyncStatusController_1.SyncStatusController();
        });
        this.bind(Types_1.Web_TYPES.ActionsMenuController, () => {
            return new ActionsMenuController_1.ActionsMenuController();
        });
        this.bind(Types_1.Web_TYPES.FilesController, () => {
            return new FilesController_1.FilesController(this.get(Types_1.Web_TYPES.NotesController), this.get(Types_1.Web_TYPES.FilePreviewModalController), this.get(Types_1.Web_TYPES.ArchiveManager), this.get(Types_1.Web_TYPES.VaultDisplayService), application.items, application.files, application.mutator, application.sync, application.protections, application.alerts, application.platform, application.mobileDevice, this.get(Types_1.Web_TYPES.IsNativeMobileWeb), application.events);
        });
        this.bind(Types_1.Web_TYPES.HistoryModalController, () => {
            return new HistoryModalController_1.HistoryModalController(this.get(Types_1.Web_TYPES.NotesController), this.get(Types_1.Web_TYPES.KeyboardService), application.events);
        });
        this.bind(Types_1.Web_TYPES.ImportModalController, () => {
            return new ImportModalController_1.ImportModalController(this.get(Types_1.Web_TYPES.Importer), this.get(Types_1.Web_TYPES.NavigationController), application.items, application.mutator);
        });
        this.bind(Types_1.Web_TYPES.ToastService, () => {
            return new ui_services_1.ToastService();
        });
        this.bind(Types_1.Web_TYPES.ApplicationEventObserver, () => {
            return new ApplicationEventObserver_1.ApplicationEventObserver(application, application.routeService, this.get(Types_1.Web_TYPES.PurchaseFlowController), this.get(Types_1.Web_TYPES.AccountMenuController), this.get(Types_1.Web_TYPES.PreferencesController), this.get(Types_1.Web_TYPES.SyncStatusController), application.sync, application.sessions, application.subscriptions, this.get(Types_1.Web_TYPES.ToastService), application.user);
        });
    }
}
exports.WebDependencies = WebDependencies;
