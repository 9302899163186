"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteSharedVault = void 0;
const responses_1 = require("@standardnotes/responses");
class DeleteSharedVault {
    constructor(sharedVaultServer, sync, deleteVault) {
        this.sharedVaultServer = sharedVaultServer;
        this.sync = sync;
        this.deleteVault = deleteVault;
    }
    async execute(params) {
        const response = await this.sharedVaultServer.deleteSharedVault({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to delete vault ${JSON.stringify(response)}`);
        }
        await this.deleteVault.execute(params.sharedVault);
        await this.sync.sync();
    }
}
exports.DeleteSharedVault = DeleteSharedVault;
