"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchOptionsController = void 0;
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
class SearchOptionsController extends AbstractViewController_1.AbstractViewController {
    constructor(protections, eventBus) {
        super(eventBus);
        this.protections = protections;
        this.includeProtectedContents = false;
        this.includeArchived = false;
        this.includeTrashed = false;
        this.toggleIncludeArchived = () => {
            this.includeArchived = !this.includeArchived;
        };
        this.toggleIncludeTrashed = () => {
            this.includeTrashed = !this.includeTrashed;
        };
        this.refreshIncludeProtectedContents = () => {
            this.includeProtectedContents = this.protections.hasUnprotectedAccessSession();
        };
        this.toggleIncludeProtectedContents = async () => {
            if (this.includeProtectedContents) {
                this.includeProtectedContents = false;
            }
            else {
                await this.protections.authorizeSearchingProtectedNotesText();
                (0, mobx_1.runInAction)(() => {
                    this.refreshIncludeProtectedContents();
                });
            }
        };
        (0, mobx_1.makeObservable)(this, {
            includeProtectedContents: mobx_1.observable,
            includeTrashed: mobx_1.observable,
            includeArchived: mobx_1.observable,
            toggleIncludeArchived: mobx_1.action,
            toggleIncludeTrashed: mobx_1.action,
            toggleIncludeProtectedContents: mobx_1.action,
            refreshIncludeProtectedContents: mobx_1.action,
        });
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.UnprotectedSessionBegan);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.UnprotectedSessionExpired);
    }
    async handleEvent(event) {
        if (event.type === snjs_1.ApplicationEvent.UnprotectedSessionBegan) {
            this.refreshIncludeProtectedContents();
        }
        else if (event.type === snjs_1.ApplicationEvent.UnprotectedSessionExpired) {
            this.refreshIncludeProtectedContents();
        }
    }
}
exports.SearchOptionsController = SearchOptionsController;
