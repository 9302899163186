"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsCountsState = void 0;
const mobx_1 = require("mobx");
class TagsCountsState {
    constructor(items) {
        this.items = items;
        this.counts = {};
        (0, mobx_1.makeAutoObservable)(this, {
            counts: mobx_1.observable.ref,
            update: mobx_1.action,
        });
    }
    update(tags) {
        const newCounts = Object.assign({}, this.counts);
        tags.forEach((tag) => {
            newCounts[tag.uuid] = this.items.countableNotesForTag(tag);
        });
        this.counts = newCounts;
    }
}
exports.TagsCountsState = TagsCountsState;
