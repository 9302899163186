"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transactionForDisassociateComponentWithCurrentNote = exports.transactionForAssociateComponentWithCurrentNote = void 0;
const transactionForAssociateComponentWithCurrentNote = (component, note) => {
    const transaction = {
        itemUuid: component.uuid,
        mutate: (m) => {
            const mutator = m;
            mutator.removeDisassociatedItemId(note.uuid);
            mutator.associateWithItem(note.uuid);
        },
    };
    return transaction;
};
exports.transactionForAssociateComponentWithCurrentNote = transactionForAssociateComponentWithCurrentNote;
const transactionForDisassociateComponentWithCurrentNote = (component, note) => {
    const transaction = {
        itemUuid: component.uuid,
        mutate: (m) => {
            const mutator = m;
            mutator.removeAssociatedItemId(note.uuid);
            mutator.disassociateWithItem(note.uuid);
        },
    };
    return transaction;
};
exports.transactionForDisassociateComponentWithCurrentNote = transactionForDisassociateComponentWithCurrentNote;
