"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptTypeAPayload = void 0;
const encryption_1 = require("@standardnotes/encryption");
class DecryptTypeAPayload {
    constructor(operatorManager) {
        this.operatorManager = operatorManager;
    }
    async executeOne(payload, key) {
        return (0, encryption_1.decryptPayload)(payload, key, this.operatorManager);
    }
    async executeMany(payloads, key) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload, key)));
    }
}
exports.DecryptTypeAPayload = DecryptTypeAPayload;
