"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSharedVaults = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetSharedVaults {
    constructor(getVaults) {
        this.getVaults = getVaults;
    }
    execute() {
        const vaults = this.getVaults
            .execute()
            .getValue()
            .filter((vault) => vault.isSharedVaultListing());
        return domain_core_1.Result.ok(vaults);
    }
}
exports.GetSharedVaults = GetSharedVaults;
