"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportPlugin = void 0;
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const DownloadBlobOnAndroid_1 = require("@/NativeMobileWeb/DownloadBlobOnAndroid");
const ShareBlobOnMobile_1 = require("@/NativeMobileWeb/ShareBlobOnMobile");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const react_1 = require("react");
const CommandProvider_1 = require("@/Components/CommandProvider");
const HeadlessSuperConverter_1 = require("../../Tools/HeadlessSuperConverter");
const ExportPlugin = () => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const commandService = (0, CommandProvider_1.useCommandService)();
    const converter = (0, react_1.useRef)(new HeadlessSuperConverter_1.HeadlessSuperConverter());
    const downloadData = (0, react_1.useCallback)((data, fileName) => {
        if (!application.isNativeMobileWeb()) {
            application.archiveService.downloadData(data, fileName);
            return;
        }
        if (application.platform === snjs_1.Platform.Android) {
            (0, DownloadBlobOnAndroid_1.downloadBlobOnAndroid)(application.mobileDevice, data, fileName).catch(console.error);
        }
        else {
            (0, ShareBlobOnMobile_1.shareBlobOnMobile)(application.mobileDevice, application.isNativeMobileWeb(), data, fileName).catch(console.error);
        }
    }, [application]);
    const exportJson = (0, react_1.useCallback)((title) => {
        const content = converter.current.convertString(JSON.stringify(editor.getEditorState()), 'json');
        const blob = new Blob([content], { type: 'application/json' });
        downloadData(blob, `${(0, ui_services_1.sanitizeFileName)(title)}.json`);
    }, [downloadData, editor]);
    const exportMarkdown = (0, react_1.useCallback)((title) => {
        const content = converter.current.convertString(JSON.stringify(editor.getEditorState()), 'md');
        const blob = new Blob([content], { type: 'text/markdown' });
        downloadData(blob, `${(0, ui_services_1.sanitizeFileName)(title)}.md`);
    }, [downloadData, editor]);
    const exportHtml = (0, react_1.useCallback)((title) => {
        const content = converter.current.convertString(JSON.stringify(editor.getEditorState()), 'html');
        const blob = new Blob([content], { type: 'text/html' });
        downloadData(blob, `${(0, ui_services_1.sanitizeFileName)(title)}.html`);
    }, [downloadData, editor]);
    (0, react_1.useEffect)(() => {
        return commandService.addCommandHandler({
            command: ui_services_1.SUPER_EXPORT_JSON,
            onKeyDown: (_, data) => {
                if (!data) {
                    throw new Error('No data provided for export command');
                }
                const title = data;
                exportJson(title);
            },
        });
    }, [commandService, exportJson]);
    (0, react_1.useEffect)(() => {
        return commandService.addCommandHandler({
            command: ui_services_1.SUPER_EXPORT_MARKDOWN,
            onKeyDown: (_, data) => {
                if (!data) {
                    throw new Error('No data provided for export command');
                }
                const title = data;
                exportMarkdown(title);
            },
        });
    }, [commandService, exportMarkdown]);
    (0, react_1.useEffect)(() => {
        return commandService.addCommandHandler({
            command: ui_services_1.SUPER_EXPORT_HTML,
            onKeyDown: (_, data) => {
                if (!data) {
                    throw new Error('No data provided for export command');
                }
                const title = data;
                exportHtml(title);
            },
        });
    }, [commandService, exportHtml]);
    return null;
};
exports.ExportPlugin = ExportPlugin;
