"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListKeyboardNavigation = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const Constants_1 = require("@/Constants/Constants");
const react_1 = require("react");
const useListKeyboardNavigation = (container, initialFocus = 0, shouldAutoFocus = false) => {
    const listItems = (0, react_1.useRef)([]);
    const focusedItemIndex = (0, react_1.useRef)(initialFocus);
    const focusItemWithIndex = (0, react_1.useCallback)((index, items) => {
        var _a, _b;
        focusedItemIndex.current = index;
        if (items && items.length > 0) {
            (_a = items[index]) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            (_b = listItems.current[index]) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, []);
    const getNextFocusableIndex = (0, react_1.useCallback)((currentIndex, items) => {
        let nextIndex = currentIndex + 1;
        if (nextIndex > items.length - 1) {
            nextIndex = 0;
        }
        while (items[nextIndex].disabled) {
            nextIndex++;
            if (nextIndex > items.length - 1) {
                nextIndex = 0;
            }
        }
        return nextIndex;
    }, []);
    const getPreviousFocusableIndex = (0, react_1.useCallback)((currentIndex, items) => {
        let previousIndex = currentIndex - 1;
        if (previousIndex < 0) {
            previousIndex = items.length - 1;
        }
        while (items[previousIndex].disabled) {
            previousIndex--;
            if (previousIndex < 0) {
                previousIndex = items.length - 1;
            }
        }
        return previousIndex;
    }, []);
    (0, react_1.useEffect)(() => {
        if (container.current) {
            container.current.tabIndex = Constants_1.FOCUSABLE_BUT_NOT_TABBABLE;
            listItems.current = Array.from(container.current.querySelectorAll('button'));
        }
    }, [container]);
    const keyDownHandler = (0, react_1.useCallback)((e) => {
        var _a, _b, _c;
        const isFocusInInput = ((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'INPUT';
        const isFocusInListbox = !!((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.closest('[role="listbox"]'));
        if (isFocusInInput || isFocusInListbox) {
            return;
        }
        if (e.key === ui_services_1.KeyboardKey.Up || e.key === ui_services_1.KeyboardKey.Down) {
            e.preventDefault();
        }
        else {
            return;
        }
        listItems.current = Array.from((_c = container.current) === null || _c === void 0 ? void 0 : _c.querySelectorAll('button'));
        if (e.key === ui_services_1.KeyboardKey.Up) {
            const previousIndex = getPreviousFocusableIndex(focusedItemIndex.current, listItems.current);
            focusItemWithIndex(previousIndex);
        }
        if (e.key === ui_services_1.KeyboardKey.Down) {
            const nextIndex = getNextFocusableIndex(focusedItemIndex.current, listItems.current);
            focusItemWithIndex(nextIndex);
        }
    }, [container, focusItemWithIndex, getNextFocusableIndex, getPreviousFocusableIndex]);
    const FIRST_ITEM_FOCUS_TIMEOUT = 20;
    const setInitialFocus = (0, react_1.useCallback)(() => {
        var _a;
        const items = Array.from((_a = container.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('button'));
        listItems.current = items;
        if (items.length < 1) {
            return;
        }
        const selectedItemIndex = Array.from(items).findIndex((item) => item.dataset.selected);
        let indexToFocus = selectedItemIndex > -1 ? selectedItemIndex : initialFocus;
        indexToFocus = getNextFocusableIndex(indexToFocus - 1, items);
        focusItemWithIndex(indexToFocus, items);
    }, [container, focusItemWithIndex, getNextFocusableIndex, initialFocus]);
    (0, react_1.useEffect)(() => {
        if (shouldAutoFocus) {
            setTimeout(() => {
                setInitialFocus();
            }, FIRST_ITEM_FOCUS_TIMEOUT);
        }
    }, [setInitialFocus, shouldAutoFocus]);
    (0, react_1.useEffect)(() => {
        if (listItems.current.length > 0) {
            listItems.current[0].tabIndex = 0;
        }
    }, []);
    (0, react_1.useEffect)(() => {
        const containerElement = container.current;
        containerElement === null || containerElement === void 0 ? void 0 : containerElement.addEventListener('keydown', keyDownHandler);
        return () => {
            containerElement === null || containerElement === void 0 ? void 0 : containerElement.removeEventListener('keydown', keyDownHandler);
        };
    }, [container, setInitialFocus, keyDownHandler]);
    return {
        setInitialFocus,
    };
};
exports.useListKeyboardNavigation = useListKeyboardNavigation;
