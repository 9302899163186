"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const lexical_1 = require("lexical");
const react_1 = require("react");
const Commands_1 = require("../Commands");
const utils_1 = require("@lexical/utils");
const Generator_1 = require("./Generator");
function PasswordPlugin() {
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    (0, react_1.useEffect)(() => {
        return (0, utils_1.mergeRegister)(editor.registerCommand(Commands_1.INSERT_PASSWORD_COMMAND, (lengthString) => {
            const length = Number(lengthString);
            const selection = (0, lexical_1.$getSelection)();
            if (!(0, lexical_1.$isRangeSelection)(selection)) {
                return false;
            }
            const paragraph = (0, lexical_1.$createParagraphNode)();
            const password = (0, Generator_1.generatePassword)(length);
            paragraph.append((0, lexical_1.$createTextNode)(password));
            selection.insertNodes([paragraph]);
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR));
    }, [editor]);
    return null;
}
exports.default = PasswordPlugin;
