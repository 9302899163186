"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderedByteChunker = void 0;
class OrderedByteChunker {
    constructor(chunkSizes, source, onChunk) {
        this.chunkSizes = chunkSizes;
        this.source = source;
        this.onChunk = onChunk;
        this.bytes = new Uint8Array();
        this.index = 1;
        this.remainingChunks = [];
        this.remainingChunks = chunkSizes.slice();
        this.fileSize = chunkSizes.reduce((acc, size) => acc + size, 0);
    }
    get bytesPopped() {
        return this.fileSize - this.bytesRemaining;
    }
    get bytesRemaining() {
        return this.remainingChunks.reduce((acc, size) => acc + size, 0);
    }
    needsPop() {
        return this.remainingChunks.length > 0 && this.bytes.length >= this.remainingChunks[0];
    }
    async addBytes(bytes) {
        this.bytes = new Uint8Array([...this.bytes, ...bytes]);
        if (this.needsPop()) {
            await this.popBytes();
        }
    }
    async popBytes() {
        const readUntil = this.remainingChunks[0];
        const chunk = this.bytes.slice(0, readUntil);
        this.bytes = new Uint8Array([...this.bytes.slice(readUntil)]);
        this.remainingChunks.shift();
        await this.onChunk({
            data: chunk,
            index: this.index++,
            isLast: this.index === this.chunkSizes.length - 1,
            progress: {
                encryptedFileSize: this.fileSize,
                encryptedBytesDownloaded: this.bytesPopped,
                encryptedBytesRemaining: this.bytesRemaining,
                percentComplete: (this.bytesPopped / this.fileSize) * 100.0,
                source: this.source,
            },
        });
        if (this.needsPop()) {
            await this.popBytes();
        }
    }
}
exports.OrderedByteChunker = OrderedByteChunker;
