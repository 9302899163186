"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetermineKeyToUse = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const encryption_1 = require("@standardnotes/encryption");
class DetermineKeyToUse {
    constructor(encryption, keys) {
        this.encryption = encryption;
        this.keys = keys;
    }
    execute(dto) {
        if ((0, models_1.ContentTypeUsesRootKeyEncryption)(dto.payload.content_type)) {
            if (!dto.rootKey) {
                throw new Error('Attempting to decrypt root key encrypted payload with no root key');
            }
            return domain_core_1.Result.ok(dto.rootKey);
        }
        if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(dto.payload.content_type)) {
            if (!dto.payload.key_system_identifier) {
                throw new Error('Attempting to decrypt key system root key encrypted payload with no key system identifier');
            }
            try {
                const recentlyDecrypted = dto.recentlyDecryptedKeys.filter(models_1.isKeySystemRootKey);
                let keySystemRootKey = recentlyDecrypted.find((key) => key.systemIdentifier === dto.payload.key_system_identifier);
                if (!keySystemRootKey) {
                    keySystemRootKey = this.keys.getPrimaryKeySystemRootKey(dto.payload.key_system_identifier);
                }
                return domain_core_1.Result.ok(keySystemRootKey);
            }
            catch (error) {
                return domain_core_1.Result.fail(JSON.stringify(error));
            }
        }
        if (dto.payload.key_system_identifier) {
            const keySystemItemsKey = dto.recentlyDecryptedKeys.find((key) => key.key_system_identifier === dto.payload.key_system_identifier);
            if (keySystemItemsKey) {
                return domain_core_1.Result.ok(keySystemItemsKey);
            }
        }
        let itemsKey;
        if (dto.payload.items_key_id) {
            itemsKey = this.encryption.itemsKeyForEncryptedPayload(dto.payload);
            if (itemsKey) {
                return domain_core_1.Result.ok(itemsKey);
            }
        }
        itemsKey = dto.recentlyDecryptedKeys.filter(encryption_1.isItemsKey).find((itemsKeyPayload) => {
            return domain_core_1.Result.ok(dto.payload.items_key_id === itemsKeyPayload.uuid);
        });
        if (itemsKey) {
            return domain_core_1.Result.ok(itemsKey);
        }
        if (!dto.keyParams) {
            return domain_core_1.Result.ok(undefined);
        }
        const payloadVersion = dto.payload.version;
        /**
         * Payloads with versions <= 003 use root key directly for encryption.
         * However, if the incoming key params are >= 004, this means we should
         * have an items key based off the 003 root key. We can't use the 004
         * root key directly because it's missing dataAuthenticationKey.
         */
        if ((0, common_1.leftVersionGreaterThanOrEqualToRight)(dto.keyParams.version, common_1.ProtocolVersion.V004)) {
            itemsKey = this.encryption.defaultItemsKeyForItemVersion(payloadVersion, dto.recentlyDecryptedKeys.filter(encryption_1.isItemsKey));
        }
        else if ((0, common_1.compareVersions)(payloadVersion, common_1.ProtocolVersion.V003) <= 0) {
            itemsKey = dto.rootKey;
        }
        return domain_core_1.Result.ok(itemsKey);
    }
}
exports.DetermineKeyToUse = DetermineKeyToUse;
