"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createObjectURLWithRef = void 0;
const createObjectURLWithRef = (type, bytes, ref) => {
    const objectURL = URL.createObjectURL(new Blob([bytes], {
        type,
    }));
    ref.current = objectURL;
    return objectURL;
};
exports.createObjectURLWithRef = createObjectURLWithRef;
