"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateItemSigner = void 0;
const V004AlgorithmHelpers_1 = require("@standardnotes/encryption/src/Domain/Operator/004/V004AlgorithmHelpers");
const models_1 = require("@standardnotes/models");
const ItemSignatureValidationResult_1 = require("./Types/ItemSignatureValidationResult");
class ValidateItemSigner {
    constructor(findContact) {
        this.findContact = findContact;
    }
    execute(item) {
        const uuidOfLastEditor = item.last_edited_by_uuid;
        if (uuidOfLastEditor) {
            return this.validateSignatureWithLastEditedByUuid(item, uuidOfLastEditor);
        }
        else {
            return this.validateSignatureWithNoLastEditedByUuid(item);
        }
    }
    isItemLocallyCreatedAndDoesNotRequireSignature(item) {
        return item.payload.source === models_1.PayloadSource.Constructor;
    }
    isItemResutOfRemoteSaveAndDoesNotRequireSignature(item) {
        return item.payload.source === models_1.PayloadSource.RemoteSaved;
    }
    validateSignatureWithLastEditedByUuid(item, uuidOfLastEditor) {
        const requiresSignature = (0, V004AlgorithmHelpers_1.doesPayloadRequireSigning)(item);
        const trustedContact = this.findContact.execute({ userUuid: uuidOfLastEditor });
        if (trustedContact.isFailed()) {
            if (requiresSignature) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
            }
            else {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
            }
        }
        if (!item.signatureData) {
            if (this.isItemLocallyCreatedAndDoesNotRequireSignature(item) ||
                this.isItemResutOfRemoteSaveAndDoesNotRequireSignature(item)) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
            }
            if (requiresSignature) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
            }
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
        }
        const signatureData = item.signatureData;
        if (!signatureData.result) {
            if (signatureData.required) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
            }
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
        }
        const signatureResult = signatureData.result;
        if (!signatureResult.passes) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
        }
        const signerPublicKey = signatureResult.publicKey;
        const trustStatus = trustedContact.getValue().getTrustStatusForSigningPublicKey(signerPublicKey);
        if (trustStatus === models_1.PublicKeyTrustStatus.Trusted) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.Trusted;
        }
        else if (trustStatus === models_1.PublicKeyTrustStatus.Previous) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.SignedWithNonCurrentKey;
        }
        return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
    }
    validateSignatureWithNoLastEditedByUuid(item) {
        const requiresSignature = (0, V004AlgorithmHelpers_1.doesPayloadRequireSigning)(item);
        if (!item.signatureData) {
            if (this.isItemLocallyCreatedAndDoesNotRequireSignature(item) ||
                this.isItemResutOfRemoteSaveAndDoesNotRequireSignature(item)) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
            }
            if (requiresSignature) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
            }
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
        }
        const signatureData = item.signatureData;
        if (!signatureData.result) {
            if (signatureData.required) {
                return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
            }
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotApplicable;
        }
        const signatureResult = signatureData.result;
        if (!signatureResult.passes) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
        }
        const signerPublicKey = signatureResult.publicKey;
        const trustedContact = this.findContact.execute({ signingPublicKey: signerPublicKey });
        if (trustedContact.isFailed()) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
        }
        const trustStatus = trustedContact.getValue().getTrustStatusForSigningPublicKey(signerPublicKey);
        if (trustStatus === models_1.PublicKeyTrustStatus.Trusted) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.Trusted;
        }
        else if (trustStatus === models_1.PublicKeyTrustStatus.Previous) {
            return ItemSignatureValidationResult_1.ItemSignatureValidationResult.SignedWithNonCurrentKey;
        }
        return ItemSignatureValidationResult_1.ItemSignatureValidationResult.NotTrusted;
    }
}
exports.ValidateItemSigner = ValidateItemSigner;
