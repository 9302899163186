"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCollapsibleBlock = void 0;
const CollapsiblePlugin_1 = require("../../Plugins/CollapsiblePlugin");
function GetCollapsibleBlock(editor) {
    return {
        name: 'Collapsible',
        iconName: 'caret-right',
        keywords: ['collapse', 'collapsible', 'toggle'],
        onSelect: () => editor.dispatchCommand(CollapsiblePlugin_1.INSERT_COLLAPSIBLE_COMMAND, undefined),
    };
}
exports.GetCollapsibleBlock = GetCollapsibleBlock;
