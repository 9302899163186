"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetReplaceabilityIdentifier = void 0;
const models_1 = require("@standardnotes/models");
const TypesUsingReplaceableIdentifiers = [
    models_1.AsymmetricMessagePayloadType.SharedVaultRootKeyChanged,
    models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged,
];
function GetReplaceabilityIdentifier(type, sharedVaultUuid, keySystemIdentifier) {
    if (!TypesUsingReplaceableIdentifiers.includes(type)) {
        return undefined;
    }
    return [type, sharedVaultUuid, keySystemIdentifier].join(':');
}
exports.GetReplaceabilityIdentifier = GetReplaceabilityIdentifier;
