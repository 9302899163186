"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractComponent = void 0;
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const react_1 = require("react");
class AbstractComponent extends react_1.Component {
    constructor(props, application) {
        super(props);
        this.application = application;
        this.reactionDisposers = [];
    }
    componentDidMount() {
        this.addAppEventObserver();
    }
    deinit() {
        var _a;
        (_a = this.unsubApp) === null || _a === void 0 ? void 0 : _a.call(this);
        for (const disposer of this.reactionDisposers) {
            disposer();
        }
        this.reactionDisposers.length = 0;
        this.unsubApp = undefined;
        this.application = undefined;
        this.props = undefined;
        this.state = undefined;
    }
    componentWillUnmount() {
        this.deinit();
    }
    autorun(view) {
        this.reactionDisposers.push((0, mobx_1.autorun)(view));
    }
    addAppEventObserver() {
        if (this.application.isStarted()) {
            this.onAppStart().catch(console.error);
        }
        if (this.application.isLaunched()) {
            this.onAppLaunch().catch(console.error);
        }
        this.unsubApp = this.application.addEventObserver(async (eventName, data) => {
            if (!this.application) {
                return;
            }
            this.onAppEvent(eventName, data);
            if (eventName === snjs_1.ApplicationEvent.Started) {
                await this.onAppStart();
            }
            else if (eventName === snjs_1.ApplicationEvent.Launched) {
                await this.onAppLaunch();
            }
            else if (eventName === snjs_1.ApplicationEvent.CompletedIncrementalSync) {
                this.onAppIncrementalSync();
            }
            else if (eventName === snjs_1.ApplicationEvent.CompletedFullSync) {
                this.onAppFullSync();
            }
            else if (eventName === snjs_1.ApplicationEvent.KeyStatusChanged) {
                this.onAppKeyChange().catch(console.error);
            }
            else if (eventName === snjs_1.ApplicationEvent.LocalDataLoaded) {
                this.onLocalDataLoaded();
            }
        });
    }
    onAppEvent(_eventName, _data) {
        /** Optional override */
    }
    async onAppStart() {
        /** Optional override */
    }
    onLocalDataLoaded() {
        /** Optional override */
    }
    async onAppLaunch() {
        /** Optional override */
    }
    async onAppKeyChange() {
        /** Optional override */
    }
    onAppIncrementalSync() {
        /** Optional override */
    }
    onAppFullSync() {
        /** Optional override */
    }
}
exports.AbstractComponent = AbstractComponent;
