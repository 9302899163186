"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BubbleNode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const LexicalDecoratorBlockNode_1 = require("@lexical/react/LexicalDecoratorBlockNode");
const BubbleUtils_1 = require("./BubbleUtils");
const BubbleComponent_1 = require("./BubbleComponent");
class BubbleNode extends LexicalDecoratorBlockNode_1.DecoratorBlockNode {
    static getType() {
        return 'snbubble';
    }
    static clone(node) {
        return new BubbleNode(node.__id, node.__format, node.__key);
    }
    static importJSON(serializedNode) {
        const node = (0, BubbleUtils_1.$createBubbleNode)(serializedNode.itemUuid);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            itemUuid: this.getId(),
            version: 1,
            type: 'snbubble',
        };
    }
    static importDOM() {
        return {
            div: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-item-uuid')) {
                    return null;
                }
                return {
                    conversion: BubbleUtils_1.convertToBubbleElement,
                    priority: 2,
                };
            },
        };
    }
    createDOM() {
        return document.createElement('span');
    }
    exportDOM() {
        const element = document.createElement('span');
        element.setAttribute('data-lexical-item-uuid', this.__id);
        const text = document.createTextNode(this.getTextContent());
        element.append(text);
        return { element };
    }
    constructor(id, format, key) {
        super(format, key);
        this.__id = id;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `[Item: ${this.__id}]`;
    }
    decorate(_editor) {
        return (0, jsx_runtime_1.jsx)(BubbleComponent_1.BubbleComponent, { node: this, itemUuid: this.__id });
    }
}
exports.BubbleNode = BubbleNode;
