"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decryptPayload = exports.encryptPayload = void 0;
const EncryptedParameters_1 = require("../Types/EncryptedParameters");
const TypeCheck_1 = require("./OperatorInterface/TypeCheck");
async function encryptPayload(payload, key, operatorManager, signingKeyPair) {
    const operator = operatorManager.operatorForVersion(key.keyVersion);
    let result = undefined;
    if ((0, TypeCheck_1.isAsyncOperator)(operator)) {
        result = await operator.generateEncryptedParametersAsync(payload, key);
    }
    else {
        result = operator.generateEncryptedParameters(payload, key, signingKeyPair);
    }
    if (!result) {
        throw 'Unable to generate encryption parameters';
    }
    return result;
}
exports.encryptPayload = encryptPayload;
async function decryptPayload(payload, key, operatorManager) {
    const operator = operatorManager.operatorForVersion(payload.version);
    try {
        if ((0, TypeCheck_1.isAsyncOperator)(operator)) {
            return await operator.generateDecryptedParametersAsync((0, EncryptedParameters_1.encryptedInputParametersFromPayload)(payload), key);
        }
        else {
            return operator.generateDecryptedParameters((0, EncryptedParameters_1.encryptedInputParametersFromPayload)(payload), key);
        }
    }
    catch (e) {
        console.error('Error decrypting payload', payload, e);
        return {
            uuid: payload.uuid,
            errorDecrypting: true,
        };
    }
}
exports.decryptPayload = decryptPayload;
