"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendOwnContactChangeMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class SendOwnContactChangeMessage {
    constructor(encryptMessage, sendMessage) {
        this.encryptMessage = encryptMessage;
        this.sendMessage = sendMessage;
    }
    async execute(params) {
        const message = {
            type: models_1.AsymmetricMessagePayloadType.SenderKeypairChanged,
            data: {
                recipientUuid: params.contact.contactUuid,
                newEncryptionPublicKey: params.senderNewKeyPair.publicKey,
                newSigningPublicKey: params.senderNewSigningKeyPair.publicKey,
            },
        };
        const encryptedMessage = this.encryptMessage.execute({
            message: message,
            keys: {
                encryption: params.senderOldKeyPair,
                signing: params.senderOldSigningKeyPair,
            },
            recipientPublicKey: params.contact.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const sendMessageResult = await this.sendMessage.execute({
            recipientUuid: params.contact.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            replaceabilityIdentifier: undefined,
        });
        return sendMessageResult;
    }
}
exports.SendOwnContactChangeMessage = SendOwnContactChangeMessage;
