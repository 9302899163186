"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const ElementIDs_1 = require("@/Constants/ElementIDs");
const react_1 = require("react");
const TextPreview = ({ bytes }) => {
    const text = (0, react_1.useMemo)(() => {
        const textDecoder = new TextDecoder();
        return textDecoder.decode(bytes);
    }, [bytes]);
    return ((0, jsx_runtime_1.jsx)("textarea", { autoComplete: "off", className: "font-editor h-full w-full flex-grow focus:shadow-none focus:outline-none", dir: "auto", id: ElementIDs_1.ElementIds.FileTextPreview, defaultValue: text, readOnly: true }));
};
exports.default = TextPreview;
