"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveVaultMember = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class RemoveVaultMember {
    constructor(vaultUserServer) {
        this.vaultUserServer = vaultUserServer;
    }
    async execute(params) {
        const response = await this.vaultUserServer.deleteSharedVaultUser({
            sharedVaultUuid: params.sharedVaultUuid,
            userUuid: params.userUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail((0, responses_1.getErrorFromErrorResponse)(response).message);
        }
        return domain_core_1.Result.ok();
    }
}
exports.RemoveVaultMember = RemoveVaultMember;
