"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternalFeatureService = void 0;
let sharedInstance;
class InternalFeatureService {
    constructor() {
        this.enabledFeatures = new Set();
    }
    static get() {
        if (!sharedInstance) {
            sharedInstance = new InternalFeatureService();
        }
        return sharedInstance;
    }
    isFeatureEnabled(feature) {
        return this.enabledFeatures.has(feature);
    }
    enableFeature(feature) {
        console.warn(`Enabling internal feature: ${feature}`);
        this.enabledFeatures.add(feature);
    }
}
exports.InternalFeatureService = InternalFeatureService;
