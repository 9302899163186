"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastTimer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Toast_1 = require("./Toast");
const enums_1 = require("./enums");
const toastStore_1 = require("./toastStore");
const getDefaultForAutoClose = (hasActions, type) => {
    return !hasActions && ![enums_1.ToastType.Loading, enums_1.ToastType.Progress].includes(type);
};
const getDefaultToastDuration = (type) => (type === enums_1.ToastType.Error ? 8000 : 4000);
const ToastTimer = ({ toast, index }) => {
    var _a, _b, _c;
    const toastElementRef = (0, react_1.useRef)(null);
    const toastTimerIdRef = (0, react_1.useRef)();
    const hasActions = Boolean((_a = toast.actions) === null || _a === void 0 ? void 0 : _a.length);
    const shouldAutoClose = (_b = toast.autoClose) !== null && _b !== void 0 ? _b : getDefaultForAutoClose(hasActions, toast.type);
    const duration = (_c = toast.duration) !== null && _c !== void 0 ? _c : getDefaultToastDuration(toast.type);
    const startTimeRef = (0, react_1.useRef)(duration);
    const remainingTimeRef = (0, react_1.useRef)(duration);
    const dismissToastOnEnd = (0, react_1.useCallback)(() => {
        if (!shouldAutoClose) {
            return;
        }
        (0, toastStore_1.dismissToast)(toast.id);
    }, [shouldAutoClose, toast.id]);
    const clearTimer = (0, react_1.useCallback)(() => {
        if (toastTimerIdRef.current) {
            clearTimeout(toastTimerIdRef.current);
        }
    }, []);
    const pauseTimer = (0, react_1.useCallback)(() => {
        clearTimer();
        remainingTimeRef.current -= Date.now() - startTimeRef.current;
    }, [clearTimer]);
    const resumeTimer = (0, react_1.useCallback)(() => {
        startTimeRef.current = Date.now();
        clearTimer();
        toastTimerIdRef.current = window.setTimeout(dismissToastOnEnd, remainingTimeRef.current);
    }, [clearTimer, dismissToastOnEnd]);
    const handleMouseEnter = (0, react_1.useCallback)(() => {
        pauseTimer();
    }, [pauseTimer]);
    const handleMouseLeave = (0, react_1.useCallback)(() => {
        resumeTimer();
    }, [resumeTimer]);
    const handlePageFocus = (0, react_1.useCallback)(() => {
        resumeTimer();
    }, [resumeTimer]);
    const handlePageBlur = (0, react_1.useCallback)(() => {
        pauseTimer();
    }, [pauseTimer]);
    (0, react_1.useEffect)(() => {
        clearTimer();
        if (shouldAutoClose) {
            resumeTimer();
        }
        const toastElement = toastElementRef.current;
        if (toastElement) {
            toastElement.addEventListener('mouseenter', handleMouseEnter);
            toastElement.addEventListener('mouseleave', handleMouseLeave);
        }
        if (toast.pauseOnWindowBlur) {
            window.addEventListener('focus', handlePageFocus);
            window.addEventListener('blur', handlePageBlur);
        }
        return () => {
            clearTimer();
            if (toastElement) {
                toastElement.removeEventListener('mouseenter', handleMouseEnter);
                toastElement.removeEventListener('mouseleave', handleMouseLeave);
            }
            if (toast.pauseOnWindowBlur) {
                window.removeEventListener('focus', handlePageFocus);
                window.removeEventListener('blur', handlePageBlur);
            }
        };
    }, [
        clearTimer,
        dismissToastOnEnd,
        duration,
        handleMouseEnter,
        handleMouseLeave,
        handlePageBlur,
        handlePageFocus,
        resumeTimer,
        shouldAutoClose,
        toast.id,
    ]);
    return (0, jsx_runtime_1.jsx)(Toast_1.Toast, { toast: toast, index: index, ref: toastElementRef });
};
exports.ToastTimer = ToastTimer;
