"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDatetimeBlockOptions = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const DateTime_1 = require("../../Blocks/DateTime");
function GetDatetimeBlockOptions(editor) {
    return (0, DateTime_1.GetDatetimeBlocks)(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetDatetimeBlockOptions = GetDatetimeBlockOptions;
