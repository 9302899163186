"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptBackupPayloads = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
const encryption_1 = require("@standardnotes/encryption");
class DecryptBackupPayloads {
    constructor(encryption, _determineKeyToUse, logger) {
        this.encryption = encryption;
        this._determineKeyToUse = _determineKeyToUse;
        this.logger = logger;
    }
    async execute(dto) {
        const results = [];
        for (const encryptedPayload of dto.payloads) {
            try {
                const key = this._determineKeyToUse
                    .execute({
                    payload: encryptedPayload,
                    recentlyDecryptedKeys: dto.recentlyDecryptedKeys,
                    keyParams: dto.keyParams,
                    rootKey: dto.rootKey,
                })
                    .getValue();
                if (!key) {
                    results.push(encryptedPayload.copy({
                        errorDecrypting: true,
                    }));
                    continue;
                }
                if ((0, encryption_1.isItemsKey)(key) || (0, encryption_1.isKeySystemItemsKey)(key)) {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesItemsKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
                else if ((0, models_1.isKeySystemRootKey)(key)) {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesKeySystemRootKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
                else {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesRootKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
            }
            catch (e) {
                results.push(encryptedPayload.copy({
                    errorDecrypting: true,
                }));
                this.logger.error('Error decrypting payload', encryptedPayload, e);
            }
        }
        return domain_core_1.Result.ok(results);
    }
}
exports.DecryptBackupPayloads = DecryptBackupPayloads;
