"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetIndentOutdentBlockOptions = void 0;
const BlockPickerOption_1 = require("../BlockPickerOption");
const IndentOutdent_1 = require("../../Blocks/IndentOutdent");
function GetIndentOutdentBlockOptions(editor) {
    return (0, IndentOutdent_1.GetIndentOutdentBlocks)(editor).map((block) => new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    }));
}
exports.GetIndentOutdentBlockOptions = GetIndentOutdentBlockOptions;
