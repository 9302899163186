"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsGlobalSpellcheckEnabled = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class IsGlobalSpellcheckEnabled {
    constructor(preferences) {
        this.preferences = preferences;
    }
    execute() {
        return domain_core_1.Result.ok(this.preferences.getValue(models_1.PrefKey.EditorSpellcheck, models_1.PrefDefaults[models_1.PrefKey.EditorSpellcheck]));
    }
}
exports.IsGlobalSpellcheckEnabled = IsGlobalSpellcheckEnabled;
