"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultUsersServer = void 0;
const Paths_1 = require("./Paths");
class SharedVaultUsersServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    getSharedVaultUsers(params) {
        return this.httpService.get(Paths_1.SharedVaultUsersPaths.getSharedVaultUsers(params.sharedVaultUuid));
    }
    deleteSharedVaultUser(params) {
        return this.httpService.delete(Paths_1.SharedVaultUsersPaths.deleteSharedVaultUser(params.sharedVaultUuid, params.userUuid));
    }
}
exports.SharedVaultUsersServer = SharedVaultUsersServer;
