"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateEncryptedProtocolStringUseCase = void 0;
const V004AlgorithmTypes_1 = require("../../V004AlgorithmTypes");
const models_1 = require("@standardnotes/models");
const Algorithm_1 = require("../../../../Algorithm");
class GenerateEncryptedProtocolStringUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(plaintext, rawKey, authenticatedData, additionalData) {
        const nonce = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionNonceLength);
        const ciphertext = this.encryptString(plaintext, rawKey, nonce, authenticatedData);
        const components = [
            models_1.ProtocolVersion.V004,
            nonce,
            ciphertext,
            authenticatedData,
            additionalData,
        ];
        return components.join(V004AlgorithmTypes_1.V004PartitionCharacter);
    }
    encryptString(plaintext, rawKey, nonce, authenticatedData) {
        if (!nonce) {
            throw 'encryptString null nonce';
        }
        if (!rawKey) {
            throw 'encryptString null rawKey';
        }
        return this.crypto.xchacha20Encrypt(plaintext, nonce, rawKey, authenticatedData);
    }
}
exports.GenerateEncryptedProtocolStringUseCase = GenerateEncryptedProtocolStringUseCase;
