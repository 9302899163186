"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengePromptTitle = void 0;
exports.ChallengePromptTitle = {
    AccountPassword: 'Account Password',
    LocalPasscode: 'Application Passcode',
    Biometrics: 'Biometrics',
    RememberFor: 'Remember For',
    Mfa: 'Two-factor Authentication Code',
    U2F: 'Security Key',
};
