"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateSymmetricAdditionalDataUseCase = void 0;
const HashString_1 = require("../Hash/HashString");
class GenerateSymmetricAdditionalDataUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.hashUseCase = new HashString_1.HashStringUseCase(this.crypto);
    }
    execute(payloadPlaintext, hashingKey, signingKeyPair) {
        const plaintextHash = this.hashUseCase.execute(payloadPlaintext, hashingKey);
        if (!signingKeyPair) {
            return {
                additionalData: {},
                plaintextHash,
            };
        }
        const signature = this.crypto.sodiumCryptoSign(plaintextHash, signingKeyPair.privateKey);
        return {
            additionalData: {
                signingData: {
                    publicKey: signingKeyPair.publicKey,
                    signature,
                },
            },
            plaintextHash,
        };
    }
}
exports.GenerateSymmetricAdditionalDataUseCase = GenerateSymmetricAdditionalDataUseCase;
