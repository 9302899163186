"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortThemes = void 0;
const snjs_1 = require("@standardnotes/snjs");
const isDarkModeTheme = (theme) => theme.featureIdentifier === snjs_1.NativeFeatureIdentifier.TYPES.DarkTheme;
const sortThemes = (a, b) => {
    const aIsLayerable = a.layerable;
    const bIsLayerable = b.layerable;
    if (aIsLayerable && !bIsLayerable) {
        return 1;
    }
    else if (!aIsLayerable && bIsLayerable) {
        return -1;
    }
    else if (!isDarkModeTheme(a) && isDarkModeTheme(b)) {
        return 1;
    }
    else {
        return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
    }
};
exports.sortThemes = sortThemes;
