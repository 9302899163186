"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebApplicationGroup = void 0;
const WebApplication_1 = require("./WebApplication");
const snjs_1 = require("@standardnotes/snjs");
const Utils_1 = require("@/Utils");
const createApplication = (descriptor, deviceInterface, defaultSyncServerHost, device, webSocketUrl) => {
    const platform = (0, Utils_1.getPlatform)(device);
    const application = new WebApplication_1.WebApplication(deviceInterface, platform, descriptor.identifier, defaultSyncServerHost, webSocketUrl);
    return application;
};
class WebApplicationGroup extends snjs_1.SNApplicationGroup {
    constructor(defaultSyncServerHost, device, webSocketUrl) {
        super(device);
        this.defaultSyncServerHost = defaultSyncServerHost;
        this.webSocketUrl = webSocketUrl;
    }
    async initialize() {
        const defaultSyncServerHost = this.defaultSyncServerHost;
        const webSocketUrl = this.webSocketUrl;
        await super.initialize({
            applicationCreator: async (descriptor, device) => {
                return createApplication(descriptor, device, defaultSyncServerHost, device, webSocketUrl);
            },
        });
        if ((0, Utils_1.isDesktopApplication)()) {
            window.webClient = this.primaryApplication.desktopManager;
        }
    }
    deinit() {
        super.deinit();
        if ((0, Utils_1.isDesktopApplication)()) {
            delete window.webClient;
        }
    }
}
exports.WebApplicationGroup = WebApplicationGroup;
