"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactService = void 0;
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("../Service/AbstractService");
const CollaborationID_1 = require("./CollaborationID");
class ContactService extends AbstractService_1.AbstractService {
    constructor(sync, mutator, session, crypto, user, selfContactManager, encryption, _deleteContact, _findContact, _getAllContacts, _createOrEditContact, _editContact, _validateItemSigner, eventBus) {
        super(eventBus);
        this.sync = sync;
        this.mutator = mutator;
        this.session = session;
        this.crypto = crypto;
        this.user = user;
        this.selfContactManager = selfContactManager;
        this.encryption = encryption;
        this._deleteContact = _deleteContact;
        this._findContact = _findContact;
        this._getAllContacts = _getAllContacts;
        this._createOrEditContact = _createOrEditContact;
        this._editContact = _editContact;
        this._validateItemSigner = _validateItemSigner;
    }
    deinit() {
        super.deinit();
        this.sync = undefined;
        this.mutator = undefined;
        this.session = undefined;
        this.crypto = undefined;
        this.user = undefined;
        this.selfContactManager = undefined;
        this.encryption = undefined;
        this._findContact = undefined;
        this._getAllContacts = undefined;
        this._createOrEditContact = undefined;
        this._editContact = undefined;
        this._validateItemSigner = undefined;
    }
    getSelfContact() {
        return this.selfContactManager.selfContact;
    }
    isCollaborationEnabled() {
        return !this.session.isUserMissingKeyPair();
    }
    async enableCollaboration() {
        await this.user.updateAccountWithFirstTimeKeyPair();
    }
    getCollaborationID() {
        const publicKey = this.session.getPublicKey();
        if (!publicKey) {
            throw new Error('Collaboration not enabled');
        }
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: this.session.getSureUser().uuid,
            publicKey,
            signingPublicKey: this.session.getSigningPublicKey(),
        });
    }
    buildCollaborationId(params) {
        const string = `${params.version}:${params.userUuid}:${params.publicKey}:${params.signingPublicKey}`;
        return this.crypto.base64Encode(string);
    }
    parseCollaborationID(collaborationID) {
        const decoded = this.crypto.base64Decode(collaborationID);
        const [version, userUuid, publicKey, signingPublicKey] = decoded.split(':');
        return { version, userUuid, publicKey, signingPublicKey };
    }
    getCollaborationIDFromInvite(invite) {
        const publicKeySet = this.encryption.getSenderPublicKeySetFromAsymmetricallyEncryptedString(invite.encrypted_message);
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: invite.sender_uuid,
            publicKey: publicKeySet.encryption,
            signingPublicKey: publicKeySet.signing,
        });
    }
    addTrustedContactFromCollaborationID(collaborationID, name) {
        const { userUuid, publicKey, signingPublicKey } = this.parseCollaborationID(collaborationID);
        if (userUuid === this.user.getUserUuid()) {
            throw new responses_1.ClientDisplayableError('You cannot add yourself as a trusted contact');
        }
        return this.createOrEditTrustedContact({
            name: name !== null && name !== void 0 ? name : '',
            contactUuid: userUuid,
            publicKey,
            signingPublicKey,
        });
    }
    async editTrustedContactFromCollaborationID(contact, params) {
        const { publicKey, signingPublicKey, userUuid } = this.parseCollaborationID(params.collaborationID);
        if (userUuid !== contact.contactUuid) {
            throw new Error("Collaboration ID's user uuid does not match contact UUID");
        }
        const updatedContact = await this.mutator.changeItem(contact, (mutator) => {
            mutator.name = params.name;
            if (publicKey !== contact.publicKeySet.encryption || signingPublicKey !== contact.publicKeySet.signing) {
                mutator.addPublicKey({
                    encryption: publicKey,
                    signing: signingPublicKey,
                });
            }
        });
        await this.sync.sync();
        return updatedContact;
    }
    async updateTrustedContact(contact, params) {
        const updatedContact = await this._editContact.execute(contact, params);
        void this.sync.sync();
        return updatedContact;
    }
    async createOrEditTrustedContact(params) {
        const contact = await this._createOrEditContact.execute(params);
        void this.sync.sync();
        return contact;
    }
    async deleteContact(contact) {
        return this._deleteContact.execute({ contact, ownUserUuid: this.session.userUuid });
    }
    getAllContacts() {
        return this._getAllContacts.execute().getValue();
    }
    findContact(userUuid) {
        const result = this._findContact.execute({ userUuid });
        if (result.isFailed()) {
            return undefined;
        }
        return result.getValue();
    }
    findContactForServerUser(user) {
        return this.findContact(user.user_uuid);
    }
    findContactForInvite(invite) {
        return this.findContact(invite.user_uuid);
    }
    getCollaborationIDForTrustedContact(contact) {
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: contact.content.contactUuid,
            publicKey: contact.content.publicKeySet.encryption,
            signingPublicKey: contact.content.publicKeySet.signing,
        });
    }
    getItemSignatureStatus(item) {
        return this._validateItemSigner.execute(item);
    }
}
exports.ContactService = ContactService;
