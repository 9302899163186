"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootKeyManager = void 0;
const common_1 = require("@standardnotes/common");
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const StorageKeys_1 = require("../Storage/StorageKeys");
const StorageTypes_1 = require("../Storage/StorageTypes");
const EncryptPayload_1 = require("../Encryption/UseCase/TypeA/EncryptPayload");
const DecryptPayload_1 = require("../Encryption/UseCase/TypeA/DecryptPayload");
const AbstractService_1 = require("../Service/AbstractService");
const RootKeyManagerEvent_1 = require("./RootKeyManagerEvent");
const KeyMode_1 = require("./KeyMode");
class RootKeyManager extends AbstractService_1.AbstractService {
    constructor(device, storage, operators, identifier, _reencryptTypeAItems, eventBus) {
        super(eventBus);
        this.device = device;
        this.storage = storage;
        this.operators = operators;
        this.identifier = identifier;
        this._reencryptTypeAItems = _reencryptTypeAItems;
        this.keyMode = KeyMode_1.KeyMode.RootKeyNone;
    }
    deinit() {
        super.deinit();
        this.device = undefined;
        this.storage = undefined;
        this.operators = undefined;
        this.identifier = undefined;
        this._reencryptTypeAItems = undefined;
        this.rootKey = undefined;
        this.memoizedRootKeyParams = undefined;
    }
    async initialize() {
        const wrappedRootKey = this.getWrappedRootKey();
        const accountKeyParams = this.recomputeAccountKeyParams();
        const hasWrapper = await this.hasRootKeyWrapper();
        const hasRootKey = wrappedRootKey != undefined || accountKeyParams != undefined;
        if (hasWrapper && hasRootKey) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyPlusWrapper;
        }
        else if (hasWrapper && !hasRootKey) {
            this.keyMode = KeyMode_1.KeyMode.WrapperOnly;
        }
        else if (!hasWrapper && hasRootKey) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyOnly;
        }
        else if (!hasWrapper && !hasRootKey) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyNone;
        }
        else {
            throw 'Invalid key mode condition';
        }
        if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly) {
            this.setRootKeyInstance(await this.getRootKeyFromKeychain());
            await this.handleKeyStatusChange();
        }
    }
    getMemoizedRootKeyParams() {
        return this.memoizedRootKeyParams;
    }
    getKeyMode() {
        return this.keyMode;
    }
    async hasRootKeyWrapper() {
        const wrapper = this.getRootKeyWrapperKeyParams();
        return wrapper != undefined;
    }
    getRootKeyWrapperKeyParams() {
        const rawKeyParams = this.storage.getValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (!rawKeyParams) {
            return undefined;
        }
        return (0, encryption_1.CreateAnyKeyParams)(rawKeyParams);
    }
    async passcodeUpgradeAvailable() {
        const passcodeParams = this.getRootKeyWrapperKeyParams();
        if (!passcodeParams) {
            return false;
        }
        return passcodeParams.version !== common_1.ProtocolVersionLatest;
    }
    hasAccount() {
        switch (this.keyMode) {
            case KeyMode_1.KeyMode.RootKeyNone:
            case KeyMode_1.KeyMode.WrapperOnly:
                return false;
            case KeyMode_1.KeyMode.RootKeyOnly:
            case KeyMode_1.KeyMode.RootKeyPlusWrapper:
                return true;
            default:
                throw Error('Unhandled keyMode value.');
        }
    }
    getUserVersion() {
        const keyParams = this.memoizedRootKeyParams;
        return keyParams === null || keyParams === void 0 ? void 0 : keyParams.version;
    }
    hasRootKeyEncryptionSource() {
        return this.hasAccount() || this.hasPasscode();
    }
    async computeRootKey(password, keyParams) {
        const version = keyParams.version;
        const operator = this.operators.operatorForVersion(version);
        return operator.computeRootKey(password, keyParams);
    }
    /**
     * Deletes root key and wrapper from keychain. Used when signing out of application.
     */
    async deleteWorkspaceSpecificKeyStateFromDevice() {
        await this.device.clearNamespacedKeychainValue(this.identifier);
        await this.storage.removeValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storage.removeValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storage.removeValue(StorageKeys_1.StorageKey.RootKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        this.keyMode = KeyMode_1.KeyMode.RootKeyNone;
        this.setRootKeyInstance(undefined);
        await this.handleKeyStatusChange();
    }
    async createRootKey(identifier, password, origination, version) {
        const operator = version ? this.operators.operatorForVersion(version) : this.operators.defaultOperator();
        return operator.createRootKey(identifier, password, origination);
    }
    async validateAccountPassword(password) {
        const key = await this.computeRootKey(password, this.memoizedRootKeyParams);
        const valid = this.getSureRootKey().compare(key);
        if (valid) {
            return { valid, artifacts: { rootKey: key } };
        }
        else {
            return { valid: false };
        }
    }
    async validatePasscode(passcode) {
        const keyParams = this.getSureRootKeyWrapperKeyParams();
        const key = await this.computeRootKey(passcode, keyParams);
        const valid = await this.validateWrappingKey(key);
        if (valid) {
            return { valid, artifacts: { wrappingKey: key } };
        }
        else {
            return { valid: false };
        }
    }
    async getEncryptionSourceVersion() {
        if (this.hasAccount()) {
            return this.getSureUserVersion();
        }
        else if (this.hasPasscode()) {
            const passcodeParams = this.getSureRootKeyWrapperKeyParams();
            return passcodeParams.version;
        }
        throw Error('Attempting to access encryption source version without source');
    }
    getSureUserVersion() {
        const keyParams = this.memoizedRootKeyParams;
        return keyParams.version;
    }
    async handleKeyStatusChange() {
        this.recomputeAccountKeyParams();
        void this.notifyEvent(RootKeyManagerEvent_1.RootKeyManagerEvent.RootKeyManagerKeyStatusChanged);
    }
    hasPasscode() {
        return this.keyMode === KeyMode_1.KeyMode.WrapperOnly || this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper;
    }
    recomputeAccountKeyParams() {
        const rawKeyParams = this.storage.getValue(StorageKeys_1.StorageKey.RootKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (!rawKeyParams) {
            return;
        }
        this.memoizedRootKeyParams = (0, encryption_1.CreateAnyKeyParams)(rawKeyParams);
        return this.memoizedRootKeyParams;
    }
    getSureRootKeyWrapperKeyParams() {
        return this.getRootKeyWrapperKeyParams();
    }
    /**
     * Wraps the current in-memory root key value using the wrappingKey,
     * then persists the wrapped value to disk.
     */
    async wrapAndPersistRootKey(wrappingKey) {
        const rootKey = this.getSureRootKey();
        const value = {
            ...rootKey.payload.ejected(),
            content: (0, models_1.FillItemContentSpecialized)(rootKey.persistableValueWhenWrapping()),
        };
        const payload = new models_1.DecryptedPayload(value);
        const usecase = new EncryptPayload_1.EncryptTypeAPayload(this.operators);
        const wrappedKey = await usecase.executeOne(payload, wrappingKey);
        const wrappedKeyPayload = new models_1.EncryptedPayload({
            ...payload.ejected(),
            ...wrappedKey,
            errorDecrypting: false,
            waitingForKey: false,
        });
        this.storage.setValue(StorageKeys_1.StorageKey.WrappedRootKey, wrappedKeyPayload.ejected(), StorageTypes_1.StorageValueModes.Nonwrapped);
    }
    async unwrapRootKey(wrappingKey) {
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
            this.setRootKeyInstance(wrappingKey);
            return;
        }
        if (this.keyMode !== KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            throw 'Invalid key mode condition for unwrapping.';
        }
        const wrappedKey = this.getWrappedRootKey();
        const payload = new models_1.EncryptedPayload(wrappedKey);
        const usecase = new DecryptPayload_1.DecryptTypeAPayload(this.operators);
        const decrypted = await usecase.executeOne(payload, wrappingKey);
        if ((0, encryption_1.isErrorDecryptingParameters)(decrypted)) {
            throw Error('Unable to decrypt root key with provided wrapping key.');
        }
        else {
            const decryptedPayload = new models_1.DecryptedPayload({
                ...payload.ejected(),
                ...decrypted,
            });
            this.setRootKeyInstance(new encryption_1.SNRootKey(decryptedPayload));
            await this.handleKeyStatusChange();
        }
    }
    /**
     * Encrypts rootKey and saves it in storage instead of keychain, and then
     * clears keychain. This is because we don't want to store large encrypted
     * payloads in the keychain. If the root key is not wrapped, it is stored
     * in plain form in the user's secure keychain.
     */
    async setNewRootKeyWrapper(wrappingKey) {
        if (this.keyMode === KeyMode_1.KeyMode.RootKeyNone) {
            this.keyMode = KeyMode_1.KeyMode.WrapperOnly;
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyPlusWrapper;
        }
        else {
            throw Error('Attempting to set wrapper on already wrapped key.');
        }
        await this.device.clearNamespacedKeychainValue(this.identifier);
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly || this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
                this.setRootKeyInstance(wrappingKey);
                await this._reencryptTypeAItems.execute();
            }
            else {
                await this.wrapAndPersistRootKey(wrappingKey);
            }
            this.storage.setValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, wrappingKey.keyParams.getPortableValue(), StorageTypes_1.StorageValueModes.Nonwrapped);
            await this.handleKeyStatusChange();
        }
        else {
            throw Error('Invalid keyMode on setNewRootKeyWrapper');
        }
    }
    /**
     * Removes root key wrapper from local storage and stores root key bare in secure keychain.
     */
    async removeRootKeyWrapper() {
        if (this.keyMode !== KeyMode_1.KeyMode.WrapperOnly && this.keyMode !== KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            throw Error('Attempting to remove root key wrapper on unwrapped key.');
        }
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyNone;
            this.setRootKeyInstance(undefined);
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyOnly;
        }
        await this.storage.removeValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storage.removeValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly) {
            await this.saveRootKeyToKeychain();
        }
        await this.handleKeyStatusChange();
    }
    async setRootKey(key, wrappingKey) {
        if (!key.keyParams) {
            throw Error('keyParams must be supplied if setting root key.');
        }
        if (this.getRootKey() === key) {
            throw Error('Attempting to set root key as same current value.');
        }
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyPlusWrapper;
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyNone) {
            this.keyMode = KeyMode_1.KeyMode.RootKeyOnly;
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly || this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            /** Root key is simply changing, mode stays the same */
            /** this.keyMode = this.keyMode; */
        }
        else {
            throw Error('Unhandled key mode for setNewRootKey');
        }
        this.setRootKeyInstance(key);
        this.storage.setValue(StorageKeys_1.StorageKey.RootKeyParams, key.keyParams.getPortableValue(), StorageTypes_1.StorageValueModes.Nonwrapped);
        if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly) {
            await this.saveRootKeyToKeychain();
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            if (!wrappingKey) {
                throw Error('wrappingKey must be supplied');
            }
            await this.wrapAndPersistRootKey(wrappingKey);
        }
        await this.handleKeyStatusChange();
    }
    getRootKeyParams() {
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
            return this.getRootKeyWrapperKeyParams();
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly || this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            return this.recomputeAccountKeyParams();
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyNone) {
            return undefined;
        }
        else {
            throw 'Unhandled key mode for getRootKeyParams';
        }
    }
    getSureRootKeyParams() {
        return this.getRootKeyParams();
    }
    async saveRootKeyToKeychain() {
        if (this.getRootKey() == undefined) {
            throw 'Attempting to non-existent root key to the keychain.';
        }
        if (this.keyMode !== KeyMode_1.KeyMode.RootKeyOnly) {
            throw 'Should not be persisting wrapped key to keychain.';
        }
        const rawKey = this.getSureRootKey().getKeychainValue();
        return this.executeCriticalFunction(() => {
            return this.device.setNamespacedKeychainValue(rawKey, this.identifier);
        });
    }
    /**
     * We know a wrappingKey is correct if it correctly decrypts
     * wrapped root key.
     */
    async validateWrappingKey(wrappingKey) {
        const wrappedRootKey = this.getWrappedRootKey();
        /** If wrapper only, storage is encrypted directly with wrappingKey */
        if (this.keyMode === KeyMode_1.KeyMode.WrapperOnly) {
            return this.storage.canDecryptWithKey(wrappingKey);
        }
        else if (this.keyMode === KeyMode_1.KeyMode.RootKeyOnly || this.keyMode === KeyMode_1.KeyMode.RootKeyPlusWrapper) {
            /**
             * In these modes, storage is encrypted with account keys, and
             * account keys are encrypted with wrappingKey. Here we validate
             * by attempting to decrypt account keys.
             */
            const wrappedKeyPayload = new models_1.EncryptedPayload(wrappedRootKey);
            const usecase = new DecryptPayload_1.DecryptTypeAPayload(this.operators);
            const decrypted = await usecase.executeOne(wrappedKeyPayload, wrappingKey);
            return !(0, encryption_1.isErrorDecryptingParameters)(decrypted);
        }
        else {
            throw 'Unhandled case in validateWrappingKey';
        }
    }
    getWrappedRootKey() {
        return this.storage.getValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
    }
    setRootKeyInstance(rootKey) {
        this.rootKey = rootKey;
    }
    getRootKey() {
        return this.rootKey;
    }
    getSureRootKey() {
        return this.rootKey;
    }
    async getRootKeyFromKeychain() {
        const rawKey = (await this.device.getNamespacedKeychainValue(this.identifier));
        if (rawKey == undefined) {
            return undefined;
        }
        const keyParams = this.getSureRootKeyParams();
        return (0, encryption_1.CreateNewRootKey)({
            ...rawKey,
            keyParams: keyParams.getPortableValue(),
        });
    }
}
exports.RootKeyManager = RootKeyManager;
