"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const Portal = ({ children, disabled = false }) => {
    const [container, setContainer] = (0, react_1.useState)();
    const id = 'portal/' + (0, react_1.useId)();
    (0, react_1.useEffect)(() => {
        const container = document.createElement('div');
        container.id = id;
        document.body.append(container);
        setContainer(container);
        return () => container.remove();
    }, [id]);
    if (disabled) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    return container ? (0, react_dom_1.createPortal)(children, container) : null;
};
exports.default = Portal;
