"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeySystemItemsKey = exports.isKeySystemItemsKey = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
function isKeySystemItemsKey(x) {
    return x.content_type === domain_core_1.ContentType.TYPES.KeySystemItemsKey;
}
exports.isKeySystemItemsKey = isKeySystemItemsKey;
/**
 * A key used to encrypt other items. Items keys are synced and persisted.
 */
class KeySystemItemsKey extends models_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.creationTimestamp = payload.content.creationTimestamp;
        this.keyVersion = payload.content.version;
        this.itemsKey = this.payload.content.itemsKey;
        this.rootKeyToken = this.payload.content.rootKeyToken;
    }
    /** Do not duplicate vault items keys. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return models_1.ConflictStrategy.KeepBase;
    }
}
exports.KeySystemItemsKey = KeySystemItemsKey;
