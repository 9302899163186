"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorMargins = exports.EditorMaxWidths = void 0;
const snjs_1 = require("@standardnotes/snjs");
exports.EditorMaxWidths = {
    [snjs_1.EditorLineWidth.Narrow]: '512px',
    [snjs_1.EditorLineWidth.Wide]: '720px',
    [snjs_1.EditorLineWidth.Dynamic]: '80%',
    [snjs_1.EditorLineWidth.FullWidth]: '100%',
};
exports.EditorMargins = {
    [snjs_1.EditorLineWidth.Narrow]: 'auto',
    [snjs_1.EditorLineWidth.Wide]: 'auto',
    [snjs_1.EditorLineWidth.Dynamic]: '10%',
    [snjs_1.EditorLineWidth.FullWidth]: '0',
};
