"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIconAndTintForNoteType = void 0;
const Constants_1 = require("@/Constants/Constants");
const features_1 = require("@standardnotes/features");
function getIconAndTintForNoteType(noteType, subtle) {
    switch (noteType) {
        case undefined:
        case features_1.NoteType.Plain:
            return ['plain-text', 1];
        case features_1.NoteType.RichText:
            return ['rich-text', 1];
        case features_1.NoteType.Markdown:
            return ['markdown', 2];
        case features_1.NoteType.Authentication:
            return ['authenticator', 6];
        case features_1.NoteType.Spreadsheet:
            return ['spreadsheets', 5];
        case features_1.NoteType.Task:
            return ['tasks', 3];
        case features_1.NoteType.Code:
            return ['code', 4];
        case features_1.NoteType.Super:
            return [
                subtle ? Constants_1.SuperEditorMetadata.subtleIcon : Constants_1.SuperEditorMetadata.icon,
                Constants_1.SuperEditorMetadata.iconTintNumber,
            ];
        case features_1.NoteType.Unknown:
        default:
            return ['editor', 1];
    }
}
exports.getIconAndTintForNoteType = getIconAndTintForNoteType;
