"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDefaultMonospaceFont = void 0;
const snjs_1 = require("@standardnotes/snjs");
const setDefaultMonospaceFont = (platform) => {
    if (platform === snjs_1.Platform.Android) {
        document.documentElement.style.setProperty('--sn-stylekit-monospace-font', '"Roboto Mono", "Droid Sans Mono", monospace');
    }
};
exports.setDefaultMonospaceFont = setDefaultMonospaceFont;
