"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.padStart = exports.truncateOTP = exports.base32Decode = exports.base32Encode = exports.base64Decode = exports.base64URLEncode = exports.base64Encode = exports.base64ToHex = exports.hexToBase64 = exports.arrayBufferToBase64 = exports.base64ToArrayBuffer = exports.hexStringToArrayBuffer = exports.arrayBufferToHexString = exports.arrayBufferToString = exports.stringToArrayBuffer = exports.generateUUID = exports.getSubtleCrypto = exports.isWebCryptoAvailable = exports.ieOrEdge = exports.getGlobalScope = void 0;
/* eslint-disable camelcase */
const libsodium_1 = require("./libsodium");
const buffer_1 = require("buffer");
const uuid_1 = require("uuid");
const SN_BASE64_VARIANT = libsodium_1.base64_variants.ORIGINAL;
/**
 * Returns `window` if available, or `global` if supported in environment.
 */
function getGlobalScope() {
    return window;
}
exports.getGlobalScope = getGlobalScope;
/**
 * Determines whether we are in an Internet Explorer or Edge environment
 * @access public
 */
function ieOrEdge() {
    return (typeof document !== 'undefined' && !!document.documentMode) || /Edge/.test(navigator.userAgent);
}
exports.ieOrEdge = ieOrEdge;
/**
 * Returns true if WebCrypto is available
 * @access public
 */
function isWebCryptoAvailable() {
    return !ieOrEdge() && getGlobalScope().crypto && !!getGlobalScope().crypto.subtle;
}
exports.isWebCryptoAvailable = isWebCryptoAvailable;
/**
 * Returns the WebCrypto instance
 * @access public
 */
function getSubtleCrypto() {
    if (!getGlobalScope().crypto) {
        throw Error('Could not obtain SubtleCrypto instance');
    }
    return getGlobalScope().crypto.subtle;
}
exports.getSubtleCrypto = getSubtleCrypto;
/**
 * Generates a UUID syncronously
 * @access public
 */
function generateUUID() {
    return (0, uuid_1.v4)();
}
exports.generateUUID = generateUUID;
/**
 * Converts a plain string into an ArrayBuffer
 * @param {string} string - A plain string
 */
function stringToArrayBuffer(string) {
    return (0, libsodium_1.from_string)(string);
}
exports.stringToArrayBuffer = stringToArrayBuffer;
/**
 * Converts an ArrayBuffer into a plain string
 * @param {ArrayBuffer} arrayBuffer
 */
function arrayBufferToString(arrayBuffer) {
    return (0, libsodium_1.to_string)(arrayBuffer);
}
exports.arrayBufferToString = arrayBufferToString;
/**
 * Converts an ArrayBuffer into a hex string
 * @param arrayBuffer
 */
function arrayBufferToHexString(arrayBuffer) {
    return (0, libsodium_1.to_hex)(buffer_1.Buffer.from(arrayBuffer));
}
exports.arrayBufferToHexString = arrayBufferToHexString;
/**
 * Converts a hex string into an ArrayBuffer
 * @access public
 * @param hex - A hex string
 */
function hexStringToArrayBuffer(hex) {
    return (0, libsodium_1.from_hex)(hex);
}
exports.hexStringToArrayBuffer = hexStringToArrayBuffer;
/**
 * Converts a base64 string into an ArrayBuffer
 * @param base64 - A base64 string
 */
function base64ToArrayBuffer(base64) {
    return (0, libsodium_1.from_base64)(base64, SN_BASE64_VARIANT);
}
exports.base64ToArrayBuffer = base64ToArrayBuffer;
/**
 * Converts an ArrayBuffer into a base64 string
 * @param buffer
 */
function arrayBufferToBase64(arrayBuffer) {
    return (0, libsodium_1.to_base64)(buffer_1.Buffer.from(arrayBuffer), SN_BASE64_VARIANT);
}
exports.arrayBufferToBase64 = arrayBufferToBase64;
/**
 * Converts a hex string into a base64 string
 * @param hex - A hex string
 */
function hexToBase64(hex) {
    return (0, libsodium_1.to_base64)((0, libsodium_1.from_hex)(hex), SN_BASE64_VARIANT);
}
exports.hexToBase64 = hexToBase64;
/**
 * Converts a base64 string into a hex string
 * @param base64 - A base64 string
 */
function base64ToHex(base64) {
    return (0, libsodium_1.to_hex)((0, libsodium_1.from_base64)(base64, SN_BASE64_VARIANT));
}
exports.base64ToHex = base64ToHex;
/**
 * Converts a plain string into base64
 * @param text - A plain string
 * @returns  A base64 encoded string
 */
function base64Encode(text) {
    return (0, libsodium_1.to_base64)(text, SN_BASE64_VARIANT);
}
exports.base64Encode = base64Encode;
/**
 * Converts a plain string into base64 url safe
 * @param text - A plain string
 * @returns  A base64 url safe encoded string
 */
function base64URLEncode(text) {
    return (0, libsodium_1.to_base64)(text, libsodium_1.base64_variants.URLSAFE_NO_PADDING);
}
exports.base64URLEncode = base64URLEncode;
/**
 * Converts a base64 string into a plain string
 * @param base64String - A base64 encoded string
 * @returns A plain string
 */
function base64Decode(base64String) {
    return (0, libsodium_1.to_string)((0, libsodium_1.from_base64)(base64String, SN_BASE64_VARIANT));
}
exports.base64Decode = base64Decode;
const RFC4648 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
function base32Encode(input) {
    const length = input.byteLength;
    const buffer = new Uint8Array(input);
    let bitIdx = 0;
    let currentVal = 0;
    let output = '';
    for (let i = 0; i < length; i++) {
        currentVal = (currentVal << 8) | buffer[i];
        bitIdx += 8;
        while (bitIdx >= 5) {
            output += RFC4648[(currentVal >>> (bitIdx - 5)) & 31];
            bitIdx -= 5;
        }
    }
    if (bitIdx > 0) {
        output += RFC4648[(currentVal << (5 - bitIdx)) & 31];
    }
    while (output.length % 8 > 0) {
        output += '=';
    }
    return output;
}
exports.base32Encode = base32Encode;
function base32Decode(b32Input) {
    const input = b32Input.toUpperCase().replace(/=+$/, '');
    for (let i = 0; i < input.length; i++) {
        if (!RFC4648.includes(input[i])) {
            throw new Error(`Invalid RFC4648 char ${input[i]} at index ${i}`);
        }
    }
    const output = new Uint8Array(((input.length * 5) / 8) | 0);
    let outIdx = 0;
    let bitIdx = 0;
    let currentVal = 0;
    for (let i = 0; i < input.length; i++) {
        currentVal = (currentVal << 5) | RFC4648.indexOf(input[i]);
        bitIdx += 5;
        if (bitIdx >= 8) {
            output[outIdx++] = (currentVal >>> (bitIdx - 8)) & 255;
            bitIdx -= 8;
        }
    }
    return output.buffer;
}
exports.base32Decode = base32Decode;
/**
 * Truncate HMAC-SHA1 calculated value for HOTP code generation
 */
function truncateOTP(hsBuffer) {
    const hs = new Uint8Array(hsBuffer);
    // First we take the last byte of our generated HS and extract last 4 bits out of it.
    // This will be our _offset_, a number between 0 and 15.
    const offset = hs[19] & 0b1111;
    // Next we take 4 bytes out of the HS, starting at the offset
    const P = ((hs[offset] & 0x7f) << 24) | (hs[offset + 1] << 16) | (hs[offset + 2] << 8) | hs[offset + 3];
    // Finally, convert it into a binary string representation
    const pString = P.toString(2);
    const Snum = parseInt(pString, 2);
    return Snum;
}
exports.truncateOTP = truncateOTP;
/**
 * Pad HOTP counter with leading zeros producing an 8 byte array
 */
function padStart(counter) {
    const buffer = new ArrayBuffer(8);
    const bView = new DataView(buffer);
    const byteString = '0'.repeat(64);
    const bCounter = (byteString + counter.toString(2)).slice(-64);
    for (let byte = 0; byte < 64; byte += 8) {
        const byteValue = parseInt(bCounter.slice(byte, byte + 8), 2);
        bView.setUint8(byte / 8, byteValue);
    }
    return buffer;
}
exports.padStart = padStart;
