"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncStatusController = void 0;
const mobx_1 = require("mobx");
class SyncStatusController {
    constructor() {
        this.inProgress = false;
        this.errorMessage = undefined;
        this.humanReadablePercentage = undefined;
        this.update = (status) => {
            var _a;
            this.errorMessage = (_a = status.error) === null || _a === void 0 ? void 0 : _a.message;
            this.inProgress = status.syncInProgress;
            const stats = status.getStats();
            const completionPercentage = stats.uploadCompletionCount === 0 ? 0 : stats.uploadCompletionCount / stats.uploadTotalCount;
            if (completionPercentage === 0) {
                this.humanReadablePercentage = undefined;
            }
            else {
                this.humanReadablePercentage = completionPercentage.toLocaleString(undefined, {
                    style: 'percent',
                });
            }
        };
        (0, mobx_1.makeObservable)(this, {
            inProgress: mobx_1.observable,
            errorMessage: mobx_1.observable,
            humanReadablePercentage: mobx_1.observable,
            update: mobx_1.action,
        });
    }
}
exports.SyncStatusController = SyncStatusController;
