"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encryptedInputParametersFromPayload = exports.isErrorDecryptingParameters = void 0;
function isErrorDecryptingParameters(x) {
    return x.errorDecrypting;
}
exports.isErrorDecryptingParameters = isErrorDecryptingParameters;
function encryptedInputParametersFromPayload(payload) {
    return {
        uuid: payload.uuid,
        content: payload.content,
        content_type: payload.content_type,
        items_key_id: payload.items_key_id,
        enc_item_key: payload.enc_item_key,
        version: payload.version,
        auth_hash: payload.auth_hash,
        key_system_identifier: payload.key_system_identifier,
        shared_vault_uuid: payload.shared_vault_uuid,
        signatureData: payload.signatureData,
    };
}
exports.encryptedInputParametersFromPayload = encryptedInputParametersFromPayload;
