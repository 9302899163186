"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCodeBlock = void 0;
const selection_1 = require("@lexical/selection");
const lexical_1 = require("lexical");
const code_1 = require("@lexical/code");
function GetCodeBlock(editor) {
    return {
        name: 'Code',
        iconName: 'code',
        keywords: ['javascript', 'python', 'js', 'codeblock'],
        onSelect: () => editor.update(() => {
            const selection = (0, lexical_1.$getSelection)();
            if ((0, lexical_1.$isRangeSelection)(selection)) {
                if (selection.isCollapsed()) {
                    (0, selection_1.$wrapNodes)(selection, () => (0, code_1.$createCodeNode)());
                }
                else {
                    const textContent = selection.getTextContent();
                    const codeNode = (0, code_1.$createCodeNode)();
                    selection.insertNodes([codeNode]);
                    selection.insertRawText(textContent);
                }
            }
        }),
    };
}
exports.GetCodeBlock = GetCodeBlock;
