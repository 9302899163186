"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComputePrivateUsername = void 0;
const PrivateUserNameV1 = 'StandardNotes-PrivateUsername-V1';
async function ComputePrivateUsername(crypto, usernameInput) {
    const result = await crypto.hmac256(await crypto.sha256(PrivateUserNameV1), await crypto.sha256(usernameInput.trim().toLowerCase()));
    if (result == undefined) {
        return undefined;
    }
    return result;
}
exports.ComputePrivateUsername = ComputePrivateUsername;
