"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendVaultDataChangedMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
const GetReplaceabilityIdentifier_1 = require("../../AsymmetricMessage/UseCase/GetReplaceabilityIdentifier");
class SendVaultDataChangedMessage {
    constructor(users, _encryptMessage, _findContact, _getVaultUsers, _sendMessage, _isVaultOwner, _getKeyPairs) {
        this.users = users;
        this._encryptMessage = _encryptMessage;
        this._findContact = _findContact;
        this._getVaultUsers = _getVaultUsers;
        this._sendMessage = _sendMessage;
        this._isVaultOwner = _isVaultOwner;
        this._getKeyPairs = _getKeyPairs;
    }
    async execute(params) {
        const isOwner = this._isVaultOwner.execute(params.vault).getValue();
        if (!isOwner) {
            return domain_core_1.Result.ok();
        }
        const users = await this._getVaultUsers.execute({
            sharedVaultUuid: params.vault.sharing.sharedVaultUuid,
            readFromCache: false,
        });
        if (users.isFailed()) {
            return domain_core_1.Result.fail('Cannot send metadata changed message; users not found');
        }
        const keys = this._getKeyPairs.execute();
        if (keys.isFailed()) {
            return domain_core_1.Result.fail('Cannot send metadata changed message; keys not found');
        }
        const errors = [];
        for (const user of users.getValue()) {
            if (user.user_uuid === this.users.sureUser.uuid) {
                continue;
            }
            const trustedContact = this._findContact.execute({ userUuid: user.user_uuid });
            if (trustedContact.isFailed()) {
                continue;
            }
            const sendMessageResult = await this.sendToContact({
                vault: params.vault,
                keys: keys.getValue(),
                contact: trustedContact.getValue(),
            });
            if (sendMessageResult.isFailed()) {
                errors.push(sendMessageResult.getError());
            }
        }
        if (errors.length > 0) {
            return domain_core_1.Result.fail(errors.join(', '));
        }
        return domain_core_1.Result.ok();
    }
    async sendToContact(params) {
        const message = {
            type: models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged,
            data: {
                recipientUuid: params.contact.contactUuid,
                sharedVaultUuid: params.vault.sharing.sharedVaultUuid,
                name: params.vault.name,
                description: params.vault.description,
            },
        };
        const encryptedMessage = this._encryptMessage.execute({
            message: message,
            keys: params.keys,
            recipientPublicKey: params.contact.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const replaceabilityIdentifier = (0, GetReplaceabilityIdentifier_1.GetReplaceabilityIdentifier)(models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged, params.vault.sharing.sharedVaultUuid, params.vault.systemIdentifier);
        const sendMessageResult = await this._sendMessage.execute({
            recipientUuid: params.contact.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            replaceabilityIdentifier,
        });
        return sendMessageResult;
    }
}
exports.SendVaultDataChangedMessage = SendVaultDataChangedMessage;
