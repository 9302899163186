"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationStage = void 0;
/* istanbul ignore file */
var ApplicationStage;
(function (ApplicationStage) {
    ApplicationStage[ApplicationStage["PreparingForLaunch_0"] = 0] = "PreparingForLaunch_0";
    ApplicationStage[ApplicationStage["ReadyForLaunch_05"] = 0.5] = "ReadyForLaunch_05";
    ApplicationStage[ApplicationStage["StorageDecrypted_09"] = 0.9] = "StorageDecrypted_09";
    ApplicationStage[ApplicationStage["Launched_10"] = 1] = "Launched_10";
    ApplicationStage[ApplicationStage["LoadingDatabase_11"] = 1.1] = "LoadingDatabase_11";
    ApplicationStage[ApplicationStage["LoadedDatabase_12"] = 1.2] = "LoadedDatabase_12";
    ApplicationStage[ApplicationStage["FullSyncCompleted_13"] = 1.3] = "FullSyncCompleted_13";
    ApplicationStage[ApplicationStage["SignedIn_30"] = 3] = "SignedIn_30";
})(ApplicationStage || (exports.ApplicationStage = ApplicationStage = {}));
