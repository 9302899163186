"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyKeys1_0_0 = exports.namespacedKey = exports.NonwrappedStorageKey = exports.StorageKey = exports.RawStorageKey = void 0;
/**
 * Unmanaged keys stored in root storage.
 * Raw storage keys exist outside of StorageManager domain
 */
var RawStorageKey;
(function (RawStorageKey) {
    RawStorageKey["StorageObject"] = "storage";
    RawStorageKey["DescriptorRecord"] = "descriptors";
    RawStorageKey["SnjsVersion"] = "snjs_version";
    RawStorageKey["HomeServerEnabled"] = "home_server_enabled";
    RawStorageKey["HomeServerDataLocation"] = "home_serve_data_location";
})(RawStorageKey || (exports.RawStorageKey = RawStorageKey = {}));
/**
 * Keys used for retrieving and saving simple key/value pairs.
 * These keys are managed and are embedded inside RawStorageKey.StorageObject
 */
var StorageKey;
(function (StorageKey) {
    StorageKey["RootKeyParams"] = "ROOT_KEY_PARAMS";
    StorageKey["WrappedRootKey"] = "WRAPPED_ROOT_KEY";
    StorageKey["RootKeyWrapperKeyParams"] = "ROOT_KEY_WRAPPER_KEY_PARAMS";
    StorageKey["Session"] = "session";
    StorageKey["User"] = "user";
    StorageKey["ServerHost"] = "server";
    StorageKey["LegacyUuid"] = "uuid";
    StorageKey["LastSyncToken"] = "syncToken";
    StorageKey["PaginationToken"] = "cursorToken";
    StorageKey["BiometricsState"] = "biometrics_state";
    StorageKey["MobilePasscodeTiming"] = "passcode_timing";
    StorageKey["MobileBiometricsTiming"] = "biometrics_timing";
    StorageKey["MobilePasscodeKeyboardType"] = "passcodeKeyboardType";
    StorageKey["MobilePreferences"] = "preferences";
    StorageKey["MobileScreenshotPrivacyEnabled"] = "screenshotPrivacy_enabled";
    StorageKey["ProtectionExpirey"] = "SessionExpiresAtKey";
    StorageKey["ProtectionSessionLength"] = "SessionLengthKey";
    StorageKey["KeyRecoveryUndecryptableItems"] = "key_recovery_undecryptable";
    StorageKey["WebSocketUrl"] = "webSocket_url";
    StorageKey["UserRoles"] = "user_roles";
    StorageKey["OfflineUserRoles"] = "offline_user_roles";
    StorageKey["ExperimentalFeatures"] = "experimental_features";
    StorageKey["DeinitMode"] = "deinit_mode";
    StorageKey["CodeVerifier"] = "code_verifier";
    StorageKey["LaunchPriorityUuids"] = "launch_priority_uuids";
    StorageKey["LastReadChangelogVersion"] = "last_read_changelog_version";
    StorageKey["MomentsEnabled"] = "moments_enabled";
    StorageKey["TextBackupsEnabled"] = "text_backups_enabled";
    StorageKey["TextBackupsLocation"] = "text_backups_location";
    StorageKey["PlaintextBackupsEnabled"] = "plaintext_backups_enabled";
    StorageKey["PlaintextBackupsLocation"] = "plaintext_backups_location";
    StorageKey["FileBackupsEnabled"] = "file_backups_enabled";
    StorageKey["FileBackupsLocation"] = "file_backups_location";
    StorageKey["VaultSelectionOptions"] = "vault_selection_options";
    StorageKey["Subscription"] = "subscription";
})(StorageKey || (exports.StorageKey = StorageKey = {}));
var NonwrappedStorageKey;
(function (NonwrappedStorageKey) {
    NonwrappedStorageKey["MobileFirstRun"] = "first_run";
})(NonwrappedStorageKey || (exports.NonwrappedStorageKey = NonwrappedStorageKey = {}));
function namespacedKey(namespace, key) {
    return `${namespace}-${key}`;
}
exports.namespacedKey = namespacedKey;
exports.LegacyKeys1_0_0 = {
    WebPasscodeParamsKey: 'offlineParams',
    MobilePasscodeParamsKey: 'pc_params',
    AllAccountKeyParamsKey: 'auth_params',
    WebEncryptedStorageKey: 'encryptedStorage',
    MobileWrappedRootKeyKey: 'encrypted_account_keys',
    MobileBiometricsPrefs: 'biometrics_prefs',
    AllMigrations: 'migrations',
    MobileThemesCache: 'ThemePreferencesKey',
    MobileLightTheme: 'lightTheme',
    MobileDarkTheme: 'darkTheme',
    MobileLastExportDate: 'LastExportDateKey',
    MobileDoNotWarnUnsupportedEditors: 'DoNotShowAgainUnsupportedEditorsKey',
    MobileOptionsState: 'options',
    MobilePasscodeKeyboardType: 'passcodeKeyboardType',
};
