"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParagraphCount = void 0;
const getParagraphCount = (text) => {
    if (text.trim().length === 0) {
        return 0;
    }
    return text.replace(/\n$/gm, '').split(/\n/).length;
};
exports.getParagraphCount = getParagraphCount;
