"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEditorMenuGroups = void 0;
const snjs_1 = require("@standardnotes/snjs");
const Constants_1 = require("@/Constants/Constants");
const insertNativeEditorsInMap = (map, application) => {
    for (const editorFeature of (0, snjs_1.GetIframeAndNativeEditors)()) {
        const isExperimental = application.features.isExperimentalFeature(editorFeature.identifier);
        if (isExperimental) {
            continue;
        }
        const isDeprecated = editorFeature.deprecated;
        if (isDeprecated) {
            continue;
        }
        const noteType = editorFeature.note_type;
        map[noteType].push({
            isEntitled: application.features.getFeatureStatus(snjs_1.NativeFeatureIdentifier.create(editorFeature.identifier).getValue()) ===
                snjs_1.FeatureStatus.Entitled,
            uiFeature: new snjs_1.UIFeature(editorFeature),
        });
    }
};
const insertInstalledComponentsInMap = (map, application) => {
    const thirdPartyOrInstalledEditors = application.componentManager
        .thirdPartyComponentsForArea(snjs_1.ComponentArea.Editor)
        .sort((a, b) => {
        return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
    });
    for (const editor of thirdPartyOrInstalledEditors) {
        const nativeFeature = (0, snjs_1.FindNativeFeature)(editor.identifier);
        if (nativeFeature && !nativeFeature.deprecated) {
            continue;
        }
        const noteType = editor.noteType;
        const editorItem = {
            uiFeature: new snjs_1.UIFeature(editor),
            isEntitled: application.features.getFeatureStatus(snjs_1.Uuid.create(editor.uuid).getValue()) === snjs_1.FeatureStatus.Entitled,
        };
        map[noteType].push(editorItem);
    }
};
const createGroupsFromMap = (map) => {
    const superNote = (0, snjs_1.GetSuperNoteFeature)();
    const groups = [
        {
            icon: 'plain-text',
            iconClassName: 'text-accessory-tint-1',
            title: 'Plain text',
            items: map[snjs_1.NoteType.Plain],
        },
        {
            icon: Constants_1.SuperEditorMetadata.icon,
            iconClassName: Constants_1.SuperEditorMetadata.iconClassName,
            title: superNote.name,
            items: map[snjs_1.NoteType.Super],
            featured: true,
        },
        {
            icon: 'rich-text',
            iconClassName: 'text-accessory-tint-1',
            title: 'Rich text',
            items: map[snjs_1.NoteType.RichText],
        },
        {
            icon: 'markdown',
            iconClassName: 'text-accessory-tint-2',
            title: 'Markdown text',
            items: map[snjs_1.NoteType.Markdown],
        },
        {
            icon: 'tasks',
            iconClassName: 'text-accessory-tint-3',
            title: 'Todo',
            items: map[snjs_1.NoteType.Task],
        },
        {
            icon: 'code',
            iconClassName: 'text-accessory-tint-4',
            title: 'Code',
            items: map[snjs_1.NoteType.Code],
        },
        {
            icon: 'spreadsheets',
            iconClassName: 'text-accessory-tint-5',
            title: 'Spreadsheet',
            items: map[snjs_1.NoteType.Spreadsheet],
        },
        {
            icon: 'authenticator',
            iconClassName: 'text-accessory-tint-6',
            title: 'Authentication',
            items: map[snjs_1.NoteType.Authentication],
        },
        {
            icon: 'editor',
            iconClassName: 'text-neutral',
            title: 'Others',
            items: map[snjs_1.NoteType.Unknown],
        },
    ];
    return groups;
};
const createBaselineMap = () => {
    const map = {
        [snjs_1.NoteType.Plain]: [],
        [snjs_1.NoteType.Super]: [],
        [snjs_1.NoteType.RichText]: [],
        [snjs_1.NoteType.Markdown]: [],
        [snjs_1.NoteType.Task]: [],
        [snjs_1.NoteType.Code]: [],
        [snjs_1.NoteType.Spreadsheet]: [],
        [snjs_1.NoteType.Authentication]: [],
        [snjs_1.NoteType.Unknown]: [],
    };
    return map;
};
const createEditorMenuGroups = (application) => {
    const map = createBaselineMap();
    insertNativeEditorsInMap(map, application);
    insertInstalledComponentsInMap(map, application);
    return createGroupsFromMap(map);
};
exports.createEditorMenuGroups = createEditorMenuGroups;
