"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeaturesController = void 0;
const PremiumFeatureModalType_1 = require("@/Components/PremiumFeaturesModal/PremiumFeatureModalType");
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const CrossControllerEvent_1 = require("./CrossControllerEvent");
class FeaturesController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.showPremiumAlert = undefined;
        this.closePremiumAlert = undefined;
        this.hasFolders = undefined;
        this.hasSmartViews = undefined;
        this.entitledToFiles = undefined;
        this.premiumAlertFeatureName = undefined;
        this.premiumAlertType = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(features, eventBus) {
        super(eventBus);
        this.features = features;
        this.premiumAlertType = undefined;
        this.showPurchaseSuccessAlert = () => {
            this.premiumAlertType = PremiumFeatureModalType_1.PremiumFeatureModalType.UpgradeSuccess;
        };
        this.hasFolders = this.isEntitledToFolders();
        this.hasSmartViews = this.isEntitledToSmartViews();
        this.entitledToFiles = this.isEntitledToFiles();
        this.premiumAlertFeatureName = undefined;
        (0, mobx_1.makeObservable)(this, {
            hasFolders: mobx_1.observable,
            hasSmartViews: mobx_1.observable,
            entitledToFiles: mobx_1.observable,
            premiumAlertType: mobx_1.observable,
            premiumAlertFeatureName: mobx_1.observable,
            showPremiumAlert: mobx_1.action,
            closePremiumAlert: mobx_1.action,
            showPurchaseSuccessAlert: mobx_1.action,
        });
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.DidPurchaseSubscription);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.FeaturesAvailabilityChanged);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.Launched);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.LocalDataLoaded);
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.UserRolesChanged);
        this.showPremiumAlert = this.showPremiumAlert.bind(this);
        this.closePremiumAlert = this.closePremiumAlert.bind(this);
    }
    async handleEvent(event) {
        switch (event.type) {
            case snjs_1.ApplicationEvent.DidPurchaseSubscription:
                this.showPurchaseSuccessAlert();
                break;
            case snjs_1.ApplicationEvent.FeaturesAvailabilityChanged:
            case snjs_1.ApplicationEvent.Launched:
            case snjs_1.ApplicationEvent.LocalDataLoaded:
            case snjs_1.ApplicationEvent.UserRolesChanged:
                (0, mobx_1.runInAction)(() => {
                    this.hasFolders = this.isEntitledToFolders();
                    this.hasSmartViews = this.isEntitledToSmartViews();
                    this.entitledToFiles = this.isEntitledToFiles();
                });
                break;
            case CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal:
                {
                    const payload = event.payload;
                    void this.showPremiumAlert(payload.featureName);
                }
                break;
        }
    }
    async showPremiumAlert(featureName) {
        this.premiumAlertFeatureName = featureName;
        this.premiumAlertType = PremiumFeatureModalType_1.PremiumFeatureModalType.UpgradePrompt;
        return (0, mobx_1.when)(() => this.premiumAlertType === undefined);
    }
    closePremiumAlert() {
        this.premiumAlertType = undefined;
    }
    isEntitledToFiles() {
        const status = this.features.getFeatureStatus(snjs_1.NativeFeatureIdentifier.create(snjs_1.NativeFeatureIdentifier.TYPES.Files).getValue());
        return status === snjs_1.FeatureStatus.Entitled;
    }
    isEntitledToFolders() {
        const status = this.features.getFeatureStatus(snjs_1.NativeFeatureIdentifier.create(snjs_1.NativeFeatureIdentifier.TYPES.TagNesting).getValue());
        return status === snjs_1.FeatureStatus.Entitled;
    }
    isEntitledToSmartViews() {
        const status = this.features.getFeatureStatus(snjs_1.NativeFeatureIdentifier.create(snjs_1.NativeFeatureIdentifier.TYPES.SmartFilters).getValue());
        return status === snjs_1.FeatureStatus.Entitled;
    }
}
exports.FeaturesController = FeaturesController;
