"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountMenuPane = void 0;
var AccountMenuPane;
(function (AccountMenuPane) {
    AccountMenuPane[AccountMenuPane["GeneralMenu"] = 0] = "GeneralMenu";
    AccountMenuPane[AccountMenuPane["SignIn"] = 1] = "SignIn";
    AccountMenuPane[AccountMenuPane["Register"] = 2] = "Register";
    AccountMenuPane[AccountMenuPane["ConfirmPassword"] = 3] = "ConfirmPassword";
})(AccountMenuPane || (exports.AccountMenuPane = AccountMenuPane = {}));
