"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevisionContentState = void 0;
var RevisionContentState;
(function (RevisionContentState) {
    RevisionContentState[RevisionContentState["Idle"] = 0] = "Idle";
    RevisionContentState[RevisionContentState["Loading"] = 1] = "Loading";
    RevisionContentState[RevisionContentState["Loaded"] = 2] = "Loaded";
    RevisionContentState[RevisionContentState["NotEntitled"] = 3] = "NotEntitled";
})(RevisionContentState || (exports.RevisionContentState = RevisionContentState = {}));
