"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ElementIds = void 0;
exports.ElementIds = {
    ContentList: 'notes-scrollable',
    EditorColumn: 'editor-column',
    EditorContent: 'editor-content',
    FileTextPreview: 'file-text-preview',
    FileTitleEditor: 'file-title-editor',
    ItemsColumn: 'items-column',
    NavigationColumn: 'navigation',
    NoteTextEditor: 'note-text-editor',
    NoteTitleEditor: 'note-title-editor',
    RootId: 'app-group-root',
    NoteStatusTooltip: 'note-status-tooltip',
    ItemLinkAutocompleteInput: 'item-link-autocomplete-input',
    SearchBar: 'search-bar',
};
