"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwoFactorAuth = exports.is2FAEnabled = exports.is2FAActivation = exports.is2FADisabled = void 0;
const mobx_1 = require("mobx");
const TwoFactorActivation_1 = require("./TwoFactorActivation");
const is2FADisabled = (status) => status === 'two-factor-disabled';
exports.is2FADisabled = is2FADisabled;
const is2FAActivation = (status) => (status === null || status === void 0 ? void 0 : status.type) === 'two-factor-activation';
exports.is2FAActivation = is2FAActivation;
const is2FAEnabled = (status) => status === 'two-factor-enabled';
exports.is2FAEnabled = is2FAEnabled;
class TwoFactorAuth {
    constructor(sessions, mfa) {
        this.sessions = sessions;
        this.mfa = mfa;
        this._status = 'fetching';
        this._errorMessage = null;
        (0, mobx_1.makeAutoObservable)(this, {
            _status: mobx_1.observable,
            _errorMessage: mobx_1.observable,
            deactivateMfa: mobx_1.action,
            startActivation: mobx_1.action,
        }, { autoBind: true });
    }
    startActivation() {
        const setDisabled = (0, mobx_1.action)(() => (this._status = 'two-factor-disabled'));
        const setEnabled = (0, mobx_1.action)(() => {
            this._status = 'two-factor-enabled';
            this.fetchStatus();
        });
        this.mfa
            .generateMfaSecret()
            .then((0, mobx_1.action)((secret) => {
            var _a;
            this._status = new TwoFactorActivation_1.TwoFactorActivation(this.mfa, (_a = this.sessions.getUser()) === null || _a === void 0 ? void 0 : _a.email, secret, setDisabled, setEnabled);
        }))
            .catch((0, mobx_1.action)((e) => {
            this.setError(e.message);
        }));
    }
    deactivate2FA() {
        this.mfa
            .disableMfa()
            .then((0, mobx_1.action)(() => {
            this.fetchStatus();
        }))
            .catch((0, mobx_1.action)((e) => {
            this.setError(e.message);
        }));
    }
    isLoggedIn() {
        return this.sessions.getUser() != undefined;
    }
    fetchStatus() {
        if (!this.isLoggedIn()) {
            return;
        }
        this.mfa
            .isMfaActivated()
            .then((0, mobx_1.action)((active) => {
            this._status = active ? 'two-factor-enabled' : 'two-factor-disabled';
            this.setError(null);
        }))
            .catch((0, mobx_1.action)((e) => {
            this._status = 'two-factor-disabled';
            this.setError(e.message);
        }));
    }
    setError(errorMessage) {
        this._errorMessage = errorMessage;
    }
    toggle2FA() {
        if (!this.isLoggedIn()) {
            return;
        }
        if (this._status === 'two-factor-disabled') {
            return this.startActivation();
        }
        if (this._status === 'two-factor-enabled') {
            return this.deactivate2FA();
        }
    }
    get errorMessage() {
        return this._errorMessage;
    }
    get status() {
        return this._status;
    }
}
exports.TwoFactorAuth = TwoFactorAuth;
