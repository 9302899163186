"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNonCollidingAlignment = exports.getNonCollidingSide = exports.checkCollisions = exports.getOverflows = exports.OppositeSide = void 0;
const Rect_1 = require("./Rect");
exports.OppositeSide = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
};
const getOverflows = (popoverRect, documentRect) => {
    const overflows = {
        top: documentRect.top - popoverRect.top,
        bottom: popoverRect.height - (documentRect.bottom - popoverRect.top),
        left: documentRect.left - popoverRect.left,
        right: popoverRect.right - documentRect.right,
    };
    return overflows;
};
exports.getOverflows = getOverflows;
const checkCollisions = (popoverRect, containerRect) => {
    const appRect = containerRect;
    return {
        top: popoverRect.top < appRect.top,
        left: popoverRect.left < appRect.left,
        bottom: popoverRect.bottom > appRect.bottom,
        right: popoverRect.right > appRect.right,
    };
};
exports.checkCollisions = checkCollisions;
const getNonCollidingSide = (preferredSide, preferredSideCollisions, oppositeSideCollisions) => {
    const oppositeSide = exports.OppositeSide[preferredSide];
    return preferredSideCollisions[preferredSide] && !oppositeSideCollisions[oppositeSide] ? oppositeSide : preferredSide;
};
exports.getNonCollidingSide = getNonCollidingSide;
const OppositeAlignment = {
    start: 'end',
    end: 'start',
};
const getNonCollidingAlignment = ({ finalSide, preferredAlignment, collisions, popoverRect, buttonRect, documentRect, }) => {
    const isHorizontalSide = finalSide === 'top' || finalSide === 'bottom';
    const boundToCheckForStart = isHorizontalSide ? 'right' : 'bottom';
    const boundToCheckForEnd = isHorizontalSide ? 'left' : 'top';
    const prefersAligningAtStart = preferredAlignment === 'start';
    const prefersAligningAtCenter = preferredAlignment === 'center';
    const prefersAligningAtEnd = preferredAlignment === 'end';
    if (prefersAligningAtCenter) {
        if (collisions[boundToCheckForStart]) {
            return 'end';
        }
        if (collisions[boundToCheckForEnd]) {
            return 'start';
        }
    }
    else {
        const oppositeAlignmentCollisions = (0, exports.checkCollisions)((0, Rect_1.getPositionedPopoverRect)(popoverRect, buttonRect, finalSide, OppositeAlignment[preferredAlignment]), documentRect);
        if (prefersAligningAtStart &&
            collisions[boundToCheckForStart] &&
            !oppositeAlignmentCollisions[boundToCheckForEnd]) {
            return 'end';
        }
        if (prefersAligningAtEnd && collisions[boundToCheckForEnd] && !oppositeAlignmentCollisions[boundToCheckForStart]) {
            return 'start';
        }
    }
    return preferredAlignment;
};
exports.getNonCollidingAlignment = getNonCollidingAlignment;
