"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVaults = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetVaults {
    constructor(items) {
        this.items = items;
    }
    execute() {
        const vaults = this.items.getItems(domain_core_1.ContentType.TYPES.VaultListing).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return domain_core_1.Result.ok(vaults);
    }
}
exports.GetVaults = GetVaults;
