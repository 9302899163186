"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRemoteImageBlockOption = void 0;
const RemoteImage_1 = require("../../Blocks/RemoteImage");
const BlockPickerOption_1 = require("../BlockPickerOption");
function GetRemoteImageBlockOption(onSelect) {
    const block = (0, RemoteImage_1.GetRemoteImageBlock)(onSelect);
    return new BlockPickerOption_1.BlockPickerOption(block.name, {
        iconName: block.iconName,
        keywords: block.keywords,
        onSelect: block.onSelect,
    });
}
exports.GetRemoteImageBlockOption = GetRemoteImageBlockOption;
