"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alertDialog = exports.confirmDialog = void 0;
const utils_1 = require("@standardnotes/utils");
const styles_1 = require("@standardnotes/styles");
/** @returns a promise resolving to true if the user confirmed, false if they canceled */
function confirmDialog({ text, title, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', confirmButtonStyle = 'info', }) {
    return new Promise((resolve) => {
        const alert = new styles_1.SKAlert({
            title: title && (0, utils_1.sanitizeHtmlString)(title),
            text: (0, utils_1.sanitizeHtmlString)(text),
            buttons: [
                {
                    text: cancelButtonText,
                    style: 'default',
                    action() {
                        resolve(false);
                    },
                },
                {
                    text: confirmButtonText,
                    style: confirmButtonStyle,
                    primary: true,
                    action() {
                        resolve(true);
                    },
                },
            ],
        });
        alert.present();
    });
}
exports.confirmDialog = confirmDialog;
function alertDialog({ title, text, closeButtonText = 'OK', }) {
    return new Promise((resolve) => {
        const alert = new styles_1.SKAlert({
            title: title && (0, utils_1.sanitizeHtmlString)(title),
            text: (0, utils_1.sanitizeHtmlString)(text),
            buttons: [
                {
                    text: closeButtonText,
                    style: 'default',
                    action: resolve,
                },
            ],
        });
        alert.present();
    });
}
exports.alertDialog = alertDialog;
