"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateVaultPassword = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class ValidateVaultPassword {
    constructor(encryption, keys) {
        this.encryption = encryption;
        this.keys = keys;
    }
    execute(vault, password) {
        if (vault.keyPasswordType !== models_1.KeySystemPasswordType.UserInputted) {
            throw new Error('Vault uses randomized password and cannot be validated with password');
        }
        const rootKey = this.keys.getPrimaryKeySystemRootKey(vault.systemIdentifier);
        if (!rootKey) {
            return domain_core_1.Result.ok(false);
        }
        const derivedRootKey = this.encryption.deriveUserInputtedKeySystemRootKey({
            keyParams: vault.rootKeyParams,
            userInputtedPassword: password,
        });
        return domain_core_1.Result.ok(rootKey.isEqual(derivedRootKey));
    }
}
exports.ValidateVaultPassword = ValidateVaultPassword;
