"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeVaultStorageMode = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class ChangeVaultStorageMode {
    constructor(mutator, sync, keys, _getVault) {
        this.mutator = mutator;
        this.sync = sync;
        this.keys = keys;
        this._getVault = _getVault;
    }
    async execute(dto) {
        const result = this._getVault.execute({ keySystemIdentifier: dto.vault.systemIdentifier });
        if (result.isFailed()) {
            return domain_core_1.Result.fail('Vault not found');
        }
        const vault = result.getValue();
        if (vault.keyPasswordType === models_1.KeySystemPasswordType.Randomized &&
            dto.newStorageMode !== models_1.KeySystemRootKeyStorageMode.Synced) {
            return domain_core_1.Result.fail('Cannot change storage mode to non-synced for randomized vault');
        }
        const latestVault = result.getValue();
        if (dto.newStorageMode === latestVault.keyStorageMode) {
            return domain_core_1.Result.fail('Vault already uses this storage preference');
        }
        if (dto.newStorageMode === models_1.KeySystemRootKeyStorageMode.Local ||
            dto.newStorageMode === models_1.KeySystemRootKeyStorageMode.Ephemeral) {
            const result = await this.changeStorageModeToLocalOrEphemeral(latestVault, dto.newStorageMode);
            if (result.isFailed()) {
                return result;
            }
        }
        else if (dto.newStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            const result = await this.changeStorageModeToSynced(latestVault);
            if (result.isFailed()) {
                return result;
            }
        }
        return domain_core_1.Result.ok();
    }
    async changeStorageModeToLocalOrEphemeral(vault, newStorageMode) {
        const primaryKey = this.keys.getPrimaryKeySystemRootKey(vault.systemIdentifier);
        if (!primaryKey) {
            return domain_core_1.Result.fail('No primary key found');
        }
        if (newStorageMode === models_1.KeySystemRootKeyStorageMode.Ephemeral) {
            this.keys.removeKeyFromCache(vault.systemIdentifier);
        }
        this.keys.cacheKey(primaryKey, newStorageMode);
        await this.keys.deleteAllSyncedKeySystemRootKeys(vault.systemIdentifier);
        const updatedVault = await this.mutator.changeItem(vault, (mutator) => {
            mutator.keyStorageMode = newStorageMode;
        });
        await this.sync.sync();
        return domain_core_1.Result.ok(updatedVault);
    }
    async changeStorageModeToSynced(vault) {
        const allRootKeys = this.keys.getAllKeySystemRootKeysForVault(vault.systemIdentifier);
        const syncedRootKeys = this.keys.getSyncedKeySystemRootKeysForVault(vault.systemIdentifier);
        this.keys.removeKeyFromCache(vault.systemIdentifier);
        for (const key of allRootKeys) {
            const existingSyncedKey = syncedRootKeys.find((syncedKey) => syncedKey.token === key.token);
            if (existingSyncedKey) {
                continue;
            }
            await this.mutator.insertItem(key);
        }
        const updatedVault = await this.mutator.changeItem(vault, (mutator) => {
            mutator.keyStorageMode = models_1.KeySystemRootKeyStorageMode.Synced;
        });
        return domain_core_1.Result.ok(updatedVault);
    }
}
exports.ChangeVaultStorageMode = ChangeVaultStorageMode;
