"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaneController = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const snjs_1 = require("@standardnotes/snjs");
const AppPaneMetadata_1 = require("../../Components/Panes/AppPaneMetadata");
const Utils_1 = require("@/Utils");
const mobx_1 = require("mobx");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
const Logging_1 = require("@/Logging");
const MinimumNavPanelWidth = snjs_1.PrefDefaults[snjs_1.PrefKey.TagsPanelWidth];
const MinimumNotesPanelWidth = snjs_1.PrefDefaults[snjs_1.PrefKey.NotesPanelWidth];
const FOCUS_MODE_CLASS_NAME = 'focus-mode';
const DISABLING_FOCUS_MODE_CLASS_NAME = 'disable-focus-mode';
const FOCUS_MODE_ANIMATION_DURATION = 1255;
class PaneController extends AbstractViewController_1.AbstractViewController {
    constructor(preferences, keyboardService, _isTabletOrMobileScreen, _panesForLayout, eventBus) {
        super(eventBus);
        this.preferences = preferences;
        this.keyboardService = keyboardService;
        this._isTabletOrMobileScreen = _isTabletOrMobileScreen;
        this._panesForLayout = _panesForLayout;
        this.isInMobileView = (0, Utils_1.isMobileScreen)();
        this.disposers = [];
        this.panes = [];
        this.currentNavPanelWidth = 0;
        this.currentItemsPanelWidth = 0;
        this.focusModeEnabled = false;
        this.listPaneExplicitelyCollapsed = false;
        this.navigationPaneExplicitelyCollapsed = false;
        this.mediumScreenMQHandler = (event) => {
            if (event.matches) {
                this.setIsInMobileView(false);
            }
            else {
                this.setIsInMobileView(true);
            }
        };
        this.setIsInMobileView = (isInMobileView) => {
            this.isInMobileView = isInMobileView;
        };
        this.setPaneLayout = (layout) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Set pane layout', layout);
            const panes = this._panesForLayout.execute(layout).getValue();
            if (panes.includes(AppPaneMetadata_1.AppPaneId.Items) && this.listPaneExplicitelyCollapsed) {
                (0, snjs_1.removeFromArray)(panes, AppPaneMetadata_1.AppPaneId.Items);
            }
            if (panes.includes(AppPaneMetadata_1.AppPaneId.Navigation) && this.navigationPaneExplicitelyCollapsed) {
                (0, snjs_1.removeFromArray)(panes, AppPaneMetadata_1.AppPaneId.Navigation);
            }
            this.replacePanes(panes);
        };
        this.replacePanes = (panes) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Replacing panes', panes);
            this.panes = panes;
        };
        this.presentPane = (pane) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Presenting pane', pane);
            if (pane === this.currentPane) {
                return;
            }
            if (pane === AppPaneMetadata_1.AppPaneId.Items && this.currentPane === AppPaneMetadata_1.AppPaneId.Editor) {
                this.dismissLastPane();
                return;
            }
            if (this.currentPane !== pane) {
                this.panes.push(pane);
            }
        };
        this.insertPaneAtIndex = (pane, index) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Inserting pane', pane, 'at index', index);
            this.panes.splice(index, 0, pane);
        };
        this.dismissLastPane = () => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Dismissing last pane');
            return this.panes.pop();
        };
        this.removePane = (pane) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Removing pane', pane);
            (0, snjs_1.removeFromArray)(this.panes, pane);
        };
        this.popToPane = (pane) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Panes, 'Popping to pane', pane);
            let index = this.panes.length - 1;
            while (index >= 0) {
                if (this.panes[index] === pane) {
                    break;
                }
                this.dismissLastPane();
                index--;
            }
        };
        this.toggleListPane = () => {
            if (this.panes.includes(AppPaneMetadata_1.AppPaneId.Items)) {
                this.removePane(AppPaneMetadata_1.AppPaneId.Items);
                this.listPaneExplicitelyCollapsed = true;
            }
            else {
                if (this.panes.includes(AppPaneMetadata_1.AppPaneId.Navigation)) {
                    this.insertPaneAtIndex(AppPaneMetadata_1.AppPaneId.Items, 1);
                }
                else {
                    this.insertPaneAtIndex(AppPaneMetadata_1.AppPaneId.Items, 0);
                }
                this.listPaneExplicitelyCollapsed = false;
            }
        };
        this.toggleNavigationPane = () => {
            if (this.panes.includes(AppPaneMetadata_1.AppPaneId.Navigation)) {
                this.removePane(AppPaneMetadata_1.AppPaneId.Navigation);
                this.navigationPaneExplicitelyCollapsed = true;
            }
            else {
                this.insertPaneAtIndex(AppPaneMetadata_1.AppPaneId.Navigation, 0);
                this.navigationPaneExplicitelyCollapsed = false;
            }
        };
        this.setFocusModeEnabled = (enabled) => {
            this.focusModeEnabled = enabled;
            if (enabled) {
                document.body.classList.add(FOCUS_MODE_CLASS_NAME);
                return;
            }
            if (document.body.classList.contains(FOCUS_MODE_CLASS_NAME)) {
                document.body.classList.add(DISABLING_FOCUS_MODE_CLASS_NAME);
                document.body.classList.remove(FOCUS_MODE_CLASS_NAME);
                setTimeout(() => {
                    document.body.classList.remove(DISABLING_FOCUS_MODE_CLASS_NAME);
                }, FOCUS_MODE_ANIMATION_DURATION);
            }
        };
        (0, mobx_1.makeObservable)(this, {
            panes: mobx_1.observable,
            isInMobileView: mobx_1.observable,
            currentNavPanelWidth: mobx_1.observable,
            currentItemsPanelWidth: mobx_1.observable,
            focusModeEnabled: mobx_1.observable,
            currentPane: mobx_1.computed,
            previousPane: mobx_1.computed,
            isListPaneCollapsed: mobx_1.computed,
            isNavigationPaneCollapsed: mobx_1.computed,
            setIsInMobileView: mobx_1.action,
            toggleListPane: mobx_1.action,
            toggleNavigationPane: mobx_1.action,
            setCurrentItemsPanelWidth: mobx_1.action,
            setCurrentNavPanelWidth: mobx_1.action,
            presentPane: mobx_1.action,
            dismissLastPane: mobx_1.action,
            replacePanes: mobx_1.action,
            popToPane: mobx_1.action,
            removePane: mobx_1.action,
            insertPaneAtIndex: mobx_1.action,
            setPaneLayout: mobx_1.action,
            setFocusModeEnabled: mobx_1.action,
        });
        this.setCurrentNavPanelWidth(preferences.getValue(snjs_1.PrefKey.TagsPanelWidth, MinimumNavPanelWidth));
        this.setCurrentItemsPanelWidth(preferences.getValue(snjs_1.PrefKey.NotesPanelWidth, MinimumNotesPanelWidth));
        const screen = this._isTabletOrMobileScreen.execute().getValue();
        this.panes = screen.isTabletOrMobile
            ? [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items]
            : [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor];
        const mediaQuery = window.matchMedia(useMediaQuery_1.MediaQueryBreakpoints.md);
        if ((mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.addEventListener) != undefined) {
            mediaQuery.addEventListener('change', this.mediumScreenMQHandler);
        }
        else {
            mediaQuery.addListener(this.mediumScreenMQHandler);
        }
        eventBus.addEventHandler(this, snjs_1.ApplicationEvent.PreferencesChanged);
        this.disposers.push(keyboardService.addCommandHandler({
            command: ui_services_1.TOGGLE_FOCUS_MODE_COMMAND,
            onKeyDown: (event) => {
                event.preventDefault();
                this.setFocusModeEnabled(!this.focusModeEnabled);
                return true;
            },
        }), keyboardService.addCommandHandler({
            command: ui_services_1.TOGGLE_LIST_PANE_KEYBOARD_COMMAND,
            onKeyDown: (event) => {
                event.preventDefault();
                this.toggleListPane();
            },
        }), keyboardService.addCommandHandler({
            command: ui_services_1.TOGGLE_NAVIGATION_PANE_KEYBOARD_COMMAND,
            onKeyDown: (event) => {
                event.preventDefault();
                this.toggleNavigationPane();
            },
        }));
    }
    async handleEvent(event) {
        if (event.type === snjs_1.ApplicationEvent.PreferencesChanged) {
            this.setCurrentNavPanelWidth(this.preferences.getValue(snjs_1.PrefKey.TagsPanelWidth, MinimumNavPanelWidth));
            this.setCurrentItemsPanelWidth(this.preferences.getValue(snjs_1.PrefKey.NotesPanelWidth, MinimumNotesPanelWidth));
        }
    }
    setCurrentNavPanelWidth(width) {
        this.currentNavPanelWidth = width;
    }
    setCurrentItemsPanelWidth(width) {
        this.currentItemsPanelWidth = width;
    }
    deinit() {
        super.deinit();
        const mq = window.matchMedia(useMediaQuery_1.MediaQueryBreakpoints.md);
        if ((mq === null || mq === void 0 ? void 0 : mq.removeEventListener) != undefined) {
            mq.removeEventListener('change', this.mediumScreenMQHandler);
        }
        else {
            mq.removeListener(this.mediumScreenMQHandler);
        }
    }
    get currentPane() {
        return this.panes[this.panes.length - 1] || this.panes[0];
    }
    get previousPane() {
        return this.panes[this.panes.length - 2] || this.panes[0];
    }
    get isListPaneCollapsed() {
        return !this.panes.includes(AppPaneMetadata_1.AppPaneId.Items);
    }
    get isNavigationPaneCollapsed() {
        return !this.panes.includes(AppPaneMetadata_1.AppPaneId.Navigation);
    }
}
exports.PaneController = PaneController;
