"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileProgressToHumanReadableString = void 0;
function fileProgressToHumanReadableString(progress, fileName, options) {
    const progressPercent = Math.floor(progress.percentComplete);
    const sourceString = progress.source === 'network' ? '' : progress.source === 'memcache' ? 'from cache' : 'from backup';
    let result = `Downloading file ${sourceString} "${fileName}"`;
    if (options.showPercent) {
        result += ` (${progressPercent}%)`;
    }
    return result;
}
exports.fileProgressToHumanReadableString = fileProgressToHumanReadableString;
