"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmojiLength = void 0;
function getEmojiLength(emoji) {
    try {
        return [...new Intl.Segmenter().segment(emoji)].length;
    }
    catch (error) {
        return [...emoji].length;
    }
}
exports.getEmojiLength = getEmojiLength;
