"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendMessage = void 0;
const responses_1 = require("@standardnotes/responses");
const domain_core_1 = require("@standardnotes/domain-core");
class SendMessage {
    constructor(messageServer) {
        this.messageServer = messageServer;
    }
    async execute(params) {
        const response = await this.messageServer.createMessage({
            recipientUuid: params.recipientUuid,
            encryptedMessage: params.encryptedMessage,
            replaceabilityIdentifier: params.replaceabilityIdentifier,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return domain_core_1.Result.fail((0, responses_1.getErrorFromErrorResponse)(response).message);
        }
        return domain_core_1.Result.ok(response.data.message);
    }
}
exports.SendMessage = SendMessage;
