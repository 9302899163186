"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isFileNode = exports.$createFileNode = exports.convertToFileElement = void 0;
const FileNode_1 = require("./FileNode");
function convertToFileElement(domNode) {
    const fileUuid = domNode.getAttribute('data-lexical-file-uuid');
    if (fileUuid) {
        const node = $createFileNode(fileUuid);
        return { node };
    }
    return null;
}
exports.convertToFileElement = convertToFileElement;
function $createFileNode(fileUuid) {
    return new FileNode_1.FileNode(fileUuid);
}
exports.$createFileNode = $createFileNode;
function $isFileNode(node) {
    return node instanceof FileNode_1.FileNode;
}
exports.$isFileNode = $isFileNode;
