"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const TabPanel = ({ state, id, children, ...props }) => {
    const { activeTab } = state;
    const isActive = activeTab === id;
    if (!isActive) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { role: "tabpanel", id: `tab-panel-${id}`, "aria-labelledby": `tab-control-${id}`, ...props, children: children }));
};
exports.default = TabPanel;
