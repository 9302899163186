"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebDevice = void 0;
const snjs_1 = require("@standardnotes/snjs");
const WebOrDesktopDevice_1 = require("./WebOrDesktopDevice");
const KEYCHAIN_STORAGE_KEY = 'keychain';
const DESTROYED_DEVICE_URL_PARAM = 'destroyed';
const DESTROYED_DEVICE_URL_VALUE = 'true';
class WebDevice extends WebOrDesktopDevice_1.WebOrDesktopDevice {
    constructor() {
        super(...arguments);
        this.environment = snjs_1.Environment.Web;
    }
    async getKeychainValue() {
        const value = localStorage.getItem(KEYCHAIN_STORAGE_KEY);
        if (value) {
            return JSON.parse(value);
        }
        return {};
    }
    async setKeychainValue(value) {
        localStorage.setItem(KEYCHAIN_STORAGE_KEY, JSON.stringify(value));
    }
    async clearRawKeychainValue() {
        localStorage.removeItem(KEYCHAIN_STORAGE_KEY);
    }
    async performHardReset() {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        params.append(DESTROYED_DEVICE_URL_PARAM, DESTROYED_DEVICE_URL_VALUE);
        window.location.replace(url.href);
    }
    isDeviceDestroyed() {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        return params.get(DESTROYED_DEVICE_URL_PARAM) === DESTROYED_DEVICE_URL_VALUE;
    }
}
exports.WebDevice = WebDevice;
