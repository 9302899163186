"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindDefaultItemsKey = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class FindDefaultItemsKey {
    execute(itemsKeys) {
        if (itemsKeys.length === 1) {
            return domain_core_1.Result.ok(itemsKeys[0]);
        }
        const defaultKeys = itemsKeys.filter((key) => {
            return key.isDefault;
        });
        if (defaultKeys.length === 0) {
            return domain_core_1.Result.ok(undefined);
        }
        if (defaultKeys.length === 1) {
            return domain_core_1.Result.ok(defaultKeys[0]);
        }
        /**
         * Prioritize one that is synced, as neverSynced keys will likely be deleted after
         * DownloadFirst sync.
         */
        const syncedKeys = defaultKeys.filter((key) => !key.neverSynced);
        if (syncedKeys.length > 0) {
            return domain_core_1.Result.ok(syncedKeys[0]);
        }
        return domain_core_1.Result.ok(undefined);
    }
}
exports.FindDefaultItemsKey = FindDefaultItemsKey;
