"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundPredicateBuilderController = void 0;
const features_1 = require("@standardnotes/features");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const PredicateKeypaths_1 = require("./PredicateKeypaths");
const getEmptyPredicate = () => {
    return {
        keypath: 'title',
        operator: '!=',
        value: '',
    };
};
class CompoundPredicateBuilderController {
    constructor() {
        this.operator = 'and';
        this.predicates = [getEmptyPredicate()];
        this.setOperator = (operator) => {
            this.operator = operator;
        };
        this.setPredicate = (index, predicate) => {
            const predicateAtIndex = this.predicates[index];
            this.predicates[index] = {
                ...predicateAtIndex,
                ...predicate,
            };
        };
        this.changePredicateKeypath = (index, keypath) => {
            const currentKeyPath = this.predicates[index].keypath;
            const currentKeyPathType = PredicateKeypaths_1.PredicateKeypathTypes[currentKeyPath];
            const newKeyPathType = PredicateKeypaths_1.PredicateKeypathTypes[keypath];
            if (currentKeyPathType !== newKeyPathType) {
                switch (newKeyPathType) {
                    case 'string':
                        this.setPredicate(index, { value: '' });
                        break;
                    case 'boolean':
                        this.setPredicate(index, { value: true });
                        break;
                    case 'number':
                        this.setPredicate(index, { value: 0 });
                        break;
                    case 'noteType':
                        this.setPredicate(index, { value: Object.values(snjs_1.NoteType)[0] });
                        break;
                    case 'editorIdentifier':
                        this.setPredicate(index, { value: features_1.NativeFeatureIdentifier.TYPES.PlainEditor });
                        break;
                    case 'date':
                        this.setPredicate(index, { value: '1.days.ago' });
                        break;
                }
            }
            this.setPredicate(index, { keypath });
        };
        this.addPredicate = () => {
            this.predicates.push(getEmptyPredicate());
        };
        this.removePredicate = (index) => {
            this.predicates.splice(index, 1);
        };
        (0, mobx_1.makeObservable)(this, {
            operator: mobx_1.observable,
            setOperator: mobx_1.action,
            predicates: mobx_1.observable,
            setPredicate: mobx_1.action,
            addPredicate: mobx_1.action,
            removePredicate: mobx_1.action,
        });
    }
    toJson() {
        return {
            operator: this.operator,
            value: this.predicates,
        };
    }
    resetState() {
        this.operator = 'and';
        this.predicates = [getEmptyPredicate()];
    }
}
exports.CompoundPredicateBuilderController = CompoundPredicateBuilderController;
