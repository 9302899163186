"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const styles = {
    base: 'active:border-info active:bg-info active:text-neutral-contrast flex-grow cursor-pointer rounded-full border border-solid px-2 py-1 text-center transition text-sm',
    unselected: 'text-neutral border-secondary-border bg-default',
    selected: 'text-neutral-contrast border-info bg-info',
};
const Bubble = ({ label, selected, onSelect }) => ((0, jsx_runtime_1.jsx)("span", { role: "tab", className: `${styles.base} ${selected ? styles.selected : styles.unselected}`, onClick: onSelect, children: label }));
exports.default = Bubble;
