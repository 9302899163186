"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAlignmentBlocks = void 0;
const lexical_1 = require("lexical");
function GetAlignmentBlocks(editor) {
    return ['left', 'center', 'right', 'justify'].map((alignment) => ({
        name: `Align ${alignment}`,
        iconName: `align-${alignment}`,
        keywords: ['align', 'justify', alignment],
        onSelect: () => editor.dispatchCommand(lexical_1.FORMAT_ELEMENT_COMMAND, alignment),
    }));
}
exports.GetAlignmentBlocks = GetAlignmentBlocks;
