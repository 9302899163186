"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthApiService = void 0;
const Api_1 = require("../../Api");
const ApiCallError_1 = require("../../Error/ApiCallError");
const ErrorMessage_1 = require("../../Error/ErrorMessage");
const AuthApiOperations_1 = require("./AuthApiOperations");
class AuthApiService {
    constructor(authServer) {
        this.authServer = authServer;
        this.operationsInProgress = new Map();
    }
    generateRecoveryCodes() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthApiOperations_1.AuthApiOperations.GenerateRecoveryCodes)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.GenerateRecoveryCodes, true);
            try {
                const response = yield this.authServer.generateRecoveryCodes();
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.GenerateRecoveryCodes, false);
            }
        });
    }
    recoveryKeyParams(dto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthApiOperations_1.AuthApiOperations.GetRecoveryKeyParams)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.GetRecoveryKeyParams, true);
            try {
                const response = yield this.authServer.recoveryKeyParams({
                    api_version: Api_1.ApiVersion.v0,
                    code_challenge: dto.codeChallenge,
                    recovery_codes: dto.recoveryCodes,
                    username: dto.username,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.GetRecoveryKeyParams, false);
            }
        });
    }
    signInWithRecoveryCodes(dto) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(AuthApiOperations_1.AuthApiOperations.SignInWithRecoveryCodes)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.SignInWithRecoveryCodes, true);
            try {
                const response = yield this.authServer.signInWithRecoveryCodes({
                    api_version: Api_1.ApiVersion.v0,
                    code_verifier: dto.codeVerifier,
                    password: dto.password,
                    recovery_codes: dto.recoveryCodes,
                    username: dto.username,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(AuthApiOperations_1.AuthApiOperations.SignInWithRecoveryCodes, false);
            }
        });
    }
}
exports.AuthApiService = AuthApiService;
