"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFullEntryLoadChunkResponse = exports.isChunkFullEntry = void 0;
function isChunkFullEntry(x) {
    return x.entries !== undefined;
}
exports.isChunkFullEntry = isChunkFullEntry;
function isFullEntryLoadChunkResponse(x) {
    return x.fullEntries !== undefined;
}
exports.isFullEntryLoadChunkResponse = isFullEntryLoadChunkResponse;
