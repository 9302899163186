"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemsReloadSource = void 0;
var ItemsReloadSource;
(function (ItemsReloadSource) {
    ItemsReloadSource[ItemsReloadSource["ItemStream"] = 0] = "ItemStream";
    ItemsReloadSource[ItemsReloadSource["SyncEvent"] = 1] = "SyncEvent";
    ItemsReloadSource[ItemsReloadSource["DisplayOptionsChange"] = 2] = "DisplayOptionsChange";
    ItemsReloadSource[ItemsReloadSource["Pagination"] = 3] = "Pagination";
    ItemsReloadSource[ItemsReloadSource["TagChange"] = 4] = "TagChange";
    ItemsReloadSource[ItemsReloadSource["UserTriggeredTagChange"] = 5] = "UserTriggeredTagChange";
    ItemsReloadSource[ItemsReloadSource["FilterTextChange"] = 6] = "FilterTextChange";
})(ItemsReloadSource || (exports.ItemsReloadSource = ItemsReloadSource = {}));
