"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countNoteAttributes = void 0;
const getWordCount_1 = require("./getWordCount");
const getParagraphCount_1 = require("./getParagraphCount");
const countNoteAttributes = (text) => {
    try {
        JSON.parse(text);
        return {
            characters: 'N/A',
            words: 'N/A',
            paragraphs: 'N/A',
        };
    }
    catch {
        const characters = text.length;
        const words = (0, getWordCount_1.getWordCount)(text);
        const paragraphs = (0, getParagraphCount_1.getParagraphCount)(text);
        return {
            characters,
            words,
            paragraphs,
        };
    }
};
exports.countNoteAttributes = countNoteAttributes;
