"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionApiService = void 0;
const ErrorMessage_1 = require("../../Error/ErrorMessage");
const ApiCallError_1 = require("../../Error/ApiCallError");
const ApiVersion_1 = require("../../Api/ApiVersion");
const responses_1 = require("@standardnotes/responses");
const SubscriptionApiOperations_1 = require("./SubscriptionApiOperations");
class SubscriptionApiService {
    constructor(subscriptionServer) {
        this.subscriptionServer = subscriptionServer;
        this.operationsInProgress = new Map();
    }
    listInvites() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.ListingInvites)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.ListingInvites, true);
            try {
                const response = yield this.subscriptionServer.listInvites({
                    [responses_1.ApiEndpointParam.ApiVersion]: ApiVersion_1.ApiVersion.v0,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.ListingInvites, false);
            }
        });
    }
    cancelInvite(inviteUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.CancelingInvite)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.CancelingInvite, true);
            try {
                const response = yield this.subscriptionServer.cancelInvite({
                    [responses_1.ApiEndpointParam.ApiVersion]: ApiVersion_1.ApiVersion.v0,
                    inviteUuid,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.CancelingInvite, false);
            }
        });
    }
    invite(inviteeEmail) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.Inviting)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.Inviting, true);
            try {
                const response = yield this.subscriptionServer.invite({
                    [responses_1.ApiEndpointParam.ApiVersion]: ApiVersion_1.ApiVersion.v0,
                    identifier: inviteeEmail,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.Inviting, false);
            }
        });
    }
    acceptInvite(inviteUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.AcceptingInvite)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.AcceptingInvite, true);
            try {
                const response = yield this.subscriptionServer.acceptInvite({
                    inviteUuid,
                });
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.AcceptingInvite, false);
            }
        });
    }
    confirmAppleIAP(params) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.ConfirmAppleIAP)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.ConfirmAppleIAP, true);
            try {
                const response = yield this.subscriptionServer.confirmAppleIAP(params);
                return response;
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.ConfirmAppleIAP, false);
            }
        });
    }
    getUserSubscription(params) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.GetSubscription)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.GetSubscription, true);
            try {
                const response = yield this.subscriptionServer.getUserSubscription(params);
                return response;
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.GetSubscription, false);
            }
        });
    }
    getAvailableSubscriptions() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.operationsInProgress.get(SubscriptionApiOperations_1.SubscriptionApiOperations.GetAvailableSubscriptions)) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
            }
            this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.GetAvailableSubscriptions, true);
            try {
                const response = yield this.subscriptionServer.getAvailableSubscriptions();
                return response;
            }
            finally {
                this.operationsInProgress.set(SubscriptionApiOperations_1.SubscriptionApiOperations.GetAvailableSubscriptions, false);
            }
        });
    }
}
exports.SubscriptionApiService = SubscriptionApiService;
