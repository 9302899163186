"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyPlaceholderBars = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function randomNumber(min, max) {
    const r = Math.random() * (max - min) + min;
    return Math.floor(r);
}
const EmptyPlaceholderBars = ({ rows }) => {
    const [barWidths, setBarWidths] = (0, react_1.useState)([]);
    const [animationInterval, setAnimationInterval] = (0, react_1.useState)(null);
    const reloadWidths = (0, react_1.useCallback)(() => {
        const widths = [];
        for (let i = 0; i < rows; i++) {
            const width = randomNumber(70, 100);
            widths.push(width);
        }
        setBarWidths(widths);
    }, [setBarWidths, rows]);
    (0, react_1.useEffect)(() => {
        reloadWidths();
    }, [rows, reloadWidths]);
    const onHoverEnter = (0, react_1.useCallback)(() => {
        reloadWidths();
        const interval = setInterval(() => {
            reloadWidths();
        }, 750);
        setAnimationInterval(interval);
    }, [setAnimationInterval, reloadWidths]);
    const onHoverExit = (0, react_1.useCallback)(() => {
        if (animationInterval) {
            clearInterval(animationInterval);
            setAnimationInterval(null);
        }
    }, [animationInterval, setAnimationInterval]);
    return ((0, jsx_runtime_1.jsx)("div", { onMouseEnter: onHoverEnter, onMouseLeave: onHoverExit, className: "w-full", children: barWidths.map((width, index) => {
            return ((0, jsx_runtime_1.jsx)("div", { style: { width: `${width}%` }, className: 'transition-slowest ease my-4 h-7 bg-passive-4-opacity-variant pb-3 transition-width duration-1000' }, index));
        }) }));
};
exports.EmptyPlaceholderBars = EmptyPlaceholderBars;
