"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageProvider = void 0;
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
class PackageProvider {
    static async load() {
        const versionMap = new Map();
        (0, snjs_1.GetFeatures)().forEach((feature) => {
            versionMap.set(feature.identifier, 'Latest');
        });
        return new PackageProvider(versionMap);
    }
    constructor(latestVersionsMap) {
        this.latestVersionsMap = latestVersionsMap;
        (0, mobx_1.makeAutoObservable)(this, {
            latestVersionsMap: mobx_1.observable.ref,
        });
    }
    getVersion(extension) {
        return this.latestVersionsMap.get(extension.package_info.identifier);
    }
}
exports.PackageProvider = PackageProvider;
