"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryStore = void 0;
class InMemoryStore {
    constructor() {
        this.values = new Map();
    }
    setValue(key, value) {
        this.values.set(key, value);
    }
    getValue(key) {
        return this.values.get(key);
    }
    removeValue(key) {
        this.values.delete(key);
    }
}
exports.InMemoryStore = InMemoryStore;
