"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeVaultKeyOptions = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class ChangeVaultKeyOptions {
    constructor(sync, _rotateVaultKey, _changeVaultStorageMode) {
        this.sync = sync;
        this._rotateVaultKey = _rotateVaultKey;
        this._changeVaultStorageMode = _changeVaultStorageMode;
    }
    async execute(dto) {
        let newStorageMode = dto.newStorageMode;
        let vault = dto.vault;
        if (dto.newPasswordOptions) {
            if (dto.newPasswordOptions.passwordType === models_1.KeySystemPasswordType.Randomized &&
                dto.newStorageMode &&
                dto.newStorageMode !== models_1.KeySystemRootKeyStorageMode.Synced) {
                return domain_core_1.Result.fail('Cannot change storage mode to non-synced for randomized vault');
            }
            if (dto.newPasswordOptions.passwordType === models_1.KeySystemPasswordType.UserInputted &&
                !dto.newPasswordOptions.userInputtedPassword) {
                return domain_core_1.Result.fail('User inputted password required');
            }
            const result = await this._rotateVaultKey.execute({
                vault: dto.vault,
                userInputtedPassword: dto.newPasswordOptions.passwordType === models_1.KeySystemPasswordType.UserInputted
                    ? dto.newPasswordOptions.userInputtedPassword
                    : undefined,
            });
            if (result.isFailed()) {
                return result;
            }
            vault = result.getValue();
            if (dto.newPasswordOptions.passwordType === models_1.KeySystemPasswordType.Randomized) {
                newStorageMode = models_1.KeySystemRootKeyStorageMode.Synced;
            }
        }
        if (newStorageMode && newStorageMode !== vault.keyStorageMode) {
            const result = await this._changeVaultStorageMode.execute({
                vault: vault,
                newStorageMode: newStorageMode,
            });
            if (result.isFailed()) {
                return result;
            }
        }
        await this.sync.sync();
        return domain_core_1.Result.ok();
    }
}
exports.ChangeVaultKeyOptions = ChangeVaultKeyOptions;
