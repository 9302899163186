"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarMonths = exports.CalendarDaysOfTheWeek = exports.CalendarDaysLeap = exports.CalendarDays = void 0;
exports.CalendarDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
exports.CalendarDaysLeap = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
exports.CalendarDaysOfTheWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
exports.CalendarMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
