"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeriveKeySystemRootKeyUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
const V004AlgorithmTypes_1 = require("../../V004AlgorithmTypes");
const Algorithm_1 = require("../../../../Algorithm");
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class DeriveKeySystemRootKeyUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(dto) {
        const seed = dto.keyParams.seed;
        const salt = this.generateSalt(dto.keyParams.systemIdentifier, seed);
        const derivedKey = this.crypto.argon2(dto.password, salt, Algorithm_1.V004Algorithm.ArgonIterations, Algorithm_1.V004Algorithm.ArgonMemLimit, Algorithm_1.V004Algorithm.ArgonOutputKeyBytes);
        const partitions = (0, utils_1.splitString)(derivedKey, 2);
        const masterKey = partitions[0];
        const token = partitions[1];
        const uuid = utils_1.UuidGenerator.GenerateUuid();
        const content = {
            systemIdentifier: dto.keyParams.systemIdentifier,
            key: masterKey,
            keyVersion: models_1.ProtocolVersion.V004,
            keyParams: dto.keyParams,
            token,
        };
        const payload = new models_1.DecryptedPayload({
            uuid: uuid,
            content_type: domain_core_1.ContentType.TYPES.KeySystemRootKey,
            content: (0, models_1.FillItemContentSpecialized)(content),
            ...(0, models_1.PayloadTimestampDefaults)(),
        });
        return new models_1.KeySystemRootKey(payload);
    }
    generateSalt(identifier, seed) {
        const hash = this.crypto.sodiumCryptoGenericHash([identifier, seed].join(V004AlgorithmTypes_1.V004PartitionCharacter));
        return (0, utils_1.truncateHexString)(hash, Algorithm_1.V004Algorithm.ArgonSaltLength);
    }
}
exports.DeriveKeySystemRootKeyUseCase = DeriveKeySystemRootKeyUseCase;
