"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbsolutePositionedParent = void 0;
function getAbsolutePositionedParent(element) {
    if (!element) {
        return null;
    }
    const parent = element.parentElement;
    if (!parent) {
        return null;
    }
    const position = window.getComputedStyle(parent).getPropertyValue('position');
    if (position === 'absolute') {
        return parent;
    }
    return getAbsolutePositionedParent(parent);
}
exports.getAbsolutePositionedParent = getAbsolutePositionedParent;
