"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const CalendarDay = ({ day, type, isToday, onClick, isLastMonth }) => {
    let classNames = '';
    if (isToday) {
        classNames += 'bg-danger text-danger-contrast font-bold';
    }
    else if (isLastMonth) {
        classNames += 'text-passive-3';
    }
    else {
        if (type === 'empty') {
            classNames += 'bg-transparent hover:bg-contrast';
        }
        else if (type === 'item') {
            classNames += 'bg-danger-light text-danger font-bold';
        }
        else {
            classNames += 'bg-contrast';
        }
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "h-7 w-[14.2%] p-0.5", children: (0, jsx_runtime_1.jsx)("div", { className: `${classNames} flex h-full w-full cursor-pointer items-center justify-center rounded`, onClick: onClick, children: day }, day) }));
};
exports.default = CalendarDay;
