"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyRecoveryStrings = void 0;
const common_1 = require("@standardnotes/common");
exports.KeyRecoveryStrings = {
    KeyRecoveryLoginFlowPrompt: (keyParams) => {
        var _a;
        const dateString = (_a = keyParams.createdDate) === null || _a === void 0 ? void 0 : _a.toLocaleString();
        switch (keyParams.origination) {
            case common_1.KeyParamsOrigination.EmailChange:
                return `Enter your account password as it was when you changed your email on ${dateString}.`;
            case common_1.KeyParamsOrigination.PasswordChange:
                return `Enter your account password after it was changed on ${dateString}.`;
            case common_1.KeyParamsOrigination.Registration:
                return `Enter your account password as it was when you registered ${dateString}.`;
            case common_1.KeyParamsOrigination.ProtocolUpgrade:
                return `Enter your account password as it was when you upgraded your encryption version on ${dateString}.`;
            case common_1.KeyParamsOrigination.PasscodeChange:
                return `Enter your application passcode after it was changed on ${dateString}.`;
            case common_1.KeyParamsOrigination.PasscodeCreate:
                return `Enter your application passcode as it was when you created it on ${dateString}.`;
            default:
                throw Error('Unhandled KeyParamsOrigination case for KeyRecoveryLoginFlowPrompt');
        }
    },
    KeyRecoveryLoginFlowReason: 'Your account password is required to revalidate your session.',
    KeyRecoveryLoginFlowInvalidPassword: 'Incorrect credentials entered. Please try again.',
    KeyRecoveryRootKeyReplaced: 'Your credentials have successfully been updated.',
    KeyRecoveryPasscodeRequiredTitle: 'Passcode Required',
    KeyRecoveryPasscodeRequiredText: 'You must enter your passcode in order to save your new credentials.',
    KeyRecoveryPasswordRequired: 'Your account password is required to recover an encryption key.',
    KeyRecoveryKeyRecovered: 'Your key has successfully been recovered.',
    KeyRecoveryUnableToRecover: 'Unable to recover your key with the attempted password. Please try again.',
};
