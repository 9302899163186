"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsiveAppPane = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const useAndroidBackHandler_1 = require("@/NativeMobileWeb/useAndroidBackHandler");
const react_1 = require("react");
const AppPaneMetadata_1 = require("./AppPaneMetadata");
const mobx_react_lite_1 = require("mobx-react-lite");
const PaneLayout_1 = require("@/Controllers/PaneController/PaneLayout");
const useStateRef_1 = require("@/Hooks/useStateRef");
const ResponsivePaneContext = (0, react_1.createContext)(undefined);
const useResponsiveAppPane = () => {
    const value = (0, react_1.useContext)(ResponsivePaneContext);
    if (!value) {
        throw new Error('Component must be a child of <ResponsivePaneProvider />');
    }
    return value;
};
exports.useResponsiveAppPane = useResponsiveAppPane;
const MemoizedChildren = (0, react_1.memo)(({ children }) => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
const ResponsivePaneProvider = ({ paneController, children }) => {
    const currentSelectedPane = paneController.currentPane;
    const currentSelectedPaneRef = (0, useStateRef_1.useStateRef)(currentSelectedPane);
    const toggleAppPane = (0, react_1.useCallback)((paneId) => {
        paneController.presentPane(paneId);
    }, [paneController]);
    const addAndroidBackHandler = (0, useAndroidBackHandler_1.useAndroidBackHandler)();
    (0, react_1.useEffect)(() => {
        const removeListener = addAndroidBackHandler(() => {
            if (currentSelectedPaneRef.current === AppPaneMetadata_1.AppPaneId.Editor ||
                currentSelectedPaneRef.current === AppPaneMetadata_1.AppPaneId.Navigation) {
                paneController.setPaneLayout(PaneLayout_1.PaneLayout.ItemSelection);
                return true;
            }
            else {
                return false;
            }
        });
        return () => {
            if (removeListener) {
                removeListener();
            }
        };
    }, [addAndroidBackHandler, currentSelectedPaneRef, paneController]);
    const contextValue = (0, react_1.useMemo)(() => ({
        selectedPane: currentSelectedPane,
        toggleAppPane,
        presentPane: paneController.presentPane,
        isListPaneCollapsed: paneController.isListPaneCollapsed,
        isNavigationPaneCollapsed: paneController.isNavigationPaneCollapsed,
        toggleListPane: paneController.toggleListPane,
        toggleNavigationPane: paneController.toggleNavigationPane,
        panes: paneController.panes,
        popToPane: paneController.popToPane,
        dismissLastPane: paneController.dismissLastPane,
        replacePanes: paneController.replacePanes,
        removePane: paneController.removePane,
        insertPaneAtIndex: paneController.insertPaneAtIndex,
        setPaneLayout: paneController.setPaneLayout,
        focusModeEnabled: paneController.focusModeEnabled,
    }), [
        currentSelectedPane,
        toggleAppPane,
        paneController.panes,
        paneController.isListPaneCollapsed,
        paneController.isNavigationPaneCollapsed,
        paneController.toggleListPane,
        paneController.toggleNavigationPane,
        paneController.presentPane,
        paneController.popToPane,
        paneController.dismissLastPane,
        paneController.replacePanes,
        paneController.removePane,
        paneController.insertPaneAtIndex,
        paneController.setPaneLayout,
        paneController.focusModeEnabled,
    ]);
    return ((0, jsx_runtime_1.jsx)(ResponsivePaneContext.Provider, { value: contextValue, children: (0, jsx_runtime_1.jsx)(MemoizedChildren, { children: children }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(ResponsivePaneProvider);
