"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangelogService = void 0;
const models_1 = require("@standardnotes/models");
const services_1 = require("@standardnotes/services");
const LegacyDesktopMapping_1 = require("./LegacyDesktopMapping");
const LegacyMobileMapping_1 = require("./LegacyMobileMapping");
const WebChangelogUrl = 'https://raw.githubusercontent.com/standardnotes/app/main/packages/web/CHANGELOG.md.json';
const DesktopDownloadsUrlBase = 'https://github.com/standardnotes/app/releases/tag/%40standardnotes%2Fdesktop%40';
class ChangelogService {
    constructor(environment, diskService) {
        this.environment = environment;
        this.diskService = diskService;
        this.lastReadChangeListeners = [];
    }
    addLastReadChangeListener(listener) {
        this.lastReadChangeListeners.push(listener);
        return () => {
            this.lastReadChangeListeners = this.lastReadChangeListeners.filter((l) => l !== listener);
        };
    }
    async performDownloadChangelog() {
        const response = await fetch(WebChangelogUrl);
        const changelog = await response.text();
        const parsedData = JSON.parse(changelog);
        return parsedData;
    }
    async getChangelog() {
        if (this.changeLog) {
            return this.changeLog;
        }
        this.changeLog = await this.performDownloadChangelog();
        if (this.environment !== models_1.Environment.Web) {
            const legacyMapping = this.getLegacyMapping();
            this.changeLog.versions = this.changeLog.versions.map((versionRecord) => {
                const versionString = versionRecord.version || '';
                return {
                    ...versionRecord,
                    version: legacyMapping[versionString] || versionRecord.version,
                };
            });
        }
        return this.changeLog;
    }
    markAsRead() {
        if (!this.changeLog) {
            return;
        }
        const version = this.changeLog.versions[0].version;
        this.diskService.setValue(services_1.StorageKey.LastReadChangelogVersion, version);
        if (version) {
            this.lastReadChangeListeners.forEach((listener) => listener(version));
        }
    }
    getLastReadVersion() {
        return this.diskService.getValue(services_1.StorageKey.LastReadChangelogVersion);
    }
    async getVersions() {
        const changelog = await this.getChangelog();
        return changelog.versions;
    }
    getLegacyMapping() {
        return this.environment === models_1.Environment.Desktop
            ? LegacyDesktopMapping_1.LegacyWebToDesktopVersionMapping
            : this.environment === models_1.Environment.Mobile
                ? LegacyMobileMapping_1.LegacyWebToMobileVersionMapping
                : {};
    }
    getDesktopDownloadsUrl(version) {
        return DesktopDownloadsUrlBase + version;
    }
    getDesktopVersionForWebVersion(webVersion) {
        var _a;
        return (_a = LegacyDesktopMapping_1.LegacyWebToDesktopVersionMapping[webVersion]) !== null && _a !== void 0 ? _a : webVersion;
    }
}
exports.ChangelogService = ChangelogService;
