"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptErroredPayloads = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class DecryptErroredPayloads {
    constructor(itemsEncryption, _decryptErroredRootPayloads) {
        this.itemsEncryption = itemsEncryption;
        this._decryptErroredRootPayloads = _decryptErroredRootPayloads;
    }
    async execute() {
        await this._decryptErroredRootPayloads.execute();
        await this.itemsEncryption.decryptErroredItemPayloads();
        return domain_core_1.Result.ok();
    }
}
exports.DecryptErroredPayloads = DecryptErroredPayloads;
