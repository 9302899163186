"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectionEvent = void 0;
var ProtectionEvent;
(function (ProtectionEvent) {
    ProtectionEvent["UnprotectedSessionBegan"] = "Protection:UnprotectedSessionBegan";
    ProtectionEvent["UnprotectedSessionExpired"] = "Protection:UnprotectedSessionExpired";
    ProtectionEvent["BiometricsSoftLockEngaged"] = "Protection:BiometricsSoftLockEngaged";
    ProtectionEvent["BiometricsSoftLockDisengaged"] = "Protection:BiometricsSoftLockDisengaged";
})(ProtectionEvent || (exports.ProtectionEvent = ProtectionEvent = {}));
