"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const mobx_react_lite_1 = require("mobx-react-lite");
const TwoFactorDescription = ({ auth }) => {
    if (!auth.isLoggedIn()) {
        return (0, jsx_runtime_1.jsx)(Content_1.Text, { children: "Sign in or register for an account to configure 2FA." });
    }
    return (0, jsx_runtime_1.jsx)(Content_1.Text, { children: "An extra layer of security when logging in to your account." });
};
exports.default = (0, mobx_react_lite_1.observer)(TwoFactorDescription);
