"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditContact = void 0;
class EditContact {
    constructor(mutator) {
        this.mutator = mutator;
    }
    async execute(contact, params) {
        const updatedContact = await this.mutator.changeItem(contact, (mutator) => {
            mutator.name = params.name;
            if (params.publicKey !== contact.publicKeySet.encryption ||
                params.signingPublicKey !== contact.publicKeySet.signing) {
                mutator.addPublicKey({
                    encryption: params.publicKey,
                    signing: params.signingPublicKey,
                });
            }
        });
        return updatedContact;
    }
}
exports.EditContact = EditContact;
