"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReuploadInvite = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class ReuploadInvite {
    constructor(decryptOwnMessage, sendInvite, encryptMessage) {
        this.decryptOwnMessage = decryptOwnMessage;
        this.sendInvite = sendInvite;
        this.encryptMessage = encryptMessage;
    }
    async execute(params) {
        var _a, _b;
        const decryptedPreviousInvite = this.decryptOwnMessage.execute({
            message: params.previousInvite.encrypted_message,
            privateKey: (_b = (_a = params.previousKeys) === null || _a === void 0 ? void 0 : _a.encryption.privateKey) !== null && _b !== void 0 ? _b : params.keys.encryption.privateKey,
            recipientPublicKey: params.recipient.publicKeySet.encryption,
        });
        if (decryptedPreviousInvite.isFailed()) {
            return domain_core_1.Result.fail(decryptedPreviousInvite.getError());
        }
        const encryptedMessage = this.encryptMessage.execute({
            message: decryptedPreviousInvite.getValue(),
            keys: params.keys,
            recipientPublicKey: params.recipient.publicKeySet.encryption,
        });
        if (encryptedMessage.isFailed()) {
            return domain_core_1.Result.fail(encryptedMessage.getError());
        }
        const createInviteResult = await this.sendInvite.execute({
            sharedVaultUuid: params.previousInvite.shared_vault_uuid,
            recipientUuid: params.recipient.contactUuid,
            encryptedMessage: encryptedMessage.getValue(),
            permission: params.previousInvite.permission,
        });
        return createInviteResult;
    }
}
exports.ReuploadInvite = ReuploadInvite;
