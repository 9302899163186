"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApplication = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const mobx_react_lite_1 = require("mobx-react-lite");
const ApplicationContext = (0, react_1.createContext)(undefined);
const useApplication = () => {
    const value = (0, react_1.useContext)(ApplicationContext);
    if (!value) {
        throw new Error('Component must be a child of <ApplicationProvider />');
    }
    return value;
};
exports.useApplication = useApplication;
const MemoizedChildren = (0, react_1.memo)(({ children }) => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
const ApplicationProvider = ({ application, children }) => {
    return ((0, jsx_runtime_1.jsx)(ApplicationContext.Provider, { value: application, children: (0, jsx_runtime_1.jsx)(MemoizedChildren, { children: children }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(ApplicationProvider);
