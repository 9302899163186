"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateReadTime = void 0;
const calculateReadTime = (words) => {
    const timeToRead = Math.round(words / 200);
    if (timeToRead === 0) {
        return '< 1 minute';
    }
    else {
        return `${timeToRead} ${timeToRead > 1 ? 'minutes' : 'minute'}`;
    }
};
exports.calculateReadTime = calculateReadTime;
