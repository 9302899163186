"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptionOperators = void 0;
const common_1 = require("@standardnotes/common");
const Functions_1 = require("./Functions");
class EncryptionOperators {
    constructor(crypto) {
        this.crypto = crypto;
        this.operators = {};
        this.crypto = crypto;
    }
    deinit() {
        ;
        this.crypto = undefined;
        this.operators = {};
    }
    operatorForVersion(version) {
        const operatorKey = version;
        let operator = this.operators[operatorKey];
        if (!operator) {
            operator = (0, Functions_1.createOperatorForVersion)(version, this.crypto);
            this.operators[operatorKey] = operator;
        }
        return operator;
    }
    /**
     * Returns the operator corresponding to the latest protocol version
     */
    defaultOperator() {
        return this.operatorForVersion(common_1.ProtocolVersionLatest);
    }
}
exports.EncryptionOperators = EncryptionOperators;
