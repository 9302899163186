"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsNativeMobileWeb = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const models_1 = require("@standardnotes/models");
class IsNativeMobileWeb {
    constructor(environment) {
        this.environment = environment;
    }
    execute() {
        return domain_core_1.Result.ok(this.environment === models_1.Environment.Mobile);
    }
}
exports.IsNativeMobileWeb = IsNativeMobileWeb;
