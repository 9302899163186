"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isYouTubeNode = exports.$createYouTubeNode = exports.YouTubeNode = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const LexicalBlockWithAlignableContents_1 = require("@lexical/react/LexicalBlockWithAlignableContents");
const LexicalDecoratorBlockNode_1 = require("@lexical/react/LexicalDecoratorBlockNode");
function YouTubeComponent({ className, format, nodeKey, videoID }) {
    return ((0, jsx_runtime_1.jsx)(LexicalBlockWithAlignableContents_1.BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey, children: (0, jsx_runtime_1.jsx)("iframe", { width: "560", height: "315", src: `https://www.youtube.com/embed/${videoID}`, frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true, title: "YouTube video" }) }));
}
function convertYoutubeElement(domNode) {
    const videoID = domNode.getAttribute('data-lexical-youtube');
    if (videoID) {
        const node = $createYouTubeNode(videoID);
        return { node };
    }
    return null;
}
class YouTubeNode extends LexicalDecoratorBlockNode_1.DecoratorBlockNode {
    static getType() {
        return 'youtube';
    }
    static clone(node) {
        return new YouTubeNode(node.__id, node.__format, node.__key);
    }
    static importJSON(serializedNode) {
        const node = $createYouTubeNode(serializedNode.videoID);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'youtube',
            version: 1,
            videoID: this.__id,
        };
    }
    exportDOM() {
        const element = document.createElement('iframe');
        element.setAttribute('data-lexical-youtube', this.__id);
        element.setAttribute('width', '560');
        element.setAttribute('height', '315');
        element.setAttribute('src', `https://www.youtube.com/embed/${this.__id}`);
        element.setAttribute('frameborder', '0');
        element.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        element.setAttribute('allowfullscreen', 'true');
        element.setAttribute('title', 'YouTube video');
        return { element };
    }
    static importDOM() {
        return {
            iframe: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-youtube')) {
                    return null;
                }
                return {
                    conversion: convertYoutubeElement,
                    priority: 1,
                };
            },
        };
    }
    constructor(id, format, key) {
        super(format, key);
        this.__id = id;
    }
    updateDOM() {
        return false;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `https://www.youtube.com/watch?v=${this.__id}`;
    }
    decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (0, jsx_runtime_1.jsx)(YouTubeComponent, { className: className, format: this.__format, nodeKey: this.getKey(), videoID: this.__id });
    }
    isInline() {
        return false;
    }
}
exports.YouTubeNode = YouTubeNode;
function $createYouTubeNode(videoID) {
    return new YouTubeNode(videoID);
}
exports.$createYouTubeNode = $createYouTubeNode;
function $isYouTubeNode(node) {
    return node instanceof YouTubeNode;
}
exports.$isYouTubeNode = $isYouTubeNode;
