"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFilePassword = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const Challenge_1 = require("../Challenge");
const Strings_1 = require("./Strings");
class GetFilePassword {
    constructor(challenges) {
        this.challenges = challenges;
    }
    async execute() {
        const challenge = new Challenge_1.Challenge([new Challenge_1.ChallengePrompt(Challenge_1.ChallengeValidation.None, Strings_1.Strings.FileAccountPassword, undefined, true)], Challenge_1.ChallengeReason.DecryptEncryptedFile, true);
        const passwordResponse = await this.challenges.promptForChallengeResponse(challenge);
        if (passwordResponse == undefined) {
            return domain_core_1.Result.fail('Import aborted due to canceled password prompt');
        }
        this.challenges.completeChallenge(challenge);
        return domain_core_1.Result.ok(passwordResponse === null || passwordResponse === void 0 ? void 0 : passwordResponse.values[0].value);
    }
}
exports.GetFilePassword = GetFilePassword;
