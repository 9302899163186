"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricStringGetAdditionalData004 = void 0;
const ParseConsistentBase64JsonPayload_1 = require("../Utils/ParseConsistentBase64JsonPayload");
const domain_core_1 = require("@standardnotes/domain-core");
class AsymmetricStringGetAdditionalData004 {
    constructor(crypto) {
        this.crypto = crypto;
        this.parseBase64Usecase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(dto) {
        const [_, __, ___, additionalDataString] = dto.encryptedString.split(':');
        const additionalData = this.parseBase64Usecase.execute(additionalDataString);
        return domain_core_1.Result.ok(additionalData);
    }
}
exports.AsymmetricStringGetAdditionalData004 = AsymmetricStringGetAdditionalData004;
