"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVaultContacts = void 0;
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
class GetVaultContacts {
    constructor(_findContact, _getVaultUsers) {
        this._findContact = _findContact;
        this._getVaultUsers = _getVaultUsers;
    }
    async execute(dto) {
        const users = await this._getVaultUsers.execute({
            sharedVaultUuid: dto.sharedVaultUuid,
            readFromCache: dto.readFromCache,
        });
        if (users.isFailed()) {
            return domain_core_1.Result.fail('Failed to get vault users');
        }
        const contacts = users
            .getValue()
            .map((user) => this._findContact.execute({ userUuid: user.user_uuid }))
            .map((result) => (result.isFailed() ? undefined : result.getValue()))
            .filter(utils_1.isNotUndefined);
        return domain_core_1.Result.ok(contacts);
    }
}
exports.GetVaultContacts = GetVaultContacts;
