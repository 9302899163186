"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfinteScroller = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Logging_1 = require("@/Logging");
exports.InfinteScroller = (0, react_1.forwardRef)(({ children, paginateFront, paginateEnd, direction = 'vertical', onElementVisibility, className, isMobileScreen, }, ref) => {
    const frontSentinel = (0, react_1.useRef)(null);
    const endSentinel = (0, react_1.useRef)(null);
    const [ignoreFirstFrontSentinelEvent, setIgnoreFirstFrontSentinelEvent] = (0, react_1.useState)(true);
    const [needsMobilePaginationFix, setNeedsMobilePaginationFix] = (0, react_1.useState)(false);
    const scrollArea = (0, react_1.useRef)(null);
    const [scrollSize, setScrollSize] = (0, react_1.useState)(0);
    const [didPaginateFront, setDidPaginateFront] = (0, react_1.useState)(false);
    (0, react_1.useImperativeHandle)(ref, () => ({
        scrollToElementId(id) {
            scrollToElementId(id);
        },
    }));
    const visibilityObserver = (0, react_1.useMemo)(() => new IntersectionObserver((entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting);
        if (visibleEntry) {
            onElementVisibility === null || onElementVisibility === void 0 ? void 0 : onElementVisibility(visibleEntry.target.id);
        }
    }, { threshold: 1.0 }), [onElementVisibility]);
    (0, react_1.useEffect)(() => {
        const childElements = Array.from(scrollArea.current.children);
        for (const child of childElements) {
            visibilityObserver.observe(child);
        }
        return () => {
            for (const child of childElements) {
                visibilityObserver.unobserve(child);
            }
        };
    }, [visibilityObserver, children]);
    const scrollToElementId = (0, react_1.useCallback)((id) => {
        const element = document.getElementById(id);
        if (!element) {
            (0, Logging_1.log)(Logging_1.LoggingDomain.DailyNotes, 'Element not found', id);
            return;
        }
        (0, Logging_1.log)(Logging_1.LoggingDomain.DailyNotes, 'Scrolling to element', id);
        element.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
        });
    }, []);
    (0, react_1.useLayoutEffect)(() => {
        if (!scrollArea.current) {
            return;
        }
        if (didPaginateFront) {
            if (direction === 'vertical') {
                scrollArea.current.scrollTop += scrollArea.current.scrollHeight - scrollSize;
                if (isMobileScreen) {
                    setNeedsMobilePaginationFix(true);
                }
            }
            else {
                scrollArea.current.scrollLeft += scrollArea.current.scrollWidth - scrollSize;
            }
            setDidPaginateFront(false);
        }
    }, [didPaginateFront, scrollSize, direction, isMobileScreen]);
    (0, react_1.useLayoutEffect)(() => {
        /**
         * iOS Safari has an issue rendering paginated items from the top where the new
         * scrolled to area is white until the user interacts with scroll again. The fix
         * we apply is to re-set scrollTop to its same value to trigger a refresh.
         * https://stackoverflow.com/questions/9807620
         */
        if (needsMobilePaginationFix) {
            setTimeout(() => {
                if (!scrollArea.current) {
                    return;
                }
                (0, Logging_1.log)(Logging_1.LoggingDomain.DailyNotes, '[InfiniteScroller] Applying mobile pagination fix');
                scrollArea.current.scrollTop += scrollArea.current.scrollHeight - scrollSize;
                setNeedsMobilePaginationFix(false);
            }, 50);
        }
    }, [needsMobilePaginationFix, scrollSize]);
    const _paginateFront = (0, react_1.useCallback)(() => {
        if (direction === 'vertical') {
            setScrollSize(scrollArea.current.scrollHeight);
        }
        else {
            setScrollSize(scrollArea.current.scrollWidth);
        }
        setDidPaginateFront(true);
        paginateFront();
    }, [paginateFront, direction]);
    const _paginateEnd = (0, react_1.useCallback)(() => {
        paginateEnd();
    }, [paginateEnd]);
    const frontObserver = (0, react_1.useMemo)(() => new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            if (ignoreFirstFrontSentinelEvent) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.DailyNotes, '[InfiniteScroller] Ignoring first front sentinel event');
                setIgnoreFirstFrontSentinelEvent(false);
                return;
            }
            _paginateFront();
        }
    }, { threshold: 0.5 }), [_paginateFront, ignoreFirstFrontSentinelEvent]);
    (0, react_1.useEffect)(() => {
        if (frontSentinel.current) {
            frontObserver.observe(frontSentinel.current);
        }
    }, [frontObserver, frontSentinel]);
    const endObserver = (0, react_1.useMemo)(() => new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            _paginateEnd();
        }
    }, { threshold: 0.5 }), [_paginateEnd]);
    (0, react_1.useEffect)(() => {
        if (endSentinel.current) {
            endObserver.observe(endSentinel.current);
        }
    }, [endObserver, endSentinel]);
    return ((0, jsx_runtime_1.jsxs)("div", { ref: scrollArea, className: className, style: {
            overflowY: 'scroll',
            flexDirection: direction === 'vertical' ? 'column' : 'row',
        }, children: [(0, jsx_runtime_1.jsx)("div", { style: { width: 1, height: 1, backgroundColor: 'transparent' }, ref: frontSentinel }), children, (0, jsx_runtime_1.jsx)("div", { style: { width: 1, height: 1, backgroundColor: 'transparent' }, ref: endSentinel })] }));
});
