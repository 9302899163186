"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscardItemsLocally = void 0;
const utils_1 = require("@standardnotes/utils");
class DiscardItemsLocally {
    constructor(items, storage) {
        this.items = items;
        this.storage = storage;
    }
    async execute(items) {
        this.items.removeItemsFromMemory(items);
        await this.storage.deletePayloadsWithUuids((0, utils_1.Uuids)(items));
    }
}
exports.DiscardItemsLocally = DiscardItemsLocally;
