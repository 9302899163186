"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsVaultOwner = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class IsVaultOwner {
    constructor(users) {
        this.users = users;
    }
    execute(vault) {
        if (!vault.sharing) {
            return domain_core_1.Result.ok(true);
        }
        if (!vault.sharing.ownerUserUuid) {
            throw new Error(`Shared vault ${vault.sharing.sharedVaultUuid} does not have an owner user uuid`);
        }
        const user = this.users.sureUser;
        return domain_core_1.Result.ok(vault.sharing.ownerUserUuid === user.uuid);
    }
}
exports.IsVaultOwner = IsVaultOwner;
