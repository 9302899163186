"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyParamsFromApiResponse = exports.CreateAnyKeyParams = exports.Create004KeyParams = exports.Create003KeyParams = exports.Create002KeyParams = exports.Create001KeyParams = void 0;
const ProtocolVersionForKeyParams_1 = require("./ProtocolVersionForKeyParams");
const RootKeyParams_1 = require("./RootKeyParams");
function Create001KeyParams(keyParams) {
    return CreateAnyKeyParams(keyParams);
}
exports.Create001KeyParams = Create001KeyParams;
function Create002KeyParams(keyParams) {
    return CreateAnyKeyParams(keyParams);
}
exports.Create002KeyParams = Create002KeyParams;
function Create003KeyParams(keyParams) {
    return CreateAnyKeyParams(keyParams);
}
exports.Create003KeyParams = Create003KeyParams;
function Create004KeyParams(keyParams) {
    return CreateAnyKeyParams(keyParams);
}
exports.Create004KeyParams = Create004KeyParams;
function CreateAnyKeyParams(keyParams) {
    if ('content' in keyParams) {
        throw Error('Raw key params shouldnt have content; perhaps you passed in a SNRootKeyParams object.');
    }
    return new RootKeyParams_1.SNRootKeyParams(keyParams);
}
exports.CreateAnyKeyParams = CreateAnyKeyParams;
function KeyParamsFromApiResponse(response, identifier) {
    const rawKeyParams = {
        identifier: identifier || response.identifier,
        pw_cost: response.pw_cost,
        pw_nonce: response.pw_nonce,
        pw_salt: response.pw_salt,
        version: (0, ProtocolVersionForKeyParams_1.ProtocolVersionForKeyParams)(response),
        origination: response.origination,
        created: response.created,
    };
    return CreateAnyKeyParams(rawKeyParams);
}
exports.KeyParamsFromApiResponse = KeyParamsFromApiResponse;
